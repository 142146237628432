import React, {useEffect} from 'react'
import Typography from '@material-ui/core/Typography'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import {Trans} from '@lingui/macro'
import PaperFlexGrow from 'component/material/PaperFlexGrow'
import BoxFullWidth from 'component/material/BoxFullWidth'
import PropTypes from 'prop-types'
import {bindActionCreators, compose} from 'redux'
import {loginCallback} from 'redux/action/authAction'
import {connect} from 'react-redux'
import {useHistory} from 'react-router-dom'
import CircularProgress from '@material-ui/core/CircularProgress'
import Box from '@material-ui/core/Box'
import {redirectTo} from 'helper/functions'

const LoginCallbackPage = (props) => {
  const {loginCallback} = props
  let history = useHistory()

  useEffect(() => {
    loginCallback(history.location.search).then(() => redirectTo('/dashboard'))
  }, [])

  return (
    <GridContainer direction="row" justifyContent="center" alignItems="center">
      <GridItem xs={'auto'}>
        <PaperFlexGrow elevation={5}>
          <BoxFullWidth p={14}>
            <BoxFullWidth pb={7}>
              <Typography variant="h4" color="primary" align="center" gutterBottom>
                <Trans>Eluvia business tools</Trans>
              </Typography>
            </BoxFullWidth>
            <BoxFullWidth pt={1}>
              <GridContainer justifyContent={'center'} alignItems={'center'}>
                <Box pr={4}>
                  <Typography variant={'body1'} color={'primary'}>
                    <Trans>Login in progress</Trans>
                  </Typography>
                </Box>
                <CircularProgress color="secondary" />
              </GridContainer>
            </BoxFullWidth>
          </BoxFullWidth>
        </PaperFlexGrow>
      </GridItem>
    </GridContainer>
  )
}

LoginCallbackPage.propTypes = {
  loginCallback: PropTypes.func.isRequired,
  classes: PropTypes.object,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      loginCallback,
    },
    dispatch
  )
}

export default compose(connect(null, mapDispatchToProps))(LoginCallbackPage)
