import React, {useEffect} from 'react'
import {Field, Form} from 'react-final-form'
import PropTypes from 'prop-types'
import {dateFromToValidation, required} from 'helper/validations'
import {Trans} from '@lingui/macro'
import SelectInput from 'component/field/SelectInput'
import DatePickerInput from 'component/field/DatePickerInput'
import BoxFullWidth from 'component/material/BoxFullWidth'
import PrimaryButton from 'component/material/PrimaryButton'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import {DB_DATE_FORMAT, LANGUAGE_OPTIONS, REPORT_VERSION} from 'helper/constants'
import {withStyles} from '@material-ui/core'
import projektomatPageStyle from 'component/ProjektomatPage/projektomatPageStyle'
import {getProjectList, postWorkReport} from 'redux/action/projektomatAction'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import moment from 'moment'
import {mapErrorResponseToForm} from 'helper/functions'

const NewWorkReportForm = (props) => {
  const {
    classes,
    onClose,
    projectList,
    getProjectList,
    projectsLoading,
    postWorkReport,
    setNewAssetCreated,
  } = props

  const prepareValues = (values) => {
    return {
      ...values,
      date_from: values.date_from && moment(values.date_from).format(DB_DATE_FORMAT),
      date_to: values.date_from && moment(values.date_to).format(DB_DATE_FORMAT),
    }
  }

  const handleSubmit = (values) => {
    const preparedValues = prepareValues(values)
    return postWorkReport(preparedValues)
      .then(() => {
        onClose()
        setNewAssetCreated(true)
      })
      .catch((err) => {
        return mapErrorResponseToForm(err)
      })
  }

  useEffect(() => {
    getProjectList(1000, 0, false, false, null, {orderBy: 'ref', orderDirection: 'asc'})
  }, [])

  return (
    <Form
      onSubmit={handleSubmit}
      validate={dateFromToValidation}
      initialValues={{
        date_from: moment().startOf('month').format(DB_DATE_FORMAT),
        date_to: moment().endOf('month').format(DB_DATE_FORMAT),
        language: 'CZ',
        version: 'full',
      }}
    >
      {(formProps) => {
        return (
          <form onSubmit={formProps.handleSubmit}>
            <GridContainer spacing={2}>
              <GridItem xs={12}>
                <Field
                  name="project_id"
                  label={<Trans>Project</Trans>}
                  component={SelectInput}
                  options={projectList.map((project) => ({
                    ...project,
                    name: `${project.name}`,
                  }))}
                  validate={!formProps.submitSucceeded && required}
                  loading={projectsLoading}
                  disabled={formProps.submitting}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <Field
                  name="date_from"
                  label={<Trans>Date from</Trans>}
                  component={DatePickerInput}
                  validate={!formProps.submitSucceeded && required}
                  disabled={formProps.submitting}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <Field
                  name="date_to"
                  label={<Trans>Date to</Trans>}
                  component={DatePickerInput}
                  validate={!formProps.submitSucceeded && required}
                  minDate={formProps.values?.date_from}
                  disabled={formProps.submitting}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <Field
                  name="language"
                  label={<Trans>Language</Trans>}
                  component={SelectInput}
                  options={LANGUAGE_OPTIONS}
                  validate={!formProps.submitSucceeded && required}
                  disabled={formProps.submitting}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <Field
                  name="version"
                  label={<Trans>Version</Trans>}
                  component={SelectInput}
                  options={REPORT_VERSION}
                  validate={!formProps.submitSucceeded && required}
                  disabled={formProps.submitting}
                />
              </GridItem>
            </GridContainer>
            <BoxFullWidth pt={3}>
              <GridContainer justifyContent={'flex-end'}>
                <PrimaryButton
                  fullWidth={false}
                  variant={'outlined'}
                  text={<Trans>Cancel</Trans>}
                  className={classes.cancelButtonPadding}
                  onClick={onClose}
                  disabled={formProps.submitting}
                />
                <PrimaryButton
                  fullWidth={false}
                  type="submit"
                  text={<Trans>Create</Trans>}
                  disabled={formProps.submitting}
                />
              </GridContainer>
            </BoxFullWidth>
          </form>
        )
      }}
    </Form>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getProjectList,
      postWorkReport,
    },
    dispatch
  )
}

NewWorkReportForm.propTypes = {
  onClose: PropTypes.func,
  classes: PropTypes.object,
  projectList: PropTypes.array,
  getProjectList: PropTypes.func,
  postWorkReport: PropTypes.func,
  projectsLoading: PropTypes.bool,
  setNewAssetCreated: PropTypes.func,
}

export default compose(
  withStyles(projektomatPageStyle),
  connect((store) => {
    return {
      projectList: store.projektomat.projects,
      projectsLoading: store.projektomat.projectsLoading,
    }
  }, mapDispatchToProps)
)(NewWorkReportForm)
