import {useParams} from 'react-router-dom'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import React, {useEffect, useState} from 'react'
import {deleteQuoteRow, getQuote, getQuoteRows, putExpandRow} from 'redux/action/odhadomatAction'
import {Add, Delete, Edit, PlaylistAdd} from '@material-ui/icons'
import {Tooltip, withStyles} from '@material-ui/core'
import {Trans} from '@lingui/macro'
import PrivateContentLayout from 'layout/PrivateLayout/PrivateContentLayout'
import Table from 'component/material/table/Table'
import {useTableSort} from 'hooks/useTableSort'
import GridContainer from 'component/material/GridContainer'
import NumberFormat from 'react-number-format'
import GridItem from 'component/material/GridItem'
import TableIconButton from 'component/general/TableIconButton'
import DialogWindow from 'component/material/DialogWindow'
import NabidkaRowForm from 'component/OdhadomatPage/Nabidky/NabidkaRowForm'
import PrimaryButton from 'component/material/PrimaryButton'
import Box from '@material-ui/core/Box'
import odhadyStyle from 'component/OdhadomatPage/odhadyStyle'
import LockIcon from '@material-ui/icons/Lock'
import Breadcrumbs from 'component/general/Breadcrumbs'
import {useEstimation} from 'hooks/useEstimation'
import {useWarningDialog} from 'hooks/useWarningDialog'

const NabidkaDetail = (props) => {
  const {
    classes,
    getQuote,
    getQuoteRows,
    quoteRows,
    quoteRowsLoading,
    quote,
    putExpandRow,
    deleteQuoteRow,
  } = props

  const {id, nabidkaId} = useParams()

  const {estimation} = useEstimation()
  const [DeleteWarningDialog, deleteData, onOpenDelete, onCloseDelete] = useWarningDialog()
  const [ExpandWarningDialog, expandData, onOpenExpand, onCloseExpand] = useWarningDialog()
  const [tableSort, onSortClick] = useTableSort({orderBy: 'category', orderDirection: 'asc'})
  const [rowDialog, setRowDialog] = useState({visible: false, row: null})

  const handleOpenRowDialog = (row) => () => {
    setRowDialog({visible: true, row: row})
  }

  const handleCloseRowDialog = () => {
    setRowDialog({visible: false, row: null})
  }

  const handleDeleteRow = () => {
    deleteQuoteRow(id, nabidkaId, deleteData?.id).then(() => {
      onCloseDelete()
      fetchQuoteRows()
    })
  }

  const handleExpandRow = () => {
    putExpandRow(id, nabidkaId, expandData?.id).then(() => {
      onCloseExpand()
      fetchQuoteRows()
    })
  }

  const columns = [
    {
      name: 'category',
      orderKey: 'category',
      label: <Trans>Category</Trans>,
      render: (val, col, rowIndex, row) => {
        const indentationLevel = val.split(' > ')?.length
        return row.id && indentationLevel !== 1 ? (
          <span style={{paddingLeft: (indentationLevel - 1) * 40 || 0}}>
            {val.split(' > ')?.[indentationLevel - 1]}
          </span>
        ) : (
          <span className={!row.id ? classes.boldText : ''}>{val}</span>
        )
      },
    },
    {
      name: 'estimated_mds',
      align: 'right',
      label: <Trans>Estimation</Trans>,
      render: (val, col, rowIndex, row) => {
        const value = row.is_fixed ? row.fixed_estimated_mds : val
        return (
          <NumberFormat thousandSeparator={' '} displayType="text" value={value} suffix={' MD'} />
        )
      },
    },
    {
      name: 'price_from',
      align: 'right',
      label: <Trans>Price from</Trans>,
      render: (val, col, rowIndex, row) => {
        const value = row.is_fixed ? row.fixed_price_from : val
        return (
          <NumberFormat
            thousandSeparator={' '}
            displayType="text"
            suffix={' Kč'}
            value={value && Number(value / 100)}
          />
        )
      },
    },
    {
      name: 'price_to',
      align: 'right',
      label: <Trans>Price to</Trans>,
      render: (val, col, rowIndex, row) => {
        const value = row.is_fixed ? row.fixed_price_to : val
        return (
          <NumberFormat
            thousandSeparator={' '}
            displayType="text"
            suffix={' Kč'}
            value={value && Number(value / 100)}
          />
        )
      },
    },
    {
      name: 'is_fixed',
      align: 'right',
      render: (val, col, rowIndex, row) =>
        row.id &&
        val && (
          <span style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
            <Tooltip placement={'top'} title={<Trans>Prices are fixed</Trans>}>
              <LockIcon fontSize={'medium'} color={'primary'} />
            </Tooltip>
          </span>
        ),
    },
    {
      name: '',
      align: 'right',
      render: (val, col, rowIndex, row) =>
        row.id && (
          <GridContainer spacing={2} direction={'row'} wrap={'nowrap'} justifyContent={'flex-end'}>
            <GridItem>
              <TableIconButton
                tooltipTitle={<Trans>Edit row</Trans>}
                onClick={handleOpenRowDialog(row)}
                Icon={Edit}
              />
            </GridItem>
            <GridItem>
              <TableIconButton
                tooltipTitle={<Trans>Expand row</Trans>}
                onClick={onOpenExpand(row)}
                Icon={PlaylistAdd}
              />
            </GridItem>
            <GridItem>
              <TableIconButton
                tooltipTitle={<Trans>Delete row</Trans>}
                onClick={onOpenDelete(row)}
                Icon={Delete}
              />
            </GridItem>
          </GridContainer>
        ),
    },
  ]

  const goThroughRecord = (index, array, newArray) => {
    if (index < array.length) {
      const record = array[index]

      const splitCategory = record?.category?.split(' > ')
      const splitCategoryLength = splitCategory?.length

      // last element from category is always added so we dont need to manipulate with it
      splitCategoryLength >= 2 && splitCategory.pop()

      // first row to add
      if (index === 0 && splitCategory.length > 1) {
        splitCategory?.forEach((cat, catIndex) => {
          newArray.push({category: splitCategory?.slice(0, catIndex + 1)?.join(' > ')})
        })
        //  if there is more than one level of category
      } else if (splitCategoryLength !== 1) {
        splitCategory?.forEach((cat, catIndex) => {
          // check if elements are not already in new array
          if (
            newArray.every(
              (rec) => splitCategory?.slice(0, catIndex + 1)?.join(' > ') !== rec.category
            )
          ) {
            newArray?.push({category: splitCategory?.slice(0, catIndex + 1)?.join(' > ')})
          }
        })
      }

      // add last category element
      newArray?.push(record)

      return goThroughRecord(index + 1, array, newArray)
    } else {
      return newArray
    }
  }

  const prepareDataForTable = (data) => {
    return goThroughRecord(0, data, [])
  }

  const fetchQuoteRows = () => {
    getQuoteRows(id, nabidkaId, tableSort)
  }

  useEffect(() => {
    getQuote(id, nabidkaId)
    fetchQuoteRows()
  }, [tableSort])

  return (
    <>
      {/* NEW/EDIT ROW */}
      <DialogWindow
        open={rowDialog.visible}
        onClose={handleCloseRowDialog}
        title={rowDialog.row?.id ? <Trans>Edit row</Trans> : <Trans>New row</Trans>}
        maxWidth={'sm'}
        fullWidth={true}
      >
        <NabidkaRowForm
          initValues={rowDialog.row}
          handleClose={handleCloseRowDialog}
          fetchQuoteRows={fetchQuoteRows}
        />
      </DialogWindow>

      {/* DELETE DIALOG */}
      <DeleteWarningDialog
        onConfirm={handleDeleteRow}
        message={<Trans>Row {deleteData?.category} will be deleted!</Trans>}
      />

      {/* EXPAND ROW */}
      <ExpandWarningDialog
        onConfirm={handleExpandRow}
        message={<Trans>Row {expandData?.category} will be expanded!</Trans>}
      />

      <PrivateContentLayout
        title={
          <Breadcrumbs
            breadcrumbs={[
              {title: <Trans>Estimations</Trans>, url: '/odhadomat/odhady'},
              {title: estimation?.title, url: `/odhadomat/odhady/${id}`},
              {title: <Trans>Quotes</Trans>, url: `/odhadomat/odhady/${id}/nabidky`},
              {title: quote?.title},
            ]}
          />
        }
        actions={
          <PrimaryButton
            fullWidth={false}
            text={
              <GridContainer alignItems={'center'}>
                <Box pr={2}>
                  <Add fontSize={'small'} />
                </Box>
                <Trans>Add</Trans>
              </GridContainer>
            }
            onClick={handleOpenRowDialog(null)}
          />
        }
        content={
          <Table
            columns={columns}
            data={prepareDataForTable(quoteRows)}
            loading={quoteRowsLoading}
            orderBy={tableSort.orderBy}
            orderDirection={tableSort.orderDirection}
            onSortClick={onSortClick}
          />
        }
      />
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getQuoteRows,
      getQuote,
      putExpandRow,
      deleteQuoteRow,
    },
    dispatch
  )
}

export default compose(
  withStyles(odhadyStyle),
  connect((store) => {
    return {
      quoteRows: store.odhadomat.quoteRows,
      quoteRowsLoading: store.odhadomat.quoteRowsLoading,
      quoteRowsMeta: store.odhadomat.quoteRowsMeta,
      quote: store.odhadomat.quote,
      quoteLoading: store.odhadomat.quoteLoading,
    }
  }, mapDispatchToProps)
)(NabidkaDetail)
