import {
  GET_ACCOMPANYING_WORKS_FAIL,
  GET_ACCOMPANYING_WORKS_REQUEST,
  GET_ACCOMPANYING_WORKS_SUCCESS,
  GET_ESTIMATION_FAIL,
  GET_ESTIMATION_LIST_FAIL,
  GET_ESTIMATION_LIST_REQUEST,
  GET_ESTIMATION_LIST_SUCCESS,
  GET_ESTIMATION_REQUEST,
  GET_ESTIMATION_SUCCESS,
  GET_PHASES_FAIL,
  GET_PHASES_REQUEST,
  GET_PHASES_SUCCESS,
  GET_QUOTE_FAIL,
  GET_QUOTE_REQUEST,
  GET_QUOTE_ROWS_FAIL,
  GET_QUOTE_ROWS_REQUEST,
  GET_QUOTE_ROWS_SUCCESS,
  GET_QUOTE_SUCCESS,
  GET_QUOTES_FAIL,
  GET_QUOTES_REQUEST,
  GET_QUOTES_SUCCESS,
  GET_SCHEDULE_FAIL,
  GET_SCHEDULE_REQUEST,
  GET_SCHEDULE_SUCCESS,
  GET_STORIES_FAIL,
  GET_STORIES_REQUEST,
  GET_STORIES_SUCCESS,
  UPDATE_GLOBAL_LOADING,
} from 'redux/actionType'
import httpClient from 'helper/httpClient'
import endpoints from 'helper/endpoints'
import {fireSuccessToast, globalApiErrorHandler} from 'helper/functions'
import {Trans} from '@lingui/macro'
import React from 'react'

export const getEstimationList = (limit = 1000, offset = 0, filters) => {
  return (dispatch) => {
    dispatch({type: GET_ESTIMATION_LIST_REQUEST})
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .get(endpoints.estimations, {
        limit: limit,
        offset: offset,
        order_by:
          filters?.orderBy &&
          filters?.orderDirection &&
          `${filters?.orderBy}:${filters?.orderDirection}`,
      })
      .then((res) => {
        dispatch({type: GET_ESTIMATION_LIST_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_ESTIMATION_LIST_FAIL})
        globalApiErrorHandler(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const createEstimation = (data) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .post(endpoints.estimations, data)
      .then((res) => {
        fireSuccessToast(<Trans>Estimation created.</Trans>)
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        if (err.response?.status === 422 && err.response?.data?.errors?.length) {
          return Promise.reject(err?.response?.data?.errors)
        } else {
          return globalApiErrorHandler(err)
        }
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const deleteEstimation = (estimationId) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .del(endpoints.estimations + '/' + estimationId)
      .then((res) => {
        fireSuccessToast(<Trans>Estimation deleted.</Trans>)
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        globalApiErrorHandler(err)
        return Promise.reject(err?.response?.data?.errors)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const patchEstimation = (estimationId, data) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .patch(endpoints.estimations + '/' + estimationId, data)
      .then((res) => {
        fireSuccessToast(<Trans>Estimation updated.</Trans>)
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        if (err.response?.status === 422 && err.response?.data?.errors?.length) {
          return Promise.reject(err?.response?.data?.errors)
        } else {
          return globalApiErrorHandler(err)
        }
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const getEstimationDetail = (estimationId) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    dispatch({type: GET_ESTIMATION_REQUEST})
    return httpClient
      .get(endpoints.estimations + '/' + estimationId)
      .then((res) => {
        dispatch({type: GET_ESTIMATION_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_ESTIMATION_FAIL})
        globalApiErrorHandler(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const getStories = (estimationId, filters, limit = 1000, offset = 0) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    dispatch({type: GET_STORIES_REQUEST})
    return httpClient
      .get(`${endpoints.estimations}/${estimationId}/stories`, {
        limit: limit,
        offset: offset,
        order_by:
          filters?.orderBy &&
          filters?.orderDirection &&
          `${filters?.orderBy}:${filters?.orderDirection}`,
      })
      .then((res) => {
        dispatch({type: GET_STORIES_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_STORIES_FAIL})
        globalApiErrorHandler(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const postStory = (estimationId, data) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .post(`${endpoints.estimations}/${estimationId}/stories`, data)
      .then((res) => {
        fireSuccessToast(<Trans>Story created.</Trans>)
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        if (err.response?.status === 422 && err.response?.data?.errors?.length) {
          return Promise.reject(err?.response?.data?.errors)
        } else {
          return globalApiErrorHandler(err)
        }
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const patchStory = (estimationId, storyId, data) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .patch(`${endpoints.estimations}/${estimationId}/stories/${storyId}`, data)
      .then((res) => {
        fireSuccessToast(<Trans>Story updated.</Trans>)
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        if (err.response?.status === 422 && err.response?.data?.errors?.length) {
          return Promise.reject(err?.response?.data?.errors)
        } else {
          return globalApiErrorHandler(err)
        }
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const deleteStory = (estimationId, storyId) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .del(`${endpoints.estimations}/${estimationId}/stories/${storyId}`)
      .then((res) => {
        fireSuccessToast(<Trans>Story deleted.</Trans>)
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        globalApiErrorHandler(err)
        return Promise.reject(err?.response?.data?.errors)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const patchStoryLayer = (estimationId, storyId, layerId, data) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .patch(`${endpoints.estimations}/${estimationId}/stories/${storyId}/layers/${layerId}`, data)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        if (err.response?.status === 422 && err.response?.data?.errors?.length) {
          return Promise.reject(err?.response?.data?.errors)
        } else {
          return globalApiErrorHandler(err)
        }
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

// START ACCOMPANYING WORK
export const getAccompanyingWorks = (estimationId, limit = 1000, offset = 0) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    dispatch({type: GET_ACCOMPANYING_WORKS_REQUEST})
    return httpClient
      .get(`${endpoints.estimations}/${estimationId}/accompanying-works`, {
        limit: limit,
        offset: offset,
      })
      .then((res) => {
        dispatch({type: GET_ACCOMPANYING_WORKS_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_ACCOMPANYING_WORKS_FAIL})
        globalApiErrorHandler(err)
        return Promise.reject(err?.response?.data?.errors)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}
export const postAccompanyingWork = (estimationId, data) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .post(`${endpoints.estimations}/${estimationId}/accompanying-works`, data)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        if (err.response?.status === 422 && err.response?.data?.errors?.length) {
          return Promise.reject(err?.response?.data?.errors)
        } else {
          return globalApiErrorHandler(err)
        }
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const patchAccompanyingWork = (estimationId, accompanyingWorkId, data) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .patch(
        `${endpoints.estimations}/${estimationId}/accompanying-works/${accompanyingWorkId}`,
        data
      )
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        if (err.response?.status === 422 && err.response?.data?.errors?.length) {
          return Promise.reject(err?.response?.data?.errors)
        } else {
          return globalApiErrorHandler(err)
        }
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const deleteAccompanyingWork = (estimationId, accompanyingWorkId) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .del(`${endpoints.estimations}/${estimationId}/accompanying-works/${accompanyingWorkId}`)
      .then((res) => {
        fireSuccessToast(<Trans>Accompanying work deleted.</Trans>)
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        globalApiErrorHandler(err)
        return Promise.reject(err?.response?.data?.errors)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}
// END ACCOMPANYING WORK

// START SCHEDULE
export const getSchedule = (estimationId) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    dispatch({type: GET_SCHEDULE_REQUEST})
    return httpClient
      .get(`${endpoints.estimations}/${estimationId}/schedule`)
      .then((res) => {
        dispatch({type: GET_SCHEDULE_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_SCHEDULE_FAIL})
        globalApiErrorHandler(err)
        return Promise.reject(err?.response?.data?.errors)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const patchSchedule = (estimationId, data) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .patch(`${endpoints.estimations}/${estimationId}/schedule`, data)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        if (err.response?.status === 422 && err.response?.data?.errors?.length) {
          return Promise.reject(err?.response?.data?.errors)
        } else {
          return globalApiErrorHandler(err)
        }
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const recalculateSchedule = (estimationId, data) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .put(`${endpoints.estimations}/${estimationId}/schedule/recalculate`, data)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        if (err.response?.status === 422 && err.response?.data?.errors?.length) {
          return Promise.reject(err?.response?.data?.errors)
        } else {
          return globalApiErrorHandler(err)
        }
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const getSchedulePhases = (estimationId) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    dispatch({type: GET_PHASES_REQUEST})
    return httpClient
      .get(`${endpoints.estimations}/${estimationId}/schedule/phases`)
      .then((res) => {
        dispatch({type: GET_PHASES_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_PHASES_FAIL})
        globalApiErrorHandler(err)
        return Promise.reject(err?.response?.data?.errors)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const postSchedulePhases = (estimationId, data) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .post(`${endpoints.estimations}/${estimationId}/schedule/phases`, data)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        if (err.response?.status === 422 && err.response?.data?.errors?.length) {
          return Promise.reject(err?.response?.data?.errors)
        } else {
          return globalApiErrorHandler(err)
        }
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const patchSchedulePhases = (estimationId, phaseId, data) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .patch(`${endpoints.estimations}/${estimationId}/schedule/phases/${phaseId}`, data)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        if (err.response?.status === 422 && err.response?.data?.errors?.length) {
          return Promise.reject(err?.response?.data?.errors)
        } else {
          return globalApiErrorHandler(err)
        }
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const deleteSchedulePhases = (estimationId, phaseId) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .del(`${endpoints.estimations}/${estimationId}/schedule/phases/${phaseId}`)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        globalApiErrorHandler(err)
        return Promise.reject(err?.response?.data?.errors)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const patchSchedulePhaseMonth = (estimationId, phaseId, monthId, data) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .patch(
        `${endpoints.estimations}/${estimationId}/schedule/phases/${phaseId}/months/${monthId}`,
        data
      )
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        if (err.response?.status === 422 && err.response?.data?.errors?.length) {
          return Promise.reject(err?.response?.data?.errors)
        } else {
          return globalApiErrorHandler(err)
        }
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}
// END SCHEDULE

// START QUOTES
export const getQuotes = (id, limit = 1000, offset = 0, filters) => {
  return (dispatch) => {
    dispatch({type: GET_QUOTES_REQUEST})
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .get(`${endpoints.estimations}/${id}/quotes`, {
        limit: limit,
        offset: offset,
        order_by:
          filters?.orderBy &&
          filters?.orderDirection &&
          `${filters?.orderBy}:${filters?.orderDirection}`,
      })
      .then((res) => {
        dispatch({type: GET_QUOTES_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_QUOTES_FAIL})
        globalApiErrorHandler(err)
        return Promise.reject(err?.response?.data?.errors)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const getQuote = (id, quoteId) => {
  return (dispatch) => {
    dispatch({type: GET_QUOTE_REQUEST})
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .get(`${endpoints.estimations}/${id}/quotes/${quoteId}`)
      .then((res) => {
        dispatch({type: GET_QUOTE_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_QUOTE_FAIL})
        globalApiErrorHandler(err)
        return Promise.reject(err?.response?.data?.errors)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const postQuote = (estimationId, data) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .post(`${endpoints.estimations}/${estimationId}/quotes`, data)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        if (err.response?.status === 422 && err.response?.data?.errors?.length) {
          return Promise.reject(err?.response?.data?.errors)
        } else {
          return globalApiErrorHandler(err)
        }
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const deleteQuote = (estimationId, quoteId) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .del(`${endpoints.estimations}/${estimationId}/quotes/${quoteId}`)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        globalApiErrorHandler(err)
        return Promise.reject(err?.response?.data?.errors)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const patchQuote = (estimationId, quoteId, data) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .patch(`${endpoints.estimations}/${estimationId}/quotes/${quoteId}`, data)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        if (err.response?.status === 422 && err.response?.data?.errors?.length) {
          return Promise.reject(err?.response?.data?.errors)
        } else {
          return globalApiErrorHandler(err)
        }
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const getQuoteRows = (estimationId, quoteId, filters, limit = 1000, offset = 0) => {
  return (dispatch) => {
    dispatch({type: GET_QUOTE_ROWS_REQUEST})
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .get(`${endpoints.estimations}/${estimationId}/quotes/${quoteId}/rows`, {
        limit: limit,
        offset: offset,
        order_by:
          filters?.orderBy &&
          filters?.orderDirection &&
          `${filters?.orderBy}:${filters?.orderDirection}`,
      })
      .then((res) => {
        dispatch({type: GET_QUOTE_ROWS_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_QUOTE_ROWS_FAIL})
        globalApiErrorHandler(err)
        return Promise.reject(err?.response?.data?.errors)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const postQuoteRows = (estimationId, quoteId, data) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .post(`${endpoints.estimations}/${estimationId}/quotes/${quoteId}/rows`, data)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        if (err.response?.status === 422 && err.response?.data?.errors?.length) {
          return Promise.reject(err?.response?.data?.errors)
        } else {
          return globalApiErrorHandler(err)
        }
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const patchQuoteRow = (estimationId, quoteId, rowId, data) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .patch(`${endpoints.estimations}/${estimationId}/quotes/${quoteId}/rows/${rowId}`, data)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        if (err.response?.status === 422 && err.response?.data?.errors?.length) {
          return Promise.reject(err?.response?.data?.errors)
        } else {
          return globalApiErrorHandler(err)
        }
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const deleteQuoteRow = (estimationId, quoteId, rowId) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .del(`${endpoints.estimations}/${estimationId}/quotes/${quoteId}/rows/${rowId}`)
      .then((res) => {
        fireSuccessToast(<Trans>Row deleted.</Trans>)
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        globalApiErrorHandler(err)
        return Promise.reject(err?.response?.data?.errors)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const putExpandRow = (estimationId, quoteId, rowId) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .put(`${endpoints.estimations}/${estimationId}/quotes/${quoteId}/rows/${rowId}/expand`)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        if (err.response?.status === 422 && err.response?.data?.errors?.length) {
          return Promise.reject(err?.response?.data?.errors)
        } else {
          return globalApiErrorHandler(err)
        }
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}
// END QUOTES
