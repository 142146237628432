import React, {useState} from 'react'
import {LabelList, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Cell} from 'recharts'
import GridContainer from 'component/material/GridContainer'
import Loader from 'component/material/Loader'
import GridItem from 'component/material/GridItem'
import BoxFullWidth from 'component/material/BoxFullWidth'
import withStyles from '@material-ui/core/styles/withStyles'
import stricklandStyle from 'component/Strickland/stricklandStyle'
import PropTypes from 'prop-types'
import {Trans} from '@lingui/macro'
import {ToggleButton, ToggleButtonGroup} from '@material-ui/lab'

const ProjectGraphStrick = (props) => {
  const {loading, classes, data} = props

  const [selectedView, setSelectedView] = useState('MD')

  const handleChangeView = (e, value) => {
    setSelectedView(value)
  }

  const formatXAxis = (value, index) => {
    if (data?.[index]?.project_is_billable) {
      return `${value} $`
    } else {
      return `${value}`
    }
  }

  const CustomTooltip = (props) => {
    const {active, payload, label} = props
    const getLabel = () => {
      if (payload?.[0]?.payload?.project_is_billable) {
        return `${label} $`
      } else {
        return `${label}`
      }
    }

    if (active && payload && payload.length) {
      return (
        <GridContainer className={classes.customGraphTooltip}>
          <GridItem xs={12}>{getLabel()}</GridItem>
          <GridItem style={{color: payload[0]?.color}} xs={8}>{`${payload[0]?.name}:`}</GridItem>
          <GridItem
            xs={4}
            style={{textAlign: 'end'}}
          >{`${payload[0]?.value} ${selectedView}`}</GridItem>
          <GridItem style={{color: payload[1]?.color}} xs={8}>{`${payload[1]?.name}:`}</GridItem>
          <GridItem
            xs={4}
            style={{textAlign: 'end'}}
          >{`${payload[1]?.value} ${selectedView}`}</GridItem>
        </GridContainer>
      )
    }

    return null
  }

  return (
    <GridContainer>
      <BoxFullWidth p={2} className={classes.gridBoxHeight}>
        {loading ? (
          <Loader wrapper={true} />
        ) : (
          <GridContainer alignItems={'flex-start'}>
            {data?.length ? (
              <>
                <GridItem xs={12} container>
                  <GridItem xs={10} container alignItems={'center'}>
                    <div className={classes.legendLine}>
                      <div className={classes.expected} />
                      <div className={classes.expectedText}>
                        <Trans>Expectation</Trans>
                        {' $'}
                      </div>
                      <div className={classes.expectedBillable} />
                      <div className={classes.expectedText}>
                        <Trans>Expectation</Trans>
                      </div>
                      <div className={classes.reality} />
                      <div className={classes.realityText}>
                        <Trans>Reality</Trans>
                        {' $'}
                      </div>
                      <div className={classes.realityBillable} />
                      <div className={classes.realityText}>
                        <Trans>Reality</Trans>
                      </div>
                    </div>
                  </GridItem>
                  <GridItem xs={2}>
                    <GridItem className={classes.toggleButtons}>
                      <ToggleButtonGroup value={selectedView} exclusive onChange={handleChangeView}>
                        <ToggleButton value="MD">MD</ToggleButton>
                        <ToggleButton value="%">%</ToggleButton>
                      </ToggleButtonGroup>
                    </GridItem>
                  </GridItem>
                </GridItem>
                <GridItem xs={12} className={classes.overflowAuto}>
                  <BarChart
                    width={data.length * 100}
                    height={340}
                    data={data.map((record) => ({
                      Očekávání:
                        selectedView === 'MD'
                          ? record.planned_utilized_mds
                          : record.planned_utilization_percentage,
                      Realita:
                        selectedView === 'MD'
                          ? record.real_utilized_mds
                          : record.real_utilization_percentage,
                      project_ref: record.project_ref,
                      project_is_billable: record.project_is_billable,
                    }))}
                    margin={{
                      top: 20,
                      right: 0,
                      left: 0,
                      bottom: 10,
                    }}
                  >
                    <CartesianGrid stroke="#dddddd" strokeDasharray="4 4" />
                    <XAxis
                      interval={0}
                      dataKey="project_ref"
                      tickLine={false}
                      tick={{fontSize: 12}}
                      tickFormatter={formatXAxis}
                      height={60}
                    />
                    <YAxis
                      unit={selectedView === 'MD' ? ' MD' : ' %'}
                      tickLine={false}
                      tick={{fontSize: 12}}
                    />
                    {/* tooltip visible on hover above single bar */}
                    <Tooltip content={<CustomTooltip />} />
                    <Bar dataKey="Očekávání" stackId="a" fill="#16407B" isAnimationActive={false}>
                      {data.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={entry.project_is_billable ? '#16407B' : '#16407B90'}
                        />
                      ))}
                      <LabelList fontSize={12} dataKey="Očekávání" position="top" />
                    </Bar>
                    <Bar dataKey="Realita" stackId="b" fill="#F8BAA0" isAnimationActive={false}>
                      {data.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={entry.project_is_billable ? '#F8BAA0' : '#F8BAA090'}
                        />
                      ))}
                      <LabelList fontSize={12} dataKey="Realita" position="top" />
                    </Bar>
                  </BarChart>
                </GridItem>
              </>
            ) : (
              <GridItem
                container
                xs={12}
                alignContent={'center'}
                justifyContent={'center'}
                className={classes.noDataGridItem}
              >
                No data
              </GridItem>
            )}
          </GridContainer>
        )}
      </BoxFullWidth>
    </GridContainer>
  )
}

ProjectGraphStrick.propTypes = {
  classes: PropTypes.object,
  loading: PropTypes.bool,
  data: PropTypes.array,
}

export default withStyles(stricklandStyle)(ProjectGraphStrick)
