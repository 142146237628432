const materialStyle = (theme) => ({
  // stretch paper to full width of container
  flexGrow: {
    flexGrow: 1,
  },

  overflowAuto: {
    overflow: 'auto',
  },

  // stretch box to full width of container
  fullWidth: {
    width: '100%',
    boxSizing: 'border-box',
    position: 'relative',
  },

  // remove bottom line under last row in table
  tableRow: {
    height: 30,
    '&:last-child': {
      '& .MuiTableCell-body': {
        borderBottom: 'none',
      },
    },
    // first and last cell in row are without left/right padding
    '& .MuiTableCell-root:first-child': {
      paddingLeft: 6,
    },
    '& .MuiTableCell-root:last-child': {
      paddingRight: 6,
    },
  },

  dialogTitle: {
    margin: 0,
    padding: theme.spacing(4),
    paddingRight: '56px',
    borderBottom: '1px solid ' + theme.palette.border,
  },

  dialogContent: {
    padding: theme.spacing(4),
  },

  dialogActions: {
    borderTop: '1px solid ' + theme.palette.border,
    padding: theme.spacing(4),
  },

  dialogCloseButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    color: theme.palette.text.secondary,
  },

  whiteSpaceNowrap: {
    whiteSpace: 'nowrap',
  },

  bodyCellClass: {
    height: 40,
    boxSizing: 'border-box',
    paddingTop: 2,
    paddingBottom: 2,
  },

  tableRowCursorPointer: {
    cursor: 'pointer',
  },

  rowClickHover: {
    '&:hover': {
      backgroundColor: theme.palette.primary.main + 10,
    },
  },

  boldRow: {
    fontWeight: 600,
    paddingTop: '12px !important',
    paddingBottom: '12px !important',
  },

  tableHeader: {
    height: 40,
    boxSizing: 'border-box',
    borderBottom: '1px solid black',
    fontWeight: 600,
  },

  loader: {
    border: '5px solid transparent',
    borderRadius: '50%',
    borderTop: '5px solid ' + theme.palette.primary.main + '90',
    width: 40,
    height: 40,
    animation: '$spin 1.2s linear infinite',
    margin: '0 auto',
    padding: 20,
  },

  '@keyframes spin': {
    from: {transform: 'rotate(0deg)'},
    to: {transform: 'rotate(360deg)'},
  },

  loaderWrapper: {
    minHeight: '25vh',
    alignItems: 'center',
    width: '100%',
    display: 'flex',
  },

  warningIcon: {
    color: theme.palette.warning.main,
    fontSize: 90,
  },
  warningBody: {
    margin: '10px 0',
    textAlign: 'center',
  },
  warningTitle: {
    fontSize: '1.5rem',
    color: theme.palette.primary.main,
  },

  tableCellCheckboxClass: {
    borderRadius: 0,
    width: '100%',
    boxSizing: 'border-box',
    justifyContent: 'flex-start',

    '& .MuiSvgIcon-root': {
      width: 20,
      height: 20,
    },
  },

  tableCellCheckboxTransition: {
    transition: 'background-color 200ms',
  },

  checkBoxInput: {
    '& .MuiFormControlLabel-root': {
      color: theme.palette.text.primary,
      textTransform: 'none',
      fontSize: 14,
      margin: 0,
    },
    '& .MuiFormControlLabel-labelPlacementTop': {
      alignItems: 'flex-start',
    },
    '& .MuiIconButton-root': {
      padding: 6,
    },
  },

  buttonClass: {
    lineHeight: 1,

    '&.MuiButton-containedSizeSmall': {
      height: 40,
      minHeight: 40,
      minWidth: 40,
    },

    '&.MuiButton-outlinedSizeSmall': {
      height: 40,
      minHeight: 40,
      minWidth: 40,
    },
  },

  tableNoData: {
    fontSize: 24,
    fontWeight: 700,
    textAlign: 'center',
    color: theme.palette.divider,
  },

  tableWrapper: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },

  // basic field label
  fieldLabel: {
    color: theme.palette.text.primary,
    textTransform: 'none',
    fontSize: 14,
  },
})

export default materialStyle
