import React, {useEffect} from 'react'
import {Trans} from '@lingui/macro'
import PrivateContentLayout from 'layout/PrivateLayout/PrivateContentLayout'
import {bindActionCreators, compose} from 'redux'
import {withStyles} from '@material-ui/core'
import kapacitomatPageStyle from 'component/KapacitomatPage/kapacitomatPageStyle'
import {connect} from 'react-redux'
import {postPiggyBankBreak, postPiggyBankInit} from 'redux/action/kapacitomatAction'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import {useAxiosCall} from 'hooks/useAxiosCall'
import endpoints from 'helper/endpoints'
import cx from 'classnames'
import PiggyTable from 'component/Strickland/Piggy/PiggyTable'

const PiggyPageStrick = (props) => {
  const {classes} = props

  const [{res, err, loading}, getPiggyBank, cancelGetPiggyBank] = useAxiosCall({
    url: endpoints.piggyBank,
  })

  useEffect(() => {
    getPiggyBank()

    return () => {
      cancelGetPiggyBank()
    }
  }, [])

  return (
    <>
      <PrivateContentLayout
        title={<Trans>Piggy bank</Trans>}
        content={
          <GridContainer>
            <GridItem xs={6}>
              <GridItem xs={12}>
                <Trans>Current amount in piggy bank</Trans>
              </GridItem>
              <GridItem className={cx()}>{res?.total_amount}</GridItem>
              <GridItem xs={12}>
                <Trans>Overflown amount in piggy bank</Trans>
              </GridItem>
              <GridItem className={cx()}>{res?.overflown_amount}</GridItem>
            </GridItem>

            <GridItem xs={6}>
              <span className="material-symbols-outlined">savings</span>
            </GridItem>
          </GridContainer>
        }
      />
      <PrivateContentLayout
        contentPadding={0}
        content={<PiggyTable data={res} loading={loading} />}
      />
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      postPiggyBankInit,
      postPiggyBankBreak,
    },
    dispatch
  )
}

export default compose(
  withStyles(kapacitomatPageStyle),
  connect((store) => {
    return {
      piggyBank: store.kapacitomat.piggyBank,
      piggyBankLoading: store.kapacitomat.piggyBankLoading,
    }
  }, mapDispatchToProps)
)(PiggyPageStrick)
