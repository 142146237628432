import React, {useEffect, useState} from 'react'
import {Trans} from '@lingui/macro'
import withStyles from '@material-ui/core/styles/withStyles'
import projektomatPageStyle from 'component/ProjektomatPage/projektomatPageStyle'
import {bindActionCreators, compose} from 'redux'
import {getEmployeeList, synchronizeAllProjektomat} from 'redux/action/projektomatAction'
import {updateGlobalSetting} from 'redux/action/globalSettingAction'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import EmployeeTable from 'component/ProjektomatPage/EmployeeTable'
import CustomTooltip from 'component/material/Tooltip'
import SyncIcon from '@material-ui/icons/Sync'
import cx from 'classnames'
import PrivateContentLayout from 'layout/PrivateLayout/PrivateContentLayout'
import GridContainer from 'component/material/GridContainer'
import {useTableSort} from 'hooks/useTableSort'
import {usePagination} from 'hooks/usePagination'

const EmployeePage = (props) => {
  const {
    classes,
    getEmployeeList,
    employeeList,
    employeeLoading,
    employeeMeta,
    updateGlobalSetting,
    synchronizeAllProjektomat,
    rowsPerPage,
  } = props

  const [page, onChangePage, onChangeRowsPerPage] = usePagination(updateGlobalSetting)
  const [syncLoading, setSyncLoading] = useState(false)
  const [tableSort, onSortClick] = useTableSort({
    orderBy: 'name',
    orderDirection: 'asc',
  })

  const fetchEmployees = () => {
    getEmployeeList(rowsPerPage, page * rowsPerPage, false, tableSort)
  }

  const handleSyncEmployeesAndProjects = () => {
    setSyncLoading(true)
    return synchronizeAllProjektomat().finally(() => {
      setSyncLoading(false)
    })
  }

  useEffect(() => {
    fetchEmployees()
  }, [tableSort, page, rowsPerPage])

  return (
    <PrivateContentLayout
      title={
        <GridContainer alignItems={'center'}>
          <Trans>Eluvians</Trans>
          <CustomTooltip
            title={<Trans>Synchronize all projects, employees and assignments from Clockify</Trans>}
          >
            <SyncIcon
              color={'primary'}
              className={cx(classes.syncButton, syncLoading ? classes.syncButtonRotating : '')}
              onClick={handleSyncEmployeesAndProjects}
            />
          </CustomTooltip>
        </GridContainer>
      }
      content={
        <EmployeeTable
          data={employeeList}
          loading={employeeLoading}
          onSortClick={onSortClick}
          orderBy={tableSort.orderBy}
          orderDirection={tableSort.orderDirection}
          count={employeeMeta?.total_count}
          page={page}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          fetchEmployees={() => fetchEmployees()}
        />
      }
    />
  )
}

EmployeePage.propTypes = {
  classes: PropTypes.object,
  getEmployeeList: PropTypes.func,
  employeeList: PropTypes.array,
  employeeLoading: PropTypes.bool,
  employeeMeta: PropTypes.object,
  rowsPerPageMeta: PropTypes.number,
  updateGlobalSetting: PropTypes.func,
  synchronizeAllProjektomat: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getEmployeeList,
      updateGlobalSetting,
      synchronizeAllProjektomat,
    },
    dispatch
  )
}

export default compose(
  withStyles(projektomatPageStyle),
  connect((store) => {
    return {
      employeeList: store.projektomat.employees,
      employeeLoading: store.projektomat.employeesLoading,
      employeeMeta: store.projektomat.employeesMeta,
      rowsPerPage: store.globalSetting.rowsPerPage,
    }
  }, mapDispatchToProps)
)(EmployeePage)
