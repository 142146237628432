import React from 'react'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import materialStyle from 'component/material/materialStyle'

const CheckBoxInput = (props) => {
  const {
    classes,
    input,
    meta,
    label,
    labelPlacement = 'end',
    helperText,
    showHelperText = true,
    onCustomChange,
    color = 'secondary',
    ...rest
  } = props

  const errorMessage = meta && meta.touched && (meta.error || meta.submitError)
  const errorHelperText = showHelperText && (errorMessage || helperText || ' ')

  return (
    <FormControl error={!!errorMessage} className={classes.checkBoxInput}>
      <FormControlLabel
        {...rest}
        control={
          <Checkbox
            checked={true}
            {...input}
            color={color}
            onChange={(e) => {
              input?.onChange(e)
              onCustomChange && onCustomChange(e.target.name, e.target.checked)
            }}
          />
        }
        label={label}
        classes={{label: classes.fieldLabel}}
        labelPlacement={labelPlacement}
      />
      <FormHelperText>{errorHelperText}</FormHelperText>
    </FormControl>
  )
}

CheckBoxInput.propTypes = {
  classes: PropTypes.object,
  input: PropTypes.object,
  meta: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  labelPlacement: PropTypes.string,
  helperText: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  labelClassName: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  showHelperText: PropTypes.bool,
}

export default withStyles(materialStyle)(CheckBoxInput)
