import {
  GET_USER_LIST_REQUEST,
  GET_USER_LIST_SUCCESS,
  GET_USER_LIST_FAIL,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAIL,
  GET_USER_ROLE_LIST_REQUEST,
  GET_USER_ROLE_LIST_SUCCESS,
  GET_USER_ROLE_LIST_FAIL,
  GET_USER_ROLE_REQUEST,
  GET_USER_ROLE_SUCCESS,
  GET_USER_ROLE_FAIL,
  GET_PERMISSION_REQUEST,
  GET_PERMISSION_SUCCESS,
  GET_PERMISSION_FAIL,
} from 'redux/actionType'

const initState = {
  userList: [],
  userListLoading: false,
  userListMeta: {},
  user: {},
  userLoading: false,
  userRoleList: [],
  userRoleListLoading: false,
  userRoleListMeta: {},
  userRole: {},
  userRoleLoading: false,
  permissionList: [],
  permissionListLoading: false,
  permissionListMeta: {},
}

const userReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_USER_LIST_REQUEST: {
      return {
        ...state,
        userListLoading: true,
      }
    }
    case GET_USER_LIST_SUCCESS: {
      return {
        ...state,
        userListLoading: false,
        userList: action.data.objects,
        userListMeta: action.data.meta,
      }
    }
    case GET_USER_LIST_FAIL: {
      return {
        ...state,
        userListLoading: false,
      }
    }
    case GET_USER_REQUEST: {
      return {
        ...state,
        userLoading: true,
      }
    }
    case GET_USER_SUCCESS: {
      return {
        ...state,
        userLoading: false,
        user: action.data,
      }
    }
    case GET_USER_FAIL: {
      return {
        ...state,
        userLoading: false,
      }
    }
    case GET_USER_ROLE_LIST_REQUEST: {
      return {
        ...state,
        userRoleListLoading: true,
      }
    }
    case GET_USER_ROLE_LIST_SUCCESS: {
      return {
        ...state,
        userRoleListLoading: false,
        userRoleList: action.data.objects,
        userRoleListMeta: action.data.meta,
      }
    }
    case GET_USER_ROLE_LIST_FAIL: {
      return {
        ...state,
        userRoleListLoading: false,
      }
    }
    case GET_USER_ROLE_REQUEST: {
      return {
        ...state,
        userRoleLoading: true,
      }
    }
    case GET_USER_ROLE_SUCCESS: {
      return {
        ...state,
        userRoleLoading: false,
        userRole: action.data,
      }
    }
    case GET_USER_ROLE_FAIL: {
      return {
        ...state,
        userRoleLoading: false,
      }
    }
    case GET_PERMISSION_REQUEST: {
      return {
        ...state,
        permissionListLoading: true,
      }
    }
    case GET_PERMISSION_SUCCESS: {
      return {
        ...state,
        permissionListLoading: false,
        permissionList: action.data.objects,
        permissionListMeta: action.data.meta,
      }
    }
    case GET_PERMISSION_FAIL: {
      return {
        ...state,
        permissionListLoading: false,
      }
    }
    default: {
      return state
    }
  }
}

export default userReducer
