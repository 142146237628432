import {getServiceUrl} from 'helper/functions'

const endpoints = {
  // auth
  login: `${getServiceUrl('auth')}/google/login`,
  logout: `${getServiceUrl('auth')}/logout`,
  users: `${getServiceUrl('auth')}/users`,
  userRoles: `${getServiceUrl('auth')}/roles`,
  permissions: `${getServiceUrl('auth')}/permissions`,

  // odhadomat
  breakdowns: `${getServiceUrl('odhadomat')}/breakdowns`,
  estimations: `${getServiceUrl('odhadomat-2')}/estimations`,
  specification: `${getServiceUrl('odhadomat')}/specifications`,
  youtrack: `${getServiceUrl('odhadomat')}/youtrack-breakdowns`,

  quotes: (estId) => `odhadomat-2/estimations/${estId}/quotes`,

  schedule: (estId) => `odhadomat-2/estimations/${estId}/schedule`,

  // vykazomat
  reports: `${getServiceUrl('vykazomat')}/reports`,
  vykazomat: `${getServiceUrl('vykazomat')}`,

  // projektomat
  clients: `${getServiceUrl('projektomat')}/clients`,
  roles: `${getServiceUrl('projektomat')}/roles`,
  projects: `${getServiceUrl('projektomat')}/projects`,
  employees: `${getServiceUrl('projektomat')}/employees`,
  issuedInvoices: `${getServiceUrl('projektomat')}/issued-invoices`,
  acceptedInvoices: `${getServiceUrl('projektomat')}/accepted-invoices`,
  workReports: `${getServiceUrl('projektomat')}/work-reports`,
  indirectExpenses: `${getServiceUrl('projektomat')}/indirect-expenses`,
  synchronizeAllPro: `${getServiceUrl('projektomat')}/synchronize-all`,
  companies: `${getServiceUrl('projektomat')}/companies`,
  dashboardPro: `${getServiceUrl('projektomat')}/dashboard`,

  // kapacitomat
  projectsKap: `${getServiceUrl('kapacitomat')}/project-obligations`,
  employeesKap: `${getServiceUrl('kapacitomat')}/employee-obligations`,
  employeesVacationsKap: `${getServiceUrl('kapacitomat')}/employee-vacations`,
  assignmentsKap: `${getServiceUrl('kapacitomat')}/assignments`,
  dashboard: `${getServiceUrl('kapacitomat')}/dashboard`,
  synchronizeAllKap: `${getServiceUrl('kapacitomat')}/synchronize-all`,

  // strickland
  projectsStrick: `${getServiceUrl('strickland')}/reports/utilization/projects`,
  employeesStrick: `${getServiceUrl('strickland')}/reports/utilization/employees`,
  potentialStrick: `${getServiceUrl('strickland')}/reports/potential/projects`,
  piggyBank: `${getServiceUrl('strickland')}/piggy-bank`,
}

export default endpoints
