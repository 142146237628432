import React from 'react'
import PrivateLayout from 'layout/PrivateLayout/PrivateLayout'
import {Route} from 'react-router-dom'

const PrivateRoute = (props) => {
  const {component, path, ...rest} = props

  return (
    <Route {...rest} path={path}>
      <PrivateLayout>{component}</PrivateLayout>
    </Route>
  )
}

export default PrivateRoute
