import React, {useEffect, useState} from 'react'
import {bindActionCreators, compose} from 'redux'
import {getEstimationDetail, patchEstimation} from 'redux/action/odhadomatAction'
import PropTypes from 'prop-types'
import {withStyles} from '@material-ui/core'
import odhadyStyle from 'component/OdhadomatPage/odhadyStyle'
import {connect} from 'react-redux'
import {useParams} from 'react-router-dom'
import {Trans} from '@lingui/macro'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import BoxFullWidth from 'component/material/BoxFullWidth'
import TabPanel from 'component/material/TabPanel'
import StoryTab from 'component/OdhadomatPage/OdhadDetail/StoryTab'
import AccompanyingWorkTab from 'component/OdhadomatPage/OdhadDetail/AccompanyingWorkTab'
import CustomTooltip from 'component/material/Tooltip'
import {Add, Edit} from '@material-ui/icons'
import GridContainer from 'component/material/GridContainer'
import DialogWindow from 'component/material/DialogWindow'
import OdhadForm from 'component/OdhadomatPage/OdhadDetail/OdhadForm'
import {STORY_LAYERS} from 'helper/constants'
import {diff} from 'deep-object-diff'
import Loader from 'component/material/Loader'
import SummaryTab from 'component/OdhadomatPage/OdhadDetail/SummaryTab'
import PrivateContentLayout from 'layout/PrivateLayout/PrivateContentLayout'
import PrimaryButton from 'component/material/PrimaryButton'
import Box from '@material-ui/core/Box'
import GridItem from 'component/material/GridItem'
import Breadcrumbs from 'component/general/Breadcrumbs'

const OdhadDetail = (props) => {
  const {classes, getEstimationDetail, estimationLoading, estimation, patchEstimation} = props

  const {id} = useParams()

  const [value, setValue] = useState(0)
  const [openEdit, setOpenEdit] = useState(false)

  const [openEditCreate, setOpenEditCreate] = useState({visible: false, row: null})

  const handleChangeTab = (e, newValue) => {
    setValue(newValue)
  }

  const getTabs = () => {
    if (
      estimation?.main_story_categories &&
      Array.isArray(estimation?.main_story_categories) &&
      estimation?.main_story_categories?.length
    ) {
      return [...estimation?.main_story_categories]
    } else {
      return []
    }
  }

  const openEditEstimation = () => {
    setOpenEdit(true)
  }

  const closeEditEstimation = () => {
    setOpenEdit(false)
  }

  const prepareData = (values) => {
    const layers = []
    values.layers?.forEach((layer) => layers.push(STORY_LAYERS[layer]))
    const newValues = {
      ...values,
      avg_story_mds: Number(values.avg_story_mds),
      reserve: values.reserve && Number(values.reserve),
      team_size: values.team_size && Number(values.team_size),
      senior_price: values.senior_price && Number(values.senior_price) * 100,
      junior_price: values.junior_price && Number(values.junior_price) * 100,
      hosting_price: values.hosting_price && Number(values.hosting_price) * 100,
    }

    const patchDiff = diff(estimation, newValues)

    delete patchDiff.layers

    let teamCapacities = {}
    for (let attr in values.team_capacities) {
      teamCapacities[attr] = Number(values.team_capacities[attr])
    }
    patchDiff.team_capacities = teamCapacities

    return patchDiff
  }

  const handleSubmit = (values) => {
    const data = prepareData(values)
    return patchEstimation(id, data).then(() => {
      closeEditEstimation()
      getEstimationDetail(id)
    })
  }

  const getInitialValues = () => {
    const storyLayerCapacities = estimation.team_capacities
      ? Object.keys(estimation.team_capacities).map((cap) => {
          return STORY_LAYERS.findIndex((layer) => layer === cap)
        })
      : {}
    return {
      ...estimation,
      senior_price: estimation.senior_price && Number(estimation.senior_price) / 100,
      junior_price: estimation.junior_price && Number(estimation.junior_price) / 100,
      hosting_price: estimation.hosting_price && Number(estimation.hosting_price) / 100,
      layers: storyLayerCapacities,
    }
  }

  const openCreateStory = () => {
    return setOpenEditCreate({visible: true, row: null})
  }

  const openCreateAccompanyingWork = () => {
    return setOpenEditCreate({visible: true, row: null})
  }

  const getAddButton = () => {
    if (value >= 1 && value <= getTabs()?.length) {
      return (
        <GridItem>
          <CustomTooltip title={<Trans>Add new story</Trans>}>
            <PrimaryButton
              fullWidth={false}
              text={
                <GridContainer alignItems={'center'}>
                  <Box pr={2}>
                    <Add fontSize={'small'} />
                  </Box>
                  <Trans>Add</Trans>
                </GridContainer>
              }
              onClick={openCreateStory}
            />
          </CustomTooltip>
        </GridItem>
      )
    } else if (value === getTabs()?.length + 1) {
      return (
        <GridItem>
          <CustomTooltip title={<Trans>Add new accompanying work</Trans>}>
            <PrimaryButton
              fullWidth={false}
              text={
                <GridContainer alignItems={'center'}>
                  <Box pr={2}>
                    <Add fontSize={'small'} />
                  </Box>
                  <Trans>Add</Trans>
                </GridContainer>
              }
              onClick={openCreateAccompanyingWork}
            />
          </CustomTooltip>
        </GridItem>
      )
    }
  }

  useEffect(() => {
    getEstimationDetail(id)
  }, [])

  return (
    <>
      {/* NEW ESTIMATION */}
      <DialogWindow
        onClose={closeEditEstimation}
        open={openEdit}
        title={<Trans>Edit estimation</Trans>}
        maxWidth={'md'}
        fullWidth={true}
      >
        <OdhadForm
          handleSubmit={handleSubmit}
          initValues={estimationLoading ? {} : getInitialValues()}
          edit={true}
        />
      </DialogWindow>

      <PrivateContentLayout
        title={
          <Breadcrumbs
            breadcrumbs={[
              {title: <Trans>Estimations</Trans>, url: '/odhadomat/odhady'},
              {title: estimation?.title},
            ]}
          />
        }
        actions={
          <GridContainer spacing={2} justifyContent={'flex-end'}>
            <GridItem>
              <CustomTooltip title={<Trans>Edit estimation</Trans>}>
                <PrimaryButton
                  fullWidth={false}
                  text={
                    <GridContainer alignItems={'center'}>
                      <Box pr={2.5}>
                        <Edit fontSize={'small'} />
                      </Box>
                      <Trans>Edit</Trans>
                    </GridContainer>
                  }
                  onClick={openEditEstimation}
                />
              </CustomTooltip>
            </GridItem>

            {getAddButton()}
          </GridContainer>
        }
        contentPadding={0}
        content={
          estimationLoading ? (
            <Loader wrapper={true} />
          ) : (
            <>
              <AppBar
                position="static"
                color="default"
                elevation={0}
                className={classes.appBarBottomBorder}
              >
                <Tabs
                  value={value}
                  className={classes.tabs}
                  onChange={handleChangeTab}
                  indicatorColor="secondary"
                  variant="scrollable"
                  scrollButtons="auto"
                >
                  {[<Trans>Overview</Trans>, ...getTabs(), <Trans>Accompanying work</Trans>]?.map(
                    (tab, index) => (
                      <Tab key={index} value={index} label={tab} />
                    )
                  )}
                </Tabs>
              </AppBar>
              <BoxFullWidth p={4} pt={2}>
                <div className={classes.root}>
                  {/* TAB PANELS */}
                  <TabPanel value={value} index={0} className={classes.overflowAuto}>
                    <SummaryTab />
                  </TabPanel>
                  {getTabs()?.map((tab, index) => (
                    <TabPanel
                      value={value}
                      index={index + 1}
                      className={classes.overflowAuto}
                      key={index}
                    >
                      <StoryTab
                        mainCategory={tab}
                        openEditCreate={openEditCreate}
                        setOpenEditCreate={setOpenEditCreate}
                      />
                    </TabPanel>
                  ))}
                  {/* ACCOMPANYING WORK */}
                  <TabPanel
                    value={value}
                    index={getTabs()?.length + 1} // dynamic index
                    className={classes.overflowAuto}
                  >
                    <AccompanyingWorkTab
                      openEditCreate={openEditCreate}
                      setOpenEditCreate={setOpenEditCreate}
                    />
                  </TabPanel>
                </div>
              </BoxFullWidth>
            </>
          )
        }
      />
    </>
  )
}

OdhadDetail.propTypes = {
  classes: PropTypes.object,
  patchEstimation: PropTypes.func,
  getEstimationDetail: PropTypes.func,
  estimationLoading: PropTypes.bool,
  estimation: PropTypes.object,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getEstimationDetail,
      patchEstimation,
    },
    dispatch
  )
}

export default compose(
  withStyles(odhadyStyle),
  connect((store) => {
    return {
      estimationLoading: store.odhadomat.estimationLoading,
      estimation: store.odhadomat.estimation,
    }
  }, mapDispatchToProps)
)(OdhadDetail)
