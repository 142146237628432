import React from 'react'
import {Form, Field} from 'react-final-form'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import PrimaryButton from 'component/material/PrimaryButton'
import {Trans} from '@lingui/macro'
import TextInput from 'component/field/TextInput'
import {required} from 'helper/validations'
import SelectInput from 'component/field/SelectInput'
import InputAdornment from '@material-ui/core/InputAdornment'

const AccompanyingWorkForm = (props) => {
  const {handleSubmit, initValues, estimation, estimationLoading} = props

  return (
    <Form onSubmit={handleSubmit} initialValues={initValues}>
      {(formProps) => {
        const {submitting} = formProps
        return (
          <form onSubmit={formProps.handleSubmit}>
            <GridContainer spacing={2}>
              <GridItem xs={12}>
                <Field
                  name="title"
                  label={<Trans>Title</Trans>}
                  component={TextInput}
                  validate={required}
                  disabled={submitting}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <Field
                  name="category"
                  label={<Trans>Category</Trans>}
                  component={SelectInput}
                  options={estimation?.all_story_categories}
                  loading={estimationLoading}
                  validate={required}
                  disabled={submitting}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <Field
                  name="seniority"
                  label={<Trans>Seniority</Trans>}
                  component={SelectInput}
                  options={[
                    {value: 'junior', label: <Trans>junior</Trans>},
                    {value: 'senior', label: <Trans>senior</Trans>},
                  ]}
                  validate={required}
                  disabled={submitting}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <Field
                  name={'estimated_mds'}
                  label={<Trans>Length</Trans>}
                  component={TextInput}
                  type={'number'}
                  validate={required}
                  disabled={submitting}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Trans>MD</Trans>
                      </InputAdornment>
                    ),
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <Field
                  name={'amount'}
                  label={<Trans>Count</Trans>}
                  component={TextInput}
                  type={'number'}
                  validate={required}
                  disabled={submitting}
                  inputProps={{min: 0, step: 1}}
                />
              </GridItem>
              <GridItem xs={12}>
                <Field
                  name="note"
                  label={<Trans>Note</Trans>}
                  component={TextInput}
                  multiline={true}
                  rows={2}
                  disabled={submitting}
                />
              </GridItem>
            </GridContainer>
            <GridContainer justifyContent={'flex-end'}>
              <Box pt={3}>
                <PrimaryButton
                  fullWidth={false}
                  disabled={submitting}
                  type="submit"
                  text={<Trans>Save</Trans>}
                />
              </Box>
            </GridContainer>
          </form>
        )
      }}
    </Form>
  )
}

AccompanyingWorkForm.propTypes = {
  handleSubmit: PropTypes.func,
  initValues: PropTypes.object,
  estimation: PropTypes.object,
  estimationLoading: PropTypes.bool,
}

export default AccompanyingWorkForm
