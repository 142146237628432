import React, {useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core'
import {Trans} from '@lingui/macro'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {updateGlobalSetting} from 'redux/action/globalSettingAction'
import {getKapacitomatProjectList, synchronizeAllKapacitomat} from 'redux/action/kapacitomatAction'
import kapacitomatPageStyle from 'component/KapacitomatPage/kapacitomatPageStyle'
import ProjectTableKap from 'component/KapacitomatPage/ProjectTableKap'
import KapacitomatPage from 'page/KapacitomatPage'
import GridContainer from 'component/material/GridContainer'
import PrimaryButton from 'component/material/PrimaryButton'
import CustomTooltip from 'component/material/Tooltip'
import SyncIcon from '@material-ui/icons/Sync'
import cx from 'classnames'
import {useTableSort} from 'hooks/useTableSort'

const ProjectPageKap = (props) => {
  const {
    classes,
    getKapacitomatProjectList,
    projectList,
    projectsLoading,
    periodId,
    synchronizeAllKapacitomat,
  } = props

  const [tableSort, onSortClick] = useTableSort({orderBy: null, orderDirection: 'asc'})
  const [showActive, setShowActive] = useState(true)
  const [syncLoading, setSyncLoading] = useState(false)

  const fetchProjects = () => {
    return getKapacitomatProjectList(1000, 0, periodId, tableSort, showActive)
  }

  const handleSyncEmployeesAndProjects = () => {
    setSyncLoading(true)
    return synchronizeAllKapacitomat().finally(() => {
      setSyncLoading(false)
    })
  }

  useEffect(() => {
    if (periodId !== undefined) {
      fetchProjects()
    }
  }, [periodId, tableSort, showActive])

  return (
    <KapacitomatPage
      title={
        <GridContainer alignItems={'center'}>
          <Trans>Projects</Trans>
          <CustomTooltip
            title={<Trans>Synchronize all projects and employees from Projektomat</Trans>}
          >
            <SyncIcon
              className={cx(classes.syncButton, syncLoading ? classes.syncButtonRotating : '')}
              onClick={handleSyncEmployeesAndProjects}
            />
          </CustomTooltip>
        </GridContainer>
      }
      actions={
        <>
          <PrimaryButton
            variant={showActive ? 'contained' : 'outlined'}
            textPadding={1}
            fullWidth={false}
            text={
              <CustomTooltip title={<Trans>Show only active projects</Trans>}>
                <Trans>Only active</Trans>
              </CustomTooltip>
            }
            onClick={() => setShowActive(true)}
          />
          <PrimaryButton
            variant={showActive ? 'outlined' : 'contained'}
            textPadding={1}
            fullWidth={false}
            text={
              <CustomTooltip title={<Trans>Show all projects</Trans>}>
                <Trans>All</Trans>
              </CustomTooltip>
            }
            onClick={() => setShowActive(false)}
          />
        </>
      }
    >
      <ProjectTableKap
        projectList={projectList}
        data={[
          ...projectList,
          {
            project: {
              ref: <Trans>Total</Trans>,
            },
            billable: projectList.reduce((acc, cur) => {
              if (cur?.project?.is_billable) {
                return acc + Number(cur.obliged_mds)
              } else {
                return acc
              }
            }, 0),
            not_billable: projectList.reduce((acc, cur) => {
              if (!cur?.project?.is_billable) {
                return acc + Number(cur.obliged_mds)
              } else {
                return acc
              }
            }, 0),
          },
        ]}
        loading={projectsLoading}
        fetchProjects={() => fetchProjects()}
        pagination={false}
        lastRowBold={true}
        onSortClick={onSortClick}
        orderBy={tableSort.orderBy}
        orderDirection={tableSort.orderDirection}
      />
    </KapacitomatPage>
  )
}

ProjectPageKap.propTypes = {
  classes: PropTypes.object,
  getKapacitomatProjectList: PropTypes.func,
  projectList: PropTypes.array,
  projectsLoading: PropTypes.bool,
  projectsMeta: PropTypes.object,
  updateGlobalSetting: PropTypes.func,
  kapacitomatPeriodId: PropTypes.string,
  periodId: PropTypes.string,
  synchronizeAllKapacitomat: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getKapacitomatProjectList,
      updateGlobalSetting,
      synchronizeAllKapacitomat,
    },
    dispatch
  )
}

export default compose(
  withStyles(kapacitomatPageStyle),
  connect((store) => {
    return {
      periodId: store.kapacitomat.period.id,
      projectList: store.kapacitomat.projects,
      projectsLoading: store.kapacitomat.projectsLoading,
    }
  }, mapDispatchToProps)
)(ProjectPageKap)
