import React from 'react'
import {Form, Field} from 'react-final-form'
import {Trans} from '@lingui/macro'
import PrimaryButton from 'component/material/PrimaryButton'
import PropTypes from 'prop-types'
import {required} from 'helper/validations'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import TextInput from 'component/field/TextInput'
import MultiSelectInput from 'component/field/MultiSelectInput'
import withStyles from '@material-ui/core/styles/withStyles'
import Box from '@material-ui/core/Box'
import odhadyStyle from 'component/OdhadomatPage/odhadyStyle'
import {compose} from 'redux'
import {connect} from 'react-redux'
import MenuItem from '@material-ui/core/MenuItem'
import Checkbox from '@material-ui/core/Checkbox'
import ListItemText from '@material-ui/core/ListItemText'
import {ListSubheader} from '@material-ui/core'

const PhaseForm = (props) => {
  const {handleSubmit, initValues = {}, estimation, estimationLoading} = props

  const optionsRenderFunction = (checked, classes) => {
    const allOptions = prepareOptionsForMultiSelect(estimation?.all_story_categories)

    let scheduled = {}
    let notScheduled = {}

    Object.entries(allOptions).filter(([key, value]) => {
      estimation?.not_scheduled_story_categories?.includes(value)
        ? (notScheduled[key] = value)
        : (scheduled[key] = value)
    })

    return [
      Object.entries(notScheduled).length && (
        <ListSubheader>
          <Trans>Not scheduled</Trans>
        </ListSubheader>
      ),
      Object.entries(notScheduled).map(([key, value], index) => (
        <MenuItem dense={true} key={index} value={parseInt(key)} className={classes.menuItem}>
          <Checkbox size={'small'} checked={checked.includes(parseInt(key))} />
          <ListItemText primary={value} primaryTypographyProps={{variant: 'body2'}} />
        </MenuItem>
      )),
      <ListSubheader>
        <Trans>Scheduled</Trans>
      </ListSubheader>,
      Object.entries(scheduled).map(([key, value], index) => (
        <MenuItem dense={true} key={index} value={parseInt(key)} className={classes.menuItem}>
          <Checkbox size={'small'} checked={checked.includes(parseInt(key))} />
          <ListItemText primary={value} primaryTypographyProps={{variant: 'body2'}} />
        </MenuItem>
      )),
    ]
  }

  const prepareOptionsForMultiSelect = () => {
    const options = {}
    estimation?.all_story_categories?.forEach((item, index) => {
      options[index] = item
    })
    return options
  }

  return (
    <Form onSubmit={handleSubmit} initialValues={initValues}>
      {(formProps) => {
        const {submitting} = formProps
        return (
          <form onSubmit={formProps.handleSubmit}>
            <GridContainer spacing={2}>
              <GridItem xs={12}>
                <Field
                  name="title"
                  label={<Trans>Title</Trans>}
                  component={TextInput}
                  validate={required}
                  disabled={submitting}
                />
              </GridItem>
              <GridItem xs={12}>
                <Field
                  name="story_categories"
                  label={<Trans>Story categories</Trans>}
                  options={prepareOptionsForMultiSelect()}
                  optionsRenderFunction={optionsRenderFunction}
                  component={MultiSelectInput}
                  validate={required}
                  disabled={submitting}
                  size={'small'}
                  loading={estimationLoading}
                />
              </GridItem>
            </GridContainer>
            <GridContainer justifyContent={'flex-end'}>
              <Box pt={3}>
                <PrimaryButton
                  fullWidth={false}
                  disabled={submitting}
                  type="submit"
                  text={<Trans>Save</Trans>}
                />
              </Box>
            </GridContainer>
          </form>
        )
      }}
    </Form>
  )
}

PhaseForm.propTypes = {
  handleSubmit: PropTypes.func,
  initValues: PropTypes.object,
  estimation: PropTypes.object,
  estimationLoading: PropTypes.bool,
}

export default compose(
  withStyles(odhadyStyle),
  connect((store) => {
    return {
      estimation: store.odhadomat.estimation,
      estimationLoading: store.odhadomat.estimationLoading,
    }
  })
)(PhaseForm)
