const fieldStyle = (theme) => ({
  formControl: {
    minWidth: 120,
    width: '100%',

    '& .MuiInputLabel-root': {
      fontSize: '14px !important',
    },
    '& .MuiInputAdornment-root': {
      fontSize: '14px !important',
    },
  },

  inputFontSize: {
    fontSize: '14px !important',

    '& .MuiInputBase-input': {
      fontSize: '14px !important',
    },

    '& .MuiInputLabel-root': {
      fontSize: '14px !important',
    },
    '& .MuiInputAdornment-root': {
      fontSize: '14px !important',
    },
  },

  // chips used inside multi select
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  chip: {
    margin: 2,
    height: 20,
    fontSize: 12,
  },

  // menu item inside the select paper
  menuItem: {
    boxSizing: 'border-box',
    paddingTop: 0,
    paddingBottom: 0,
  },

  // number inputs
  inputJustifyRight: {
    '& input': {
      textAlign: 'end',
    },
  },

  // date input full width
  datePickerInput: {
    width: '100%',
    fontSize: '14px !important',
    '& .MuiInputBase-input': {
      fontSize: '14px !important',
    },
    '& .MuiFormControl-root': {
      width: '100%',
    },
    '& .MuiIconButton-root': {
      padding: 8,
    },
  },
  datePickerInputIconSmall: {
    '& .MuiIconButton-root': {
      padding: 8,
    },
  },
  // error || helper text color
  formHelperText: {
    '&.MuiFormHelperText-root': {
      color: theme.palette.error.main,
      lineHeight: 1.2,
      fontSize: 11,
      margin: '4px 12px',
    },
  },
  inputFormHelperText: {
    '& .MuiFormHelperText-root': {
      color: theme.palette.error.main,
      lineHeight: 1.2,
      fontSize: 11,
      margin: '4px 12px',
    },
  },
  noDataText: {
    color: theme.palette.text.disabled,
  },
  selectMenuItem: {
    fontSize: '13px !important',
  },

  // loading indicator in select field
  loadingEndAdornment: {
    paddingRight: '20px',
    '& .MuiCircularProgress-root': {
      width: '20px !important',
      height: '20px !important',
      position: 'absolute',
      right: 40,
    },
  },

  multiSelectPaddingEnhance: {
    '& .MuiSelect-select.MuiSelect-select': {
      padding: '8px 14px 8px 14px',
    },
  },

  disabledCursor: {
    '& .MuiSelect-select.Mui-disabled': {
      cursor: 'not-allowed !important',

      '& .MuiChip-root': {
        cursor: 'not-allowed !important',
      },
    },
  },
})

export default fieldStyle
