import React, {useState} from 'react'
import WarningDialog from 'component/material/WarningDialog'

export const useWarningDialog = () => {
  const [open, setOpen] = useState(false)
  const [data, setData] = useState(null)

  const onOpen = (data) => () => {
    setOpen(true)
    setData(data)
  }

  const onClose = () => {
    setOpen(false)
    setData(null)
  }

  const WarningDialogComponent = (props) => {
    const {message, onConfirm} = props
    return (
      <WarningDialog
        open={open}
        handleClose={onClose}
        handleConfirm={onConfirm}
        message={message}
      />
    )
  }

  return [WarningDialogComponent, data, onOpen, onClose]
}
