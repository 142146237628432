import {
  GET_PROJECT_LIST_FAIL,
  GET_PROJECT_LIST_REQUEST,
  GET_PROJECT_LIST_SUCCESS,
  GET_EMPLOYEES_FAIL,
  GET_EMPLOYEES_REQUEST,
  GET_EMPLOYEES_SUCCESS,
  GET_ACCEPTED_INVOICES_REQUEST,
  GET_ACCEPTED_INVOICES_SUCCESS,
  GET_ACCEPTED_INVOICES_FAIL,
  GET_ISSUED_INVOICES_REQUEST,
  GET_ISSUED_INVOICES_SUCCESS,
  GET_ISSUED_INVOICES_FAIL,
  GET_WORK_REPORTS_SUCCESS,
  GET_WORK_REPORTS_REQUEST,
  GET_WORK_REPORTS_FAIL,
  GET_INDIRECT_EXPENSES_REQUEST,
  GET_INDIRECT_EXPENSES_FAIL,
  GET_INDIRECT_EXPENSES_SUCCESS,
  GET_CLIENT_LIST_REQUEST,
  GET_CLIENT_LIST_SUCCESS,
  GET_CLIENT_LIST_FAIL,
  GET_PROJECT_ASSIGNMENTS_REQUEST,
  GET_PROJECT_ASSIGNMENTS_SUCCESS,
  GET_PROJECT_ASSIGNMENTS_FAIL,
  GET_ELUVIA_COMPANIES_REQUEST,
  GET_ELUVIA_COMPANIES_SUCCESS,
  GET_ELUVIA_COMPANIES_FAIL,
  GET_CLIENT_COMPANIES_REQUEST,
  GET_CLIENT_COMPANIES_SUCCESS,
  GET_CLIENT_COMPANIES_FAIL,
  GET_EMPLOYEE_COMPANIES_REQUEST,
  GET_EMPLOYEE_COMPANIES_SUCCESS,
  GET_EMPLOYEE_COMPANIES_FAIL,
  GET_COMPANIES_REQUEST,
  GET_COMPANIES_SUCCESS,
  GET_COMPANIES_FAIL,
  GET_EMPLOYEE_ASSIGNMENT_REQUEST,
  GET_EMPLOYEE_ASSIGNMENT_SUCCESS,
  GET_EMPLOYEE_ASSIGNMENT_FAIL,
  GET_EMPLOYEE_WAGES_REQUEST,
  GET_EMPLOYEE_WAGES_SUCCESS,
  GET_EMPLOYEE_WAGES_FAIL,
  GET_DASHBOARD_ASSIGNMENT_REQUEST,
  GET_DASHBOARD_ASSIGNMENT_SUCCESS,
  GET_DASHBOARD_ASSIGNMENT_FAIL,
} from 'redux/actionType'

const initState = {
  projects: [],
  projectsLoading: false,
  projectsMeta: {},

  employees: [],
  employeesLoading: false,
  employeesMeta: {},

  employeeAssignments: [],
  employeeAssignmentsMeta: {},
  employeeAssignmentsLoading: false,

  acceptedInvoices: [],
  acceptedInvoicesLoading: false,
  acceptedInvoicesMeta: {},

  issuedInvoices: [],
  issuedInvoicesLoading: false,
  issuedInvoicesMeta: {},

  workReports: [],
  workReportsLoading: false,
  workReportsMeta: {},

  indirectExpenses: [],
  indirectExpensesLoading: false,
  indirectExpensesMeta: {},

  clients: [],
  clientsLoading: false,
  clientsMeta: {},

  projectAssignments: [],
  projectAssignmentsMeta: {},
  projectAssignmentsLoading: false,

  companies: [],
  companiesLoading: false,
  companiesMeta: {},

  eluviaCompanies: [],
  eluviaCompaniesLoading: false,
  eluviaCompaniesMeta: {},

  clientCompanies: [],
  clientCompaniesLoading: false,
  clientCompaniesMeta: {},

  employeeCompanies: [],
  employeeCompaniesLoading: false,
  employeeCompaniesMeta: {},

  employeeWages: [],
  employeeWagesLoading: false,
  employeeWagesMeta: {},

  dashboard: [],
  dashboardLoading: false,
  dashboardMeta: {},
}

const projektomatReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_PROJECT_LIST_REQUEST: {
      return {
        ...state,
        projectsLoading: true,
      }
    }
    case GET_PROJECT_LIST_SUCCESS: {
      return {
        ...state,
        projectsLoading: false,
        projects: action.data.objects,
        projectsMeta: action.data.meta,
      }
    }
    case GET_PROJECT_LIST_FAIL: {
      return {
        ...state,
        projectsLoading: false,
      }
    }
    case GET_EMPLOYEES_REQUEST: {
      return {
        ...state,
        employeesLoading: true,
      }
    }
    case GET_EMPLOYEES_SUCCESS: {
      return {
        ...state,
        employeesLoading: false,
        employees: action.data.objects,
        employeesMeta: action.data.meta,
      }
    }
    case GET_EMPLOYEES_FAIL: {
      return {
        ...state,
        employeesLoading: false,
      }
    }
    case GET_EMPLOYEE_ASSIGNMENT_REQUEST: {
      return {
        ...state,
        employeeAssignmentsLoading: true,
      }
    }
    case GET_EMPLOYEE_ASSIGNMENT_SUCCESS: {
      return {
        ...state,
        employeeAssignments: action.data.objects,
        employeeAssignmentsLoading: false,
        employeeAssignmentsMeta: action.data.meta,
      }
    }
    case GET_EMPLOYEE_ASSIGNMENT_FAIL: {
      return {
        ...state,
        employeeAssignmentsLoading: false,
      }
    }
    case GET_ACCEPTED_INVOICES_REQUEST: {
      return {
        ...state,
        acceptedInvoicesLoading: true,
      }
    }
    case GET_ACCEPTED_INVOICES_SUCCESS: {
      return {
        ...state,
        acceptedInvoicesLoading: false,
        acceptedInvoices: action.data.objects,
        acceptedInvoicesMeta: action.data.meta,
      }
    }
    case GET_ACCEPTED_INVOICES_FAIL: {
      return {
        ...state,
        acceptedInvoicesLoading: false,
      }
    }
    case GET_ISSUED_INVOICES_REQUEST: {
      return {
        ...state,
        issuedInvoicesLoading: true,
      }
    }
    case GET_ISSUED_INVOICES_SUCCESS: {
      return {
        ...state,
        issuedInvoices: action.data.objects,
        issuedInvoicesLoading: false,
        issuedInvoicesMeta: action.data.meta,
      }
    }
    case GET_ISSUED_INVOICES_FAIL: {
      return {
        ...state,
        issuedInvoicesLoading: false,
      }
    }
    case GET_WORK_REPORTS_REQUEST: {
      return {
        ...state,
        workReportsLoading: true,
      }
    }
    case GET_WORK_REPORTS_SUCCESS: {
      return {
        ...state,
        workReports: action.data.objects,
        workReportsLoading: false,
        workReportsMeta: action.data.meta,
      }
    }
    case GET_WORK_REPORTS_FAIL: {
      return {
        ...state,
        workReportsLoading: false,
      }
    }
    case GET_INDIRECT_EXPENSES_REQUEST: {
      return {
        ...state,
        indirectExpensesLoading: true,
      }
    }
    case GET_INDIRECT_EXPENSES_SUCCESS: {
      return {
        ...state,
        indirectExpenses: action.data.objects,
        indirectExpensesLoading: false,
        indirectExpensesMeta: action.data.meta,
      }
    }
    case GET_INDIRECT_EXPENSES_FAIL: {
      return {
        ...state,
        indirectExpensesLoading: false,
      }
    }
    case GET_CLIENT_LIST_REQUEST: {
      return {
        ...state,
        clientsLoading: true,
      }
    }
    case GET_CLIENT_LIST_SUCCESS: {
      return {
        ...state,
        clientsLoading: false,
        clients: action.data.objects,
        clientsMeta: action.data.meta,
      }
    }
    case GET_CLIENT_LIST_FAIL: {
      return {
        ...state,
        clientsLoading: false,
      }
    }
    case GET_PROJECT_ASSIGNMENTS_REQUEST: {
      return {
        ...state,
        projectAssignmentsLoading: true,
        projectAssignments: [],
        projectAssignmentsMeta: {},
      }
    }
    case GET_PROJECT_ASSIGNMENTS_SUCCESS: {
      return {
        ...state,
        projectAssignmentsLoading: false,
        projectAssignments: action.data.objects,
        projectAssignmentsMeta: action.data.meta,
      }
    }
    case GET_PROJECT_ASSIGNMENTS_FAIL: {
      return {
        ...state,
        projectAssignmentsLoading: false,
      }
    }
    case GET_ELUVIA_COMPANIES_REQUEST: {
      return {
        ...state,
        eluviaCompaniesLoading: true,
      }
    }
    case GET_ELUVIA_COMPANIES_SUCCESS: {
      return {
        ...state,
        eluviaCompaniesLoading: false,
        eluviaCompanies: action.data.objects,
        eluviaCompaniesMeta: action.data.meta,
      }
    }
    case GET_ELUVIA_COMPANIES_FAIL: {
      return {
        ...state,
        eluviaCompaniesLoading: false,
      }
    }
    case GET_CLIENT_COMPANIES_REQUEST: {
      return {
        ...state,
        clientCompaniesLoading: true,
      }
    }
    case GET_CLIENT_COMPANIES_SUCCESS: {
      return {
        ...state,
        clientCompaniesLoading: false,
        clientCompanies: action.data.objects,
        clientCompaniesMeta: action.data.meta,
      }
    }
    case GET_CLIENT_COMPANIES_FAIL: {
      return {
        ...state,
        clientCompaniesLoading: false,
      }
    }
    case GET_EMPLOYEE_COMPANIES_REQUEST: {
      return {
        ...state,
        employeeCompaniesLoading: true,
      }
    }
    case GET_EMPLOYEE_COMPANIES_SUCCESS: {
      return {
        ...state,
        employeeCompaniesLoading: false,
        employeeCompanies: action.data.objects,
        employeeCompaniesMeta: action.data.meta,
      }
    }
    case GET_EMPLOYEE_COMPANIES_FAIL: {
      return {
        ...state,
        employeeCompaniesLoading: false,
      }
    }
    case GET_COMPANIES_REQUEST: {
      return {
        ...state,
        companiesLoading: true,
      }
    }
    case GET_COMPANIES_SUCCESS: {
      return {
        ...state,
        companiesLoading: false,
        companies: action.data.objects,
        companiesMeta: action.data.meta,
      }
    }
    case GET_COMPANIES_FAIL: {
      return {
        ...state,
        companiesLoading: false,
      }
    }
    case GET_EMPLOYEE_WAGES_REQUEST: {
      return {
        ...state,
        employeeWagesLoading: true,
      }
    }
    case GET_EMPLOYEE_WAGES_SUCCESS: {
      return {
        ...state,
        employeeWages: action.data.objects,
        employeeWagesLoading: false,
        employeeWagesMeta: action.data.meta,
      }
    }
    case GET_EMPLOYEE_WAGES_FAIL: {
      return {
        ...state,
        employeeWagesLoading: false,
      }
    }
    case GET_DASHBOARD_ASSIGNMENT_REQUEST: {
      return {
        ...state,
        dashboardLoading: true,
      }
    }
    case GET_DASHBOARD_ASSIGNMENT_SUCCESS: {
      return {
        ...state,
        dashboard: action.data.objects,
        dashboardLoading: false,
        dashboardMeta: action.data.meta,
      }
    }
    case GET_DASHBOARD_ASSIGNMENT_FAIL: {
      return {
        ...state,
        dashboardLoading: false,
      }
    }
    default: {
      return state
    }
  }
}

export default projektomatReducer
