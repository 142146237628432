import React, {useState} from 'react'
import Table from 'component/material/table/Table'
import {Trans} from '@lingui/macro'
import PropTypes from 'prop-types'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import DialogWindow from 'component/material/DialogWindow'
import EmployeeAssignments from 'component/ProjektomatPage/EmployeeAssignments'
import EmployeeWages from 'component/ProjektomatPage/EmployeeWages'
import {ListAlt, MonetizationOnOutlined} from '@material-ui/icons'
import TableIconButton from 'component/general/TableIconButton'

const EmployeeTable = (props) => {
  const {
    data,
    loading,
    onSortClick,
    orderBy,
    orderDirection,
    page,
    onChangePage,
    onChangeRowsPerPage,
    count,
  } = props

  const [openEmployeeAssignments, setOpenEmployeeAssignments] = useState({
    visible: false,
    emp: null,
  })
  const [openEmployeeWages, setOpenEmployeeWages] = useState({visible: false, emp: null})

  const handleOpenEmployeeWages = (row) => () => {
    setOpenEmployeeWages({visible: true, emp: row})
  }
  const handleCloseEmployeeWages = () => {
    setOpenEmployeeWages({visible: false, emp: null})
  }

  const handleOpenEmployeeAssignment = (row) => () => {
    setOpenEmployeeAssignments({visible: true, emp: row})
  }

  const handleCloseEmployeeAssignment = () => {
    setOpenEmployeeAssignments({visible: false, emp: null})
  }

  const columns = [
    {
      name: 'name',
      orderKey: 'name',
      label: <Trans>Name</Trans>,
    },
    {
      name: 'email',
      orderKey: 'email',
      label: <Trans>Email</Trans>,
    },
    {
      name: '',
      align: 'right',
      render: (val, col, rowIndex, row) => (
        <GridContainer spacing={2} direction={'row'} wrap={'nowrap'} justifyContent={'flex-end'}>
          <GridItem>
            <TableIconButton
              tooltipTitle={<Trans>Display projects and roles on them</Trans>}
              onClick={handleOpenEmployeeAssignment(row)}
              Icon={ListAlt}
            />
          </GridItem>
          <GridItem>
            <TableIconButton
              tooltipTitle={<Trans>View employee wages</Trans>}
              onClick={handleOpenEmployeeWages(row)}
              Icon={MonetizationOnOutlined}
            />
          </GridItem>
        </GridContainer>
      ),
    },
  ]

  return (
    <>
      {/* EMPLOYEE ASSIGNMENT DIALOG */}
      <DialogWindow
        onClose={handleCloseEmployeeAssignment}
        open={openEmployeeAssignments.visible}
        title={<Trans>Projects and roles on them</Trans>}
        maxWidth={'md'}
        fullWidth={true}
      >
        <EmployeeAssignments
          open={openEmployeeAssignments.visible}
          employee={openEmployeeAssignments.emp}
        />
      </DialogWindow>

      {/* EMPLOYEE WAGES DIALOG */}
      <DialogWindow
        onClose={handleCloseEmployeeWages}
        open={openEmployeeWages.visible}
        title={<Trans>Wages table - {openEmployeeWages.emp?.name}</Trans>}
        maxWidth={'md'}
        fullWidth={true}
      >
        <EmployeeWages open={openEmployeeWages.visible} employee={openEmployeeWages.emp} />
      </DialogWindow>

      <Table
        columns={columns}
        data={data}
        loading={loading}
        orderDirection={orderDirection}
        orderBy={orderBy}
        onSortClick={onSortClick}
        count={count}
        page={page}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        pagination={true}
      />
    </>
  )
}

EmployeeTable.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  onSortClick: PropTypes.func,
  orderBy: PropTypes.string,
  orderDirection: PropTypes.string,
  page: PropTypes.number,
  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
  count: PropTypes.number,
  periodId: PropTypes.string,
  fetchEmployees: PropTypes.func,
}

export default EmployeeTable
