import {createTheme} from '@material-ui/core/styles'

const defaultTheme = createTheme({
  typography: {
    // htmlFontSize sets size of 1rem for app
    htmlFontSize: 16,
    fontSize: 14,
    fontFamily: ['Montserrat', 'Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    h1: {
      fontFamily: "'Montserrat', 'Roboto', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 300,
      fontSize: '5rem',
      lineHeight: 1.167,
      letterSpacing: '-0.01562em',
    },
    h2: {
      fontFamily: "'Montserrat', 'Roboto', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 300,
      fontSize: '3.75rem',
      lineHeight: 1.2,
      letterSpacing: '-0.00833em',
    },
    h3: {
      fontFamily: "'Montserrat', 'Roboto', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 400,
      fontSize: '3rem',
      lineHeight: 1.167,
      letterSpacing: '0em',
    },
    h4: {
      fontFamily: "'Montserrat', 'Roboto', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 400,
      fontSize: '2.125rem',
      lineHeight: 1.235,
      letterSpacing: '0.00735em',
    },
    h5: {
      fontFamily: "'Montserrat', 'Roboto', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 400,
      fontSize: '1.45rem',
      lineHeight: 1.334,
      letterSpacing: '0em',
    },
    h6: {
      fontFamily: "'Montserrat', 'Roboto', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: 1,
      letterSpacing: '0.0075em',
    },
    body1: {
      fontFamily: "'Montserrat', 'Roboto', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: 1.5,
      letterSpacing: '0.00938em',
    },
    body2: {
      fontFamily: "'Montserrat', 'Roboto', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: 1.43,
      letterSpacing: '0.01071em',
    },
    button: {
      fontFamily: "'Montserrat', 'Roboto', 'Helvetica', 'Arial', sans-serif",
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: 1.75,
      letterSpacing: '0.02857em',
      textTransform: 'uppercase',
    },
  },
  palette: {
    // color shades generated by https://material-ui.com/customization/color/#color playground
    primary: {
      light: '#446695',
      main: '#16407B',
      dark: '#0f2c56',
      contrastText: '#F8BAA0',
    },
    secondary: {
      light: '#f9c7b3',
      main: '#F8BAA0',
      dark: '#ad8270',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    success: {
      light: '#81c784',
      main: '#4caf50',
      dark: '#388e3c',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    info: {
      light: '#64b5f6',
      main: '#2196f3',
      dark: '#1976d2',
      contrastText: '#fff',
    },
    warning: {
      light: '#ffb74d',
      main: '#ff9800',
      dark: '#f57c00',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
      contrastText: '#fff',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
      white: '#ffffff',
    },
    divider: 'rgba(0, 0, 0, 0.12)',
    white: '#ffffff',
    black: '#000000',
    border: '#c4c4c4',
    background: {
      paper: '#fff',
      default: '#fafafa',
      tableHead: '#eaeaea',
      tableRow: '#efefef',
    },
  },
  // border radius of input fields and buttons
  shape: {
    borderRadius: 0,
  },
  // spacing sets size of padding and margin
  spacing: 4,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
})

export default defaultTheme
