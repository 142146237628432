import React, {useEffect, useState} from 'react'
import {Trans} from '@lingui/macro'
import {bindActionCreators, compose} from 'redux'
import withStyles from '@material-ui/core/styles/withStyles'
import stricklandStyle from 'component/Strickland/stricklandStyle'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import BoxFullWidth from 'component/material/BoxFullWidth'
import TabPanel from 'component/material/TabPanel'
import PotentialTableStrick from 'component/Strickland/Potential/PotentialTableStrick'
import {getProjectsPotentialStrickland} from 'redux/action/stricklandAction'
import moment from 'moment'
import PrivateContentLayout from 'layout/PrivateLayout/PrivateContentLayout'

const PotentialStrick = (props) => {
  const {classes, projectPotentialLoading, projectPotential, getProjectsPotentialStrickland} = props

  const [value, setValue] = useState(0)
  const [listOfTabs, setListOfTabs] = useState([])

  const handleChangeTab = (e, newValue) => {
    setValue(newValue)
    fetchProjectsPotential(listOfTabs, newValue)
  }

  const prepareTabs = () => {
    let tabs = []

    const previousYear = moment().subtract(1, 'year').year()

    for (let i = 1; i <= 4; i++) {
      tabs.push({
        label: `Q${i} ${previousYear?.toString()?.slice(-2)}`,
        value: `${previousYear}-Q-0${i}`,
      })
      // if last quarter, put year period as well
      if (i === 4) {
        tabs.push({
          label: `Y ${previousYear?.toString()?.slice(-2)}`,
          value: `${previousYear}-Y-01`,
        })
      }
    }

    const currentQuarter = moment().quarter()
    const currentYear = moment().year()

    for (let i = 1; i <= currentQuarter - 1; i++) {
      tabs.push({
        label: `Q${i} ${currentYear?.toString()?.slice(-2)}`,
        value: `${currentYear}-Q-0${i}`,
      })
    }

    setListOfTabs(tabs)
    setValue(tabs.length - 1)

    fetchProjectsPotential(tabs, tabs.length - 1)
  }

  const fetchProjectsPotential = (tabs, val) => {
    const periodId = tabs?.[val]?.value
    getProjectsPotentialStrickland(periodId)
  }

  useEffect(() => {
    prepareTabs()
  }, [])

  return (
    <PrivateContentLayout
      title={<Trans>Potential</Trans>}
      contentPadding={0}
      content={
        <>
          <AppBar
            position="static"
            color="default"
            elevation={0}
            className={classes.appBarBottomBorder}
          >
            <Tabs
              value={value}
              className={classes.tabs}
              onChange={handleChangeTab}
              indicatorColor="secondary"
              variant="scrollable"
              scrollButtons="auto"
            >
              {listOfTabs.map((tab, index) => (
                <Tab
                  key={index}
                  index={index}
                  label={
                    <>
                      {tab.label} {tab.year}
                    </>
                  }
                />
              ))}
            </Tabs>
          </AppBar>
          <BoxFullWidth p={4} pt={2}>
            <div className={classes.root}>
              {/* TAB PANELS */}
              {listOfTabs.map((tab, index) => (
                <TabPanel value={value} index={index} key={index} className={classes.overflowAuto}>
                  <PotentialTableStrick
                    classes={classes}
                    data={projectPotential}
                    loading={projectPotentialLoading}
                  />
                </TabPanel>
              ))}
            </div>
          </BoxFullWidth>
        </>
      }
    />
  )
}

PotentialStrick.propTypes = {
  classes: PropTypes.object,
  projectPotentialLoading: PropTypes.bool,
  projectPotential: PropTypes.array,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getProjectsPotentialStrickland,
    },
    dispatch
  )
}

export default compose(
  withStyles(stricklandStyle),
  connect((store) => {
    return {
      projectPotential: store.strickland.projectPotential,
      projectPotentialLoading: store.strickland.projectPotentialLoading,
    }
  }, mapDispatchToProps)
)(PotentialStrick)
