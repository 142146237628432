import {Trans} from '@lingui/macro'
import React from 'react'
import moment from 'moment'
import {DATE_FORMAT, DB_DATE_TIME_FORMAT} from 'helper/constants'
import Table from '@material-ui/core/Table'

const PiggyTable = (props) => {
  const {data, loading} = props

  const columns = [
    {
      name: 'id',
      orderKey: 'id',
      label: <Trans>Id</Trans>,
    },
    {
      name: 'total_amount',
      orderKey: 'total_amount',
      label: <Trans>Total amount</Trans>,
    },
    {
      name: 'created_at',
      orderKey: 'created_at',
      label: <Trans>Created at</Trans>,
      render: (val) => val && moment(val, DB_DATE_TIME_FORMAT)?.format(DATE_FORMAT),
    },
    {
      name: 'updated_at',
      orderKey: 'updated_at',
      label: <Trans>Updated at</Trans>,
      render: (val) => val && moment(val, DB_DATE_TIME_FORMAT)?.format(DATE_FORMAT),
    },
  ]

  return <Table columns={columns} data={data} loading={loading} />
}

export default PiggyTable
