import React from 'react'
import {redirectTo} from 'helper/functions'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import {Trans} from '@lingui/macro'
import withStyles from '@material-ui/core/styles/withStyles'
import generalStyle from 'component/general/generalStyle'

const BackText = (props) => {
  const {classes, path} = props

  const handleClick = () => {
    redirectTo(path)
  }

  return (
    <div className={classes.backButton} onClick={handleClick}>
      <ArrowBackIosIcon />
      <Trans>Back</Trans>
    </div>
  )
}

export default withStyles(generalStyle)(BackText)
