import {
  GET_DASHBOARD_ASSIGNMENTS_REQUEST,
  GET_DASHBOARD_ASSIGNMENTS_SUCCESS,
  GET_DASHBOARD_ASSIGNMENTS_FAIL,
  GET_DASHBOARD_ASSIGNMENTS_NEXT_REQUEST,
  GET_DASHBOARD_ASSIGNMENTS_NEXT_SUCCESS,
  GET_DASHBOARD_ASSIGNMENTS_NEXT_FAIL,
} from 'redux/actionType'

const initState = {
  current: [],
  currentLoading: false,
  currentMeta: {},
  next: [],
  nextLoading: false,
  nextMeta: {},
}

const dashboardReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_DASHBOARD_ASSIGNMENTS_REQUEST: {
      return {
        ...state,
        currentLoading: true,
      }
    }
    case GET_DASHBOARD_ASSIGNMENTS_SUCCESS: {
      return {
        ...state,
        currentLoading: false,
        current: action.data.objects,
        currentSummary: action.data.summary,
        currentMeta: action.data.meta,
      }
    }
    case GET_DASHBOARD_ASSIGNMENTS_FAIL: {
      return {
        ...state,
        currentLoading: false,
      }
    }
    case GET_DASHBOARD_ASSIGNMENTS_NEXT_REQUEST: {
      return {
        ...state,
        nextLoading: true,
      }
    }
    case GET_DASHBOARD_ASSIGNMENTS_NEXT_SUCCESS: {
      return {
        ...state,
        nextLoading: false,
        next: action.data.objects,
        nextSummary: action.data.summary,
        nextMeta: action.data.meta,
      }
    }
    case GET_DASHBOARD_ASSIGNMENTS_NEXT_FAIL: {
      return {
        ...state,
        nextLoading: false,
      }
    }
    default: {
      return state
    }
  }
}

export default dashboardReducer
