import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import PropTypes from 'prop-types'
import Chip from '@material-ui/core/Chip'
import MenuItem from '@material-ui/core/MenuItem'
import Checkbox from '@material-ui/core/Checkbox'
import ListItemText from '@material-ui/core/ListItemText'
import withStyles from '@material-ui/core/styles/withStyles'
import fieldStyle from 'component/field/fieldStyle'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputAdornment from '@material-ui/core/InputAdornment'
import CircularProgress from '@material-ui/core/CircularProgress'
import cx from 'classnames'

/**
 * React-final-form needs to have values of multi select in type of number
 * therefore the array options for select may look like:
 * { 0: 'Analýza', 1: 'Návrh API' }
 */

const MultiSelectInput = (props) => {
  const {
    classes,
    label,
    input,
    meta,
    options,
    onBlur,
    helperText,
    showHelperText = true,
    variant = 'outlined',
    loading,
    size = 'medium',
    optionsRenderFunction,
    ...rest
  } = props

  const errorMessage = meta && meta.touched && meta.error
  const errorHelperText = showHelperText && (errorMessage || helperText || ' ')

  const multiselectClass = input?.value?.length !== 0

  return (
    <FormControl
      disabled={props.disabled}
      size={size}
      error={!!errorMessage}
      variant={variant}
      className={classes.formControl}
    >
      <InputLabel>{label}</InputLabel>
      <Select
        className={cx(
          multiselectClass ? classes.multiSelectPaddingEnhance : '',
          props.disabled ? classes.disabledCursor : '',
          classes.inputFontSize
        )}
        multiple
        {...input}
        value={[...input.value]}
        onBlur={() => {
          onBlur && onBlur(input?.value, input?.name)
          input?.onBlur()
        }}
        label={label}
        renderValue={(selected) => (
          <div className={classes.chips}>
            {selected.map((value) => (
              <Chip size={'small'} key={value} label={options[value]} className={classes.chip} />
            ))}
          </div>
        )}
        endAdornment={
          loading && (
            <InputAdornment position="end" className={classes.loadingEndAdornment}>
              <CircularProgress fontSize={'small'} color="secondary" />
            </InputAdornment>
          )
        }
        {...rest}
      >
        {optionsRenderFunction
          ? optionsRenderFunction(input.value, classes)
          : Object.entries(options).map(([key, value], index) => (
              <MenuItem key={index} value={parseInt(key)} className={classes.menuItem}>
                <Checkbox size={'small'} checked={input.value.includes(parseInt(key))} />
                <ListItemText primary={value} primaryTypographyProps={{variant: 'body2'}} />
              </MenuItem>
            ))}
      </Select>
      {showHelperText && (
        <FormHelperText className={classes.formHelperText}>{errorHelperText}</FormHelperText>
      )}
    </FormControl>
  )
}

MultiSelectInput.propTypes = {
  classes: PropTypes.object,
  label: PropTypes.object,
  input: PropTypes.object,
  meta: PropTypes.object,
  options: PropTypes.object,
  variant: PropTypes.string,
  onBlur: PropTypes.func,
  showHelperText: PropTypes.bool,
  helperText: PropTypes.string,
  loading: PropTypes.bool,
}

export default withStyles(fieldStyle)(MultiSelectInput)
