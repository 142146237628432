import {
  GET_ACCEPTED_INVOICES_FAIL,
  GET_ACCEPTED_INVOICES_REQUEST,
  GET_ACCEPTED_INVOICES_SUCCESS,
  GET_CLIENT_COMPANIES_FAIL,
  GET_CLIENT_COMPANIES_REQUEST,
  GET_CLIENT_COMPANIES_SUCCESS,
  GET_CLIENT_LIST_FAIL,
  GET_CLIENT_LIST_REQUEST,
  GET_CLIENT_LIST_SUCCESS,
  GET_DASHBOARD_ASSIGNMENT_FAIL,
  GET_DASHBOARD_ASSIGNMENT_REQUEST,
  GET_DASHBOARD_ASSIGNMENT_SUCCESS,
  GET_ELUVIA_COMPANIES_FAIL,
  GET_ELUVIA_COMPANIES_REQUEST,
  GET_ELUVIA_COMPANIES_SUCCESS,
  GET_EMPLOYEE_ASSIGNMENT_FAIL,
  GET_EMPLOYEE_ASSIGNMENT_REQUEST,
  GET_EMPLOYEE_ASSIGNMENT_SUCCESS,
  GET_EMPLOYEE_COMPANIES_FAIL,
  GET_EMPLOYEE_COMPANIES_REQUEST,
  GET_EMPLOYEE_COMPANIES_SUCCESS,
  GET_EMPLOYEE_WAGES_FAIL,
  GET_EMPLOYEE_WAGES_REQUEST,
  GET_EMPLOYEE_WAGES_SUCCESS,
  GET_EMPLOYEES_FAIL,
  GET_EMPLOYEES_REQUEST,
  GET_EMPLOYEES_SUCCESS,
  GET_INDIRECT_EXPENSES_FAIL,
  GET_INDIRECT_EXPENSES_REQUEST,
  GET_INDIRECT_EXPENSES_SUCCESS,
  GET_ISSUED_INVOICES_FAIL,
  GET_ISSUED_INVOICES_REQUEST,
  GET_ISSUED_INVOICES_SUCCESS,
  GET_PROJECT_ASSIGNMENTS_FAIL,
  GET_PROJECT_ASSIGNMENTS_REQUEST,
  GET_PROJECT_ASSIGNMENTS_SUCCESS,
  GET_PROJECT_LIST_FAIL,
  GET_PROJECT_LIST_REQUEST,
  GET_PROJECT_LIST_SUCCESS,
  GET_WORK_REPORTS_FAIL,
  GET_WORK_REPORTS_REQUEST,
  GET_WORK_REPORTS_SUCCESS,
  UPDATE_GLOBAL_LOADING,
} from 'redux/actionType'
import httpClient from 'helper/httpClient'
import endpoints from 'helper/endpoints'
import {fireErrorToast, fireSuccessToast, globalApiErrorHandler} from 'helper/functions'
import {Trans} from '@lingui/macro'
import React from 'react'

export const getProjectList = (limit, offset, show_archived, filters) => {
  return (dispatch) => {
    dispatch({type: GET_PROJECT_LIST_REQUEST})
    return httpClient
      .get(endpoints.projects, {
        limit: limit,
        offset: offset,
        show_archived: show_archived ? 1 : null,
        order_by:
          filters?.orderBy &&
          filters?.orderDirection &&
          `${filters?.orderBy}:${filters?.orderDirection}`,
      })
      .then((res) => {
        dispatch({type: GET_PROJECT_LIST_SUCCESS, data: res.data})
      })
      .catch((err) => {
        dispatch({type: GET_PROJECT_LIST_FAIL})
        globalApiErrorHandler(err)
      })
  }
}

export const getEmployeeList = (limit, offset, show_archived, filters) => {
  return (dispatch) => {
    dispatch({type: GET_EMPLOYEES_REQUEST})
    return httpClient
      .get(endpoints.employees, {
        limit: limit,
        offset: offset,
        show_archived: show_archived ? 1 : null,
        order_by:
          filters?.orderBy &&
          filters?.orderDirection &&
          `${filters?.orderBy}:${filters?.orderDirection}`,
      })
      .then((res) => {
        dispatch({type: GET_EMPLOYEES_SUCCESS, data: res.data})
      })
      .catch((err) => {
        dispatch({type: GET_EMPLOYEES_FAIL})
        globalApiErrorHandler(err)
      })
  }
}

export const getEmployeeAssignments = (id, limit, offset, filters) => {
  return (dispatch) => {
    dispatch({type: GET_EMPLOYEE_ASSIGNMENT_REQUEST})
    return httpClient
      .get(`${endpoints.employees}/${id}/assignments`, {
        limit: limit,
        offset: offset,
        order_by:
          filters?.orderBy &&
          filters?.orderDirection &&
          `${filters?.orderBy}:${filters?.orderDirection}`,
      })
      .then((res) => {
        dispatch({type: GET_EMPLOYEE_ASSIGNMENT_SUCCESS, data: res.data})
      })
      .catch((err) => {
        dispatch({type: GET_EMPLOYEE_ASSIGNMENT_FAIL})
        globalApiErrorHandler(err)
      })
  }
}

export const patchEmployeeAssignment = (empId, assId, data) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .patch(`${endpoints.employees}/${empId}/assignments/${assId}`, {role_refs: data})
      .then((res) => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
        fireSuccessToast(<Trans>Eluvian role updated.</Trans>)
        return res.data
      })
      .catch((err) => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
        if (err.response?.status === 422 && err.response?.data?.errors?.length) {
          return Promise.reject(err?.response?.data?.errors)
        } else {
          return globalApiErrorHandler(err)
        }
      })
  }
}

export const getAcceptedInvoices = (limit, offset, filters) => {
  return (dispatch) => {
    dispatch({type: GET_ACCEPTED_INVOICES_REQUEST})
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .get(endpoints.acceptedInvoices, {
        limit: limit,
        offset: offset,
        employee_email: filters?.employee_email,
        define_date_from: filters?.define_date_from,
        define_date_to: filters?.define_date_to,
        issuer_company_id: filters?.issuer_company_id,
        acceptor_company_id: filters?.acceptor_company_id,
        order_by:
          filters?.orderBy &&
          filters?.orderDirection &&
          `${filters?.orderBy}:${filters?.orderDirection}`,
      })
      .then((res) => {
        dispatch({type: GET_ACCEPTED_INVOICES_SUCCESS, data: res.data})
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
      .catch((err) => {
        dispatch({type: GET_ACCEPTED_INVOICES_FAIL})
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
        globalApiErrorHandler(err)
      })
  }
}

export const postAcceptedInvoice = (data) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .post(endpoints.acceptedInvoices, data)
      .then((res) => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
        fireSuccessToast(<Trans>Accepted invoice created.</Trans>)
        return res.data
      })
      .catch((err) => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
        if (err.response?.status === 422 && err.response?.data?.errors?.length) {
          return Promise.reject(err?.response?.data?.errors)
        } else {
          return globalApiErrorHandler(err)
        }
      })
  }
}

export const putAcceptedInvoice = (id, data) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .put(`${endpoints.acceptedInvoices}/${id}`, data)
      .then((res) => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
        fireSuccessToast(<Trans>Accepted invoice updated.</Trans>)
        return res.data
      })
      .catch((err) => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
        if (err.response?.status === 422 && err.response?.data?.errors?.length) {
          return Promise.reject(err?.response?.data?.errors)
        } else {
          return globalApiErrorHandler(err)
        }
      })
  }
}

export const deleteAcceptedInvoice = (id) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .del(`${endpoints.acceptedInvoices}/${id}`)
      .then((res) => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
        fireSuccessToast(<Trans>Accepted invoice deleted.</Trans>)
      })
      .catch((err) => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
        globalApiErrorHandler(err)
      })
  }
}

export const getIssuedInvoices = (limit, offset, filters) => {
  return (dispatch) => {
    dispatch({type: GET_ISSUED_INVOICES_REQUEST})
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .get(endpoints.issuedInvoices, {
        limit: limit,
        offset: offset,
        project_ref: filters?.project_ref,
        define_date_from: filters?.define_date_from,
        define_date_to: filters?.define_date_to,
        issuer_company_id: filters?.issuer_company_id,
        acceptor_company_id: filters?.acceptor_company_id,
        order_by:
          filters?.orderBy &&
          filters?.orderDirection &&
          `${filters?.orderBy}:${filters?.orderDirection}`,
      })
      .then((res) => {
        dispatch({type: GET_ISSUED_INVOICES_SUCCESS, data: res.data})
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
      .catch((err) => {
        dispatch({type: GET_ISSUED_INVOICES_FAIL})
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
        globalApiErrorHandler(err)
      })
  }
}

export const postIssuedInvoice = (data) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .post(endpoints.issuedInvoices, data)
      .then((res) => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
        fireSuccessToast(<Trans>Issued invoice created.</Trans>)
        return res.data
      })
      .catch((err) => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
        if (err.response?.status === 422 && err.response?.data?.errors?.length) {
          return Promise.reject(err?.response?.data?.errors)
        } else {
          return globalApiErrorHandler(err)
        }
      })
  }
}

export const putIssuedInvoice = (id, data) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .put(`${endpoints.issuedInvoices}/${id}`, data)
      .then((res) => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
        fireSuccessToast(<Trans>Issued invoice updated.</Trans>)
        return res.data
      })
      .catch((err) => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
        if (err.response?.status === 422 && err.response?.data?.errors?.length) {
          return Promise.reject(err?.response?.data?.errors)
        } else {
          return globalApiErrorHandler(err)
        }
      })
  }
}

export const deleteIssuedInvoice = (id) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .del(`${endpoints.issuedInvoices}/${id}`)
      .then((res) => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
        fireSuccessToast(<Trans>Issued invoice deleted.</Trans>)
        return res.data
      })
      .catch((err) => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
        globalApiErrorHandler(err)
      })
  }
}

export const getWorkReports = (limit, offset, filters) => {
  return (dispatch) => {
    dispatch({type: GET_WORK_REPORTS_REQUEST})
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .get(endpoints.workReports, {
        limit: limit,
        offset: offset,
        project_ref: filters?.project_ref,
        date_from: filters?.date_from,
        date_to: filters?.date_to,
        order_by:
          filters?.orderBy &&
          filters?.orderDirection &&
          `${filters?.orderBy}:${filters?.orderDirection}`,
      })
      .then((res) => {
        dispatch({type: GET_WORK_REPORTS_SUCCESS, data: res.data})
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
      .catch((err) => {
        dispatch({type: GET_WORK_REPORTS_FAIL})
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
        globalApiErrorHandler(err)
      })
  }
}

export const postWorkReport = (data) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .post(endpoints.workReports, data)
      .then((res) => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
        fireSuccessToast(<Trans>Work report created.</Trans>)
        return res.data
      })
      .catch((err) => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
        if (err.response?.status === 422 && err.response?.data?.errors?.length) {
          return Promise.reject(err?.response?.data?.errors)
        } else {
          return globalApiErrorHandler(err)
        }
      })
  }
}

export const deleteWorkReport = (id) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .del(`${endpoints.workReports}/${id}`)
      .then((res) => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
        fireSuccessToast(<Trans>Work report deleted.</Trans>)
        return res.data
      })
      .catch((err) => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
        globalApiErrorHandler(err)
      })
  }
}

export const postProjectEvent = (id, data) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .post(`${endpoints.projects}/${id}/events`, data)
      .then((res) => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
        if (data.event === 'ignore_invoice') {
          fireSuccessToast(<Trans>Event ignore MDs succeeded.</Trans>)
        } else if (data.event === 'transfer_invoice') {
          fireSuccessToast(<Trans>Event transfer MDs succeeded.</Trans>)
        } else {
          fireSuccessToast(<Trans>Event create invoice succeeded.</Trans>)
        }
      })
      .catch((err) => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
        globalApiErrorHandler(err)
        fireErrorToast(<Trans>Event failed.</Trans>)
      })
  }
}

export const postEmployeesEvent = (id, data) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .post(`${endpoints.projects}/${id}/events`, data)
      .then((res) => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
        if (data.event === 'create_invoice') {
          fireSuccessToast(<Trans>Event create invoice succeeded.</Trans>)
        }
      })
      .catch((err) => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
        globalApiErrorHandler(err)
        fireErrorToast(<Trans>Event failed.</Trans>)
      })
  }
}

export const getIndirectExpenses = (limit, offset, filters) => {
  return (dispatch) => {
    dispatch({type: GET_INDIRECT_EXPENSES_REQUEST})
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .get(endpoints.indirectExpenses, {
        limit: limit,
        offset: offset,
        define_date_from: filters?.define_date_from,
        define_date_to: filters?.define_date_to,
        order_by:
          filters?.orderBy &&
          filters?.orderDirection &&
          `${filters?.orderBy}:${filters?.orderDirection}`,
      })
      .then((res) => {
        dispatch({type: GET_INDIRECT_EXPENSES_SUCCESS, data: res.data})
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
      .catch((err) => {
        dispatch({type: GET_INDIRECT_EXPENSES_FAIL})
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
        globalApiErrorHandler(err)
      })
  }
}

export const postIndirectExpense = (data) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .post(endpoints.indirectExpenses, data)
      .then((res) => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
        fireSuccessToast(<Trans>Indirect expense created.</Trans>)
        return res.data
      })
      .catch((err) => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
        if (err.response?.status === 422 && err.response?.data?.errors?.length) {
          return Promise.reject(err?.response?.data?.errors)
        } else {
          return globalApiErrorHandler(err)
        }
      })
  }
}

export const putIndirectExpense = (id, data) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .put(`${endpoints.indirectExpenses}/${id}`, data)
      .then((res) => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
        fireSuccessToast(<Trans>Indirect expense updated.</Trans>)
        return res.data
      })
      .catch((err) => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
        if (err.response?.status === 422 && err.response?.data?.errors?.length) {
          return Promise.reject(err?.response?.data?.errors)
        } else {
          return globalApiErrorHandler(err)
        }
      })
  }
}

export const deleteIndirectExpense = (id) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .del(`${endpoints.indirectExpenses}/${id}`)
      .then((res) => {
        fireSuccessToast(<Trans>Indirect expense deleted.</Trans>)
        return res.data
      })
      .catch((err) => {
        globalApiErrorHandler(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const getClients = (limit = 1000, offset = 0, filters) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    dispatch({type: GET_CLIENT_LIST_REQUEST})
    return httpClient
      .get(endpoints.clients, {
        limit: limit,
        offset: offset,
        order_by:
          filters?.orderBy &&
          filters?.orderDirection &&
          `${filters?.orderBy}:${filters?.orderDirection}`,
      })
      .then((res) => {
        dispatch({type: GET_CLIENT_LIST_SUCCESS, data: res.data})
        return res.data
      })
      .catch((err) => {
        dispatch({type: GET_CLIENT_LIST_FAIL})
        globalApiErrorHandler(err)
        return err
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const getProjectAssignments = (id, limit = 1000, offset = 0) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    dispatch({type: GET_PROJECT_ASSIGNMENTS_REQUEST})
    return httpClient
      .get(`${endpoints.projects}/${id}/assignments`, {
        limit: limit,
        offset: offset,
      })
      .then((res) => {
        dispatch({type: GET_PROJECT_ASSIGNMENTS_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_PROJECT_ASSIGNMENTS_FAIL})
        globalApiErrorHandler(err)
        return Promise.reject(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const patchProjectAssignments = (id, assignmentId, data) => {
  return (dispatch) => {
    return httpClient
      .patch(`${endpoints.projects}/${id}/assignments/${assignmentId}`, {role_refs: data})
      .then((res) => {
        fireSuccessToast(<Trans>Eluvian role updated.</Trans>)
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        fireSuccessToast(<Trans>Role update failed.</Trans>)
        globalApiErrorHandler(err)
        return Promise.reject(err)
      })
  }
}

export const synchronizeAllProjektomat = () => {
  return (dispatch) => {
    return httpClient
      .put(endpoints.synchronizeAllPro)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        globalApiErrorHandler(err)
        return Promise.reject(err?.response?.data?.errors)
      })
  }
}

export const getOnlyEluviaCompanies = (limit = 1000, offset = 0, filters) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    dispatch({type: GET_ELUVIA_COMPANIES_REQUEST})
    return httpClient
      .get(endpoints.companies, {
        limit: limit,
        offset: offset,
        show_archived: filters?.show_archived ? 1 : null,
        order_by:
          filters?.orderBy &&
          filters?.orderDirection &&
          `${filters?.orderBy}:${filters?.orderDirection}`,
        show_only_eluvia_companies: true,
      })
      .then((res) => {
        dispatch({type: GET_ELUVIA_COMPANIES_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_ELUVIA_COMPANIES_FAIL})
        globalApiErrorHandler(err)
        return Promise.reject(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const getOnlyClientCompanies = (limit = 1000, offset = 0, filters) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    dispatch({type: GET_CLIENT_COMPANIES_REQUEST})
    return httpClient
      .get(endpoints.companies, {
        limit: limit,
        offset: offset,
        show_archived: filters?.show_archived ? 1 : null,
        order_by:
          filters?.orderBy &&
          filters?.orderDirection &&
          `${filters?.orderBy}:${filters?.orderDirection}`,
        show_only_client_companies: true,
      })
      .then((res) => {
        dispatch({type: GET_CLIENT_COMPANIES_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_CLIENT_COMPANIES_FAIL})
        globalApiErrorHandler(err)
        return Promise.reject(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const getOnlyEmployeeCompanies = (limit = 1000, offset = 0, filters) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    dispatch({type: GET_EMPLOYEE_COMPANIES_REQUEST})
    return httpClient
      .get(endpoints.companies, {
        limit: limit,
        offset: offset,
        show_archived: filters?.show_archived ? 1 : null,
        order_by:
          filters?.orderBy &&
          filters?.orderDirection &&
          `${filters?.orderBy}:${filters?.orderDirection}`,
        show_only_employee_companies: true,
      })
      .then((res) => {
        dispatch({type: GET_EMPLOYEE_COMPANIES_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_EMPLOYEE_COMPANIES_FAIL})
        globalApiErrorHandler(err)
        return Promise.reject(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const getEmployeeWages = (empId) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    dispatch({type: GET_EMPLOYEE_WAGES_REQUEST})
    return httpClient
      .get(`${endpoints.employees}/${empId}/wages`)
      .then((res) => {
        dispatch({type: GET_EMPLOYEE_WAGES_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_EMPLOYEE_WAGES_FAIL})
        globalApiErrorHandler(err)
        return Promise.reject(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const postEmployeeWage = (empId, data) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .post(`${endpoints.employees}/${empId}/wages`, data)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        globalApiErrorHandler(err)
        return Promise.reject(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const getDashboardAssignments = () => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    dispatch({type: GET_DASHBOARD_ASSIGNMENT_REQUEST})
    return httpClient
      .get(endpoints.dashboardPro + '/assignments')
      .then((res) => {
        dispatch({type: GET_DASHBOARD_ASSIGNMENT_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_DASHBOARD_ASSIGNMENT_FAIL})
        globalApiErrorHandler(err)
        return Promise.reject(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}
