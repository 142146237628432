const dashboardStyle = (theme) => ({
  gridBoxHeight: {
    minHeight: 180,
    display: 'flex',
  },
  gridTitleBorder: {
    height: 49,
    boxSizing: 'border-box',
    borderBottom: '1px solid ' + theme.palette.border,
    display: 'flex',
    alignItems: 'center',
  },
  gridTitleIcon: {
    paddingRight: 10,
    display: 'flex',
    '& svg': {
      fill: theme.palette.primary.main,
    },
  },
  gridData: {
    fontSize: 14,
  },
  projectRow: {
    paddingBottom: 12,
  },
  chipClass: {
    marginTop: 3,
    marginRight: 3,
    fontSize: 12,
    height: 22,
  },
  gridDataRow: {
    paddingBottom: 4,
  },
  gridSummaryRow: {
    fontSize: 15,
    display: 'flex',
    alignSelf: 'self-end',
    borderTop: '1px solid ' + theme.palette.border,
  },
  toggleButtons: {
    flexGrow: '1',
    textAlign: 'end',
    '& .MuiToggleButton-root': {
      padding: 5,
    },
  },
})

export default dashboardStyle
