import BoxFullWidth from 'component/material/BoxFullWidth'
import {Paper} from '@material-ui/core'
import GridItem from 'component/material/GridItem'
import withStyles from '@material-ui/core/styles/withStyles'
import dashboardStyle from 'component/DashboardPage/dashboardStyle'
import React, {useEffect} from 'react'
import {bindActionCreators, compose} from 'redux'
import {getDashboardAssignments} from 'redux/action/projektomatAction'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import GridContainer from 'component/material/GridContainer'
import {Trans} from '@lingui/macro'
import AssignmentIcon from '@material-ui/icons/Assignment'
import cx from 'classnames'
import Chip from '@material-ui/core/Chip'
import {stringToColour} from 'helper/functions'
import Loader from 'component/material/Loader'

const DashboardAssignments = (props) => {
  const {classes, getDashboardAssignments, dashboardData, dashboardDataLoading} = props

  useEffect(() => {
    getDashboardAssignments()
  }, [])

  return (
    <GridItem xs={12} md={6} xl={4}>
      <Paper elevation={5}>
        <BoxFullWidth p={2} className={classes.gridTitleBorder}>
          <GridContainer direction={'row'} alignItems={'center'}>
            <GridItem className={classes.gridTitleIcon}>
              <AssignmentIcon />
            </GridItem>
            <GridItem>
              <Trans>Roles on projects</Trans>
            </GridItem>
          </GridContainer>
        </BoxFullWidth>
        <BoxFullWidth p={2} className={classes.gridBoxHeight}>
          <GridContainer alignContent={'flex-start'}>
            {dashboardDataLoading ? (
              <Loader wrapper={true} />
            ) : (
              dashboardData?.map((record, index) => (
                <GridContainer
                  key={index}
                  item
                  xs={12}
                  className={cx(classes.gridData, classes.projectRow)}
                >
                  <GridItem xs={12}>{record.project?.name}</GridItem>
                  <GridItem container xs={12}>
                    {record.roles?.map((role) => (
                      <Chip
                        size={'small'}
                        key={role?.id}
                        label={role?.role_ref}
                        className={classes.chipClass}
                        style={{backgroundColor: stringToColour(role?.role_ref) + '50'}}
                      />
                    ))}
                  </GridItem>
                </GridContainer>
              ))
            )}
          </GridContainer>
        </BoxFullWidth>
      </Paper>
    </GridItem>
  )
}

DashboardAssignments.propTypes = {
  getDashboardAssignments: PropTypes.func,
  dashboardData: PropTypes.array,
  dashboardDataLoading: PropTypes.bool,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({getDashboardAssignments}, dispatch)
}

export default compose(
  withStyles(dashboardStyle),
  connect((store) => {
    return {
      dashboardData: store.projektomat.dashboard,
      dashboardDataLoading: store.projektomat.dashboardLoading,
    }
  }, mapDispatchToProps)
)(DashboardAssignments)
