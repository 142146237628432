import React from 'react'
import GridContainer from 'component/material/GridContainer'
import CustomTooltip from 'component/material/Tooltip'
import {Trans} from '@lingui/macro'
import IconButton from '@material-ui/core/IconButton'
import {Edit} from '@material-ui/icons'
import DeleteIcon from '@material-ui/icons/Delete'
import GridItem from 'component/material/GridItem'
import cx from 'classnames'
import {withStyles} from '@material-ui/core'
import odhadyStyle from 'component/OdhadomatPage/odhadyStyle'
import {stringToColour} from 'helper/functions'

const PhasesTable = (props) => {
  const {
    classes,
    phases,
    handleOpenEditPhase,
    handleOpenDeletePhase,
    editableIndex,
    setEditableIndex,
    handleInlineEdit,
    handleSubmitMonth,
  } = props

  return phases.map((phase, index) => (
    <span key={index}>
      <GridContainer key={index} className={classes.phaseNameCell}>
        {`${phase.title} (${phase?.story_categories?.join(', ')})`}
        <CustomTooltip classNames={classes.iconTooltip} title={<Trans>Edit phase</Trans>}>
          <IconButton
            color={'primary'}
            onClick={() => handleOpenEditPhase(phase)}
            className={classes.iconButtonRow}
          >
            <Edit />
          </IconButton>
        </CustomTooltip>

        <CustomTooltip classNames={classes.iconTooltip} title={<Trans>Delete phase</Trans>}>
          <IconButton
            color={'primary'}
            onClick={handleOpenDeletePhase(phase)}
            className={classes.iconButtonRow}
          >
            <DeleteIcon />
          </IconButton>
        </CustomTooltip>
      </GridContainer>

      {phase?.layers?.map((layer, layerIndex) => (
        <GridContainer
          direction={'row'}
          wrap={'nowrap'}
          key={layerIndex}
          className={
            layerIndex !== phase?.layers?.length - 1
              ? classes.borderBottom
              : classes.borderBottomBlack
          }
        >
          <GridItem
            style={{
              backgroundColor: stringToColour(layer.layer) + '50',
            }}
            className={classes.scheduleCell}
          >
            {layer.layer}
          </GridItem>
          <GridItem
            className={cx(classes.scheduleCell, classes.smallScheduleCell, classes.textEnd)}
          >
            {layer.estimated_mds}
          </GridItem>
          <GridItem className={cx(classes.scheduleCell, classes.textEnd)}>
            {layer.estimated_mms}
          </GridItem>
          <GridItem className={cx(classes.scheduleCell, classes.textEnd, classes.borderRightBlack)}>
            {layer.scheduled_mms}
          </GridItem>
          {layer?.months?.map((month, monthIndex) => (
            <GridItem
              key={monthIndex}
              className={cx(
                classes.scheduleCell,
                classes.editableScheduleCell,
                classes.textEnd,
                editableIndex.phase === index &&
                  editableIndex.layer === layerIndex &&
                  editableIndex.month === monthIndex &&
                  classes.activeScheduleCell
              )}
              onClick={handleInlineEdit(index, layerIndex, monthIndex)}
              style={{
                backgroundColor: `${month.scheduled_mms !== 0 && stringToColour(layer.layer)}50`,
              }}
            >
              {editableIndex.phase === index &&
              editableIndex.layer === layerIndex &&
              editableIndex.month === monthIndex ? (
                <input
                  autoFocus
                  autoComplete="off"
                  id={'scheduled_mms'}
                  name={'scheduled_mms'}
                  onKeyPress={(e) => handleSubmitMonth(e, phase, month)}
                  defaultValue={month.scheduled_mms !== 0 ? month.scheduled_mms : 0.0}
                  onBlur={() => setEditableIndex({phase: null, layer: null, month: null})}
                  // select all text in the field
                  onFocus={(e) =>
                    document
                      .getElementById('scheduled_mms')
                      .setSelectionRange(0, e.target.value.length)
                  }
                />
              ) : (
                month.scheduled_mms
              )}
            </GridItem>
          ))}
        </GridContainer>
      ))}
    </span>
  ))
}

export default withStyles(odhadyStyle)(PhasesTable)
