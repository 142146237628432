import React from 'react'
import {NavigateNext} from '@material-ui/icons'
import {Breadcrumbs, Link} from '@material-ui/core'
import {redirectTo} from 'helper/functions'
import Typography from '@material-ui/core/Typography'

const CustomBreadcrumbs = (props) => {
  const {breadcrumbs} = props

  return (
    <Breadcrumbs separator={<NavigateNext color={'primary'} fontSize="small" />}>
      {breadcrumbs?.map((b, i) => {
        if (breadcrumbs.length === i + 1) {
          return (
            <Typography key={i} variant={'h6'} color="primary" align="center">
              {b?.title}
            </Typography>
          )
        } else {
          return (
            <Link
              key={i}
              style={{cursor: 'pointer'}}
              color="primary"
              onClick={() => redirectTo(b?.url)}
            >
              <Typography variant={'h6'} color="primary" align="center">
                {b?.title}
              </Typography>
            </Link>
          )
        }
      })}
    </Breadcrumbs>
  )
}

export default CustomBreadcrumbs
