import React, {useEffect, useRef, useState} from 'react'
import {bindActionCreators, compose} from 'redux'
import {
  deleteAccompanyingWork,
  getAccompanyingWorks,
  patchAccompanyingWork,
  postAccompanyingWork,
} from 'redux/action/odhadomatAction'
import PropTypes from 'prop-types'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core'
import odhadyStyle from 'component/OdhadomatPage/odhadyStyle'
import {connect} from 'react-redux'
import {useParams} from 'react-router-dom'
import GridContainer from 'component/material/GridContainer'
import {Cancel, Edit, ExpandMore, Save} from '@material-ui/icons'
import {Trans} from '@lingui/macro'
import GridItem from 'component/material/GridItem'
import CustomTooltip from 'component/material/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import cx from 'classnames'
import Loader from 'component/material/Loader'
import {
  fireSuccessToast,
  mapErrorResponseToForm,
  renderAmountWithCurrency,
  showErrorToastFromObject,
} from 'helper/functions'
import DialogWindow from 'component/material/DialogWindow'
import AccompanyingWorkForm from 'component/OdhadomatPage/OdhadDetail/AccompanyingWorkForm'
import NumberFormatCustom from 'component/field/NumberFormatCustom'
import NumberFormat from 'react-number-format'
import InputAdornment from '@material-ui/core/InputAdornment'
import {useWarningDialog} from 'hooks/useWarningDialog'

const AccompanyingWorkTab = (props) => {
  const {
    classes,
    getAccompanyingWorks,
    accompanyingWorks,
    deleteAccompanyingWork,
    patchAccompanyingWork,
    estimation,
    estimationLoading,
    postAccompanyingWork,
    openEditCreate,
    setOpenEditCreate,
  } = props

  const [initialLoad, setInitialLoad] = useState(true)
  const [editRowId, setEditRowId] = useState(null)
  const [layerError, setLayerError] = useState({})
  const [patchLoading, setPatchLoading] = useState(false)
  const [DeleteWarningDialog, deleteData, onOpenDelete, onCloseDelete] = useWarningDialog()

  const titleRef = useState(null)
  const noteRef = useRef(null)
  const estimatedRef = useRef(null)
  const seniorityRef = useRef(null)
  const amountRef = useRef(null)

  const {id} = useParams()

  const handleCloseEdit = () => {
    return setOpenEditCreate({visible: false, row: null})
  }

  const handleDeleteWork = () => {
    return deleteAccompanyingWork(id, deleteData?.id).then(() => {
      fetchAccompanyingWorks()
      onCloseDelete()
    })
  }

  const prepareData = (values) => {
    return {
      title: values.title,
      category: values.category,
      seniority: values.seniority,
      estimated_mds: values.estimated_mds && Number(values.estimated_mds),
      amount: values.amount && Number(values.amount),
      note: values.note,
    }
  }

  const preparePatchData = () => {
    return {
      title: titleRef.current?.value,
      note: noteRef.current?.value,
      estimated_mds: estimatedRef.current?.value && Number(estimatedRef.current.value),
      seniority: seniorityRef.current?.value,
      amount: amountRef.current?.value && Number(amountRef.current.value),
    }
  }

  const handleSubmitWork = (row) => {
    if (!row?.id) {
      const data = prepareData(row)
      return postAccompanyingWork(id, data)
        .then(() => {
          fetchAccompanyingWorks().then(() => {
            fireSuccessToast(<Trans>Accompanying work created.</Trans>)
            handleCloseEdit()
          })
        })
        .catch((err) => {
          return mapErrorResponseToForm(err)
        })
    } else {
      const patchData = preparePatchData()
      setPatchLoading(true)
      return patchAccompanyingWork(id, row.id, patchData)
        .then(() => {
          fetchAccompanyingWorks().then(() => {
            fireSuccessToast(<Trans>Accompanying work updated.</Trans>)
            handleCancelChanges()
            setLayerError({})
          })
        })
        .catch((err) => {
          setLayerError(mapErrorResponseToForm(err))
          showErrorToastFromObject(mapErrorResponseToForm(err))
        })
        .finally(() => {
          setPatchLoading(false)
        })
    }
  }

  const handleEnterPress = (e, work) => {
    if (e.keyCode === 13) {
      // ENTER
      handleSubmitWork(work)
    } else if (e.keyCode === 27) {
      // ESCAPE
      handleCancelChanges()
    }
  }

  const fetchAccompanyingWorks = () => {
    return getAccompanyingWorks(id)
  }

  const handleEditLayer = (row) => () => {
    if (row?.id) {
      setLayerError({})
      setEditRowId(row?.id)
    }
  }

  const handleCancelChanges = () => {
    setEditRowId(null)
  }

  const StoryGridItem = ({width, text, style, children, classNames, ...rest}) => {
    return (
      <GridItem
        px={2}
        style={{width: `${width}px`, ...style}}
        className={cx(classes.storyLayer, classNames)}
        {...rest}
      >
        {text}
        {children}
      </GridItem>
    )
  }

  const getSummaryValue = (attribute, story) => {
    let sum = 0

    if (story) {
      story.forEach((layer) => (sum += layer?.[attribute]))
    } else {
      Object.entries(accompanyingWorks).forEach((accompanyingWork) =>
        accompanyingWork?.[1]?.forEach((layer) => (sum += layer?.[attribute]))
      )
    }

    return sum
  }

  useEffect(() => {
    fetchAccompanyingWorks().then(() => setInitialLoad(false))
  }, [])

  return initialLoad ? (
    <Loader wrapper={true} />
  ) : (
    <>
      {/* EDIT & CREATE ACCOMPANYING WORK DIALOG */}
      <DialogWindow
        onClose={handleCloseEdit}
        open={openEditCreate.visible}
        title={
          openEditCreate.row ? (
            <Trans>Edit accompanying work</Trans>
          ) : (
            <Trans>Create accompanying work</Trans>
          )
        }
        maxWidth={'sm'}
        fullWidth={true}
      >
        <AccompanyingWorkForm
          handleSubmit={handleSubmitWork}
          initValues={openEditCreate.row}
          estimation={estimation}
          estimationLoading={estimationLoading}
        />
      </DialogWindow>

      {/* DELETE DIALOG */}
      <DeleteWarningDialog
        onConfirm={handleDeleteWork}
        message={<Trans>Accompanying work {deleteData?.title} will be deleted!</Trans>}
      />

      <GridContainer>
        <div className={classes.storyTab}>
          {Object.entries(accompanyingWorks)
            ?.sort((a, b) => a?.[0]?.localeCompare(b?.[0]))
            ?.map((val, index) => (
              <Paper key={index} elevation={3} square style={{marginBottom: 12}}>
                <Accordion
                  elevation={0}
                  className={classes.storyAccordion}
                  square
                  defaultExpanded={true}
                >
                  <AccordionSummary
                    className={classes.storyAccordionSummary}
                    expandIcon={<ExpandMore />}
                  >
                    <GridContainer direction={'row'}>
                      <GridItem xs={10} container alignItems={'center'}>
                        <Typography variant={'body2'} noWrap={true}>
                          {val[0]}
                        </Typography>
                      </GridItem>
                    </GridContainer>
                  </AccordionSummary>
                  <AccordionDetails>
                    <GridContainer style={{overflow: 'auto'}}>
                      <GridItem
                        container
                        xs={12}
                        style={{minWidth: 'max-content'}}
                        className={classes.storyLayerHeader}
                      >
                        <StoryGridItem width={200} text={<Trans>Operation</Trans>} />
                        <StoryGridItem
                          width={140}
                          style={{flex: 'auto'}}
                          text={<Trans>Note</Trans>}
                        />
                        <StoryGridItem
                          width={90}
                          text={<Trans>Length</Trans>}
                          classNames={classes.textAlignEnd}
                        />
                        <StoryGridItem width={120} text={<Trans>Junior x Senior</Trans>} />
                        <StoryGridItem
                          width={100}
                          text={<Trans>Wage for MD</Trans>}
                          classNames={classes.textAlignEnd}
                        />
                        <StoryGridItem
                          width={50}
                          text={<Trans>Count</Trans>}
                          classNames={classes.textAlignEnd}
                        />
                        <StoryGridItem
                          width={140}
                          text={<Trans>Price</Trans>}
                          classNames={classes.textAlignEnd}
                        />
                        <StoryGridItem
                          width={140}
                          text={<Trans>Price with reserve</Trans>}
                          classNames={classes.textAlignEnd}
                        />
                        {/* empty header for column with edit */}
                        <StoryGridItem width={60} />
                      </GridItem>

                      {val[1]
                        ?.sort((a, b) => a.title.localeCompare(b.title))
                        .map((work, index) => (
                          <GridItem
                            key={index}
                            container
                            xs={12}
                            style={{minWidth: 'max-content'}}
                            className={classes.storyLayerRow}
                          >
                            {editRowId === work.id ? (
                              <GridItem
                                container
                                xs={12}
                                onKeyDown={(e) => handleEnterPress(e, work)}
                              >
                                <TextField
                                  autoComplete="off"
                                  style={{width: '200px'}}
                                  name={'title'}
                                  defaultValue={work.title}
                                  error={!!layerError?.['title']}
                                  className={classes.inlineTextField}
                                  inputRef={titleRef}
                                  disabled={patchLoading}
                                />
                                <TextField
                                  autoComplete="off"
                                  style={{width: '140px', flex: 'auto'}}
                                  name={'note'}
                                  defaultValue={work.note}
                                  error={!!layerError?.['note']}
                                  className={classes.inlineTextField}
                                  inputRef={noteRef}
                                  disabled={patchLoading}
                                />

                                <TextField
                                  autoComplete="off"
                                  style={{width: '90px'}}
                                  name={'estimated_mds'}
                                  defaultValue={Number(work.estimated_mds)?.toFixed(2) || 0}
                                  error={!!layerError?.['estimated_mds']}
                                  InputProps={{
                                    inputComponent: NumberFormatCustom,
                                    endAdornment: (
                                      <InputAdornment
                                        className={classes.inlineAdornment}
                                        position="end"
                                        disableTypography={true}
                                      >
                                        &nbsp;MD
                                      </InputAdornment>
                                    ),
                                  }}
                                  inputProps={{suffix: ''}}
                                  className={cx(classes.inlineTextField, classes.textAlignEnd)}
                                  inputRef={estimatedRef}
                                  disabled={patchLoading}
                                />

                                <FormControl
                                  error={!!layerError?.['seniority']}
                                  size={'small'}
                                  className={classes.inlineSelectFormControl}
                                >
                                  <Select
                                    className={classes.inlineSelectField}
                                    size={'small'}
                                    name={'seniority'}
                                    defaultValue={work?.seniority}
                                    inputRef={seniorityRef}
                                    disabled={patchLoading}
                                  >
                                    <MenuItem
                                      value={'junior'}
                                      className={classes.inlineSelectMenuItem}
                                    >
                                      <Trans>junior</Trans>
                                    </MenuItem>
                                    <MenuItem
                                      value={'senior'}
                                      className={classes.inlineSelectMenuItem}
                                    >
                                      <Trans>senior</Trans>
                                    </MenuItem>
                                  </Select>
                                </FormControl>

                                <StoryGridItem
                                  classNames={classes.textAlignEnd}
                                  width={100}
                                  text={
                                    work.seniority === 'junior'
                                      ? estimation?.junior_price &&
                                        renderAmountWithCurrency(
                                          estimation?.junior_price,
                                          estimation?.currency
                                        )
                                      : estimation?.senior_price &&
                                        renderAmountWithCurrency(
                                          estimation?.senior_price,
                                          estimation?.currency
                                        )
                                  }
                                />
                                <TextField
                                  autoComplete="off"
                                  style={{width: '50px'}}
                                  name={'amount'}
                                  defaultValue={work.amount || 0}
                                  error={!!layerError?.['amount']}
                                  className={cx(classes.inlineTextField, classes.textAlignEnd)}
                                  inputRef={amountRef}
                                  disabled={patchLoading}
                                />
                                <StoryGridItem
                                  classNames={classes.textAlignEnd}
                                  width={140}
                                  text={
                                    work?.price &&
                                    renderAmountWithCurrency(work?.price, estimation?.currency)
                                  }
                                />
                                <StoryGridItem
                                  classNames={classes.textAlignEnd}
                                  width={140}
                                  text={
                                    work?.price_with_reserve &&
                                    renderAmountWithCurrency(
                                      work?.price_with_reserve,
                                      estimation?.currency
                                    )
                                  }
                                />
                                <StoryGridItem
                                  width={60}
                                  text={
                                    <GridContainer
                                      direction={'row'}
                                      wrap={'nowrap'}
                                      justifyContent={'flex-end'}
                                    >
                                      <CustomTooltip
                                        classNames={classes.iconTooltip}
                                        title={<Trans>Save changes</Trans>}
                                      >
                                        <IconButton
                                          color={'primary'}
                                          onClick={() => handleSubmitWork(work)}
                                          className={classes.iconButtonRow}
                                          disabled={patchLoading}
                                        >
                                          <Save />
                                        </IconButton>
                                      </CustomTooltip>

                                      <CustomTooltip
                                        classNames={classes.iconTooltip}
                                        title={<Trans>Cancel changes</Trans>}
                                      >
                                        <IconButton
                                          color={'primary'}
                                          onClick={handleCancelChanges}
                                          className={classes.iconButtonRow}
                                          disabled={patchLoading}
                                        >
                                          <Cancel />
                                        </IconButton>
                                      </CustomTooltip>
                                    </GridContainer>
                                  }
                                />
                              </GridItem>
                            ) : (
                              <GridItem
                                style={{cursor: 'pointer'}}
                                container
                                xs={12}
                                onClick={handleEditLayer(work)}
                              >
                                <StoryGridItem
                                  width={200}
                                  text={
                                    <CustomTooltip
                                      classNames={classes.overflowEllipsis}
                                      title={work.title}
                                    >
                                      {work.title}
                                    </CustomTooltip>
                                  }
                                />
                                <StoryGridItem
                                  width={140}
                                  style={{flex: 'auto'}}
                                  text={work.note}
                                />
                                <StoryGridItem
                                  width={90}
                                  text={Number(work.estimated_mds).toFixed(2) + ' MD'}
                                  classNames={classes.textAlignEnd}
                                />
                                <StoryGridItem width={120} text={work.seniority} />
                                <StoryGridItem
                                  classNames={classes.textAlignEnd}
                                  width={100}
                                  text={
                                    work.seniority === 'junior'
                                      ? estimation?.junior_price &&
                                        renderAmountWithCurrency(
                                          estimation?.junior_price,
                                          estimation?.currency
                                        )
                                      : estimation?.senior_price &&
                                        renderAmountWithCurrency(
                                          estimation?.senior_price,
                                          estimation?.currency
                                        )
                                  }
                                />
                                <StoryGridItem
                                  width={50}
                                  text={work.amount}
                                  classNames={classes.textAlignEnd}
                                />
                                <StoryGridItem
                                  classNames={classes.textAlignEnd}
                                  width={140}
                                  text={
                                    work?.price &&
                                    renderAmountWithCurrency(work?.price, estimation?.currency)
                                  }
                                />
                                <StoryGridItem
                                  classNames={classes.textAlignEnd}
                                  width={140}
                                  text={
                                    work?.price_with_reserve &&
                                    renderAmountWithCurrency(
                                      work?.price_with_reserve,
                                      estimation?.currency
                                    )
                                  }
                                />
                                <StoryGridItem
                                  width={60}
                                  text={
                                    <GridContainer
                                      direction={'row'}
                                      wrap={'nowrap'}
                                      justifyContent={'flex-end'}
                                    >
                                      <CustomTooltip
                                        classNames={classes.iconTooltip}
                                        title={<Trans>Edit layer</Trans>}
                                      >
                                        <IconButton
                                          color={'primary'}
                                          onClick={handleEditLayer(work)}
                                          className={classes.iconButtonRow}
                                        >
                                          <Edit />
                                        </IconButton>
                                      </CustomTooltip>

                                      <CustomTooltip
                                        classNames={classes.iconTooltip}
                                        title={<Trans>Delete accompanying work</Trans>}
                                      >
                                        <IconButton
                                          color={'primary'}
                                          onClick={onOpenDelete(work)}
                                          className={classes.iconButtonRow}
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </CustomTooltip>
                                    </GridContainer>
                                  }
                                />
                              </GridItem>
                            )}
                          </GridItem>
                        ))}
                    </GridContainer>
                  </AccordionDetails>
                </Accordion>
                <GridContainer>
                  <GridItem
                    container
                    xs={12}
                    className={cx(classes.boldText, classes.noFlexWrap, classes.accordionSummary)}
                  >
                    <StoryGridItem style={{flex: 'auto'}} text={<Trans>Total</Trans>} />
                    <StoryGridItem
                      width={90}
                      text={
                        <NumberFormat
                          thousandSeparator={' '}
                          displayType="text"
                          suffix={' MD'}
                          value={Number(getSummaryValue('estimated_mds', val[1])).toFixed(2)}
                        />
                      }
                      classNames={classes.textAlignEnd}
                    />
                    <StoryGridItem width={270} />
                    <StoryGridItem
                      width={140}
                      text={renderAmountWithCurrency(
                        getSummaryValue('price', val[1]),
                        estimation?.currency
                      )}
                      classNames={classes.textAlignEnd}
                    />
                    <StoryGridItem
                      width={140}
                      text={renderAmountWithCurrency(
                        getSummaryValue('price_with_reserve', val[1]),
                        estimation?.currency
                      )}
                      classNames={classes.textAlignEnd}
                    />
                    <StoryGridItem width={60} />
                  </GridItem>
                </GridContainer>
              </Paper>
            ))}

          {/* SUMMARY */}
          <Paper elevation={3} className={classes.summary}>
            <GridContainer style={{overflow: 'auto'}}>
              <GridItem
                container
                xs={12}
                style={{minWidth: 'max-content'}}
                className={classes.storyLayerHeader}
              >
                <StoryGridItem style={{flex: 'auto'}} />
                <StoryGridItem
                  width={90}
                  text={<Trans>Length</Trans>}
                  classNames={classes.textAlignEnd}
                />
                <StoryGridItem width={270} />

                <StoryGridItem
                  width={140}
                  text={<Trans>Price</Trans>}
                  classNames={classes.textAlignEnd}
                />
                <StoryGridItem
                  width={140}
                  text={<Trans>Price with reserve</Trans>}
                  classNames={classes.textAlignEnd}
                />
                <StoryGridItem width={60} />
              </GridItem>

              <GridItem container xs={12} className={cx(classes.boldText, classes.noFlexWrap)}>
                <StoryGridItem style={{flex: 'auto'}} text={<Trans>Total</Trans>} />
                <StoryGridItem
                  width={90}
                  text={
                    <NumberFormat
                      thousandSeparator={' '}
                      displayType="text"
                      suffix={' MD'}
                      value={Number(getSummaryValue('estimated_mds')).toFixed(2)}
                    />
                  }
                  classNames={classes.textAlignEnd}
                />
                <StoryGridItem width={270} />
                <StoryGridItem
                  width={140}
                  text={renderAmountWithCurrency(getSummaryValue('price'), estimation?.currency)}
                  classNames={classes.textAlignEnd}
                />
                <StoryGridItem
                  width={140}
                  text={renderAmountWithCurrency(
                    getSummaryValue('price_with_reserve'),
                    estimation?.currency
                  )}
                  classNames={classes.textAlignEnd}
                />
                <StoryGridItem width={60} />
              </GridItem>
            </GridContainer>
          </Paper>
        </div>
      </GridContainer>
    </>
  )
}

AccompanyingWorkTab.propTypes = {
  classes: PropTypes.object,
  getAccompanyingWorks: PropTypes.func,
  accompanyingWorks: PropTypes.array,
  accompanyingWorksLoading: PropTypes.bool,
  accompanyingWorksMeta: PropTypes.object,
  deleteAccompanyingWork: PropTypes.func,
  patchAccompanyingWork: PropTypes.func,
  estimation: PropTypes.object,
  estimationLoading: PropTypes.bool,
  postAccompanyingWork: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getAccompanyingWorks,
      deleteAccompanyingWork,
      patchAccompanyingWork,
      postAccompanyingWork,
    },
    dispatch
  )
}

export default compose(
  withStyles(odhadyStyle),
  connect((store) => {
    return {
      accompanyingWorks: store.odhadomat.accompanyingWorks,
      accompanyingWorksLoading: store.odhadomat.accompanyingWorksLoading,
      accompanyingWorksMeta: store.odhadomat.accompanyingWorksMeta,
      estimation: store.odhadomat.estimation,
      estimationLoading: store.odhadomat.estimationLoading,
    }
  }, mapDispatchToProps)
)(AccompanyingWorkTab)
