const managementPageStyle = (theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  iconButtonHeight: {
    padding: 6,
  },
  projectCellClass: {
    height: 30,
    boxSizing: 'border-box',
    paddingTop: 0,
    paddingBottom: 0,
  },
  createButtonFlex: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    fontSize: 14,

    '& svg': {
      height: 20,
      width: 20,
      paddingRight: 10,
    },
  },
  activeState: {
    color: theme.palette.success.main,
  },
  disabledState: {
    color: theme.palette.error.main,
  },
  // basic field label
  fieldLabel: {
    color: theme.palette.text.primary,
    textTransform: 'none',
    fontSize: 14,
    fontWeight: 600,
    paddingBottom: 5,
  },
  spanEllipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: 500,
    width: 500,
  },
})

export default managementPageStyle
