import React, {useEffect, useState} from 'react'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import cx from 'classnames'
import Tab from '@material-ui/core/Tab'
import {Trans} from '@lingui/macro'
import TabPanel from 'component/material/TabPanel'
import {Field, Form} from 'react-final-form'
import {dateFromToValidation, required} from 'helper/validations'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import TextInput from 'component/field/TextInput'
import DatePickerInput from 'component/field/DatePickerInput'
import SelectInput from 'component/field/SelectInput'
import {CURRENCY_LIST, DB_DATE_FORMAT} from 'helper/constants'
import BoxFullWidth from 'component/material/BoxFullWidth'
import PrimaryButton from 'component/material/PrimaryButton'
import {bindActionCreators, compose} from 'redux'
import {
  getClients,
  getOnlyClientCompanies,
  getOnlyEluviaCompanies,
  getProjectList,
  putIssuedInvoice,
} from 'redux/action/projektomatAction'
import {withStyles} from '@material-ui/core'
import projektomatPageStyle from 'component/ProjektomatPage/projektomatPageStyle'
import {connect} from 'react-redux'
import moment from 'moment'
import PropTypes from 'prop-types'
import CustomTable from 'component/material/table/Table'
import {mapErrorResponseToForm, renderAmountWithCurrency} from 'helper/functions'
import NumberFormatCustom from 'component/field/NumberFormatCustom'
import {Delete} from '@material-ui/icons'
import TableIconButton from 'component/general/TableIconButton'

const EditIssuedInvoiceForm = (props) => {
  const {
    classes,
    onClose,
    putIssuedInvoice,
    getProjectList,
    projectsLoading,
    projectsList,
    setEdited,
    document,
    getOnlyEluviaCompanies,
    getOnlyClientCompanies,
    eluviaCompanies,
    eluviaCompaniesLoading,
    clientCompanies,
    clientCompaniesLoading,
  } = props

  const [value, setValue] = useState(0)
  const [data, setData] = useState({})
  const [projectBreakup, setProjectBreakup] = useState([])
  const [selectedCurrency, setSelectedCurrency] = useState(document?.currency) // form initial value

  const handleChangeCurrency = (e) => {
    setSelectedCurrency(e.target.value)
  }

  const handleChangeTab = (formValues) => (e, newValue) => {
    setData(formValues)
    setValue(newValue)
  }

  const prepareValues = (values) => {
    let originalProjectIds = []
    originalProjectIds = document?.projects?.map((project) => ({
      id: project.id,
      project_id: project.project?.id,
    }))

    let projectBreakupIdsArray = projectBreakup?.map((project) => project.project_id)
    let projectsToRemove = originalProjectIds?.filter(
      (project) => !projectBreakupIdsArray.includes(project.project_id)
    )
    let finalProjects

    if (projectsToRemove) {
      finalProjects = [
        ...projectBreakup,
        ...projectsToRemove?.map((project) => ({id: project.id, _destroy: '1'})),
      ]
    } else {
      finalProjects = [...projectBreakup]
    }

    return {
      ...values,
      amount: Math.round(Number(values.amount) * 100),
      define_date: values.define_date && moment(values.define_date).format(DB_DATE_FORMAT),
      maturity_date: values.maturity_date && moment(values.maturity_date).format(DB_DATE_FORMAT),
      projects: finalProjects,
    }
  }

  const handleSubmit = (values) => {
    const preparedValues = prepareValues(values)
    return putIssuedInvoice(document.id, preparedValues)
      .then(() => {
        onClose()
        setEdited(true)
      })
      .catch((err) => {
        setValue(0)
        return mapErrorResponseToForm(err)
      })
  }

  const handleAddProjectBreakdown = async (project, amount) => {
    return setProjectBreakup((prevState) => {
      return [
        ...prevState,
        {
          project_id: project,
          amount: amount ? Math.round(Number(amount) * 100) : 0,
        },
      ]
    })
  }

  const handleRemoveProjectBreakdown = (rowIndex) => () => {
    return setProjectBreakup(projectBreakup.filter((project, index) => index !== rowIndex))
  }

  const columns = [
    {
      name: 'project_id',
      label: <Trans>Reference</Trans>,
      render: (val) => projectsList.find((project) => project.id === val)?.ref,
    },
    {
      name: 'project_id',
      label: <Trans>Project</Trans>,
      render: (val) => projectsList.find((project) => project.id === val)?.name,
    },
    {
      name: 'amount',
      label: <Trans>Amount</Trans>,
      render: (val) => renderAmountWithCurrency(val, selectedCurrency),
    },
    {
      name: '',
      align: 'right',
      render: (val, col, rowIndex) => (
        <TableIconButton
          tooltipTitle={<Trans>Remove project breakdown</Trans>}
          onClick={handleRemoveProjectBreakdown(rowIndex)}
          Icon={Delete}
        />
      ),
    },
  ]

  useEffect(() => {
    getProjectList(1000, 0, false, {
      orderBy: 'name',
      orderDirection: 'asc',
    })
    getOnlyEluviaCompanies(1000, 0, {orderBy: 'name', orderDirection: 'asc'})
    getOnlyClientCompanies(1000, 0, {orderBy: 'name', orderDirection: 'asc'})

    if (document?.projects?.length) {
      setProjectBreakup(
        document?.projects?.map((project) => ({
          id: project.id,
          project_id: project.project?.id,
          amount: project.amount,
        }))
      )
    }
  }, [])

  return (
    <GridContainer spacing={2}>
      <Form
        onSubmit={handleSubmit}
        initialValues={{
          ...document,
          amount: document?.amount && Number(document.amount / 100).toFixed(2),
          acceptor_company_id: document?.acceptor_company?.id,
          issuer_company_id: document?.issuer_company?.id,
          ...data,
        }}
        validate={dateFromToValidation}
      >
        {(formProps) => {
          return (
            <form onSubmit={formProps.handleSubmit} className={classes.fullWidth}>
              <AppBar position="static" color="default" elevation={0}>
                <Tabs
                  value={value}
                  className={cx(classes.tabs, classes.borderBottomTabs)}
                  onChange={handleChangeTab(formProps.values)}
                  indicatorColor="secondary"
                >
                  <Tab index={0} label={<Trans>Issued invoice</Trans>} />
                  <Tab index={1} label={<Trans>Project</Trans>} />
                </Tabs>
              </AppBar>

              <TabPanel value={value} index={0} className={classes.createAcceptedInvoiceDialogTab}>
                <GridContainer spacing={2}>
                  <GridItem xs={12} sm={6}>
                    <Field
                      name="number"
                      label={<Trans>Invoice number</Trans>}
                      component={TextInput}
                      validate={!formProps.submitSucceeded && required}
                      disabled={formProps.submitting}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6} />
                  <GridItem xs={12} sm={6}>
                    <Field
                      name="issuer_company_id"
                      label={<Trans>Issuer company</Trans>}
                      component={SelectInput}
                      options={eluviaCompanies}
                      loading={eluviaCompaniesLoading}
                      validate={required}
                      disabled={formProps.submitting}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <Field
                      name="acceptor_company_id"
                      label={<Trans>Acceptor company</Trans>}
                      component={SelectInput}
                      options={clientCompanies}
                      loading={clientCompaniesLoading}
                      validate={required}
                      disabled={formProps.submitting}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <Field
                      name="define_date"
                      label={<Trans>Define date</Trans>}
                      component={DatePickerInput}
                      validate={!formProps.submitSucceeded && required}
                      disabled={formProps.submitting}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <Field
                      name="maturity_date"
                      label={<Trans>Maturity date</Trans>}
                      component={DatePickerInput}
                      validate={!formProps.submitSucceeded && required}
                      disabled={formProps.submitting}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <Field
                      name="amount"
                      label={<Trans>Amount</Trans>}
                      component={TextInput}
                      type={'number'}
                      validate={!formProps.submitSucceeded && required}
                      disabled={formProps.submitting}
                      autoComplete="off"
                      InputProps={{inputComponent: NumberFormatCustom}}
                      inputProps={{suffix: ''}}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={6}>
                    <Field
                      name="currency"
                      label={<Trans>Currency</Trans>}
                      component={SelectInput}
                      options={CURRENCY_LIST}
                      onChange={handleChangeCurrency}
                      validate={!formProps.submitSucceeded && required}
                      disabled={formProps.submitting}
                    />
                  </GridItem>
                  <GridItem xs={12}>
                    <Field
                      name="note"
                      label={<Trans>Note</Trans>}
                      component={TextInput}
                      multiline={true}
                      rows={2}
                      disabled={formProps.submitting}
                    />
                  </GridItem>
                </GridContainer>
              </TabPanel>
              <TabPanel value={value} index={1} className={classes.createAcceptedInvoiceDialogTab}>
                <GridContainer spacing={2}>
                  <GridItem xs={12} sm={5}>
                    <Field
                      name="project_id"
                      label={<Trans>Project</Trans>}
                      component={SelectInput}
                      loading={projectsLoading}
                      options={projectsList.map((project) => ({
                        ...project,
                        name: `${project.name}`,
                      }))}
                      disabled={formProps.submitting}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={5}>
                    <Field
                      name="project_amount"
                      label={<Trans>Amount</Trans>}
                      component={TextInput}
                      type={'number'}
                      disabled={formProps.submitting}
                      InputProps={{inputComponent: NumberFormatCustom}}
                      inputProps={{suffix: ''}}
                    />
                  </GridItem>
                  <GridItem xs={2}>
                    <PrimaryButton
                      onClick={() =>
                        handleAddProjectBreakdown(
                          formProps.values?.project_id,
                          formProps.values?.project_amount
                        ).then(() => {
                          formProps.form.change('project_id', null)
                          formProps.form.change('project_amount', null)
                        })
                      }
                      className={classes.addButtonClass}
                      fullWidth={true}
                      text={<Trans>Add</Trans>}
                      disabled={formProps.submitting}
                    />
                  </GridItem>
                  <GridItem xs={12} className={classes.minHeightTable}>
                    {projectBreakup?.length !== 0 && (
                      <CustomTable
                        size={'small'}
                        columns={columns}
                        data={projectBreakup}
                        pagination={false}
                      />
                    )}
                  </GridItem>
                </GridContainer>
              </TabPanel>

              <BoxFullWidth pt={3}>
                <GridContainer justifyContent={'flex-end'}>
                  <PrimaryButton
                    fullWidth={false}
                    variant={'outlined'}
                    text={<Trans>Cancel</Trans>}
                    className={classes.cancelButtonPadding}
                    onClick={onClose}
                    disabled={formProps.submitting}
                  />
                  <PrimaryButton
                    fullWidth={false}
                    type="submit"
                    text={<Trans>Edit</Trans>}
                    disabled={formProps.submitting}
                  />
                </GridContainer>
              </BoxFullWidth>
            </form>
          )
        }}
      </Form>
    </GridContainer>
  )
}

EditIssuedInvoiceForm.propTypes = {
  clientsLoading: PropTypes.bool,
  clientsList: PropTypes.array,
  onClose: PropTypes.func,
  getClients: PropTypes.func,
  putIssuedInvoice: PropTypes.func,
  setNewAssetCreated: PropTypes.func,
  getProjectList: PropTypes.func,
  projectsLoading: PropTypes.bool,
  projectsList: PropTypes.array,
  setEdited: PropTypes.func,
  document: PropTypes.object,
  getOnlyEluviaCompanies: PropTypes.func,
  getOnlyClientCompanies: PropTypes.func,
  eluviaCompanies: PropTypes.array,
  eluviaCompaniesLoading: PropTypes.bool,
  clientCompanies: PropTypes.array,
  clientCompaniesLoading: PropTypes.bool,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getClients,
      getProjectList,
      putIssuedInvoice,
      getOnlyEluviaCompanies,
      getOnlyClientCompanies,
    },
    dispatch
  )
}

export default compose(
  withStyles(projektomatPageStyle),
  connect((store) => {
    return {
      eluviaCompanies: store.projektomat.eluviaCompanies,
      eluviaCompaniesLoading: store.projektomat.eluviaCompaniesLoading,

      clientCompanies: store.projektomat.clientCompanies,
      clientCompaniesLoading: store.projektomat.clientCompaniesLoading,

      projectsLoading: store.projektomat.projectsLoading,
      projectsList: store.projektomat.projects,
    }
  }, mapDispatchToProps)
)(EditIssuedInvoiceForm)
