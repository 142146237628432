import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import {Typography} from '@material-ui/core'
import DialogActions from '@material-ui/core/DialogActions'
import withStyles from '@material-ui/core/styles/withStyles'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import {Close} from '@material-ui/icons'
import materialStyle from 'component/material/materialStyle'

const DialogWindow = (props) => {
  const {
    classes,
    title,
    open,
    onClose,
    children,
    dialogActions,
    maxWidth = 'sm',
    fullWidth = true,
    ...rest
  } = props

  return (
    <Dialog onClose={onClose} open={open} maxWidth={maxWidth} fullWidth={fullWidth} {...rest}>
      <DialogTitle disableTypography className={classes.dialogTitle}>
        <Typography variant="h6">{title}</Typography>
        {onClose ? (
          <IconButton className={classes.dialogCloseButton} onClick={onClose}>
            <Close />
          </IconButton>
        ) : null}
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>{children}</DialogContent>
      {dialogActions && (
        <DialogActions className={classes.dialogActions}>{dialogActions}</DialogActions>
      )}
    </Dialog>
  )
}

export default withStyles(materialStyle)(DialogWindow)
