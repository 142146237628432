import React, {useEffect, useState} from 'react'
import {Trans} from '@lingui/macro'
import Table from 'component/material/table/Table'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import {updateGlobalSetting} from 'redux/action/globalSettingAction'
import {deleteIndirectExpense, getIndirectExpenses} from 'redux/action/projektomatAction'
import PropTypes from 'prop-types'
import moment from 'moment'
import {DATE_FORMAT, DB_DATE_FORMAT} from 'helper/constants'
import DialogWindow from 'component/material/DialogWindow'
import GridItem from 'component/material/GridItem'
import {Delete, Edit} from '@material-ui/icons'
import GridContainer from 'component/material/GridContainer'
import EditIndirectExpenseForm from 'component/ProjektomatPage/EditIndirectExpenseForm'
import {renderAmountWithCurrency} from 'helper/functions'
import {useTableSort} from 'hooks/useTableSort'
import TableIconButton from 'component/general/TableIconButton'
import {usePagination} from 'hooks/usePagination'
import {useWarningDialog} from 'hooks/useWarningDialog'

const IndirectExpensesDocument = (props) => {
  const {
    rowsPerPage,
    updateGlobalSetting,
    getIndirectExpenses,
    indirectExpenses,
    indirectExpensesLoading,
    indirectExpensesMeta,
    filters,
    deleteIndirectExpense,
    selectedDateFrom,
    selectedDateTo,
    newAssetCreated,
    setNewAssetCreated,
  } = props

  const [openEdit, setOpenEdit] = useState({visible: false, document: null})
  const [edited, setEdited] = useState(false)
  const [tableSort, onSortClick] = useTableSort({
    orderBy: filters.orderBy,
    orderDirection: filters.orderDirection,
  })

  const [page, onChangePage, onChangeRowsPerPage] = usePagination(updateGlobalSetting)
  const [DeleteWarningDialog, deleteData, onOpenDelete, onCloseDelete] = useWarningDialog()

  const columns = [
    {
      name: 'note',
      orderKey: 'note',
      label: <Trans>Note</Trans>,
    },
    {
      name: 'define_date',
      orderKey: 'define_date',
      align: 'right',
      label: <Trans>Define date</Trans>,
      render: (val) => val && moment(val).format(DATE_FORMAT),
    },
    {
      name: 'maturity_date',
      orderKey: 'maturity_date',
      align: 'right',
      label: <Trans>Maturity date</Trans>,
      render: (val) => val && moment(val).format(DATE_FORMAT),
    },
    {
      name: 'amount',
      orderKey: 'amount',
      align: 'right',
      label: <Trans>Amount</Trans>,
      render: (val, col, rowIndex, row) => renderAmountWithCurrency(val, row.currency),
    },
    {
      name: '',
      align: 'right',
      render: (val, col, rowIndex, row) => (
        <GridContainer spacing={2} direction={'row'} wrap={'nowrap'} justifyContent={'flex-end'}>
          <GridItem>
            <TableIconButton
              tooltipTitle={<Trans>Edit indirect expense</Trans>}
              onClick={openEditIndirectExpense(row)}
              Icon={Edit}
            />
          </GridItem>
          <GridItem>
            <TableIconButton
              tooltipTitle={<Trans>Delete indirect expense</Trans>}
              onClick={onOpenDelete(row)}
              Icon={Delete}
            />
          </GridItem>
        </GridContainer>
      ),
    },
  ]

  const openEditIndirectExpense = (row) => () => {
    setOpenEdit({visible: true, document: row})
  }

  const closeEditIndirectExpense = () => {
    setOpenEdit({visible: false, document: null})
  }

  const handleDeleteIndirectExpense = () => {
    deleteIndirectExpense(deleteData?.id).then(() => {
      getIndirectExpenses(rowsPerPage, page * rowsPerPage, filters)
      onCloseDelete()
    })
  }

  useEffect(() => {
    getIndirectExpenses(rowsPerPage, page * rowsPerPage, {
      ...filters,
      define_date_from: selectedDateFrom && moment(selectedDateFrom).format(DB_DATE_FORMAT),
      define_date_to: selectedDateTo && moment(selectedDateTo).format(DB_DATE_FORMAT),
    })
  }, [filters, page, rowsPerPage, selectedDateFrom, selectedDateTo])

  useEffect(() => {
    if (newAssetCreated || edited) {
      getIndirectExpenses(rowsPerPage, page * rowsPerPage, {
        ...filters,
        define_date_from: selectedDateFrom && moment(selectedDateFrom).format(DB_DATE_FORMAT),
        define_date_to: selectedDateTo && moment(selectedDateTo).format(DB_DATE_FORMAT),
      })
      setNewAssetCreated(false)
      setEdited(false)
    }
  }, [newAssetCreated, edited])

  return (
    <>
      {/* DELETE DIALOG */}
      <DeleteWarningDialog
        onConfirm={handleDeleteIndirectExpense}
        message={<Trans>Indirect expense {deleteData?.note} will be deleted!</Trans>}
      />

      {/* EDIT INDIRECT EXPENSE */}
      <DialogWindow
        onClose={closeEditIndirectExpense}
        open={openEdit.visible}
        title={<Trans>Edit indirect expense</Trans>}
        maxWidth={'md'}
      >
        <EditIndirectExpenseForm
          onClose={closeEditIndirectExpense}
          setEdited={setEdited}
          document={openEdit.document}
        />
      </DialogWindow>

      <Table
        columns={columns}
        data={indirectExpenses}
        loading={indirectExpensesLoading}
        count={indirectExpensesMeta?.total_count}
        page={page}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        pagination={true}
        filters={filters}
        filterName={'indirectExpensesDocument'}
        onSortClick={onSortClick}
        orderBy={tableSort.orderBy}
        orderDirection={tableSort.orderDirection}
      />
    </>
  )
}

IndirectExpensesDocument.propTypes = {
  updateGlobalSetting: PropTypes.func,
  getIndirectExpenses: PropTypes.func,
  indirectExpenses: PropTypes.array,
  indirectExpensesLoading: PropTypes.bool,
  indirectExpensesMeta: PropTypes.object,
  filters: PropTypes.object,
  deleteIndirectExpense: PropTypes.func,
  setNewAssetCreated: PropTypes.func,
  newAssetCreated: PropTypes.bool,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateGlobalSetting,
      getIndirectExpenses,
      deleteIndirectExpense,
    },
    dispatch
  )
}

export default compose(
  connect((store) => {
    return {
      rowsPerPage: store.globalSetting.rowsPerPage,
      indirectExpenses: store.projektomat.indirectExpenses,
      indirectExpensesLoading: store.projektomat.indirectExpensesLoading,
      indirectExpensesMeta: store.projektomat.indirectExpensesMeta,
      filters: store.filter.indirectExpensesDocument,
    }
  }, mapDispatchToProps)
)(IndirectExpensesDocument)
