const projektomatPageStyle = (theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },

  tabs: {
    backgroundColor: theme.palette.white,
    color: theme.palette.primary.main,

    '& .MuiTabScrollButton-root:first-of-type': {
      borderRight: '0px solid ' + theme.palette.secondary.main,
    },
    '& .MuiTabScrollButton-root:last-of-type': {
      borderLeft: '0px solid ' + theme.palette.secondary.main,
    },
    '& .MuiTab-textColorInherit.Mui-selected': {
      fontWeight: 700,
      opacity: 1,
    },
    '& .MuiTab-textColorInherit': {
      opacity: 0.8,
      fontWeight: 600,
    },
    '& .MuiTab-root': {
      minWidth: '90px !important',
      padding: '6px 24px',
    },
  },

  borderBottomTabs: {
    '& .MuiTabs-flexContainer': {
      borderBottom: '0px solid ' + theme.palette.secondary.main,
    },
  },

  appBarBottomBorder: {
    borderBottom: '0px solid ' + theme.palette.secondary.main,
  },

  summaryBorderTop: {
    borderTop: '1px solid ' + theme.palette.border + ' !important',
    width: 'calc(100% + 32px)',
    marginLeft: '-16px',
  },

  attachmentIdRow: {
    display: 'flex',
    alignItems: 'center',
  },
  attachmentIdText: {
    width: 150,
    fontSize: 14,
  },
  defaultCheck: {
    cursor: 'default',
    pointerEvents: 'none',
    color: theme.palette.border,
    width: 26,
    height: 26,
    opacity: 0.4,
  },
  attachmentCheck: {
    color: theme.palette.primary.main,
    opacity: 1,
  },
  summaryText: {
    color: theme.palette.primary.main,
    fontWeight: 500,
    paddingBottom: 10,
  },
  createInvoiceDialogTab: {
    paddingTop: 20,
    width: 928,
    height: 355,
  },
  createAcceptedInvoiceDialogTab: {
    width: '100%',
    height: 'auto',
    paddingTop: '20px',
    boxSizing: 'border-box',
  },
  fullWidth: {
    width: '100%',
  },
  addButtonClass: {
    height: 56,
    boxSizing: 'border-box',
  },

  minHeightTable: {
    minHeight: 100,
  },

  overflowAuto: {
    overflow: 'auto',
  },

  newDocumentButton: {
    height: 40,
    fontSize: 14,
    position: 'absolute',
    right: 16,
    top: -40,
  },

  iconButtonHeight: {
    padding: 6,
  },

  projectCellClass: {
    height: 30,
    boxSizing: 'border-box',
    paddingTop: 0,
    paddingBottom: 0,
  },

  createButtonFlex: {
    display: 'flex',
    alignItems: 'center',
  },

  tooltipWidth: {
    maxWidth: 'fit-content',
    margin: '0 0 0 auto',
  },

  unprocessedMdsChip: {
    fontWeight: 500,
    minWidth: 70,
  },

  redChipColor: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.white,
  },

  greenChipColor: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.white,
  },
  downloadLink: {
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  errorLink: {
    color: theme.palette.error.main,
    fontWeight: 500,
  },
  pendingLink: {
    color: theme.palette.warning.main,
    fontWeight: 500,
  },

  fromToPanel: {
    height: 60,
    padding: '6px 16px 0px',
    paddingRight: 120,
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
  },

  fromToPanelDatePicker: {
    paddingRight: 12,
    '& .MuiOutlinedInput-root': {
      maxHeight: 40,
      height: 40,
      width: 'auto',
      minWidth: '150px',
      maxWidth: '150px',
    },
    '& .MuiOutlinedInput-input': {
      padding: '12px 12px',
      width: 'auto',
      minWidth: '150px',
      maxWidth: '150px',
      fontSize: 13,
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 12px) scale(1)',
      fontSize: 13,
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
    '& .MuiFormHelperText-contained': {
      display: 'none',
    },
  },

  companyFormControl: {
    width: 'auto',
    minWidth: '150px',
    paddingRight: 12,

    '& .MuiInputLabel-animated': {
      fontSize: 13,
    },
    '& .MuiSelect-select': {
      fontSize: 13,
      '&:focus': {
        backgroundColor: 'white',
      },
    },
  },

  cancelButtonPadding: {
    marginRight: 12,
  },

  documentTitle: {
    paddingTop: '24px !important',
  },

  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  tableMenuItem: {
    height: 40,
    '& .MuiTypography-body1': {
      fontSize: 14,
    },
  },
  syncButton: {
    marginLeft: 10,
    cursor: 'pointer',
  },

  syncButtonRotating: {
    animation: 'rotation 1.5s infinite linear',
  },

  deleteIconButton: {
    padding: 8,
  },

  chipMargin: {
    marginRight: 2,
    marginBottom: 2,
  },

  marginRight: {
    marginRight: 8,
  },
})

export default projektomatPageStyle
