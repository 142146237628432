import React, {useState} from 'react'
import DialogWindow from 'component/material/DialogWindow'

export const useDialog = () => {
  const [open, setOpen] = useState(false)
  const [data, setData] = useState(null)

  const onOpen = (data) => () => {
    setOpen(true)
    setData(data)
  }

  const onClose = () => {
    setOpen(false)
    setData(null)
  }

  const DialogComponent = (props) => {
    const {children, title, ...rest} = props
    return (
      <DialogWindow onClose={onClose} open={open} title={title} {...rest}>
        {children}
      </DialogWindow>
    )
  }

  return [DialogComponent, data, onOpen, onClose]
}
