import React, {useState} from 'react'
import {Form, Field} from 'react-final-form'
import {Trans} from '@lingui/macro'
import PrimaryButton from 'component/material/PrimaryButton'
import PropTypes from 'prop-types'
import {required} from 'helper/validations'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import TextInput from 'component/field/TextInput'
import {CURRENCY_LIST, ODHAD_LEVEL, STORY_LAYERS} from 'helper/constants'
import MultiSelectInput from 'component/field/MultiSelectInput'
import withStyles from '@material-ui/core/styles/withStyles'
import SelectInput from 'component/field/SelectInput'
import Box from '@material-ui/core/Box'
import NumberFormatCustom from 'component/field/NumberFormatCustom'
import {Step, StepLabel, Stepper} from '@material-ui/core'
import odhadyStyle from 'component/OdhadomatPage/odhadyStyle'
import InputAdornment from '@material-ui/core/InputAdornment'
import {Person} from '@material-ui/icons'
import {renderCurrencySymbol} from 'helper/functions'

const OdhadForm = (props) => {
  const {handleSubmit, initValues = {}, classes, edit = false} = props

  const [activeStep, setActiveStep] = useState(0)
  const [formData, setFormData] = useState({currency: 'CZK', ...initValues})

  const prepareOptionsForMultiSelect = () => {
    const options = {}
    STORY_LAYERS.forEach((item, index) => {
      options[index] = item
    })
    return options
  }

  const getInitialValues = () => {
    return {
      ...formData,
      layers:
        formData.team_capacities &&
        Object.keys(formData.team_capacities)?.map((layer) =>
          STORY_LAYERS.findIndex((l) => l === layer)
        ),
    }
  }

  return (
    <Form onSubmit={handleSubmit} initialValues={getInitialValues()}>
      {(formProps) => {
        const {submitting} = formProps
        return (
          <form onSubmit={formProps.handleSubmit}>
            <Stepper alternativeLabel activeStep={activeStep} className={classes.estimationStepper}>
              <Step>
                <StepLabel>
                  <Trans>General information</Trans>
                </StepLabel>
              </Step>
              <Step>
                <StepLabel>
                  <Trans>Prices</Trans>
                </StepLabel>
              </Step>
              <Step>
                <StepLabel>
                  <Trans>Stories</Trans>
                </StepLabel>
              </Step>
            </Stepper>
            {activeStep === 0 && (
              // FIRST STEP
              <GridContainer spacing={2}>
                {!edit && (
                  <GridItem xs={12}>
                    <Field
                      name="breakdown_parent_folder_url"
                      label={<Trans>Breakdown folder url</Trans>}
                      component={TextInput}
                      validate={required}
                      disabled={submitting}
                    />
                  </GridItem>
                )}
                <GridItem xs={12} sm={6}>
                  <Field
                    name="title"
                    label={<Trans>Title</Trans>}
                    component={TextInput}
                    validate={required}
                    disabled={submitting}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <Field
                    name="project_ref"
                    label={<Trans>Reference</Trans>}
                    component={TextInput}
                    validate={required}
                    disabled={submitting}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <Field
                    name="level"
                    label={<Trans>Level</Trans>}
                    component={SelectInput}
                    options={ODHAD_LEVEL}
                    validate={required}
                    disabled={submitting}
                  />
                </GridItem>
              </GridContainer>
            )}
            {activeStep === 1 && (
              // SECOND STEP
              <GridContainer spacing={2}>
                <GridItem xs={12} sm={6}>
                  <Field
                    name="currency"
                    label={<Trans>Currency</Trans>}
                    component={SelectInput}
                    options={CURRENCY_LIST}
                    validate={required}
                    disabled={submitting}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <Field
                    name={'hosting_price'}
                    label={<Trans>Hosting price</Trans>}
                    component={TextInput}
                    type={'number'}
                    validate={required}
                    disabled={submitting}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    inputProps={renderCurrencySymbol(formProps?.values?.currency)}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <Field
                    name={'junior_price'}
                    label={<Trans>Junior price</Trans>}
                    component={TextInput}
                    type={'number'}
                    validate={required}
                    disabled={submitting}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    inputProps={renderCurrencySymbol(formProps?.values?.currency)}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <Field
                    name={'senior_price'}
                    label={<Trans>Senior price</Trans>}
                    component={TextInput}
                    type={'number'}
                    validate={required}
                    disabled={submitting}
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    inputProps={renderCurrencySymbol(formProps?.values?.currency)}
                  />
                </GridItem>
              </GridContainer>
            )}
            {activeStep === 2 && (
              // THIRD STEP
              <GridContainer spacing={2}>
                <GridItem sm={6}>
                  <Field
                    name={'avg_story_mds'}
                    label={<Trans>Average story</Trans>}
                    component={TextInput}
                    type={'number'}
                    validate={required}
                    disabled={submitting}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">MD</InputAdornment>,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <Field
                    name={'reserve'}
                    label={<Trans>Reserve</Trans>}
                    component={TextInput}
                    type={'number'}
                    validate={required}
                    disabled={submitting}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <Field
                    name={'team_size'}
                    label={<Trans>Team size</Trans>}
                    component={TextInput}
                    type={'number'}
                    validate={required}
                    disabled={submitting}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Person fontSize={'small'} color={'disabled'} />
                        </InputAdornment>
                      ),
                    }}
                  />
                </GridItem>
                <GridItem container spacing={2}>
                  <GridItem xs={6}>
                    <Field
                      name={'layers'}
                      label={<Trans>Story layers</Trans>}
                      options={prepareOptionsForMultiSelect()}
                      component={MultiSelectInput}
                      validate={required}
                      disabled={submitting || edit}
                      size={'small'}
                    />
                  </GridItem>
                  <GridItem xs={6} container>
                    {formProps.values.layers?.map((layer, index) => (
                      <GridItem xs={12} key={index}>
                        <Field
                          name={`team_capacities.${STORY_LAYERS[layer]}`}
                          label={<Trans>Capacity of team by {STORY_LAYERS[layer]}</Trans>}
                          component={TextInput}
                          validate={required}
                          type={'number'}
                          disabled={submitting}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Person fontSize={'small'} color={'disabled'} />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </GridItem>
                    ))}
                  </GridItem>
                </GridItem>
              </GridContainer>
            )}

            <Box pt={3}>
              <button
                id={'validate'}
                style={{display: 'none'}}
                onClick={() => {
                  return formProps.form.resumeValidation()
                }}
              />

              {activeStep === 0 && (
                <GridContainer justifyContent={'flex-end'}>
                  <PrimaryButton
                    fullWidth={false}
                    disabled={submitting}
                    onClick={() => {
                      if (formProps.valid) {
                        setActiveStep(1)
                        setFormData((prevState) => ({...prevState, ...formProps.values}))
                      } else {
                        document.getElementById('validate').click()
                      }
                    }}
                    text={<Trans>Next</Trans>}
                  />
                </GridContainer>
              )}
              {activeStep === 1 && (
                <GridContainer justifyContent={'space-between'}>
                  <GridItem>
                    <PrimaryButton
                      variant={'text'}
                      fullWidth={false}
                      onClick={() => {
                        setActiveStep(0)
                        setFormData((prevState) => ({...prevState, ...formProps.values}))
                      }}
                      disabled={submitting}
                      text={<Trans>Back</Trans>}
                    />
                  </GridItem>
                  <GridItem>
                    <PrimaryButton
                      fullWidth={false}
                      disabled={submitting}
                      onClick={() => {
                        if (formProps.valid) {
                          setActiveStep(2)
                          setFormData((prevState) => ({...prevState, ...formProps.values}))
                        } else {
                          document.getElementById('validate').click()
                        }
                      }}
                      text={<Trans>Next</Trans>}
                    />
                  </GridItem>
                </GridContainer>
              )}
              {activeStep === 2 && (
                <GridContainer justifyContent={'space-between'}>
                  <GridItem>
                    <PrimaryButton
                      variant={'text'}
                      fullWidth={false}
                      onClick={() => {
                        setActiveStep(1)
                        setFormData((prevState) => ({...prevState, ...formProps.values}))
                      }}
                      disabled={submitting}
                      text={<Trans>Back</Trans>}
                    />
                  </GridItem>
                  <GridItem>
                    <PrimaryButton
                      fullWidth={false}
                      disabled={submitting}
                      type="submit"
                      text={edit ? <Trans>Edit</Trans> : <Trans>Save</Trans>}
                    />
                  </GridItem>
                </GridContainer>
              )}
            </Box>
          </form>
        )
      }}
    </Form>
  )
}

OdhadForm.propTypes = {
  handleSubmit: PropTypes.func,
  estimationFiles: PropTypes.array,
  estimationInProgress: PropTypes.bool,
  breakdownList: PropTypes.array,
  breakdownLoading: PropTypes.bool,
  classes: PropTypes.object,
  edit: PropTypes.bool,
}

export default withStyles(odhadyStyle)(OdhadForm)
