import {Trans} from '@lingui/macro'
import {DatePicker} from '@material-ui/pickers'
import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import cx from 'classnames'
import fieldStyle from 'component/field/fieldStyle'

const MonthPicker = (props) => {
  const {
    classes,
    value,
    onChange,
    label = <Trans>Month</Trans>,
    variant = 'outlined',
    showHelperText = true,
    size = 'small',
    customError,
    helperText,
    input,
    meta,
    ...rest
  } = props

  const errorMessage = customError || (meta && meta.touched && (meta.error || meta.submitError))
  const errorHelperText = showHelperText && (errorMessage || helperText || ' ')

  return (
    <div
      className={cx(
        classes.datePickerInput,
        size === 'small' ? classes.datePickerInputIconSmall : '',
        classes.inputFormHelperText
      )}
    >
      <DatePicker
        className={classes.inputFontSize}
        autoOk
        label={label}
        clearable
        size={size}
        inputVariant={variant}
        format={'MMMM'}
        views={['year', 'month']}
        openTo={'month'}
        clearLabel={<Trans>Clear</Trans>}
        cancelLabel={<Trans>Close</Trans>}
        okLabel={<Trans>Ok</Trans>}
        invalidDateMessage={<Trans>Invalid Date Format</Trans>}
        maxDateMessage={<Trans>Date should not be after maximal date</Trans>}
        minDateMessage={<Trans>Date should not be before minimal date</Trans>}
        {...input}
        value={input.value ? input.value : value}
        error={!!errorMessage}
        helperText={errorHelperText}
        onChange={(e) => {
          input?.onChange(e) //final-form's onChange
          if (onChange) {
            //props.onChange
            onChange(e)
          }
        }}
        {...rest}
      />
    </div>
  )
}

export default withStyles(fieldStyle)(MonthPicker)
