import React from 'react'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import {Field, Form} from 'react-final-form'
import {Trans} from '@lingui/macro'
import {required} from 'helper/validations'
import TextInput from 'component/field/TextInput'
import PrimaryButton from 'component/material/PrimaryButton'
import MonthPicker from 'component/general/MonthPicker'

const ScheduleForm = (props) => {
  const {handleSubmit, schedule} = props

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={{start_date: schedule?.start_date, months_count: schedule?.months_count}}
    >
      {(formProps) => {
        const {submitting} = formProps
        return (
          <form onSubmit={formProps.handleSubmit}>
            <GridContainer spacing={2}>
              <GridItem xs={6}>
                <Field
                  name="start_date"
                  label={<Trans>Start month</Trans>}
                  component={MonthPicker}
                  validate={required}
                  disabled={submitting}
                />
              </GridItem>
              <GridItem xs={6}>
                <Field
                  name="months_count"
                  label={<Trans>Months count</Trans>}
                  component={TextInput}
                  validate={required}
                  type={'number'}
                  disabled={submitting}
                />
              </GridItem>
              <GridItem container xs={'auto'} justifyContent={'flex-end'}>
                <PrimaryButton
                  fullWidth={false}
                  disabled={submitting}
                  type="submit"
                  text={<Trans>Save</Trans>}
                />
              </GridItem>
            </GridContainer>
          </form>
        )
      }}
    </Form>
  )
}

export default ScheduleForm
