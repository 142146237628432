const loadingStyle = (theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
  },

  // stretch box to full width of container
  dot: {
    color: theme.palette.primary.main,
    display: 'inline',
    marginLeft: '0.1em',
    marginRight: '0.1em',
    position: 'relative',
    top: '3px',
    opacity: '0',
    animationName: '$showHideDot',
    animation: '2.5s ease-in-out infinite',
  },

  '@keyframes showHideDot': {
    '0%': {opacity: 0},
    '50%': {opacity: 1},
    '60%': {opacity: 1},
    '100%': {opacity: 0},
  },

  one: {animationDelay: '0.2s'},
  two: {animationDelay: '0.4s'},
  three: {animationDelay: '0.6s'},
})

export default loadingStyle
