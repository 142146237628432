import React from 'react'
import GridItem from 'component/material/GridItem'
import {Typography} from '@material-ui/core'
import GridContainer from 'component/material/GridContainer'
import PropTypes from 'prop-types'
import BoxFullWidth from 'component/material/BoxFullWidth'
import BackText from 'component/general/BackText'

const PageTitleWrapper = (props) => {
  const {children, title, backButton, backButtonUrl} = props

  return (
    <GridContainer direction="row" alignItems="center" justifyContent={'center'}>
      <GridItem
        container
        xs={12}
        alignItems={'flex-start'}
        justifyContent={backButton ? 'space-between' : 'flex-start'}
      >
        <Typography variant={'h6'} color="primary" align="center">
          {title}
        </Typography>
        {backButton && <BackText path={backButtonUrl} />}
      </GridItem>
      <GridItem container xs={12} justifyContent="center" alignItems={'center'}>
        <BoxFullWidth pt={8}>{children}</BoxFullWidth>
      </GridItem>
    </GridContainer>
  )
}

PageTitleWrapper.propTypes = {
  children: PropTypes.node,
  title: PropTypes.node,
  backButton: PropTypes.bool,
  backButtonUrl: PropTypes.string,
}

export default PageTitleWrapper
