import React from 'react'
import {Field, Form} from 'react-final-form'
import PropTypes from 'prop-types'
import {dateFromToValidation, required} from 'helper/validations'
import {Trans} from '@lingui/macro'
import SelectInput from 'component/field/SelectInput'
import DatePickerInput from 'component/field/DatePickerInput'
import BoxFullWidth from 'component/material/BoxFullWidth'
import PrimaryButton from 'component/material/PrimaryButton'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import TextInput from 'component/field/TextInput'
import {CURRENCY_LIST, DB_DATE_FORMAT} from 'helper/constants'
import {withStyles} from '@material-ui/core'
import projektomatPageStyle from 'component/ProjektomatPage/projektomatPageStyle'
import {postIndirectExpense} from 'redux/action/projektomatAction'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import moment from 'moment'
import {mapErrorResponseToForm} from 'helper/functions'
import NumberFormatCustom from 'component/field/NumberFormatCustom'

const NewIndirectExpenseForm = (props) => {
  const {classes, onClose, postIndirectExpense, setNewAssetCreated} = props

  const prepareValues = (values) => {
    return {
      ...values,
      amount: Math.round(Number(values.amount) * 100),
      define_date: values.define_date && moment(values.define_date).format(DB_DATE_FORMAT),
      maturity_date: values.maturity_date && moment(values.maturity_date).format(DB_DATE_FORMAT),
    }
  }

  const handleSubmit = (values) => {
    const preparedValues = prepareValues(values)
    return postIndirectExpense(preparedValues)
      .then(() => {
        onClose()
        setNewAssetCreated(true)
      })
      .catch((err) => {
        return mapErrorResponseToForm(err)
      })
  }

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={{
        define_date: moment(),
        maturity_date: moment().add(14, 'days'),
        currency: 'CZK',
      }}
      validate={dateFromToValidation}
    >
      {(formProps) => {
        return (
          <form onSubmit={formProps.handleSubmit}>
            <GridContainer spacing={2}>
              <GridItem xs={12} sm={6}>
                <Field
                  name="define_date"
                  label={<Trans>Define date</Trans>}
                  component={DatePickerInput}
                  validate={!formProps.submitSucceeded && required}
                  disabled={formProps.submitting}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <Field
                  name="maturity_date"
                  label={<Trans>Maturity date</Trans>}
                  component={DatePickerInput}
                  validate={!formProps.submitSucceeded && required}
                  disabled={formProps.submitting}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <Field
                  name="amount"
                  label={<Trans>Amount</Trans>}
                  component={TextInput}
                  type={'number'}
                  validate={!formProps.submitSucceeded && required}
                  disabled={formProps.submitting}
                  autoComplete="off"
                  InputProps={{inputComponent: NumberFormatCustom}}
                  inputProps={{suffix: ''}}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <Field
                  name="currency"
                  label={<Trans>Currency</Trans>}
                  component={SelectInput}
                  options={CURRENCY_LIST}
                  validate={!formProps.submitSucceeded && required}
                  disabled={formProps.submitting}
                />
              </GridItem>
              <GridItem xs={12}>
                <Field
                  name="note"
                  label={<Trans>Note</Trans>}
                  component={TextInput}
                  multiline={true}
                  rows={2}
                  disabled={formProps.submitting}
                />
              </GridItem>
            </GridContainer>
            <BoxFullWidth pt={3}>
              <GridContainer justifyContent={'flex-end'}>
                <PrimaryButton
                  fullWidth={false}
                  variant={'outlined'}
                  text={<Trans>Cancel</Trans>}
                  className={classes.cancelButtonPadding}
                  onClick={onClose}
                  disabled={formProps.submitting}
                />
                <PrimaryButton
                  fullWidth={false}
                  type="submit"
                  text={<Trans>Create</Trans>}
                  disabled={formProps.submitting}
                />
              </GridContainer>
            </BoxFullWidth>
          </form>
        )
      }}
    </Form>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      postIndirectExpense,
    },
    dispatch
  )
}

NewIndirectExpenseForm.propTypes = {
  onClose: PropTypes.func,
  classes: PropTypes.object,
  postIndirectExpense: PropTypes.func,
  setNewAssetCreated: PropTypes.func,
}

export default compose(
  withStyles(projektomatPageStyle),
  connect((store) => {
    return {}
  }, mapDispatchToProps)
)(NewIndirectExpenseForm)
