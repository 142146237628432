import {
  GET_KAPACITOMAT_ASSIGNMENT_LIST_FAIL,
  GET_KAPACITOMAT_ASSIGNMENT_LIST_REQUEST,
  GET_KAPACITOMAT_ASSIGNMENT_LIST_SUCCESS,
  GET_KAPACITOMAT_EMPLOYEE_LIST_FAIL,
  GET_KAPACITOMAT_EMPLOYEE_LIST_REQUEST,
  GET_KAPACITOMAT_EMPLOYEE_LIST_SUCCESS,
  GET_KAPACITOMAT_PROJECT_LIST_FAIL,
  GET_KAPACITOMAT_PROJECT_LIST_REQUEST,
  GET_KAPACITOMAT_PROJECT_LIST_SUCCESS,
  GET_KAPACITOMAT_PIGGY_REQUEST,
  GET_KAPACITOMAT_PIGGY_SUCCESS,
  GET_KAPACITOMAT_PIGGY_FAIL,
  UPDATE_KAPACITOMAT_PROJECT_ID,
} from 'redux/actionType'

const initState = {
  period: {},

  projects: [],
  projectsLoading: false,
  projectsMeta: {},

  employees: [],
  employeesLoading: false,
  employeesMeta: {},

  assignments: null,
  assignmentsLoading: false,

  piggyBank: {},
  piggyBankLoading: false,
}

const kapacitomatReducer = (state = initState, action) => {
  switch (action.type) {
    case UPDATE_KAPACITOMAT_PROJECT_ID: {
      return {
        ...state,
        period: {
          id: action.data.id,
          tab: action.data.tab,
        },
      }
    }
    case GET_KAPACITOMAT_PROJECT_LIST_REQUEST: {
      return {
        ...state,
        projectsLoading: true,
      }
    }
    case GET_KAPACITOMAT_PROJECT_LIST_SUCCESS: {
      return {
        ...state,
        projectsLoading: false,
        projects: action.data.objects,
        projectsMeta: action.data.meta,
      }
    }
    case GET_KAPACITOMAT_PROJECT_LIST_FAIL: {
      return {
        ...state,
        projectsLoading: false,
      }
    }
    case GET_KAPACITOMAT_EMPLOYEE_LIST_REQUEST: {
      return {
        ...state,
        employeesLoading: true,
      }
    }
    case GET_KAPACITOMAT_EMPLOYEE_LIST_SUCCESS: {
      return {
        ...state,
        employeesLoading: false,
        employees: action.data.objects,
        employeesMeta: action.data.meta,
      }
    }
    case GET_KAPACITOMAT_EMPLOYEE_LIST_FAIL: {
      return {
        ...state,
        employeesLoading: false,
      }
    }
    case GET_KAPACITOMAT_ASSIGNMENT_LIST_REQUEST: {
      return {
        ...state,
        assignmentsLoading: true,
      }
    }
    case GET_KAPACITOMAT_ASSIGNMENT_LIST_SUCCESS: {
      return {
        ...state,
        assignmentsLoading: false,
        assignments: action.data,
      }
    }
    case GET_KAPACITOMAT_ASSIGNMENT_LIST_FAIL: {
      return {
        ...state,
        assignmentsLoading: false,
      }
    }
    case GET_KAPACITOMAT_PIGGY_REQUEST: {
      return {
        ...state,
        piggyBankLoading: true,
      }
    }
    case GET_KAPACITOMAT_PIGGY_SUCCESS: {
      return {
        ...state,
        piggyBankLoading: false,
        piggyBank: action.data,
      }
    }
    case GET_KAPACITOMAT_PIGGY_FAIL: {
      return {
        ...state,
        piggyBankLoading: false,
      }
    }
    default: {
      return state
    }
  }
}

export default kapacitomatReducer
