import React from 'react'
import Table from 'component/material/table/Table'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import projektomatPageStyle from 'component/ProjektomatPage/projektomatPageStyle'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import {getEmployeeList, postProjectEvent} from 'redux/action/projektomatAction'
import getProjectTableColumns from 'component/ProjektomatPage/getProjectTableColumns'
import {Trans} from '@lingui/macro'
import ProjectAssignment from 'component/ProjektomatPage/ProjectAssignment'
import {useDialog} from 'hooks/useDialog'

const ProjectTable = (props) => {
  const {
    data,
    cellClassName,
    loading,
    onSortClick,
    orderBy,
    orderDirection,
    page,
    onChangePage,
    onChangeRowsPerPage,
    count,
    classes,
  } = props

  const [Dialog, dialogData, onOpen] = useDialog()

  const columns = getProjectTableColumns(classes, onOpen)

  return (
    <>
      {/* PROJECT ASSIGNMENT DIALOG */}
      <Dialog title={<Trans>Team and roles on project</Trans>} maxWidth={'md'}>
        <ProjectAssignment project={dialogData} />
      </Dialog>

      <Table
        columns={columns}
        data={data}
        cellClassName={cellClassName}
        loading={loading}
        orderDirection={orderDirection}
        orderBy={orderBy}
        onSortClick={onSortClick}
        count={count}
        page={page}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        pagination={true}
      />
    </>
  )
}

ProjectTable.propTypes = {
  data: PropTypes.array,
  cellClassName: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  loading: PropTypes.bool,
  onSortClick: PropTypes.func,
  orderBy: PropTypes.string,
  orderDirection: PropTypes.string,
  pagination: PropTypes.bool,
  page: PropTypes.number,
  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
  count: PropTypes.number,
  getEmployeeList: PropTypes.func,
  tab: PropTypes.object,
  employeeList: PropTypes.array,
  fetchProjects: PropTypes.func,
  periodId: PropTypes.string,
  postProjectEvent: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getEmployeeList,
      postProjectEvent,
    },
    dispatch
  )
}

export default compose(
  withStyles(projektomatPageStyle),
  connect((store) => {
    return {}
  }, mapDispatchToProps)
)(ProjectTable)
