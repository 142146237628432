import {useParams} from 'react-router-dom'
import {Add, Edit, Sync} from '@material-ui/icons'
import {mapErrorResponseToForm} from 'helper/functions'
import {Trans} from '@lingui/macro'
import React, {useEffect, useState} from 'react'
import PrivateContentLayout from 'layout/PrivateLayout/PrivateContentLayout'
import {bindActionCreators, compose} from 'redux'
import {
  getEstimationDetail,
  getSchedule,
  getSchedulePhases,
  patchSchedule,
  postSchedulePhases,
  recalculateSchedule,
} from 'redux/action/odhadomatAction'
import {connect} from 'react-redux'
import Loader from 'component/material/Loader'
import GridContainer from 'component/material/GridContainer'
import ScheduleForm from 'component/OdhadomatPage/Harmonogram/ScheduleForm'
import ScheduleTable from 'component/OdhadomatPage/Harmonogram/ScheduleTable'
import moment from 'moment'
import {DB_DATE_FORMAT} from 'helper/constants'
import GridItem from 'component/material/GridItem'
import CustomTooltip from 'component/material/Tooltip'
import PrimaryButton from 'component/material/PrimaryButton'
import DialogWindow from 'component/material/DialogWindow'
import PhaseForm from 'component/OdhadomatPage/Harmonogram/PhaseForm'
import withStyles from '@material-ui/core/styles/withStyles'
import odhadyStyle from 'component/OdhadomatPage/odhadyStyle'
import Breadcrumbs from 'component/general/Breadcrumbs'

const SchedulePage = (props) => {
  const {
    schedule,
    getSchedule,
    patchSchedule,
    scheduleLoading,
    getSchedulePhases,
    getEstimationDetail,
    estimation,
    postSchedulePhases,
    recalculateSchedule,
    classes,
  } = props

  const {id} = useParams()

  const [openEdit, setOpenEdit] = useState(false)
  const [openPhaseCreate, setOpenPhaseCreate] = useState(false)
  const [recLoading, setRecLoading] = useState(false)

  const preparePatchData = (values) => {
    return {
      start_date:
        values.start_date && moment(values.start_date).startOf('month').format(DB_DATE_FORMAT),
      months_count: Number(values.months_count),
    }
  }

  const handlePatchSchedule = (values) => {
    const data = preparePatchData(values)
    return patchSchedule(id, data).then(() => {
      fetchSchedulePhases()
      getSchedule(id)
      closeScheduleDialog()
    })
  }

  const fetchSchedulePhases = () => {
    return getSchedulePhases(id)
  }

  const openScheduleDialog = () => {
    setOpenEdit(true)
  }

  const closeScheduleDialog = () => {
    setOpenEdit(false)
  }

  const openPhaseDialog = () => {
    setOpenPhaseCreate(true)
  }

  const closePhaseDialog = () => {
    setOpenPhaseCreate(false)
  }

  const handleRecalculate = () => {
    setRecLoading(true)
    recalculateSchedule(id).finally(() => {
      setRecLoading(false)
    })
  }

  const preparePostData = (data) => {
    return {
      title: data.title,
      story_categories: estimation?.all_story_categories?.filter((cat, index) =>
        data.story_categories.includes(index)
      ),
    }
  }

  const handleSubmitPhase = (values) => {
    const data = preparePostData(values)
    return postSchedulePhases(id, data)
      .then(() => {
        getEstimationDetail(id)
        fetchSchedulePhases()
        closePhaseDialog()
        getSchedule(id)
      })
      .catch((err) => mapErrorResponseToForm(err))
  }

  useEffect(() => {
    getSchedule(id)
    getEstimationDetail(id)
    fetchSchedulePhases()
  }, [])

  return (
    <>
      {/* EDIT SCHEDULE DIALOG */}
      <DialogWindow
        onClose={closeScheduleDialog}
        open={openEdit}
        title={<Trans>Edit schedule</Trans>}
        maxWidth={'xs'}
        fullWidth={false}
      >
        <ScheduleForm handleSubmit={handlePatchSchedule} schedule={schedule} />
      </DialogWindow>

      {/* ADD PHASE DIALOG */}
      <DialogWindow
        onClose={closePhaseDialog}
        open={openPhaseCreate}
        title={<Trans>Add new phase</Trans>}
        maxWidth={'xs'}
        fullWidth={true}
      >
        <PhaseForm handleSubmit={handleSubmitPhase} />
      </DialogWindow>

      <PrivateContentLayout
        title={
          <Breadcrumbs
            breadcrumbs={[
              {title: <Trans>Estimations</Trans>, url: '/odhadomat/odhady'},
              {title: <Trans>Schedule</Trans>},
            ]}
          />
        }
        actions={
          <GridContainer spacing={2} justifyContent={'flex-end'}>
            <GridItem>
              <CustomTooltip title={<Trans>Recalculate schedule</Trans>}>
                <PrimaryButton
                  fullWidth={false}
                  text={
                    <GridContainer alignItems={'center'}>
                      <Sync
                        fontSize={'small'}
                        className={recLoading ? classes.syncButtonRotating : ''}
                      />
                    </GridContainer>
                  }
                  textPadding={0}
                  onClick={handleRecalculate}
                />
              </CustomTooltip>
            </GridItem>
            <GridItem>
              <CustomTooltip title={<Trans>Edit schedule</Trans>}>
                <PrimaryButton
                  fullWidth={false}
                  text={
                    <GridContainer alignItems={'center'}>
                      <Edit fontSize={'small'} />
                    </GridContainer>
                  }
                  textPadding={0}
                  onClick={openScheduleDialog}
                />
              </CustomTooltip>
            </GridItem>
            <GridItem>
              <CustomTooltip title={<Trans>Add new phase</Trans>}>
                <PrimaryButton
                  fullWidth={false}
                  text={
                    <GridContainer alignItems={'center'} justifyContent={'center'}>
                      <Add fontSize={'small'} />
                    </GridContainer>
                  }
                  textPadding={0}
                  onClick={openPhaseDialog}
                />
              </CustomTooltip>
            </GridItem>
          </GridContainer>
        }
        content={
          scheduleLoading ? (
            <Loader wrapper={true} />
          ) : (
            <GridContainer>
              <ScheduleTable fetchSchedulePhases={fetchSchedulePhases} />
            </GridContainer>
          )
        }
      />
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getSchedule,
      patchSchedule,
      getSchedulePhases,
      getEstimationDetail,
      postSchedulePhases,
      recalculateSchedule,
    },
    dispatch
  )
}

export default compose(
  withStyles(odhadyStyle),
  connect((store) => {
    return {
      schedule: store.odhadomat.schedule,
      scheduleLoading: store.odhadomat.scheduleLoading,
      estimation: store.odhadomat.estimation,
    }
  }, mapDispatchToProps)
)(SchedulePage)
