import React from 'react'
import Button from '@material-ui/core/Button'
import PropTypes from 'prop-types'
import BoxFullWidth from 'component/material/BoxFullWidth'
import withStyles from '@material-ui/core/styles/withStyles'
import materialStyle from 'component/material/materialStyle'
import cx from 'classnames'

const PrimaryButton = (props) => {
  const {
    classes,
    text,
    textPadding = 2,
    fullWidth = true,
    variant = 'contained',
    size = 'small',
    color = 'primary',
    boxClassName,
    className,
    ...rest
  } = props

  return (
    <Button
      className={cx(classes.buttonClass, className)}
      color={color}
      fullWidth={fullWidth}
      variant={variant}
      size={size}
      {...rest}
    >
      <BoxFullWidth className={boxClassName} p={textPadding}>
        {text}
      </BoxFullWidth>
    </Button>
  )
}

PrimaryButton.propTypes = {
  classes: PropTypes.object,
  text: PropTypes.node,
  textPadding: PropTypes.number,
  fullWidth: PropTypes.bool,
  variant: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  boxClassName: PropTypes.string,
}

export default withStyles(materialStyle)(PrimaryButton)
