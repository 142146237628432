import httpClient from 'helper/httpClient'
import endpoints from 'helper/endpoints'
import {
  GET_KAPACITOMAT_ASSIGNMENT_LIST_FAIL,
  GET_KAPACITOMAT_ASSIGNMENT_LIST_REQUEST,
  GET_KAPACITOMAT_ASSIGNMENT_LIST_SUCCESS,
  GET_KAPACITOMAT_EMPLOYEE_LIST_FAIL,
  GET_KAPACITOMAT_EMPLOYEE_LIST_REQUEST,
  GET_KAPACITOMAT_EMPLOYEE_LIST_SUCCESS,
  GET_KAPACITOMAT_PIGGY_FAIL,
  GET_KAPACITOMAT_PIGGY_REQUEST,
  GET_KAPACITOMAT_PIGGY_SUCCESS,
  GET_KAPACITOMAT_PROJECT_LIST_FAIL,
  GET_KAPACITOMAT_PROJECT_LIST_REQUEST,
  GET_KAPACITOMAT_PROJECT_LIST_SUCCESS,
  UPDATE_GLOBAL_LOADING,
  UPDATE_KAPACITOMAT_PROJECT_ID,
} from 'redux/actionType'
import {fireSuccessToast, globalApiErrorHandler} from 'helper/functions'
import {Trans} from '@lingui/macro'
import React from 'react'

export const updateKapacitomatPeriodId = (period) => {
  return (dispatch) => {
    dispatch({type: UPDATE_KAPACITOMAT_PROJECT_ID, data: period})
  }
}

export const getKapacitomatProjectList = (
  limit = 1000,
  offset = 0,
  period_id,
  filters,
  show_active
) => {
  return (dispatch) => {
    dispatch({type: GET_KAPACITOMAT_PROJECT_LIST_REQUEST})
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .get(endpoints.projectsKap, {
        limit: limit,
        offset: offset,
        period_id: period_id || null,
        order_by:
          filters?.orderBy &&
          filters?.orderDirection &&
          `${filters?.orderBy}:${filters?.orderDirection}`,
        show_active: show_active || null,
      })
      .then((res) => {
        dispatch({type: GET_KAPACITOMAT_PROJECT_LIST_SUCCESS, data: res.data})
      })
      .catch((err) => {
        dispatch({type: GET_KAPACITOMAT_PROJECT_LIST_FAIL})
        globalApiErrorHandler(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const patchKapacitomatProject = (id, data) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .patch(endpoints.projectsKap + '/' + id, data)
      .then((res) => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
        return res.data
      })
      .catch((err) => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
        globalApiErrorHandler(err)
      })
  }
}

export const getKapacitomatEmployeeList = (limit = 1000, offset = 0, period_id, filters) => {
  return (dispatch) => {
    dispatch({type: GET_KAPACITOMAT_EMPLOYEE_LIST_REQUEST})
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .get(endpoints.employeesKap, {
        limit: limit,
        offset: offset,
        period_id: period_id || null,
        order_by:
          filters?.orderBy &&
          filters?.orderDirection &&
          `${filters?.orderBy}:${filters?.orderDirection}`,
      })
      .then((res) => {
        dispatch({type: GET_KAPACITOMAT_EMPLOYEE_LIST_SUCCESS, data: res.data})
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
        return res.data
      })
      .catch((err) => {
        dispatch({type: GET_KAPACITOMAT_EMPLOYEE_LIST_FAIL})
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
        globalApiErrorHandler(err)
      })
  }
}

export const patchKapacitomatEmployee = (id, data) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .patch(endpoints.employeesKap + '/' + id, data)
      .then((res) => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
        fireSuccessToast(<Trans>Eluvian utilization updated.</Trans>)
        return res.data
      })
      .catch((err) => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
        fireSuccessToast(<Trans>Update failed.</Trans>)
        globalApiErrorHandler(err)
      })
  }
}

export const getKapacitomatAssignments = (limit = 1000, offset = 0, period_id, show_active) => {
  return (dispatch) => {
    dispatch({type: GET_KAPACITOMAT_ASSIGNMENT_LIST_REQUEST})
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .get(endpoints.assignmentsKap, {
        limit: limit,
        offset: offset,
        period_id: period_id || null,
        show_active: show_active || null,
      })
      .then((res) => {
        dispatch({type: GET_KAPACITOMAT_ASSIGNMENT_LIST_SUCCESS, data: res.data})
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
        return res.data
      })
      .catch((err) => {
        dispatch({type: GET_KAPACITOMAT_ASSIGNMENT_LIST_FAIL})
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
        globalApiErrorHandler(err)
      })
  }
}

export const patchKapacitomatAssignment = (id, data) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .patch(endpoints.assignmentsKap + '/' + id, data)
      .then((res) => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
        fireSuccessToast(<Trans>Assignment updated.</Trans>)
        return res.data
      })
      .catch((err) => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
        fireSuccessToast(<Trans>Update failed.</Trans>)
        globalApiErrorHandler(err)
      })
  }
}

export const synchronizeAllKapacitomat = () => {
  return (dispatch) => {
    return httpClient
      .put(endpoints.synchronizeAllKap)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        globalApiErrorHandler(err)
        return Promise.reject(err?.response?.data?.errors)
      })
  }
}

// PIGGY BANK
export const getPiggyBank = () => {
  return (dispatch) => {
    dispatch({type: GET_KAPACITOMAT_PIGGY_REQUEST})
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .get(endpoints.piggyBank)
      .then((res) => {
        dispatch({type: GET_KAPACITOMAT_PIGGY_SUCCESS, data: res.data})
        return res.data
      })
      .catch((err) => {
        dispatch({type: GET_KAPACITOMAT_PIGGY_FAIL})
        globalApiErrorHandler(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const postPiggyBankInit = (data) => {
  return (dispatch) => {
    return httpClient
      .post(`${endpoints.piggyBank}/init`, data)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        globalApiErrorHandler(err)
        return Promise.reject(err?.response?.data?.errors)
      })
  }
}

export const postPiggyBankBreak = (data) => {
  return (dispatch) => {
    return httpClient
      .post(`${endpoints.piggyBank}/break`, data)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        globalApiErrorHandler(err)
        return Promise.reject(err?.response?.data?.errors)
      })
  }
}
