import React, {useEffect, useState} from 'react'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import {Trans} from '@lingui/macro'
import {bindActionCreators, compose} from 'redux'
import withStyles from '@material-ui/core/styles/withStyles'
import managementPageStyle from 'component/ManagementPage/managementPageStyle'
import {connect} from 'react-redux'
import {updateGlobalSetting} from 'redux/action/globalSettingAction'
import PropTypes from 'prop-types'
import {deleteUserRole, getUserRoleList} from 'redux/action/userAction'
import Table from 'component/material/table/Table'
import {Delete, Add, Edit} from '@material-ui/icons'
import PrimaryButton from 'component/material/PrimaryButton'
import DialogWindow from 'component/material/DialogWindow'
import NewRoleForm from 'component/ManagementPage/NewRoleForm'
import PrivateContentLayout from 'layout/PrivateLayout/PrivateContentLayout'
import Box from '@material-ui/core/Box'
import {useTableSort} from 'hooks/useTableSort'
import TableIconButton from 'component/general/TableIconButton'
import {usePagination} from 'hooks/usePagination'
import {useWarningDialog} from 'hooks/useWarningDialog'

const ManagementRolePage = (props) => {
  const {
    classes,
    rowsPerPage,
    updateGlobalSetting,
    getUserRoleList,
    userRoleList,
    userRoleListLoading,
    userRoleListMeta,
    deleteUserRole,
  } = props

  const [DeleteWarningDialog, deleteData, onOpenDelete, onCloseDelete] = useWarningDialog()
  const [page, onChangePage, onChangeRowsPerPage] = usePagination(updateGlobalSetting)
  const [tableSort, onSortClick] = useTableSort({orderBy: 'name', orderDirection: 'asc'})

  const [openDialog, setOpenDialog] = useState({visible: false, row: null})

  const handleOpenDialog = (row) => () => {
    if (row?.id) {
      setOpenDialog({visible: true, row: row})
    } else {
      setOpenDialog({visible: true, row: null})
    }
  }

  const handleCloseDialog = () => {
    setOpenDialog({visible: false, row: null})
  }

  const handleDeleteUserRole = () => {
    return deleteUserRole(deleteData?.id).then(() => {
      handleGetUserRoleList()
      onCloseDelete()
    })
  }

  const columns = [
    {
      name: 'name',
      orderKey: 'name',
      label: <Trans>Name</Trans>,
    },
    {
      name: 'permissions',
      className: classes.spanEllipsis,
      label: <Trans>Permissions</Trans>,
      render: (val) => (
        <span className={classes.spanEllipsis}>
          {val.map((permission) => permission.code).join(', ')}
        </span>
      ),
    },
    {
      name: '',
      align: 'right',
      render: (val, col, rowIndex, row) => (
        <GridContainer spacing={2} direction={'row'} wrap={'nowrap'} justifyContent={'flex-end'}>
          <GridItem>
            <TableIconButton
              tooltipTitle={<Trans>Edit role</Trans>}
              onClick={handleOpenDialog(row)}
              Icon={Edit}
            />
          </GridItem>
          <GridItem>
            <TableIconButton
              tooltipTitle={<Trans>Delete role</Trans>}
              onClick={onOpenDelete(row)}
              Icon={Delete}
            />
          </GridItem>
        </GridContainer>
      ),
    },
  ]

  const handleGetUserRoleList = () => {
    getUserRoleList(rowsPerPage, page * rowsPerPage, tableSort)
  }

  useEffect(() => {
    handleGetUserRoleList()
  }, [tableSort, page, rowsPerPage])

  return (
    <>
      <DialogWindow
        onClose={handleCloseDialog}
        open={openDialog?.visible}
        title={openDialog?.row?.id ? <Trans>Edit role</Trans> : <Trans>New role</Trans>}
        maxWidth={'xs'}
        fullWidth={true}
      >
        <NewRoleForm
          initValues={openDialog.row}
          onClose={handleCloseDialog}
          handleGetUserRoleList={handleGetUserRoleList}
        />
      </DialogWindow>

      {/* DELETE DIALOG */}
      <DeleteWarningDialog
        onConfirm={handleDeleteUserRole}
        message={<Trans>User role {deleteData?.name} will be deleted!</Trans>}
      />

      <PrivateContentLayout
        title={<Trans>Roles</Trans>}
        actions={
          <PrimaryButton
            fullWidth={false}
            text={
              <GridContainer alignItems={'center'}>
                <Box pr={2}>
                  <Add fontSize={'small'} />
                </Box>
                <Trans>Create</Trans>
              </GridContainer>
            }
            onClick={handleOpenDialog()}
          />
        }
        content={
          <Table
            columns={columns}
            data={userRoleList}
            loading={userRoleListLoading}
            count={userRoleListMeta.total_count}
            page={page}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            pagination={true}
            onSortClick={onSortClick}
            orderBy={tableSort.orderBy}
            orderDirection={tableSort.orderDirection}
          />
        }
      />
    </>
  )
}

ManagementRolePage.propTypes = {
  classes: PropTypes.object,
  rowsPerPage: PropTypes.number,
  updateGlobalSetting: PropTypes.func,
  getUserRoleList: PropTypes.func,
  userRoleList: PropTypes.array,
  userRoleListLoading: PropTypes.bool,
  userRoleListMeta: PropTypes.object,
  deleteUserRole: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getUserRoleList,
      updateGlobalSetting,
      deleteUserRole,
    },
    dispatch
  )
}

export default compose(
  withStyles(managementPageStyle),
  connect((store) => {
    return {
      userRoleList: store.user.userRoleList,
      userRoleListLoading: store.user.userRoleListLoading,
      userRoleListMeta: store.user.userRoleListMeta,
      rowsPerPage: store.globalSetting.rowsPerPage,
    }
  }, mapDispatchToProps)
)(ManagementRolePage)
