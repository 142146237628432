import React from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import fieldStyle from 'component/field/fieldStyle'
import withStyles from '@material-ui/core/styles/withStyles'

const AutocompleteInput = (props) => {
  const {classes, loading, options, label, input, meta, showHelperText = true, ...rest} = props
  const {name, onChange, value, ...restInput} = input

  const errorMessage = meta && meta.touched && meta.error
  const errorHelperText = showHelperText && (errorMessage || ' ')

  return (
    <Autocomplete
      {...rest}
      size={'small'}
      options={options}
      value={options.find((option) => option === value)}
      inputValue={options.find((option) => option === value)}
      onChange={(e, option) => onChange(option)}
      getOptionLabel={(option) => option}
      renderInput={(params) => (
        <TextField
          {...params}
          {...restInput}
          label={label}
          helperText={errorHelperText}
          error={!!errorMessage}
          fullWidth
          variant={'outlined'}
          className={classes.inputFormHelperText}
        />
      )}
    />
  )
}

export default withStyles(fieldStyle)(AutocompleteInput)
