import httpClient from 'helper/httpClient'
import endpoints from 'helper/endpoints'
import {
  GET_DASHBOARD_ASSIGNMENTS_REQUEST,
  GET_DASHBOARD_ASSIGNMENTS_SUCCESS,
  GET_DASHBOARD_ASSIGNMENTS_FAIL,
  UPDATE_GLOBAL_LOADING,
  GET_DASHBOARD_ASSIGNMENTS_NEXT_REQUEST,
  GET_DASHBOARD_ASSIGNMENTS_NEXT_SUCCESS,
  GET_DASHBOARD_ASSIGNMENTS_NEXT_FAIL,
} from 'redux/actionType'
import {globalApiErrorHandler} from 'helper/functions'

export const getDashboardAssignmentsCurrent = (period_id) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    dispatch({type: GET_DASHBOARD_ASSIGNMENTS_REQUEST})
    return httpClient
      .get(endpoints.dashboard + '/assignments', {
        period_id: period_id,
      })
      .then((res) => {
        dispatch({type: GET_DASHBOARD_ASSIGNMENTS_SUCCESS, data: res.data})
        return res.data
      })
      .catch((err) => {
        dispatch({type: GET_DASHBOARD_ASSIGNMENTS_FAIL})
        globalApiErrorHandler(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const getDashboardAssignmentsNext = (period_id) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    dispatch({type: GET_DASHBOARD_ASSIGNMENTS_NEXT_REQUEST})
    return httpClient
      .get(endpoints.dashboard + '/assignments', {
        period_id: period_id,
      })
      .then((res) => {
        dispatch({type: GET_DASHBOARD_ASSIGNMENTS_NEXT_SUCCESS, data: res.data})
        return res.data
      })
      .catch((err) => {
        dispatch({type: GET_DASHBOARD_ASSIGNMENTS_NEXT_FAIL})
        globalApiErrorHandler(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}
