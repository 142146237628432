import React from 'react'
import App from 'App/App'
import {Provider} from 'react-redux'
import store from 'redux/store'
import {ThemeProvider} from '@material-ui/core/styles'
import defaultTheme from 'style/theme/defaultTheme'
import {MuiPickersUtilsProvider} from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import {AbilityContextProvider, authorizedAbility} from 'helper/authorizeAbility'

const AppProvider = () => {
  return (
    <Provider store={store}>
      <AbilityContextProvider value={authorizedAbility}>
        <ThemeProvider theme={defaultTheme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <App />
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </AbilityContextProvider>
    </Provider>
  )
}

export default AppProvider
