import React from 'react'
import httpClient from 'helper/httpClient'
import endpoints from 'helper/endpoints'
import {
  fireSuccessToast,
  globalApiErrorHandler,
  redirectTo,
  setItemToStorage,
} from 'helper/functions'
import {Trans} from '@lingui/macro'
import {LS_LOGGED_USER, LS_SESSION_ID_NAME} from 'helper/configConstants'
import {authorizedAbility} from 'helper/authorizeAbility'
import mapPermissionsToAbilities from 'helper/mapPermissionToAbility'

export const login = (data) => {
  return () => {
    return httpClient
      .get(endpoints.login, data)
      .then((res) => {
        return res.data
      })
      .catch(globalApiErrorHandler)
  }
}

export const loginCallback = (data) => {
  return () => {
    return httpClient
      .get(`${endpoints.login}/callback${data}`)
      .then((res) => {
        if (res.status === 200) {
          setItemToStorage(LS_SESSION_ID_NAME, res.headers['session-id'])
          setItemToStorage(LS_LOGGED_USER, res.data)
          res.data?.user?.permissions &&
            authorizedAbility.update(mapPermissionsToAbilities(res.data.user.permissions))
          fireSuccessToast(<Trans>Successfully logged in.</Trans>)
        }
        return res
      })
      .catch(globalApiErrorHandler)
  }
}

export const logout = () => {
  return () => {
    return httpClient
      .del(endpoints.logout)
      .then((response) => {
        if (response.status === 204) {
          localStorage.removeItem(LS_SESSION_ID_NAME)
          localStorage.removeItem(LS_LOGGED_USER)
          redirectTo('/login')
        }
        return response
      })
      .catch(globalApiErrorHandler)
  }
}
