// accessing theme which is active in ThemeProvider
const publicLayoutStyle = (theme) => ({
  container: {
    display: 'flex',
    minHeight: '100vh',
    position: 'relative',
  },
  bottomRightImage: {
    position: 'absolute',
    zIndex: -1000,
    bottom: 0,
    right: 0,
    maxWidth: '50%',
    display: 'flex',

    '& img': {
      width: '100%',
      height: 'auto',
    },
  },

  googleLoginButton: {
    padding: '1px 1px',
    display: 'flex',
    justifyContent: 'center',
  },
  googleLogin: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  googleLoginLogo: {
    width: '47px',
    height: '47px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,

    '& img': {
      width: '28px',
      height: '28px',
    },
  },
  googleLoginText: {
    alignItems: 'center',
    display: 'flex',
    padding: '0 16px',
    flexGrow: 1,
    justifyContent: 'center',
  },
  googleLoginLoading: {
    padding: 0,
    width: 30,
    height: 30,
  },
})

export default publicLayoutStyle
