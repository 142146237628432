import React from 'react'
import {Field, Form} from 'react-final-form'
import PropTypes from 'prop-types'
import {required} from 'helper/validations'
import {Trans} from '@lingui/macro'
import PrimaryButton from 'component/material/PrimaryButton'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import TextInput from 'component/field/TextInput'
import Box from '@material-ui/core/Box'

const ObligatedMdsForm = (props) => {
  const {handleSubmit, data} = props

  return (
    <Form onSubmit={handleSubmit} initialValues={{obliged_mds: data.obliged_mds}}>
      {(formProps) => {
        return (
          <form onSubmit={formProps.handleSubmit}>
            <GridContainer spacing={2}>
              <GridItem xs={12}>
                <Field
                  autoFocus
                  autoComplete="off"
                  onFocus={(e) =>
                    document
                      .getElementById('obliged_mds')
                      .setSelectionRange(0, e.target.value.length)
                  }
                  id="obliged_mds"
                  name="obliged_mds"
                  label={<Trans>Number of MD</Trans>}
                  component={TextInput}
                  inputType={'number'}
                  validate={required}
                />
              </GridItem>
            </GridContainer>

            <GridContainer justifyContent={'flex-end'}>
              <Box pt={3}>
                <PrimaryButton
                  fullWidth={false}
                  disabled={formProps.submitting}
                  type="submit"
                  text={<Trans>Save</Trans>}
                />
              </Box>
            </GridContainer>
          </form>
        )
      }}
    </Form>
  )
}

ObligatedMdsForm.propTypes = {
  handleSubmit: PropTypes.func,
  data: PropTypes.object,
}

export default ObligatedMdsForm
