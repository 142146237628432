import React, {useState} from 'react'
import GridItem from 'component/material/GridItem'
import BoxFullWidth from 'component/material/BoxFullWidth'
import withStyles from '@material-ui/core/styles/withStyles'
import dashboardStyle from 'component/DashboardPage/dashboardStyle'
import PropTypes from 'prop-types'
import GridContainer from 'component/material/GridContainer'
import {ToggleButton, ToggleButtonGroup} from '@material-ui/lab'
import TableChartIcon from '@material-ui/icons/TableChart'
import PieChartIcon from '@material-ui/icons/PieChart'
import {Cell, Pie, PieChart, Tooltip, Legend, ResponsiveContainer} from 'recharts'
import {Paper} from '@material-ui/core'
import Loader from 'component/material/Loader'
import {Trans} from '@lingui/macro'
import {COLORS} from 'helper/constants'

const DashboardGridItemTablePie = (props) => {
  const {classes, title, icon, data, summary, loading} = props

  const [selectedView, setSelectedView] = useState('table')

  const handleChangeView = (e, value) => {
    let newValue = value ? value : 'table'
    setSelectedView(newValue)
  }

  const getDataWithSummary = (data, summary) => {
    let chartData = [...data]

    if (summary?.vacated_mds > 0) {
      chartData = [
        ...chartData,
        {
          assigned_mds: summary?.vacated_mds,
          project_name: 'Dovolená',
          project_ref: 'Dovolená',
        },
      ]
    }
    if (summary?.not_utilized_mds > 0) {
      chartData = [
        ...chartData,
        {
          assigned_mds: summary?.not_utilized_mds,
          project_name: 'Ostatní',
          project_ref: 'Ostatní',
        },
      ]
    }

    return chartData
  }

  const dataLength = data?.length > 7 ? data?.length : 7

  return (
    <GridItem xs={12} md={6} xl={4}>
      <Paper elevation={5}>
        <BoxFullWidth p={2} className={classes.gridTitleBorder}>
          <GridContainer direction={'row'} alignItems={'center'}>
            <GridItem className={classes.gridTitleIcon}>{icon}</GridItem>
            <GridItem>{title}</GridItem>
            <GridItem className={classes.toggleButtons}>
              <ToggleButtonGroup value={selectedView} exclusive onChange={handleChangeView}>
                <ToggleButton value="table">
                  <TableChartIcon fontSize="small" />
                </ToggleButton>
                <ToggleButton value="pie">
                  <PieChartIcon fontSize="small" />
                </ToggleButton>
              </ToggleButtonGroup>
            </GridItem>
          </GridContainer>
        </BoxFullWidth>
        <BoxFullWidth p={2} className={classes.gridBoxHeight}>
          {loading ? (
            <Loader wrapper={true} />
          ) : (
            <GridContainer alignItems={'flex-start'}>
              <GridItem xs={12}>
                {selectedView === 'pie' ? (
                  <ResponsiveContainer width="100%" height={dataLength * 22}>
                    <PieChart cx={'100%'} cy={'50%'}>
                      <Pie
                        data={getDataWithSummary(data, summary)}
                        cx={'50%'}
                        cy={'50%'}
                        labelLine={false}
                        dataKey="assigned_mds"
                        nameKey="project_name"
                        outerRadius={dataLength * 10}
                        fill="#8884d8"
                        isAnimationActive={false}
                      >
                        {getDataWithSummary(data, summary)?.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                      <Tooltip />
                      <Legend
                        layout={'vertical'}
                        align={'left'}
                        verticalAlign={'top'}
                        wrapperStyle={{fontSize: '15px'}}
                        iconType={'square'}
                        iconSize={12}
                        formatter={(value, entry) => `${entry.payload.project_ref}`}
                      />
                    </PieChart>
                  </ResponsiveContainer>
                ) : (
                  <GridContainer className={classes.gridData}>
                    {data?.map((item, index) => (
                      <GridContainer key={index} className={classes.gridDataRow}>
                        <GridItem xs={9}>{item.project_name}</GridItem>
                        <GridItem
                          container
                          justifyContent={'flex-end'}
                          xs={3}
                        >{`${item.assigned_mds} MD`}</GridItem>
                      </GridContainer>
                    ))}
                  </GridContainer>
                )}
              </GridItem>
            </GridContainer>
          )}
        </BoxFullWidth>
        <BoxFullWidth p={2} className={classes.gridSummaryRow}>
          <GridItem xs={12}>
            <span>
              <GridItem container xs={12} className={classes.gridData}>
                <GridItem xs={6} className={classes.gridDataRow}>
                  <Trans>On projects</Trans>
                </GridItem>
                <GridItem
                  container
                  justifyContent={'flex-end'}
                  xs={6}
                  className={classes.gridDataRow}
                >{`${summary.utilized_mds * 8} MH / ${summary.utilized_mds} MD`}</GridItem>
                <GridItem xs={6} className={classes.gridDataRow}>
                  <Trans>Others</Trans>
                </GridItem>
                <GridItem
                  container
                  justifyContent={'flex-end'}
                  xs={6}
                  className={classes.gridDataRow}
                >{`${summary.not_utilized_mds * 8} MH / ${summary.not_utilized_mds} MD`}</GridItem>
                <GridItem xs={6}>
                  <Trans>Vacation</Trans>
                </GridItem>
                <GridItem container justifyContent={'flex-end'} xs={6}>{`${
                  summary.vacated_mds * 8
                } MH / ${summary.vacated_mds} MD`}</GridItem>
              </GridItem>
            </span>
          </GridItem>
        </BoxFullWidth>
      </Paper>
    </GridItem>
  )
}

DashboardGridItemTablePie.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.node,
  icon: PropTypes.node,
  data: PropTypes.array,
  summary: PropTypes.object,
  loading: PropTypes.bool,
}

export default withStyles(dashboardStyle)(DashboardGridItemTablePie)
