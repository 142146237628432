import {useRef, useState} from 'react'
import axiosClient from 'helper/axiosClient'

export const useAxiosCall = (axiosOptions) => {
  const [res, setResponse] = useState(null)
  const [err, setError] = useState(null)
  const [loading, setIsLoading] = useState(false)

  const controllerRef = useRef(new AbortController())

  const cancel = () => {
    controllerRef.current.abort()
  }

  const call = async (payload) => {
    try {
      setIsLoading(true)
      const res = await axiosClient({
        data: payload?.data,
        headers: {'Content-Type': 'application/json', ...payload?.headers},
        url: axiosOptions.url,
        method: axiosOptions.method || 'get',
        signal: controllerRef.current.signal,
      })
      setResponse(res.data)
      return Promise.resolve(res.data)
    } catch (err) {
      setError(err.response)
      return Promise.reject(err.response)
    } finally {
      setIsLoading(false)
    }
  }

  return [{res, err, loading}, call, cancel]
}
