import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import PropTypes from 'prop-types'
import materialStyle from 'component/material/materialStyle'
import Grid from '@material-ui/core/Grid'
import cx from 'classnames'

const GridContainer = (props) => {
  const {children, flexGrow, classes, className, ...rest} = props
  return (
    <Grid container {...rest} className={cx(className, flexGrow && classes.flexGrow)}>
      {children}
    </Grid>
  )
}

GridContainer.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
}

export default withStyles(materialStyle)(GridContainer)
