import React from 'react'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import withStyles from '@material-ui/core/styles/withStyles'
import PropTypes from 'prop-types'
import {Trans} from '@lingui/macro'
import Dialog from '@material-ui/core/Dialog'
import materialStyle from 'component/material/materialStyle'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import PrimaryButton from 'component/material/PrimaryButton'
import BoxFullWidth from 'component/material/BoxFullWidth'

const WarningDialog = (props) => {
  const {open, handleClose, handleConfirm, classes, message, title, ...rest} = props

  return (
    <Dialog onClose={handleClose} open={open} maxWidth={'xs'} fullWidth={true} {...rest}>
      <BoxFullWidth p={4}>
        <GridContainer justifyContent="center">
          <ErrorOutlineIcon className={classes.warningIcon} />
          <BoxFullWidth pb={4}>
            <GridContainer justifyContent="center" className={classes.warningTitle}>
              {title || <Trans>Are you sure?</Trans>}
            </GridContainer>
          </BoxFullWidth>

          <BoxFullWidth pb={4}>
            <GridContainer justifyContent="center" className={classes.warningBody}>
              {message}
            </GridContainer>
          </BoxFullWidth>

          <GridContainer spacing={2}>
            <GridItem container xs={6} justifyContent="flex-end">
              <PrimaryButton
                variant={'outlined'}
                text={<Trans>Cancel</Trans>}
                onClick={handleClose}
              />
            </GridItem>
            <GridItem xs={6}>
              <PrimaryButton text={<Trans>Confirm</Trans>} onClick={handleConfirm} />
            </GridItem>
          </GridContainer>
        </GridContainer>
      </BoxFullWidth>
    </Dialog>
  )
}

WarningDialog.propTypes = {
  rest: PropTypes.object,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  title: PropTypes.node,
}

export default withStyles(materialStyle)(WarningDialog)
