import React, {useEffect} from 'react'
import {Typography} from '@material-ui/core'
import {Trans} from '@lingui/macro'
import Box from '@material-ui/core/Box'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {fireErrorToast, fireSuccessToast, redirectTo} from 'helper/functions'
import LoadingText from 'component/loading/LoadingText'
import VykazomatForm from 'component/VykazomatPage/VykazomatForm'
import {DB_DATE_FORMAT} from 'helper/constants'
import BoxFullWidth from 'component/material/BoxFullWidth'
import {getVykazomatProjects} from 'redux/action/vykazomatAction'
import {createReport, getReportResult, getReportState} from 'redux/action/vykazomatAction'
import {updateGlobalLoading} from 'redux/action/globalLoadingAction'
import PrivateContentLayout from 'layout/PrivateLayout/PrivateContentLayout'

// VYKAZOMAT STRANKA
const VykazomatPage = (props) => {
  const {
    getVykazomatProjects,
    createReport,
    getReportState,
    getReportResult,
    reportInProgress,
    projectList,
    reportId,
    projectLoading,
    updateGlobalLoading,
  } = props

  let timeoutId = null

  // periodically ask for report status
  // when succeed ask for result
  const repeatGetReportState = (id) => {
    timeoutId = setTimeout(() => {
      getReportState(id).then((res) => {
        if (res?.state === 'PENDING') {
          repeatGetReportState(id)
        } else if (res?.state === 'FAILURE' || !res.state) {
          fireErrorToast(
            <>
              <Trans>Report failed</Trans> {': ' + res?.result && res.result}
            </>
          )
          updateGlobalLoading(false)
        } else {
          getReportResult(id).then((res) => {
            fireSuccessToast(
              <>
                <Trans>Report created</Trans> {': ' + res?.result && res.result}
              </>
            )
            redirectTo('/vykazomat/vykaz')
          })
        }
      })
    }, 2000)
  }

  const prepareData = (data) => {
    const project = projectList.find((item) => item.name === data?.name)

    return {
      project_ref: project.ref,
      date_from: data?.date_from && data.date_from.format(DB_DATE_FORMAT),
      date_to: data?.date_to && data.date_to.format(DB_DATE_FORMAT),
      language: data?.language,
      version: data?.version,
    }
  }

  const handleSubmit = (data) => {
    const preparedData = prepareData(data)
    return createReport(preparedData).then((res) => {
      getReportState(res.task_id)
    })
  }

  useEffect(() => {
    if (reportInProgress && reportId) {
      repeatGetReportState(reportId)
    }
  }, [reportInProgress, reportId])

  const prepareProjectListForSelect = (list) => {
    return list.map((item) => item.name)
  }

  useEffect(() => {
    getVykazomatProjects()

    return () => {
      clearTimeout(timeoutId)
    }
  }, [])

  return (
    <PrivateContentLayout
      title={<Trans>Výkazomat</Trans>}
      content={
        <>
          <Typography variant={'h5'} color="primary" align="center">
            <Trans>Select project for report</Trans>
          </Typography>
          <BoxFullWidth pt={8}>
            <VykazomatForm
              projectList={projectList && prepareProjectListForSelect(projectList)}
              reportInProgress={reportInProgress}
              projectLoading={projectLoading}
              handleSubmit={handleSubmit}
            />
          </BoxFullWidth>
          {reportInProgress && (
            <Box pt={6}>
              <LoadingText text={<Trans>Report is being created</Trans>} />
            </Box>
          )}
        </>
      }
    />
  )
}

VykazomatPage.propTypes = {
  classes: PropTypes.object,
  getVykazomatProjects: PropTypes.func,
  reportInProgress: PropTypes.bool,
  projectLoading: PropTypes.bool,
  projectList: PropTypes.array,
  createReport: PropTypes.func,
  getReportState: PropTypes.func,
  getReportResult: PropTypes.func,
  updateGlobalLoading: PropTypes.func,
  reportId: PropTypes.string,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getVykazomatProjects,
      createReport,
      getReportState,
      getReportResult,
      updateGlobalLoading,
    },
    dispatch
  )
}

export default compose(
  connect((store) => {
    return {
      reportInProgress: store.vykazomat.reportInProgress,
      projectList: store.vykazomat.projectList,
      reportId: store.vykazomat.reportId,
      projectLoading: store.vykazomat.loading,
    }
  }, mapDispatchToProps)
)(VykazomatPage)
