const mapPermissionsToAbilities = (permissions) => {
  const rules = []
  permissions.forEach((permission) => {
    switch (permission) {
      case 'can_manage_users':
        rules.push({subject: 'Users', action: 'update'})
        break
      case 'can_manage_roles':
        rules.push({subject: 'Roles', action: 'update'})
        break
      case 'can_use_vykazomat':
        rules.push({subject: 'Vykazomat', action: 'read'})
        break
      case 'can_use_odhadomat':
        rules.push({subject: 'Odhadomat', action: 'read'})
        break
      case 'can_use_projektomat':
        rules.push({subject: 'Projektomat', action: 'read'})
        break
      case 'can_use_kapacitomat':
        rules.push({subject: 'Kapacitomat', action: 'read'})
        break
      case 'can_use_strickland':
        rules.push({subject: 'Strickland', action: 'read'})
        break
      case 'can_use_emmett':
        rules.push({subject: 'Emmett', action: 'read'})
        break
      default:
        break
    }
  })
  return rules
}

export default mapPermissionsToAbilities
