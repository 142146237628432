import React, {useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core'
import {Trans} from '@lingui/macro'
import projektomatPageStyle from 'component/ProjektomatPage/projektomatPageStyle'
import {getProjectList, synchronizeAllProjektomat} from 'redux/action/projektomatAction'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import ProjectTable from 'component/ProjektomatPage/ProjectTable'
import PropTypes from 'prop-types'
import {updateGlobalSetting} from 'redux/action/globalSettingAction'
import SyncIcon from '@material-ui/icons/Sync'
import cx from 'classnames'
import CustomTooltip from 'component/material/Tooltip'
import PrivateContentLayout from 'layout/PrivateLayout/PrivateContentLayout'
import GridContainer from 'component/material/GridContainer'
import {useTableSort} from 'hooks/useTableSort'
import {usePagination} from 'hooks/usePagination'

const ProjectPage = (props) => {
  const {
    classes,
    getProjectList,
    projectList,
    projectsLoading,
    projectsMeta,
    rowsPerPage,
    updateGlobalSetting,
    synchronizeAllProjektomat,
  } = props

  const [syncLoading, setSyncLoading] = useState(false)
  const [tableSort, onSortClick] = useTableSort({orderBy: 'ref', orderDirection: 'asc'})
  const [page, onChangePage, onChangeRowsPerPage] = usePagination(updateGlobalSetting)

  const fetchProjects = () => {
    return getProjectList(rowsPerPage, page * rowsPerPage, false, tableSort)
  }

  const handleSyncEmployeesAndProjects = () => {
    setSyncLoading(true)
    return synchronizeAllProjektomat().finally(() => {
      setSyncLoading(false)
    })
  }

  useEffect(() => {
    fetchProjects()
  }, [tableSort, page, rowsPerPage])

  return (
    <PrivateContentLayout
      title={
        <GridContainer alignItems={'center'}>
          <Trans>Projects</Trans>
          <CustomTooltip
            title={<Trans>Synchronize all projects, employees and assignments from Clockify</Trans>}
          >
            <SyncIcon
              color={'primary'}
              className={cx(classes.syncButton, syncLoading ? classes.syncButtonRotating : '')}
              onClick={handleSyncEmployeesAndProjects}
            />
          </CustomTooltip>
        </GridContainer>
      }
      content={
        <ProjectTable
          data={projectList}
          loading={projectsLoading}
          onSortClick={onSortClick}
          orderBy={tableSort.orderBy}
          orderDirection={tableSort.orderDirection}
          count={projectsMeta?.total_count}
          page={page}
          onChangePage={onChangePage}
          rowsPerPage={rowsPerPage}
          onChangeRowsPerPage={onChangeRowsPerPage}
          fetchProjects={() => fetchProjects()}
        />
      }
    />
  )
}

ProjectPage.propTypes = {
  classes: PropTypes.object,
  getProjectList: PropTypes.func,
  projectList: PropTypes.array,
  projectsLoading: PropTypes.bool,
  projectsMeta: PropTypes.object,
  rowsPerPage: PropTypes.number,
  updateGlobalSetting: PropTypes.func,
  synchronizeAllProjektomat: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getProjectList,
      updateGlobalSetting,
      synchronizeAllProjektomat,
    },
    dispatch
  )
}

export default compose(
  withStyles(projektomatPageStyle),
  connect((store) => {
    return {
      projectList: store.projektomat.projects,
      projectsLoading: store.projektomat.projectsLoading,
      projectsMeta: store.projektomat.projectsMeta,
      rowsPerPage: store.globalSetting.rowsPerPage,
    }
  }, mapDispatchToProps)
)(ProjectPage)
