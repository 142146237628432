import NumberFormat from 'react-number-format'
import React from 'react'
import PropTypes from 'prop-types'

/**
 use in InputProps

 InputProps={{
    inputComponent: NumberFormatCustom,
  }}

 */

const NumberFormatCustom = (props) => {
  const {inputRef, onChange, prefix, suffix = ' Kč', name, ...other} = props

  return (
    <NumberFormat
      {...other}
      type={'text'}
      suffix={suffix}
      prefix={prefix}
      isNumericString={true}
      allowedDecimalSeparators={[',', '.']}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: name,
            value: values.value,
          },
        })
      }}
      thousandSeparator={' '}
    />
  )
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
}

export default NumberFormatCustom
