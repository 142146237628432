import React, {useEffect} from 'react'
import GridContainer from 'component/material/GridContainer'
import {Field, Form} from 'react-final-form'
import GridItem from 'component/material/GridItem'
import {Trans} from '@lingui/macro'
import MultiSelectInput from 'component/field/MultiSelectInput'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import {getUserRoleList, updateUser} from 'redux/action/userAction'
import PropTypes from 'prop-types'
import {
  fireErrorToast,
  fireSuccessToast,
  mapErrorResponseToForm,
  prepareOptionsForMultiSelect,
} from 'helper/functions'
import BoxFullWidth from 'component/material/BoxFullWidth'
import Box from '@material-ui/core/Box'
import PrimaryButton from 'component/material/PrimaryButton'
import CheckBoxInput from 'component/field/CheckBoxInput'
import withStyles from '@material-ui/core/styles/withStyles'
import managementPageStyle from 'component/ManagementPage/managementPageStyle'

const UserManagementForm = (props) => {
  const {
    classes,
    initValues,
    onClose,
    updateUser,
    getUserRoleList,
    roleList,
    roleListLoading,
    handleGetUserList,
  } = props

  const prepareValues = (values) => {
    return {
      is_active: values.is_active,
      role_ids: values.role_ids,
    }
  }

  const handleSubmit = (values) => {
    const preparedValues = prepareValues(values)
    return updateUser(initValues?.id, preparedValues)
      .then((res) => {
        fireSuccessToast(<Trans>User updated.</Trans>)
        handleGetUserList()
        onClose()
      })
      .catch((err) => {
        fireErrorToast(<Trans>Update failed.</Trans>)
        return mapErrorResponseToForm(err)
      })
  }

  const getInitValues = () => {
    return {
      ...initValues,
      role_ids: initValues?.roles?.map((role) => role.id),
    }
  }

  useEffect(() => {
    getUserRoleList()
  }, [])

  return (
    <Form onSubmit={handleSubmit} initialValues={getInitValues()}>
      {(formProps) => {
        return (
          <form onSubmit={formProps.handleSubmit}>
            <GridContainer spacing={2}>
              <GridItem xs={12}>
                <Field
                  name="role_ids"
                  label={<Trans>Roles</Trans>}
                  options={roleList && prepareOptionsForMultiSelect(roleList)}
                  component={MultiSelectInput}
                  disabled={formProps.submitting}
                  loading={roleListLoading}
                />
              </GridItem>
              <GridItem container xs={12}>
                <Field
                  disabled={formProps.submitting}
                  name="is_active"
                  label={<Trans>Is user active</Trans>}
                  component={CheckBoxInput}
                  type="checkbox"
                />
              </GridItem>
            </GridContainer>
            <BoxFullWidth pt={3}>
              <GridContainer justifyContent={'flex-end'}>
                <Box pr={4}>
                  <PrimaryButton
                    fullWidth={false}
                    variant={'outlined'}
                    text={<Trans>Cancel</Trans>}
                    onClick={onClose}
                    disabled={formProps.submitting}
                  />
                </Box>
                <PrimaryButton
                  fullWidth={false}
                  type="submit"
                  text={initValues?.id ? <Trans>Edit</Trans> : <Trans>Create</Trans>}
                  disabled={formProps.submitting}
                />
              </GridContainer>
            </BoxFullWidth>
          </form>
        )
      }}
    </Form>
  )
}

UserManagementForm.propTypes = {
  classes: PropTypes.object,
  handleGetUserList: PropTypes.func,
  onClose: PropTypes.func,
  updateUser: PropTypes.func,
  getUserRoleList: PropTypes.func,
  roleList: PropTypes.array,
  roleListLoading: PropTypes.bool,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateUser,
      getUserRoleList,
    },
    dispatch
  )
}

export default compose(
  withStyles(managementPageStyle),
  connect((store) => {
    return {
      roleList: store.user.userRoleList,
      roleListLoading: store.user.userRoleListLoading,
    }
  }, mapDispatchToProps)
)(UserManagementForm)
