import {GET_ROLE_REQUEST, GET_ROLE_SUCCESS, GET_ROLE_FAIL} from 'redux/actionType'

const initState = {
  roles: [],
  rolesLoading: false,
}

const roleReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_ROLE_REQUEST: {
      return {
        ...state,
        rolesLoading: true,
      }
    }
    case GET_ROLE_SUCCESS: {
      return {
        ...state,
        rolesLoading: false,
        roles: action.data.objects,
      }
    }
    case GET_ROLE_FAIL: {
      return {
        ...state,
        rolesLoading: false,
      }
    }
    default: {
      return state
    }
  }
}

export default roleReducer
