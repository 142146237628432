import {DRAWER_WIDTH} from 'helper/constants'

const drawerWidth = DRAWER_WIDTH

// accessing theme which is active in ThemeProvider
const privateLayoutStyle = (theme) => ({
  container: {
    display: 'flex',
    flexGrow: '1',
    flexDirection: 'row',
    height: '100vh',
    position: 'relative',
    // bottom padding of height same as top app bar
    boxSizing: 'border-box',
  },
  content: {
    padding: 30,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    overflow: 'auto',
  },
  appMenu: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: drawerWidth,
    backgroundColor: theme.palette.primary.main,
    maxHeight: '100vh',
  },
  appMenuItems: {
    overflowY: 'auto',
    flexGrow: 1,
    overflowX: 'hidden',
  },
  navList: {
    width: drawerWidth,
  },
  menuItem: {
    maxWidth: '100%',
    color: theme.palette.text.white,

    '& .MuiListItemText-root .MuiTypography-body1': {
      fontSize: 14,
    },

    '& .MuiListItemIcon-root': {
      minWidth: 40,
    },

    '& .MuiListItemText-inset': {
      paddingLeft: 50,
    },
  },
  collapseMenu: {
    backgroundColor: theme.palette.text.white,
  },
  collapseMenuItem: {
    backgroundColor: theme.palette.primary.main + 'ee',
    '&:hover': {
      backgroundColor: theme.palette.primary.main + 'dd',
    },
  },
  menuItemIcon: {
    '& svg': {
      fill: theme.palette.secondary.main,
      width: 22,
      height: 22,
    },
  },
  menuLogo: {
    padding: 24,
    boxSizing: 'border-box',
    width: '100%',
    height: 'auto',
    '& img': {
      width: '100%',
      height: 'auto',
    },
  },
  logoutButton: {
    color: theme.palette.text.white,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 16,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    '& .MuiTypography-body1': {
      fontSize: 14,
    },
  },
  logoutIcon: {
    transform: 'rotate(180deg)',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 10,
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  bottomRightImage: {
    position: 'absolute',
    zIndex: -1000,
    bottom: 0,
    right: 0,
    width: '50%',
    maxWidth: 500,

    '& img': {
      width: '100%',
      height: 'auto',
      display: 'flex',
    },
  },

  topBarLinks: {
    display: 'flex !important',
    flexDirection: 'row !important',
  },

  topBarItem: {
    color: theme.palette.primary.main,
    fontSize: '1.2rem',
    fontWeight: 600,
    cursor: 'pointer',
    transition: 'color 200ms',
    borderBottom: '2px solid transparent',

    '&:hover': {
      color: theme.palette.primary.dark,
      transition: 'color 200ms',
    },
  },

  topBarItemActive: {
    borderBottom: '2px solid ' + theme.palette.secondary.main,
  },

  topBarItemLogin: {
    fontSize: '1rem',
  },

  topBarLinearProgress: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '4px',
  },

  activeMenuItem: {
    backgroundColor: 'rgba(0, 0, 0, 0.14)',
  },
  activeSubMenuItem: {
    backgroundColor: theme.palette.primary.main + 'bb',
    '&:hover': {
      backgroundColor: theme.palette.primary.main + 'bb',
    },
  },

  flexBasisZero: {
    flexBasis: 0,
    flexGrow: 0,
  },

  privateContentLayoutTopBar: {
    height: 40,
    minHeight: 40,
  },
})

export default privateLayoutStyle
