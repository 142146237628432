import React from 'react'
import {Form, Field} from 'react-final-form'
import GridItem from 'component/material/GridItem'
import {Trans} from '@lingui/macro'
import TextInput from 'component/field/TextInput'
import {required} from 'helper/validations'
import GridContainer from 'component/material/GridContainer'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import {patchQuoteRow, postQuoteRows} from 'redux/action/odhadomatAction'
import {useParams} from 'react-router-dom'
import {fireErrorToast, fireSuccessToast, mapErrorResponseToForm} from 'helper/functions'
import BoxFullWidth from 'component/material/BoxFullWidth'
import PrimaryButton from 'component/material/PrimaryButton'
import CheckBoxInput from 'component/field/CheckBoxInput'
import NumberFormatCustom from 'component/field/NumberFormatCustom'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import {useEstimation} from 'hooks/useEstimation'
import SelectInput from 'component/field/SelectInput'
import InputAdornment from '@material-ui/core/InputAdornment'

const NabidkaRowForm = (props) => {
  const {initValues, handleClose, fetchQuoteRows, postQuoteRows, patchQuoteRow} = props

  const {estimation, estimationLoading} = useEstimation()

  const {id, nabidkaId} = useParams()

  const getInitialValues = (val) => {
    const isFixed = val.is_fixed

    return {
      ...val,
      fixed_estimated_mds: isFixed ? val.fixed_estimated_mds : val.estimated_mds,
      fixed_price_from: isFixed ? Number(val.fixed_price_from / 100) : Number(val.price_from / 100),
      fixed_price_to: isFixed ? Number(val.fixed_price_to / 100) : Number(val.price_to / 100),
    }
  }

  const prepareData = (values) => {
    return {
      category: values.category,
      title: values.title,
      is_fixed: !!values.is_fixed,
      fixed_estimated_mds: Number(values.fixed_estimated_mds),
      fixed_price_from: Number(values.fixed_price_from * 100),
      fixed_price_to: Number(values.fixed_price_to * 100),
    }
  }

  const handleSubmit = (values) => {
    const data = prepareData(values)
    if (initValues?.id) {
      return patchQuoteRow(id, nabidkaId, initValues?.id, data)
        .then(() => {
          fireSuccessToast(<Trans>Row updated.</Trans>)
          handleClose()
          fetchQuoteRows()
        })
        .catch((err) => {
          fireErrorToast(<Trans>Update failed.</Trans>)
          return mapErrorResponseToForm(err)
        })
    } else {
      return postQuoteRows(id, nabidkaId, data)
        .then(() => {
          fireSuccessToast(<Trans>Row created.</Trans>)
          handleClose()
          fetchQuoteRows()
        })
        .catch((err) => {
          fireErrorToast(<Trans>Create failed.</Trans>)
          return mapErrorResponseToForm(err)
        })
    }
  }

  return (
    <Form onSubmit={handleSubmit} initialValues={initValues ? getInitialValues(initValues) : {}}>
      {(formProps) => {
        const {submitting} = formProps
        return (
          <form onSubmit={formProps.handleSubmit}>
            <GridContainer spacing={2}>
              <GridItem xs={12} sm={6}>
                <Field
                  name="category"
                  label={<Trans>Category</Trans>}
                  component={SelectInput}
                  options={estimation?.all_story_categories}
                  loading={estimationLoading}
                  validate={required}
                  disabled={submitting}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <Field
                  name="title"
                  label={<Trans>Title</Trans>}
                  component={TextInput}
                  validate={required}
                  disabled={submitting}
                />
              </GridItem>
              {initValues?.id && (
                <>
                  <GridItem xs={12} sm={6}>
                    <Field
                      disabled={submitting}
                      name="is_fixed"
                      label={<Trans>Fixed price</Trans>}
                      component={CheckBoxInput}
                      type="checkbox"
                    />
                  </GridItem>
                  {formProps.values.is_fixed && (
                    <>
                      <GridContainer>
                        <GridItem xs={12}>
                          <Box sx={{margin: 40, marginTop: 0}}>
                            <Divider />
                          </Box>
                        </GridItem>
                      </GridContainer>

                      <GridItem xs={12} sm={6}>
                        <Field
                          name={'fixed_price_from'}
                          label={<Trans>Fixed price from</Trans>}
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                          }}
                          component={TextInput}
                          type={'number'}
                          disabled={submitting}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6}>
                        <Field
                          name={'fixed_price_to'}
                          label={<Trans>Fixed price to</Trans>}
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                          }}
                          component={TextInput}
                          type={'number'}
                          disabled={submitting}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={6}>
                        <Field
                          name={'fixed_estimated_mds'}
                          label={<Trans>Fixed estimated</Trans>}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Trans>MD</Trans>
                              </InputAdornment>
                            ),
                          }}
                          component={TextInput}
                          type={'number'}
                          disabled={submitting}
                        />
                      </GridItem>
                    </>
                  )}
                </>
              )}
            </GridContainer>
            <BoxFullWidth pt={3}>
              <GridContainer justifyContent={'flex-end'}>
                <PrimaryButton
                  fullWidth={false}
                  type="submit"
                  text={initValues?.id ? <Trans>Edit</Trans> : <Trans>Create</Trans>}
                  disabled={formProps.submitting}
                />
              </GridContainer>
            </BoxFullWidth>
          </form>
        )
      }}
    </Form>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      postQuoteRows,
      patchQuoteRow,
    },
    dispatch
  )
}

export default compose(
  connect((store) => {
    return {}
  }, mapDispatchToProps)
)(NabidkaRowForm)
