import React, {useEffect, useState} from 'react'
import BoxFullWidth from 'component/material/BoxFullWidth'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import TabPanel from 'component/material/TabPanel'
import GridContainer from 'component/material/GridContainer'
import {Trans} from '@lingui/macro'
import withStyles from '@material-ui/core/styles/withStyles'
import projektomatPageStyle from 'component/ProjektomatPage/projektomatPageStyle'
import IssuedDocument from 'component/ProjektomatPage/IssuedDocument'
import AcceptedDocument from 'component/ProjektomatPage/AcceptedDocument'
// import WorkReportsDocument from 'component/ProjektomatPage/WorkReportsDocument'
import IndirectExpensesDocument from 'component/ProjektomatPage/IndirectExpensesDocument'
import {DatePicker} from '@material-ui/pickers'
import PrimaryButton from 'component/material/PrimaryButton'
import {Add} from '@material-ui/icons'
import DialogWindow from 'component/material/DialogWindow'
import NewAcceptedInvoiceForm from 'component/ProjektomatPage/NewAcceptedInvoiceForm'
import NewWorkReportForm from 'component/ProjektomatPage/NewWorkReportForm'
import NewIndirectExpenseForm from 'component/ProjektomatPage/NewIndirectExpenseForm'
import NewIssuedInvoiceForm from 'component/ProjektomatPage/NewIssuedInvoiceForm'
import PropTypes from 'prop-types'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import {
  getOnlyClientCompanies,
  getOnlyEluviaCompanies,
  getOnlyEmployeeCompanies,
} from 'redux/action/projektomatAction'
import SelectInput from 'component/field/SelectInput'
import PrivateContentLayout from 'layout/PrivateLayout/PrivateContentLayout'
import Box from '@material-ui/core/Box'

const DocumentPage = (props) => {
  const {
    classes,
    getOnlyEluviaCompanies,
    getOnlyEmployeeCompanies,
    getOnlyClientCompanies,
    eluviaCompanies,
    eluviaCompaniesLoading,
    clientCompanies,
    clientCompaniesLoading,
    employeeCompanies,
    employeeCompaniesLoading,
  } = props

  const [tabValue, setValue] = useState(0)
  const [selectedDateFrom, handleDateFromChange] = useState(null)
  const [selectedDateTo, handleDateToChange] = useState(null)
  const [openIssuedInvoice, setOpenIssuedInvoice] = useState(false)
  const [openAcceptedInvoice, setOpenAcceptedInvoice] = useState(false)
  const [openIndirectExpense, setOpenIndirectExpense] = useState(false)
  const [openWorkReport, setOpenWorkReport] = useState(false)

  const [newAssetCreated, setNewAssetCreated] = useState(false)

  const [companiesSelects, setCompaniesSelects] = useState({
    issuedIssuerId: '',
    issuedAccepterId: '',
    acceptedIssuerId: '',
    acceptedAccepterId: '',
  })

  const handleChangeDateFilter = (val) => {
    handleDateFromChange(val ? val?.clone()?.startOf('month') : null)
    handleDateToChange(val ? val?.clone()?.endOf('month') : null)
  }

  const handleChangeTab = (e, newValue) => {
    setValue(newValue)
  }

  const tabList = [
    <Trans>Issued</Trans>,
    <Trans>Accepted</Trans>,
    <Trans>Indirect expenses</Trans>,
    <Trans>Work reports</Trans>,
  ]

  const handleOpenCreateWindow = () => {
    switch (tabValue) {
      case 0: {
        return setOpenIssuedInvoice(true)
      }
      case 1: {
        return setOpenAcceptedInvoice(true)
      }
      case 2: {
        return setOpenIndirectExpense(true)
      }
      case 3: {
        return setOpenWorkReport(true)
      }
      default: {
        break
      }
    }
  }

  const handleChangeSelect = (e) => {
    const name = e.target.name
    const value = e.target.value

    if (tabValue === 0) {
      if (name === 'acceptor_company_id') {
        setCompaniesSelects((prevState) => ({...prevState, issuedAccepterId: value}))
      } else if (name === 'issuer_company_id') {
        setCompaniesSelects((prevState) => ({...prevState, issuedIssuerId: value}))
      }
    } else if (tabValue === 1) {
      if (name === 'acceptor_company_id') {
        setCompaniesSelects((prevState) => ({...prevState, acceptedAccepterId: value}))
      } else if (name === 'issuer_company_id') {
        setCompaniesSelects((prevState) => ({...prevState, acceptedIssuerId: value}))
      }
    }
  }

  useEffect(() => {
    getOnlyEluviaCompanies(1000, 0, {orderBy: 'name', orderDirection: 'asc'})
    getOnlyClientCompanies(1000, 0, {orderBy: 'name', orderDirection: 'asc'})
    getOnlyEmployeeCompanies(1000, 0, {orderBy: 'name', orderDirection: 'asc'})
  }, [])

  return (
    <PrivateContentLayout
      title={
        <>
          <Trans>Documents</Trans>
          {` - `} {tabList[tabValue]}
        </>
      }
      contentPadding={0}
      content={
        <>
          <AppBar
            position="static"
            color="default"
            elevation={0}
            className={classes.appBarBottomBorder}
          >
            <Tabs
              value={tabValue}
              className={classes.tabs}
              onChange={handleChangeTab}
              indicatorColor="secondary"
              variant="scrollable"
              scrollButtons="auto"
            >
              {[
                <Trans>Issued</Trans>,
                <Trans>Accepted</Trans>,
                <Trans>Indirect expenses</Trans>,
                // <Trans>Work reports</Trans> might be used in near future
              ].map((tab, index) => (
                <Tab key={index} index={index} label={tab} />
              ))}
            </Tabs>
          </AppBar>

          <div className={classes.fromToPanel}>
            <DatePicker
              className={classes.fromToPanelDatePicker}
              autoOk
              label={<Trans>Define date month</Trans>}
              clearable
              value={selectedDateFrom}
              onChange={(val) => handleChangeDateFilter(val)}
              inputVariant={'outlined'}
              format={'MMMM'}
              views={['year', 'month']}
              openTo={'month'}
              clearLabel={<Trans>Clear</Trans>}
              cancelLabel={<Trans>Close</Trans>}
              okLabel={<Trans>Ok</Trans>}
            />

            {tabValue === 0 ? (
              <>
                <SelectInput
                  formControlClassName={classes.companyFormControl}
                  options={clientCompanies}
                  loading={clientCompaniesLoading}
                  variant={'outlined'}
                  name="acceptor_company_id"
                  label={<Trans>Acceptor</Trans>}
                  showHelperText={false}
                  emptyOption={true}
                  onChange={handleChangeSelect}
                  value={companiesSelects?.issuedAccepterId}
                />
                <SelectInput
                  formControlClassName={classes.companyFormControl}
                  options={eluviaCompanies}
                  loading={eluviaCompaniesLoading}
                  variant={'outlined'}
                  name="issuer_company_id"
                  label={<Trans>Issuer</Trans>}
                  showHelperText={false}
                  emptyOption={true}
                  onChange={handleChangeSelect}
                  value={companiesSelects?.issuedIssuerId}
                />
              </>
            ) : tabValue === 1 ? (
              <>
                <SelectInput
                  formControlClassName={classes.companyFormControl}
                  options={eluviaCompanies}
                  loading={eluviaCompaniesLoading}
                  variant={'outlined'}
                  name="acceptor_company_id"
                  label={<Trans>Acceptor</Trans>}
                  showHelperText={false}
                  emptyOption={true}
                  onChange={handleChangeSelect}
                  value={companiesSelects?.acceptedAccepterId}
                />
                <SelectInput
                  formControlClassName={classes.companyFormControl}
                  options={employeeCompanies}
                  loading={employeeCompaniesLoading}
                  variant={'outlined'}
                  name="issuer_company_id"
                  label={<Trans>Issuer</Trans>}
                  showHelperText={false}
                  emptyOption={true}
                  onChange={handleChangeSelect}
                  value={companiesSelects?.acceptedIssuerId}
                />
              </>
            ) : (
              ''
            )}
          </div>

          {/* CREATE ISSUED INVOICE */}
          <DialogWindow
            onClose={() => setOpenIssuedInvoice(false)}
            open={openIssuedInvoice}
            title={<Trans>New issued invoice</Trans>}
            maxWidth={'md'}
            fullWidth={true}
          >
            <NewIssuedInvoiceForm
              setNewAssetCreated={setNewAssetCreated}
              onClose={() => setOpenIssuedInvoice(false)}
            />
          </DialogWindow>

          {/* CREATE ACCEPTED INVOICE */}
          <DialogWindow
            onClose={() => setOpenAcceptedInvoice(false)}
            open={openAcceptedInvoice}
            title={<Trans>New accepted invoice</Trans>}
            maxWidth={'md'}
          >
            <NewAcceptedInvoiceForm
              setNewAssetCreated={setNewAssetCreated}
              onClose={() => setOpenAcceptedInvoice(false)}
            />
          </DialogWindow>

          {/* INDIRECT EXPENSE */}
          <DialogWindow
            onClose={() => setOpenIndirectExpense(false)}
            open={openIndirectExpense}
            title={<Trans>New indirect expense</Trans>}
            maxWidth={'md'}
          >
            <NewIndirectExpenseForm
              setNewAssetCreated={setNewAssetCreated}
              onClose={() => setOpenIndirectExpense(false)}
            />
          </DialogWindow>

          {/* WORK REPORT */}
          <DialogWindow
            onClose={() => setOpenWorkReport(false)}
            open={openWorkReport}
            title={<Trans>New work report</Trans>}
            maxWidth={'md'}
          >
            <NewWorkReportForm
              setNewAssetCreated={setNewAssetCreated}
              onClose={() => setOpenWorkReport(false)}
            />
          </DialogWindow>

          <BoxFullWidth p={4} pt={2}>
            <div className={classes.root}>
              {/* TAB PANELS */}
              <TabPanel value={tabValue} index={0} className={classes.overflowAuto}>
                <PrimaryButton
                  fullWidth={false}
                  text={
                    <GridContainer alignItems={'center'}>
                      <Box pr={2}>
                        <Add fontSize={'small'} />
                      </Box>
                      <Trans>Create</Trans>
                    </GridContainer>
                  }
                  className={classes.newDocumentButton}
                  onClick={handleOpenCreateWindow}
                />
                <IssuedDocument
                  companiesSelects={companiesSelects}
                  newAssetCreated={newAssetCreated}
                  setNewAssetCreated={setNewAssetCreated}
                  selectedDateFrom={selectedDateFrom}
                  selectedDateTo={selectedDateTo}
                />
              </TabPanel>
              <TabPanel value={tabValue} index={1} className={classes.overflowAuto}>
                <PrimaryButton
                  fullWidth={false}
                  text={
                    <GridContainer alignItems={'center'}>
                      <Box pr={2}>
                        <Add fontSize={'small'} />
                      </Box>
                      <Trans>Create</Trans>
                    </GridContainer>
                  }
                  className={classes.newDocumentButton}
                  onClick={handleOpenCreateWindow}
                />
                <AcceptedDocument
                  companiesSelects={companiesSelects}
                  newAssetCreated={newAssetCreated}
                  setNewAssetCreated={setNewAssetCreated}
                  selectedDateFrom={selectedDateFrom}
                  selectedDateTo={selectedDateTo}
                />
              </TabPanel>
              <TabPanel value={tabValue} index={2} className={classes.overflowAuto}>
                <PrimaryButton
                  fullWidth={false}
                  text={
                    <GridContainer alignItems={'center'}>
                      <Box pr={2}>
                        <Add fontSize={'small'} />
                      </Box>
                      <Trans>Create</Trans>
                    </GridContainer>
                  }
                  className={classes.newDocumentButton}
                  onClick={handleOpenCreateWindow}
                />
                <IndirectExpensesDocument
                  newAssetCreated={newAssetCreated}
                  setNewAssetCreated={setNewAssetCreated}
                  selectedDateFrom={selectedDateFrom}
                  selectedDateTo={selectedDateTo}
                />
              </TabPanel>

              {/* might be used in future */}
              {/*<TabPanel value={tabValue} index={3} className={classes.overflowAuto}>*/}
              {/*  <PrimaryButton*/}
              {/*    fullWidth={false}*/}
              {/*    text={*/}
              {/*      <GridContainer alignItems={'center'}>*/}
              {/*        <Box pr={2}>*/}
              {/*          <Add fontSize={'small'} />*/}
              {/*        </Box>*/}
              {/*        <Trans>Create</Trans>*/}
              {/*      </GridContainer>*/}
              {/*    }*/}
              {/*    className={classes.newDocumentButton}*/}
              {/*    onClick={handleOpenCreateWindow}*/}
              {/*  />*/}
              {/*  <WorkReportsDocument*/}
              {/*    newAssetCreated={newAssetCreated}*/}
              {/*    setNewAssetCreated={setNewAssetCreated}*/}
              {/*    selectedDateFrom={selectedDateFrom}*/}
              {/*    selectedDateTo={selectedDateTo}*/}
              {/*  />*/}
              {/*</TabPanel>*/}
            </div>
          </BoxFullWidth>
        </>
      }
    />
  )
}

DocumentPage.propTypes = {
  classes: PropTypes.object,
  getOnlyEluviaCompanies: PropTypes.func,
  getOnlyClientCompanies: PropTypes.func,
  getOnlyEmployeeCompanies: PropTypes.func,
  eluviaCompanies: PropTypes.array,
  eluviaCompaniesLoading: PropTypes.bool,
  clientCompanies: PropTypes.array,
  clientCompaniesLoading: PropTypes.bool,
  employeeCompanies: PropTypes.array,
  employeeCompaniesLoading: PropTypes.bool,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getOnlyEluviaCompanies,
      getOnlyClientCompanies,
      getOnlyEmployeeCompanies,
    },
    dispatch
  )
}

export default compose(
  withStyles(projektomatPageStyle),
  connect((store) => {
    return {
      eluviaCompanies: store.projektomat.eluviaCompanies,
      eluviaCompaniesLoading: store.projektomat.eluviaCompaniesLoading,
      clientCompanies: store.projektomat.clientCompanies,
      clientCompaniesLoading: store.projektomat.clientCompaniesLoading,
      employeeCompanies: store.projektomat.employeeCompanies,
      employeeCompaniesLoading: store.projektomat.employeeCompaniesLoading,
    }
  }, mapDispatchToProps)
)(DocumentPage)
