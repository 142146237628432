import {
  GET_STRICKLAND_EMPLOYEES_REQUEST,
  GET_STRICKLAND_EMPLOYEES_SUCCESS,
  GET_STRICKLAND_EMPLOYEES_FAIL,
  GET_STRICKLAND_PROJECTS_REQUEST,
  GET_STRICKLAND_PROJECTS_SUCCESS,
  GET_STRICKLAND_PROJECTS_FAIL,
  GET_STRICKLAND_PROJECTS_POTENTIAL_REQUEST,
  GET_STRICKLAND_PROJECTS_POTENTIAL_SUCCESS,
  GET_STRICKLAND_PROJECTS_POTENTIAL_FAIL,
} from 'redux/actionType'

const initState = {
  employeesReport: [],
  employeesReportLoading: false,
  employeesReportMeta: {},

  projectsReport: [],
  projectsReportLoading: false,
  projectsReportMeta: {},

  projectPotential: [],
  projectPotentialLoading: false,
  projectPotentialMeta: {},
}

const stricklandReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_STRICKLAND_EMPLOYEES_REQUEST: {
      return {
        ...state,
        employeesReportLoading: true,
        employeesReport: [],
        employeesReportMeta: {},
      }
    }
    case GET_STRICKLAND_EMPLOYEES_SUCCESS: {
      return {
        ...state,
        employeesReportLoading: false,
        employeesReport: action.data.objects,
        employeesReportMeta: action.data.meta,
      }
    }
    case GET_STRICKLAND_EMPLOYEES_FAIL: {
      return {
        ...state,
        employeesReportLoading: false,
        employeesReport: [],
        employeesReportMeta: {},
      }
    }
    case GET_STRICKLAND_PROJECTS_REQUEST: {
      return {
        ...state,
        projectsReportLoading: true,
        projectsReport: [],
        projectsReportMeta: {},
      }
    }
    case GET_STRICKLAND_PROJECTS_SUCCESS: {
      return {
        ...state,
        projectsReportLoading: false,
        projectsReport: action.data.objects,
        projectsReportMeta: action.data.meta,
      }
    }
    case GET_STRICKLAND_PROJECTS_FAIL: {
      return {
        ...state,
        projectsReportLoading: false,
        projectsReport: [],
        projectsReportMeta: {},
      }
    }
    case GET_STRICKLAND_PROJECTS_POTENTIAL_REQUEST: {
      return {
        ...state,
        projectPotentialLoading: true,
      }
    }
    case GET_STRICKLAND_PROJECTS_POTENTIAL_SUCCESS: {
      return {
        ...state,
        projectPotentialMeta: action.data.meta,
        projectPotential: action.data.objects,
        projectPotentialLoading: false,
      }
    }
    case GET_STRICKLAND_PROJECTS_POTENTIAL_FAIL: {
      return {
        ...state,
        projectPotentialLoading: false,
      }
    }
    default: {
      return state
    }
  }
}

export default stricklandReducer
