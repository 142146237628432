import React from 'react'
import publicLayoutStyle from 'layout/PublicLayout/publicLayoutStyle'
import PropTypes from 'prop-types'
import Circles from 'asset/svg/circles.svg'
import withStyles from '@material-ui/core/styles/withStyles'

const PublicLayout = (props) => {
  const {classes, children} = props
  return (
    <div className={classes.container}>
      {children}
      <div className={classes.bottomRightImage}>
        <img src={Circles} alt="circles" />
      </div>
    </div>
  )
}

PublicLayout.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node,
}

export default withStyles(publicLayoutStyle)(PublicLayout)
