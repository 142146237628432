import {GET_ROLE_REQUEST, GET_ROLE_SUCCESS, GET_ROLE_FAIL} from 'redux/actionType'
import httpClient from 'helper/httpClient'
import endpoints from 'helper/endpoints'
import {globalApiErrorHandler} from 'helper/functions'

export const getRoles = () => {
  return (dispatch) => {
    dispatch({type: GET_ROLE_REQUEST})
    return httpClient
      .get(endpoints.roles, {limit: 100, offset: 0, order_by: 'ref:ASC'})
      .then((res) => {
        dispatch({type: GET_ROLE_SUCCESS, data: res.data})
      })
      .catch((err) => {
        dispatch({type: GET_ROLE_FAIL})
        globalApiErrorHandler(err)
      })
  }
}
