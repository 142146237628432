import {
  UPDATE_GLOBAL_LOADING,
  CREATE_VYKAZOMAT_REPORT_REQUEST,
  CREATE_VYKAZOMAT_REPORT_SUCCESS,
  CREATE_VYKAZOMAT_REPORT_FAIL,
  GET_VYKAZOMAT_REPORT_STATE_REQUEST,
  GET_VYKAZOMAT_REPORT_STATE_SUCCESS,
  GET_VYKAZOMAT_REPORT_STATE_FAIL,
  GET_VYKAZOMAT_REPORT_RESULT_REQUEST,
  GET_VYKAZOMAT_REPORT_RESULT_SUCCESS,
  GET_VYKAZOMAT_REPORT_RESULT_FAIL,
  GET_VYKAZOMAT_PROJECT_LIST_REQUEST,
  GET_VYKAZOMAT_PROJECT_LIST_SUCCESS,
  GET_VYKAZOMAT_PROJECT_LIST_FAIL,
} from 'redux/actionType'
import httpClient from 'helper/httpClient'
import endpoints from 'helper/endpoints'
import {globalApiErrorHandler} from 'helper/functions'

export const getVykazomatProjects = () => {
  return (dispatch) => {
    dispatch({type: GET_VYKAZOMAT_PROJECT_LIST_REQUEST})
    return httpClient
      .get(endpoints.vykazomat + '/projects')
      .then((res) => {
        dispatch({type: GET_VYKAZOMAT_PROJECT_LIST_SUCCESS, data: res.data})
      })
      .catch((err) => {
        dispatch({type: GET_VYKAZOMAT_PROJECT_LIST_FAIL})
        globalApiErrorHandler(err)
        return err
      })
  }
}

export const createReport = (data) => {
  return (dispatch) => {
    dispatch({type: CREATE_VYKAZOMAT_REPORT_REQUEST})
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .post(endpoints.reports, data)
      .then((res) => {
        dispatch({type: CREATE_VYKAZOMAT_REPORT_SUCCESS, data: res.data})
        return res.data
      })
      .catch((err) => {
        dispatch({type: CREATE_VYKAZOMAT_REPORT_FAIL})
        globalApiErrorHandler(err)
        return err
      })
  }
}

export const getReportState = (id) => {
  return (dispatch) => {
    dispatch({type: GET_VYKAZOMAT_REPORT_STATE_REQUEST})
    return httpClient
      .get(`${endpoints.reports}/status/${id}`)
      .then((res) => {
        if (res.data.state === 'FAILURE') {
          dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
          dispatch({type: GET_VYKAZOMAT_REPORT_STATE_FAIL})
        } else {
          dispatch({type: GET_VYKAZOMAT_REPORT_STATE_SUCCESS, data: res.data})
        }
        return res.data
      })
      .catch((err) => {
        dispatch({type: GET_VYKAZOMAT_REPORT_STATE_FAIL})
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
        globalApiErrorHandler(err)
        return err
      })
  }
}

export const getReportResult = (id) => {
  return (dispatch) => {
    dispatch({type: GET_VYKAZOMAT_REPORT_RESULT_REQUEST})
    return httpClient
      .get(`${endpoints.reports}/result/${id}`)
      .then((res) => {
        dispatch({type: GET_VYKAZOMAT_REPORT_RESULT_SUCCESS, data: res.data})
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
        return res.data
      })
      .catch((err) => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
        dispatch({type: GET_VYKAZOMAT_REPORT_RESULT_FAIL})
        globalApiErrorHandler(err)
        return err
      })
  }
}
