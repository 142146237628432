import httpClient from 'helper/httpClient'
import endpoints from 'helper/endpoints'
import {
  catchResponseError,
  fireErrorToast,
  fireSuccessToast,
  globalApiErrorHandler,
} from 'helper/functions'
import {
  GET_PERMISSION_FAIL,
  GET_PERMISSION_REQUEST,
  GET_PERMISSION_SUCCESS,
  GET_USER_FAIL,
  GET_USER_LIST_FAIL,
  GET_USER_LIST_REQUEST,
  GET_USER_LIST_SUCCESS,
  GET_USER_REQUEST,
  GET_USER_ROLE_FAIL,
  GET_USER_ROLE_LIST_FAIL,
  GET_USER_ROLE_LIST_REQUEST,
  GET_USER_ROLE_LIST_SUCCESS,
  GET_USER_ROLE_REQUEST,
  GET_USER_ROLE_SUCCESS,
  GET_USER_SUCCESS,
  UPDATE_GLOBAL_LOADING,
} from 'redux/actionType'
import React from 'react'
import {Trans} from '@lingui/macro'

export const getUserList = (limit = 1000, offset = 0, filters) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    dispatch({type: GET_USER_LIST_REQUEST})
    return httpClient
      .get(endpoints.users, {
        limit: limit,
        offset: offset,
        order_by:
          filters?.orderBy &&
          filters?.orderDirection &&
          `${filters?.orderBy}:${filters?.orderDirection}`,
      })
      .then((res) => {
        dispatch({type: GET_USER_LIST_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_USER_LIST_FAIL})
        globalApiErrorHandler(err)
        return Promise.reject(err?.response?.data?.errors)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const getUser = (id) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    dispatch({type: GET_USER_REQUEST})
    return httpClient
      .get(endpoints.users)
      .then((res) => {
        dispatch({type: GET_USER_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_USER_FAIL})
        globalApiErrorHandler(err)
        return Promise.reject(err?.response?.data?.errors)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const updateUser = (id, data) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .patch(endpoints.users + '/' + id, data)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        return catchResponseError(err)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const getUserRoleList = (limit = 1000, offset = 0, filters) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    dispatch({type: GET_USER_ROLE_LIST_REQUEST})
    return httpClient
      .get(endpoints.userRoles, {
        limit: limit,
        offset: offset,
        order_by:
          filters?.orderBy &&
          filters?.orderDirection &&
          `${filters?.orderBy}:${filters?.orderDirection}`,
      })
      .then((res) => {
        dispatch({type: GET_USER_ROLE_LIST_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_USER_ROLE_LIST_FAIL})
        globalApiErrorHandler(err)
        return Promise.reject(err?.response?.data?.errors)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const getUserRole = (id) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    dispatch({type: GET_USER_ROLE_REQUEST})
    return httpClient
      .get(endpoints.userRoles + '/' + id)
      .then((res) => {
        dispatch({type: GET_USER_ROLE_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_USER_ROLE_FAIL})
        globalApiErrorHandler(err)
        return Promise.reject(err?.response?.data?.errors)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const createUserRole = (data) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .post(endpoints.userRoles, data)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        globalApiErrorHandler(err)
        return Promise.reject(err?.response?.data?.errors)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const patchUserRole = (id, data) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .patch(endpoints.userRoles + '/' + id, data)
      .then((res) => {
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        globalApiErrorHandler(err)
        return Promise.reject(err?.response?.data?.errors)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const deleteUserRole = (id) => {
  return (dispatch) => {
    dispatch({type: UPDATE_GLOBAL_LOADING, data: true})
    return httpClient
      .del(endpoints.userRoles + '/' + id)
      .then((res) => {
        fireSuccessToast(<Trans>User role deleted.</Trans>)
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        fireErrorToast(<Trans>Delete failed.</Trans>)
        globalApiErrorHandler(err)
        return Promise.reject(err?.response?.data?.errors)
      })
      .finally(() => {
        dispatch({type: UPDATE_GLOBAL_LOADING, data: false})
      })
  }
}

export const getPermissions = (limit = 1000, offset = 0, filters) => {
  return (dispatch) => {
    dispatch({type: GET_PERMISSION_REQUEST})
    return httpClient
      .get(endpoints.permissions, {
        limit: limit,
        offset: offset,
        order_by:
          filters?.orderBy &&
          filters?.orderDirection &&
          `${filters?.orderBy}:${filters?.orderDirection}`,
      })
      .then((res) => {
        dispatch({type: GET_PERMISSION_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_PERMISSION_FAIL})
        return Promise.reject(err?.response?.data?.errors)
      })
  }
}
