import React, {useEffect, useState} from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import Collapse from '@material-ui/core/Collapse'
import IconExpandLess from '@material-ui/icons/ExpandLess'
import IconExpandMore from '@material-ui/icons/ExpandMore'
import IconLibraryBooks from '@material-ui/icons/LibraryBooks'
import AssessmentIcon from '@material-ui/icons/Assessment'
import AccountTreeIcon from '@material-ui/icons/AccountTree'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import DashboardIcon from '@material-ui/icons/Dashboard'
import EqualizerIcon from '@material-ui/icons/Equalizer'
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import EluviaLogo from 'asset/img/eluviaLogo.png'
import withStyles from '@material-ui/core/styles/withStyles'
import privateLayoutStyle from 'layout/PrivateLayout/privateLayoutStyle'
import {redirectTo} from 'helper/functions'
import cx from 'classnames'
import {Trans} from '@lingui/macro'
import {bindActionCreators, compose} from 'redux'
import {logout} from 'redux/action/authAction'
import {connect} from 'react-redux'
import {Typography} from '@material-ui/core'
import {useLocation} from 'react-router-dom'
import PropTypes from 'prop-types'
import {authorizedAbility, Can} from 'helper/authorizeAbility'

const DrawerMenu = (props) => {
  const {classes, logout} = props

  const [openOdhadomat, setOpenOdhadomat] = useState(false)
  const [openProjektomat, setOpenProjektomat] = useState(false)
  const [openKapacitomat, setOpenKapacitomat] = useState(false)
  const [openStrickland, setOpenStrickland] = useState(false)
  const [openManagement, setOpenManagement] = useState(false)

  let location = useLocation()

  const handleToggleOdhadomat = () => {
    setOpenOdhadomat((prevState) => !prevState)
  }
  const handleToggleProjektomat = () => {
    setOpenProjektomat((prevState) => !prevState)
  }
  const handleToggleKapacitomat = () => {
    setOpenKapacitomat((prevState) => !prevState)
  }
  const handleToggleManagement = () => {
    setOpenManagement((prevState) => !prevState)
  }
  const handleToggleStrickland = () => {
    setOpenStrickland((prevState) => !prevState)
  }

  const redirectToPath = (path) => () => {
    return redirectTo(path)
  }

  const handleLogout = () => {
    logout()
  }

  const isActiveMenu = (menuPath) => {
    return location.pathname.includes(menuPath)
  }

  const isSubMenuActive = (subMenuPath) => {
    return location.pathname.includes(subMenuPath)
  }

  const NestedListItem = (props) => {
    const {path, name} = props
    return (
      <ListItem
        button
        className={cx(
          classes.menuItem,
          classes.collapseMenuItem,
          isSubMenuActive(path) && classes.activeSubMenuItem
        )}
        onClick={redirectToPath(path)}
      >
        <ListItemText inset primary={name} />
      </ListItem>
    )
  }

  useEffect(() => {
    if (location.pathname.includes('/odhadomat')) {
      setOpenOdhadomat(true)
      setOpenProjektomat(false)
      setOpenKapacitomat(false)
      setOpenManagement(false)
      setOpenStrickland(false)
    } else if (location.pathname.includes('/projektomat')) {
      setOpenProjektomat(true)
      setOpenOdhadomat(false)
      setOpenKapacitomat(false)
      setOpenManagement(false)
      setOpenStrickland(false)
    } else if (location.pathname.includes('/kapacitomat')) {
      setOpenProjektomat(false)
      setOpenOdhadomat(false)
      setOpenKapacitomat(true)
      setOpenManagement(false)
      setOpenStrickland(false)
    } else if (location.pathname.includes('/management')) {
      setOpenProjektomat(false)
      setOpenOdhadomat(false)
      setOpenKapacitomat(false)
      setOpenManagement(true)
      setOpenStrickland(false)
    } else if (location.pathname.includes('/strickland')) {
      setOpenProjektomat(false)
      setOpenOdhadomat(false)
      setOpenKapacitomat(false)
      setOpenManagement(false)
      setOpenStrickland(true)
    } else {
      setOpenOdhadomat(false)
      setOpenProjektomat(false)
      setOpenKapacitomat(false)
      setOpenManagement(false)
      setOpenStrickland(false)
    }
  }, [location])

  return (
    <List component="nav" className={classes.appMenu} disablePadding>
      <div className={classes.menuLogo}>
        <img src={EluviaLogo} alt={'logo'} />
      </div>
      <div className={classes.appMenuItems}>
        {/* DASHBOARD */}
        <ListItem
          button
          onClick={redirectToPath('/dashboard')}
          className={cx(classes.menuItem, isActiveMenu('/dashboard') && classes.activeMenuItem)}
        >
          <ListItemIcon className={classes.menuItemIcon}>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>

        {/* ODHADOMAT */}
        <Can I="read" an="Odhadomat">
          <ListItem
            button
            onClick={handleToggleOdhadomat}
            className={cx(classes.menuItem, isActiveMenu('/odhadomat') && classes.activeMenuItem)}
          >
            <ListItemIcon className={classes.menuItemIcon}>
              <AccountTreeIcon />
            </ListItemIcon>
            <ListItemText primary="Odhadomat" />
            {openOdhadomat ? <IconExpandLess /> : <IconExpandMore />}
          </ListItem>
          <Collapse
            in={openOdhadomat}
            timeout="auto"
            unmountOnExit
            className={classes.collapseMenu}
          >
            <Divider />
            <List component="div" disablePadding>
              <NestedListItem path={'/odhadomat/odhady'} name={<Trans>Estimations</Trans>} />
            </List>
          </Collapse>
        </Can>

        {/* VYKAZOMAT */}
        <Can I="read" an="Vykazomat">
          <ListItem
            button
            className={cx(classes.menuItem, isActiveMenu('/vykazomat') && classes.activeMenuItem)}
            onClick={redirectToPath('/vykazomat')}
          >
            <ListItemIcon className={classes.menuItemIcon}>
              <AssessmentIcon />
            </ListItemIcon>
            <ListItemText primary="Výkazomat" />
          </ListItem>
        </Can>

        {/* PROJEKTOMAT */}
        <Can I="read" a="Projektomat">
          <ListItem
            button
            onClick={handleToggleProjektomat}
            className={cx(classes.menuItem, isActiveMenu('/projektomat') && classes.activeMenuItem)}
          >
            <ListItemIcon className={classes.menuItemIcon}>
              <IconLibraryBooks />
            </ListItemIcon>
            <ListItemText primary="Projektomat" />
            {openProjektomat ? <IconExpandLess /> : <IconExpandMore />}
          </ListItem>
          <Collapse
            in={openProjektomat}
            timeout="auto"
            unmountOnExit
            className={classes.collapseMenu}
          >
            <Divider />
            <List component="div" disablePadding>
              <NestedListItem path={'/projektomat/projekty'} name={<Trans>Projects</Trans>} />
              <NestedListItem path={'/projektomat/zamestnanci'} name={<Trans>Eluvians</Trans>} />
              <NestedListItem path={'/projektomat/dokumenty'} name={<Trans>Documents</Trans>} />
            </List>
          </Collapse>
        </Can>

        {/* KAPACITOMAT */}
        <Can I="read" a="Kapacitomat">
          <ListItem
            button
            onClick={handleToggleKapacitomat}
            className={cx(classes.menuItem, isActiveMenu('/kapacitomat') && classes.activeMenuItem)}
          >
            <ListItemIcon className={classes.menuItemIcon}>
              <PermContactCalendarIcon />
            </ListItemIcon>
            <ListItemText primary="Kapacitomat" />
            {openKapacitomat ? <IconExpandLess /> : <IconExpandMore />}
          </ListItem>
          <Collapse
            in={openKapacitomat}
            timeout="auto"
            unmountOnExit
            className={classes.collapseMenu}
          >
            <Divider />
            <List component="div" disablePadding>
              <NestedListItem path={'/kapacitomat/projekty'} name={<Trans>Projects</Trans>} />
              <NestedListItem path={'/kapacitomat/zamestnanci'} name={<Trans>Eluvians</Trans>} />
              <NestedListItem
                path={'/kapacitomat/zakazky'}
                name={<Trans>Monthly utilization</Trans>}
              />
            </List>
          </Collapse>
        </Can>

        {/* STRICKLAND */}
        <Can I="read" a="Strickland">
          <ListItem
            button
            onClick={handleToggleStrickland}
            className={cx(classes.menuItem, isActiveMenu('/strickland') && classes.activeMenuItem)}
          >
            <ListItemIcon className={classes.menuItemIcon}>
              <EqualizerIcon />
            </ListItemIcon>
            <ListItemText primary="Strickland" />
            {openStrickland ? <IconExpandLess /> : <IconExpandMore />}
          </ListItem>
          <Collapse
            in={openStrickland}
            timeout="auto"
            unmountOnExit
            className={classes.collapseMenu}
          >
            <Divider />
            <List component="div" disablePadding>
              <NestedListItem
                path={'/strickland/projekty'}
                name={<Trans>Projects utilization</Trans>}
              />
              <NestedListItem
                path={'/strickland/zamestnanci'}
                name={<Trans>Eluvians utilization</Trans>}
              />
              <NestedListItem path={'/strickland/potencial'} name={<Trans>Potential</Trans>} />
              <NestedListItem path={'/strickland/prasatko'} name={<Trans>Piggy bank</Trans>} />
            </List>
          </Collapse>
        </Can>

        {/* USER MANAGEMENT */}
        {(authorizedAbility.can('update', 'Users') || authorizedAbility.can('update', 'Roles')) && (
          <>
            <ListItem
              button
              onClick={handleToggleManagement}
              className={cx(
                classes.menuItem,
                isActiveMenu('/management') && classes.activeMenuItem
              )}
            >
              <ListItemIcon className={classes.menuItemIcon}>
                <SupervisorAccountIcon />
              </ListItemIcon>
              <ListItemText primary="Management" />
              {openKapacitomat ? <IconExpandLess /> : <IconExpandMore />}
            </ListItem>
            <Collapse
              in={openManagement}
              timeout="auto"
              unmountOnExit
              className={classes.collapseMenu}
            >
              <Divider />
              <List component="div" disablePadding>
                <Can I="update" an="Users">
                  <NestedListItem path={'/management/uzivatele'} name={<Trans>Users</Trans>} />
                </Can>

                <Can I="update" an="Roles">
                  <NestedListItem path={'/management/role'} name={<Trans>Roles</Trans>} />
                </Can>
              </List>
            </Collapse>
          </>
        )}
      </div>
      <div className={classes.logoutButton} onClick={handleLogout}>
        <div className={classes.logoutIcon}>
          <ExitToAppIcon />
        </div>
        <Typography className={classes.cursorPointer}>
          <Trans>Logout</Trans>
        </Typography>
      </div>
    </List>
  )
}

DrawerMenu.propTypes = {
  classes: PropTypes.object,
  logout: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      logout,
    },
    dispatch
  )
}

export default compose(
  withStyles(privateLayoutStyle),
  connect((store) => {
    return {
      loading: store.globalLoading.loading,
    }
  }, mapDispatchToProps)
)(DrawerMenu)
