import {Field, Form} from 'react-final-form'
import React, {useEffect} from 'react'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import PropTypes from 'prop-types'
import {bindActionCreators, compose} from 'redux'
import {createUserRole, getPermissions, patchUserRole} from 'redux/action/userAction'
import {Trans} from '@lingui/macro'
import TextInput from 'component/field/TextInput'
import PrimaryButton from 'component/material/PrimaryButton'
import BoxFullWidth from 'component/material/BoxFullWidth'
import {connect} from 'react-redux'
import Box from '@material-ui/core/Box'
import {fireErrorToast, fireSuccessToast, mapErrorResponseToForm} from 'helper/functions'
import MultiSelectInput from 'component/field/MultiSelectInput'

const NewRoleForm = (props) => {
  const {
    handleGetUserRoleList,
    onClose,
    initValues,
    createUserRole,
    patchUserRole,
    getPermissions,
    permissionList,
    permissionListLoading,
  } = props

  const handleSubmit = (values) => {
    if (initValues?.id) {
      return patchUserRole(initValues.id, values)
        .then(() => {
          fireSuccessToast(<Trans>User role updated.</Trans>)
          onClose()
          handleGetUserRoleList()
        })
        .catch((err) => {
          fireErrorToast(<Trans>Update failed.</Trans>)
          return mapErrorResponseToForm(err)
        })
    } else {
      return createUserRole(values)
        .then(() => {
          fireSuccessToast(<Trans>User role created.</Trans>)
          onClose()
          handleGetUserRoleList()
        })
        .catch((err) => {
          fireErrorToast(<Trans>Create failed.</Trans>)
          return mapErrorResponseToForm(err)
        })
    }
  }

  const prepareOptions = (optionArray) => {
    if (Array.isArray(optionArray)) {
      const options = {}
      optionArray.forEach((item, index) => {
        // if you provide array of objects take id as option value
        options[item.id] = item.code
      })
      return options
    } else {
      return {}
    }
  }

  const getInitValues = () => {
    const permissionIds = initValues?.permissions?.map((per) => per.id)

    return {
      ...initValues,
      permission_ids: permissionIds,
    }
  }

  useEffect(() => {
    getPermissions()
  }, [])

  return (
    <Form onSubmit={handleSubmit} initialValues={getInitValues()}>
      {(formProps) => {
        return (
          <form onSubmit={formProps.handleSubmit}>
            <GridContainer spacing={2}>
              <GridItem xs={12}>
                <Field
                  name="name"
                  label={<Trans>Name</Trans>}
                  component={TextInput}
                  disabled={formProps.submitting}
                />
              </GridItem>

              <GridItem xs={12}>
                <Field
                  name="permission_ids"
                  label={<Trans>Permissions</Trans>}
                  component={MultiSelectInput}
                  options={permissionList && prepareOptions(permissionList)}
                  disabled={formProps.submitting}
                />
              </GridItem>
            </GridContainer>
            <BoxFullWidth pt={3}>
              <GridContainer justifyContent={'flex-end'}>
                <Box pr={4}>
                  <PrimaryButton
                    fullWidth={false}
                    variant={'outlined'}
                    text={<Trans>Cancel</Trans>}
                    onClick={onClose}
                    disabled={formProps.submitting}
                  />
                </Box>
                <PrimaryButton
                  fullWidth={false}
                  type="submit"
                  text={initValues?.id ? <Trans>Edit</Trans> : <Trans>Create</Trans>}
                  disabled={formProps.submitting}
                />
              </GridContainer>
            </BoxFullWidth>
          </form>
        )
      }}
    </Form>
  )
}

NewRoleForm.propTypes = {
  handleGetUserRoleList: PropTypes.func,
  onClose: PropTypes.func,
  createUserRole: PropTypes.func,
  patchUserRole: PropTypes.func,
  initValues: PropTypes.object,
  getPermissions: PropTypes.func,
  permissionList: PropTypes.array,
  permissionListLoading: PropTypes.bool,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      createUserRole,
      patchUserRole,
      getPermissions,
    },
    dispatch
  )
}

export default compose(
  connect((store) => {
    return {
      permissionList: store.user.permissionList,
      permissionListLoading: store.user.permissionListLoading,
    }
  }, mapDispatchToProps)
)(NewRoleForm)
