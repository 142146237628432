import React, {useEffect, useState} from 'react'
import {withStyles} from '@material-ui/core'
import {Trans} from '@lingui/macro'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {updateGlobalSetting} from 'redux/action/globalSettingAction'
import KapacitomatPage from 'page/KapacitomatPage'
import {getKapacitomatEmployeeList, synchronizeAllKapacitomat} from 'redux/action/kapacitomatAction'
import EmployeeTableKap from 'component/KapacitomatPage/EmployeeTableKap'
import kapacitomatPageStyle from 'component/KapacitomatPage/kapacitomatPageStyle'
import SyncIcon from '@material-ui/icons/Sync'
import GridContainer from 'component/material/GridContainer'
import CustomTooltip from 'component/material/Tooltip'
import cx from 'classnames'
import {useTableSort} from 'hooks/useTableSort'

const EmployeePageKap = (props) => {
  const {
    classes,
    getKapacitomatEmployeeList,
    employeeList,
    employeesLoading,
    periodId,
    synchronizeAllKapacitomat,
  } = props

  const [tableSort, onSortClick] = useTableSort({orderBy: null, orderDirection: 'asc'})

  const [syncLoading, setSyncLoading] = useState(false)

  const fetchEmployees = () => {
    return getKapacitomatEmployeeList(1000, 0, periodId, tableSort)
  }

  const handleSyncEmployeesAndProjects = () => {
    setSyncLoading(true)
    return synchronizeAllKapacitomat().finally(() => {
      setSyncLoading(false)
    })
  }

  useEffect(() => {
    if (periodId !== undefined) {
      fetchEmployees()
    }
  }, [periodId, tableSort])

  return (
    <KapacitomatPage
      title={
        <GridContainer alignItems={'center'}>
          <Trans>Eluvians</Trans>
          <CustomTooltip
            title={<Trans>Synchronize all projects and employees from Projektomat</Trans>}
          >
            <SyncIcon
              className={cx(classes.syncButton, syncLoading ? classes.syncButtonRotating : '')}
              onClick={handleSyncEmployeesAndProjects}
            />
          </CustomTooltip>
        </GridContainer>
      }
    >
      <EmployeeTableKap
        data={[
          ...employeeList,
          {
            employee: {name: <Trans>Total</Trans>},
            obliged_mds: employeeList.reduce((acc, cur) => acc + cur.obliged_mds, 0),
            utilized_mds:
              Math.round(
                Number(employeeList.reduce((acc, cur) => acc + cur.utilized_mds, 0) * 10)
              ) / 10,
          },
        ]}
        loading={employeesLoading}
        fetchEmployees={() => fetchEmployees()}
        pagination={false}
        lastRowBold={true}
        onSortClick={onSortClick}
        orderBy={tableSort.orderBy}
        orderDirection={tableSort.orderDirection}
      />
    </KapacitomatPage>
  )
}

EmployeePageKap.propTypes = {
  classes: PropTypes.object,
  getKapacitomatEmployeeList: PropTypes.func,
  employeeList: PropTypes.array,
  employeesLoading: PropTypes.bool,
  employeesMeta: PropTypes.object,
  updateGlobalSetting: PropTypes.func,
  kapacitomatPeriodId: PropTypes.string,
  periodId: PropTypes.string,
  synchronizeAllKapacitomat: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getKapacitomatEmployeeList,
      updateGlobalSetting,
      synchronizeAllKapacitomat,
    },
    dispatch
  )
}

export default compose(
  withStyles(kapacitomatPageStyle),
  connect((store) => {
    return {
      periodId: store.kapacitomat.period.id,
      employeesLoading: store.kapacitomat.employeesLoading,
      employeeList: store.kapacitomat.employees,
    }
  }, mapDispatchToProps)
)(EmployeePageKap)
