import React from 'react'
import materialStyle from 'component/material/materialStyle'
import withStyles from '@material-ui/core/styles/withStyles'
import Box from '@material-ui/core/Box'
import cx from 'classnames'

const CustomBox = (props) => {
  const {classes, className, ...rest} = props

  return <Box {...rest} className={cx(classes.fullWidth, className)} />
}

export default withStyles(materialStyle)(CustomBox)
