import React from 'react'
import Table from 'component/material/table/Table'
import {Trans} from '@lingui/macro'
import PropTypes from 'prop-types'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import {Edit} from '@material-ui/icons'
import {patchKapacitomatEmployee} from 'redux/action/kapacitomatAction'
import EmployeeMdsForm from 'component/KapacitomatPage/EmployeeMdsForm'
import TableIconButton from 'component/general/TableIconButton'
import {useDialog} from 'hooks/useDialog'

const EmployeeTableKap = (props) => {
  const {
    data,
    loading,
    onSortClick,
    orderBy,
    orderDirection,
    pagination,
    fetchEmployees,
    patchKapacitomatEmployee,
    periodId,
  } = props

  const [Dialog, dialogData, onOpen, onClose] = useDialog()

  const columns = [
    {
      name: 'employee',
      label: <Trans>Name</Trans>,
      render: (val) => val.name,
    },
    {
      name: 'employee',
      label: <Trans>Email</Trans>,
      render: (val) => val.email,
    },
    {
      name: 'obligation_percentage',
      orderKey: 'obligation_percentage',
      align: 'right',
      label: <Trans>Obligation</Trans>,
      render: (val) => val && val + ' %',
    },
    {
      name: 'obliged_mds',
      label: '',
      align: 'right',
      render: (val) => val + ' MD',
    },
    {
      name: 'utilization_percentage',
      orderKey: 'utilization_percentage',
      align: 'right',
      label: <Trans>Utilized</Trans>,
      render: (val) => val && val + ' %',
    },
    {
      name: 'utilized_mds',
      align: 'right',
      label: '',
      render: (val) => val + ' MDs',
    },
    {
      name: '',
      align: 'right',
      render: (val, col, rowIndex, row) =>
        data.length - 1 !== rowIndex && (
          <TableIconButton
            tooltipTitle={<Trans>Edit eluvian MD</Trans>}
            onClick={onOpen(row)}
            Icon={Edit}
          />
        ),
    },
  ]

  const prepareData = (data) => {
    return {
      obligation_percentage: data.obligation_percentage && Number(data.obligation_percentage),
      utilization_percentage: data.utilization_percentage && Number(data.utilization_percentage),
      period_id: periodId,
    }
  }

  const handleSubmitEmployeeMds = (data) => {
    const preparedData = prepareData(data)
    return patchKapacitomatEmployee(dialogData?.id, preparedData).then(() => {
      fetchEmployees()
      onClose()
    })
  }

  return (
    <>
      {/* EMPLOYEE MDS DIALOG */}
      <Dialog title={dialogData?.employee?.name} maxWidth={'xs'}>
        <EmployeeMdsForm initialValues={dialogData} handleSubmit={handleSubmitEmployeeMds} />
      </Dialog>

      <Table
        columns={columns}
        data={data}
        loading={loading}
        orderDirection={orderDirection}
        orderBy={orderBy}
        onSortClick={onSortClick}
        pagination={pagination}
        lastRowBold={true}
      />
    </>
  )
}

EmployeeTableKap.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  onSortClick: PropTypes.func,
  orderBy: PropTypes.string,
  orderDirection: PropTypes.string,
  pagination: PropTypes.bool,
  fetchEmployees: PropTypes.func,
  patchKapacitomatEmployee: PropTypes.func,
  periodId: PropTypes.string,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      patchKapacitomatEmployee,
    },
    dispatch
  )
}

export default compose(
  connect((store) => {
    return {
      periodId: store.kapacitomat.periodId,
    }
  }, mapDispatchToProps)
)(EmployeeTableKap)
