const odhadyStyle = (theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },

  tableWrapper: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  projectCellClass: {
    height: 37,
    boxSizing: 'border-box',
    paddingTop: 0,
    paddingBottom: 0,
  },
  flexBasisZero: {
    flexBasis: 0,
    flexGrow: 0,
  },
  appBarBottomBorder: {
    borderBottom: '0px solid ' + theme.palette.secondary.main,
  },

  tabs: {
    backgroundColor: theme.palette.white,
    color: theme.palette.primary.main,

    '& .MuiTabScrollButton-root:first-of-type': {
      borderRight: '0px solid ' + theme.palette.secondary.main,
    },
    '& .MuiTabScrollButton-root:last-of-type': {
      borderLeft: '0px solid ' + theme.palette.secondary.main,
    },
    '& .MuiTab-textColorInherit.Mui-selected': {
      fontWeight: 700,
      opacity: 1,
    },
    '& .MuiTab-textColorInherit': {
      opacity: 0.8,
      fontWeight: 600,
    },
    '& .MuiTab-root': {
      minWidth: '90px !important',
      padding: '6px 24px',
    },
  },

  overflowAnchor: {
    overflow: 'auto',
  },
  // SUMMARY
  summaryRow: {
    minWidth: 800,
    overflow: 'auto',
    marginBottom: 5,
  },
  summaryTotalRow: {
    minWidth: 800,
    overflow: 'auto',
    borderTop: '2px solid ' + theme.palette.primary.main,
    marginTop: 10,
    fontWeight: 700,
  },
  summaryTab: {
    overflow: 'auto',
    width: '100%',
    fontSize: 13,
    paddingBottom: 10,
  },
  // STORY
  storyTab: {
    width: '100%',
    fontSize: 13,
  },
  storyAccordion: {
    marginBottom: 0,

    '&.MuiAccordion-root:before': {
      opacity: 0,
    },

    '&.MuiAccordion-root.Mui-expanded': {
      margin: '0px 0px 0px 0px',
    },

    '& .MuiAccordionSummary-root': {
      '&.Mui-expanded': {
        minHeight: 48,
      },
    },
    '& .MuiIconButton-edgeEnd': {
      marginRight: '-8px',
    },
    '& .MuiAccordionSummary-content': {
      '&.Mui-expanded': {
        margin: '12px 0',
      },
    },
    '& .MuiSvgIcon-root': {
      fill: theme.palette.primary.main,
    },
  },
  storyAccordionSummary: {
    cursor: 'pointer',
  },
  storyLayerHeader: {
    fontWeight: 500,
    borderBottom: '2px solid ' + theme.palette.primary.main,
  },
  overflowEllipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'block !important',
  },
  storyLayer: {
    padding: '8px 6px',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    height: 34,
  },
  flexAuto: {
    flex: 'auto',
  },
  storyLayerRow: {
    height: 34,
    '&:nth-child(odd)': {
      backgroundColor: theme.palette.background.tableRow,
    },
  },
  iconButtonHeight: {
    padding: 6,
    '& .MuiSvgIcon-root': {
      width: 22,
      height: 22,
    },
  },
  iconButtonRow: {
    padding: 0,
    margin: '0px 0px 0px 12px',
    '& .MuiSvgIcon-root': {
      fill: theme.palette.primary.main,
      width: 18,
      height: 18,
    },
  },
  iconTooltip: {
    height: 18,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  inlineTextField: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    padding: '8px 4px',
    boxSizing: 'border-box',
    height: 34,

    '& input': {
      fontSize: 13,
      padding: 0,
      letterSpacing: 0,
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderWidth: 1,
    },
    '& .MuiInput-underline:after': {
      borderWidth: 1,
    },
    '& .MuiInput-root': {
      padding: '0px 2px',
      height: 16,
      letterSpacing: 0,
      lineHeight: 1,
      flexGrow: 1,
    },
  },
  inlineAdornment: {
    marginLeft: 0,
    fontSize: 13,
  },
  inlineSelectFormControl: {
    height: 34,
    padding: '8px 4px',
    boxSizing: 'border-box',
    width: 120,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  inlineSelectField: {
    flexGrow: 1,
    boxSizing: 'border-box',
    fontSize: '13px !important',
    padding: 0,
    height: 16,
    letterSpacing: 0,
    lineHeight: 1,

    '& .MuiSelect-selectMenu': {
      fontSize: 13,
      padding: '0px 2px',
      height: 16,
      boxSizing: 'border-box',
      backgroundColor: 'transparent',
      letterSpacing: 0,
      display: 'flex',
      alignItems: 'center',
    },

    '&.MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderWidth: '1px !important',
    },
    '&.MuiInput-underline:after': {
      borderWidth: '1px !important',
    },
  },
  inlineSelectMenuItem: {
    fontSize: '13px !important',
  },
  textAlignEnd: {
    textAlign: 'end',
    justifyContent: 'flex-end',
    '& input': {
      textAlign: 'end',
      justifyContent: 'flex-end',
    },
  },
  editEstimationTooltip: {
    height: 18,
    display: 'flex',
  },
  editEstimationButton: {
    padding: 0,
    margin: '0 4px 0 0',
    '& .MuiSvgIcon-root': {
      fill: theme.palette.primary.main,
      width: 18,
      height: 18,
    },
  },
  scheduleTable: {
    fontSize: 12,
    maxWidth: '100%',
  },
  scheduleCell: {
    height: 24,
    maxHeight: 24,
    padding: 4,
    width: 90,
    minWidth: 90,
    boxSizing: 'border-box',
    borderRight: '1px solid ' + theme.palette.divider,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'default',
    backgroundColor: theme.palette.white,

    '&:first-child': {
      borderLeft: '1px solid ' + theme.palette.common.black,
    },

    '&:last-child': {
      borderRight: '1px solid ' + theme.palette.common.black,
    },

    '& input': {
      boxSizing: 'border-box',
      width: '100%',
      textAlign: 'right',
      border: 0,
      fontFamily: 'Montserrat',
      fontSize: 12,

      '&:focus-visible': {
        outline: 'none',
      },
    },
  },
  phasesTable: {
    overflow: 'auto',
    display: 'grid',
    position: 'relative',
    maxHeight: 'calc(100vh - 60.2px)',
    paddingBottom: 16,
  },
  phaseTableHeader: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
  },
  textEnd: {
    textAlign: 'end',
    justifyContent: 'flex-end',
  },
  scheduleCellTopRow: {
    borderTop: '1px solid ' + theme.palette.common.black,
    borderBottom: '1px solid ' + theme.palette.divider,
  },
  monthTextCapitalized: {
    textTransform: 'capitalized',
  },
  phaseNameCell: {
    borderBottom: '1px solid ' + theme.palette.common.black,
    borderLeft: '1px solid ' + theme.palette.common.black,
    borderRight: '1px solid ' + theme.palette.common.black,
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'default',
    backgroundColor: '#f8f8f8',
    boxSizing: 'border-box',
    padding: 4,
    fontWeight: 700,
    display: 'flex',
    alignItems: 'center',
  },

  scheduleSummary: {
    borderBottom: '1px solid ' + theme.palette.common.black,
    borderLeft: '1px solid ' + theme.palette.common.black,
    borderRight: '1px solid ' + theme.palette.common.black,
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'default',
    backgroundColor: '#f8f8f8',
    boxSizing: 'border-box',
    fontWeight: 700,
    display: 'flex',
    alignItems: 'center',
    height: 26,
    maxHeight: 26,
  },

  scheduleSummaryCell: {
    height: 26,
    maxHeight: 26,
    padding: 4,
    width: 90,
    minWidth: 90,
    boxSizing: 'border-box',
    borderRight: '1px solid ' + theme.palette.divider,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'default',
    display: 'flex',
    alignItems: 'center',

    '&:first-child': {
      width: 89,
      minWidth: 89,
    },

    '& input': {
      boxSizing: 'border-box',
      width: '100%',
      textAlign: 'right',
      border: 0,
      fontFamily: 'Montserrat',
      fontSize: 12,

      '&:focus-visible': {
        outline: 'none',
      },
    },
  },

  estimationStepper: {
    paddingTop: 0,

    '& .MuiStepIcon-text': {
      fill: theme.palette.common.white,
    },
  },

  smallScheduleCell: {
    width: 60,
    minWidth: 60,
  },

  editableScheduleCell: {
    cursor: 'pointer',
    transition: 'all 200ms',
    '&:hover': {
      boxShadow: 'inset 0 0 0 2px #3F659Dcc',
    },
  },
  activeScheduleCell: {
    backgroundColor: theme.palette.common.white + ' !important',
  },
  borderBottom: {
    borderBottom: '1px solid ' + theme.palette.divider,
  },
  borderBottomBlack: {
    borderBottom: '1px solid ' + theme.palette.common.black,
  },
  borderRightBlack: {
    borderRight: '1px solid ' + theme.palette.common.black,
  },
  boldText: {
    fontWeight: 700,
  },
  noFlexWrap: {
    flexWrap: 'nowrap',
  },
  summary: {
    padding: 8,
  },
  accordionSummaryHeader: {
    margin: '0 8px',
  },
  accordionSummary: {
    margin: '0 8px',
    borderTop: '2px solid ' + theme.palette.primary.main,
  },
})

export default odhadyStyle
