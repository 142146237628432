import React, {useEffect, useState} from 'react'
import {Trans} from '@lingui/macro'
import Table from 'component/material/table/Table'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import {updateGlobalSetting} from 'redux/action/globalSettingAction'
import {deleteIssuedInvoice, getIssuedInvoices} from 'redux/action/projektomatAction'
import PropTypes from 'prop-types'
import moment from 'moment'
import {DATE_FORMAT, DB_DATE_FORMAT} from 'helper/constants'
import DialogWindow from 'component/material/DialogWindow'
import GridItem from 'component/material/GridItem'
import GridContainer from 'component/material/GridContainer'
import {Edit, Delete} from '@material-ui/icons'
import EditIssuedInvoiceForm from 'component/ProjektomatPage/EditIssuedInvoiceForm'
import {renderAmountWithCurrency} from 'helper/functions'
import {useTableSort} from 'hooks/useTableSort'
import TableIconButton from 'component/general/TableIconButton'
import {usePagination} from 'hooks/usePagination'
import {useWarningDialog} from 'hooks/useWarningDialog'

const IssuedDocument = (props) => {
  const {
    rowsPerPage,
    updateGlobalSetting,
    getIssuedInvoices,
    issuedInvoices,
    issuedInvoicesLoading,
    issuedInvoicesMeta,
    filters,
    deleteIssuedInvoice,
    selectedDateFrom,
    selectedDateTo,
    newAssetCreated,
    setNewAssetCreated,
    companiesSelects,
  } = props

  const [openEdit, setOpenEdit] = useState({visible: false, document: null})
  const [edited, setEdited] = useState(false)
  const [tableSort, onSortClick] = useTableSort({
    orderBy: filters.orderBy,
    orderDirection: filters.orderDirection,
  })
  const [DeleteWarningDialog, deleteData, onOpenDelete, onCloseDelete] = useWarningDialog()
  const [page, onChangePage, onChangeRowsPerPage] = usePagination(updateGlobalSetting)

  const columns = [
    {
      name: 'number',
      orderKey: 'number',
      label: <Trans>Invoice number</Trans>,
    },
    {
      name: 'acceptor_company',
      label: <Trans>Acceptor company</Trans>,
      render: (val) => val?.name,
    },
    {
      name: 'issuer_company',
      label: <Trans>Issuer company</Trans>,
      render: (val) => val?.name,
    },
    {
      name: 'define_date',
      orderKey: 'define_date',
      align: 'right',
      label: <Trans>Define date</Trans>,
      render: (val) => val && moment(val).format(DATE_FORMAT),
    },
    {
      name: 'maturity_date',
      orderKey: 'maturity_date',
      align: 'right',
      label: <Trans>Maturity date</Trans>,
      render: (val) => val && moment(val).format(DATE_FORMAT),
    },
    {
      name: 'amount',
      orderKey: 'amount',
      align: 'right',
      label: <Trans>Amount</Trans>,
      render: (val, col, rowIndex, row) => renderAmountWithCurrency(val, row.currency),
    },
    {
      name: 'projects',
      orderKey: 'projects',
      label: <Trans>Projects</Trans>,
      render: (val) => {
        let projects = []
        val?.forEach((project) => projects.push(project.project?.ref))
        return projects.join(', ')
      },
    },
    {
      name: '',
      align: 'right',
      render: (val, col, rowIndex, row) => (
        <GridContainer spacing={2} direction={'row'} wrap={'nowrap'} justifyContent={'flex-end'}>
          <GridItem>
            <TableIconButton
              tooltipTitle={<Trans>Edit issued invoice</Trans>}
              onClick={openEditIssuedInvoice(row)}
              Icon={Edit}
            />
          </GridItem>
          <GridItem>
            <TableIconButton
              tooltipTitle={<Trans>Delete issued invoice</Trans>}
              onClick={onOpenDelete(row)}
              Icon={Delete}
            />
          </GridItem>
        </GridContainer>
      ),
    },
  ]

  const openEditIssuedInvoice = (row) => () => {
    setOpenEdit({visible: true, document: row})
  }

  const closeEditIssuedInvoice = () => {
    setOpenEdit({visible: false, document: null})
  }

  const handleGetIssuedInvoices = () => {
    getIssuedInvoices(rowsPerPage, page * rowsPerPage, {
      ...filters,
      define_date_from: selectedDateFrom && moment(selectedDateFrom).format(DB_DATE_FORMAT),
      define_date_to: selectedDateTo && moment(selectedDateTo).format(DB_DATE_FORMAT),
      issuer_company_id: companiesSelects?.issuedIssuerId,
      acceptor_company_id: companiesSelects?.issuedAccepterId,
    })
  }

  const handleDeleteIssuedInvoice = () => {
    deleteIssuedInvoice(deleteData?.id).then(() => {
      handleGetIssuedInvoices()
      onCloseDelete()
    })
  }

  useEffect(() => {
    handleGetIssuedInvoices()
  }, [filters, page, rowsPerPage, selectedDateFrom, selectedDateTo, companiesSelects])

  useEffect(() => {
    if (newAssetCreated || edited) {
      handleGetIssuedInvoices()
      setNewAssetCreated(false)
      setEdited(false)
    }
  }, [newAssetCreated, edited])

  return (
    <>
      {/* DELETE DIALOG */}
      <DeleteWarningDialog
        onConfirm={handleDeleteIssuedInvoice}
        message={<Trans>Invoice {deleteData?.number} will be deleted!</Trans>}
      />

      {/* EDIT ISSUED INVOICE */}
      <DialogWindow
        onClose={closeEditIssuedInvoice}
        open={openEdit.visible}
        title={<Trans>Edit issued invoice</Trans>}
        maxWidth={'md'}
        fullWidth={true}
      >
        <EditIssuedInvoiceForm
          onClose={closeEditIssuedInvoice}
          document={openEdit.document}
          setEdited={setEdited}
        />
      </DialogWindow>

      <Table
        columns={columns}
        data={issuedInvoices}
        loading={issuedInvoicesLoading}
        count={issuedInvoicesMeta?.total_count}
        page={page}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
        pagination={true}
        filters={filters}
        filterName={'issuedDocument'}
        onSortClick={onSortClick}
        orderBy={tableSort.orderBy}
        orderDirection={tableSort.orderDirection}
      />
    </>
  )
}

IssuedDocument.propTypes = {
  updateGlobalSetting: PropTypes.func,
  getIssuedInvoices: PropTypes.func,
  issuedInvoices: PropTypes.array,
  issuedInvoicesLoading: PropTypes.bool,
  issuedInvoicesMeta: PropTypes.object,
  filters: PropTypes.object,
  deleteIssuedInvoice: PropTypes.func,
  setNewAssetCreated: PropTypes.func,
  newAssetCreated: PropTypes.bool,
  companiesSelects: PropTypes.object,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateGlobalSetting,
      getIssuedInvoices,
      deleteIssuedInvoice,
    },
    dispatch
  )
}

export default compose(
  connect((store) => {
    return {
      rowsPerPage: store.globalSetting.rowsPerPage,
      issuedInvoices: store.projektomat.issuedInvoices,
      issuedInvoicesLoading: store.projektomat.issuedInvoicesLoading,
      issuedInvoicesMeta: store.projektomat.issuedInvoicesMeta,
      filters: store.filter.issuedDocument,
    }
  }, mapDispatchToProps)
)(IssuedDocument)
