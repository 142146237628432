import React from 'react'
import {Trans} from '@lingui/macro'
import moment from 'moment'

/// undefined == no error for the react-final-form ///

// connect multiple validations together,
// otherwise you can omit this function and use single validation on Field
export const composeValidators = (...validators) => (value) => {
  return validators.reduce((error, validator) => error || validator(value), undefined)
}

export const required = (value) => {
  const valueExist = value === 0 || value
  return valueExist ? undefined : <Trans>Required field</Trans>
}

export const validateEmail = (value) => {
  if (!value?.includes('@')) {
    return <Trans>Wrong email address</Trans>
  }
  return undefined
}

// form level validation for date_from < date_to
export const dateFromToValidation = (values) => {
  const momentFrom = moment(values.from_date)
  const momentTo = moment(values.to_date)
  if (momentFrom > momentTo) {
    return {to_date: <Trans>Date to must be bigger than date from</Trans>}
  }
  return undefined
}

export const priceFromToValidation = (values) => {
  const error = {target_price: [], fix: []}
  values?.target_price?.forEach((section, index) => {
    if (Number(section.price_from) > Number(section.price_to)) {
      error.target_price[index] = {price_from: true, price_to: true}
    }
  })
  values?.fix?.forEach((item, index) => {
    if (Number(item.price_from) > Number(item.price_to)) {
      error.fix[index] = {price_from: true, price_to: true}
    }
  })

  return error
}
