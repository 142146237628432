import React, {useEffect, useState} from 'react'
import {bindActionCreators, compose} from 'redux'
import withStyles from '@material-ui/core/styles/withStyles'
import projektomatPageStyle from 'component/ProjektomatPage/projektomatPageStyle'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {getEmployeeAssignments, patchEmployeeAssignment} from 'redux/action/projektomatAction'
import Loader from 'component/material/Loader'
import Table from 'component/material/table/Table'
import {Trans} from '@lingui/macro'
import Select from '@material-ui/core/Select'
import CustomTooltip from 'component/material/Tooltip'
import Chip from '@material-ui/core/Chip'
import MenuItem from '@material-ui/core/MenuItem'
import Checkbox from '@material-ui/core/Checkbox'
import ListItemText from '@material-ui/core/ListItemText'
import {getRoles} from 'redux/action/roleAction'
import {updateGlobalSetting} from 'redux/action/globalSettingAction'
import {useTableSort} from 'hooks/useTableSort'
import {usePagination} from 'hooks/usePagination'

const EmployeeAssignments = (props) => {
  const {
    classes,
    employeeAssignments,
    employeeAssignmentsLoading,
    getEmployeeAssignments,
    patchEmployeeAssignment,
    open,
    employee,
    roleList,
    getRoles,
    employeeAssignmentsMeta,
    rowsPerPage,
    updateGlobalSetting,
  } = props

  const [focusedSelect, setFocusedSelect] = useState([])
  const [shouldUpdate, setShouldUpdate] = useState(false)
  const [tableSort, onSortClick] = useTableSort({
    orderBy: 'project__name',
    orderDirection: 'asc',
  })

  const [page, onChangePage, onChangeRowsPerPage] = usePagination(updateGlobalSetting)

  const handleBlur = (row) => {
    const rowRoles = row.roles.map((role) => role.role_ref)
    if (rowRoles.sort().toString() !== focusedSelect.sort().toString()) {
      patchEmployeeAssignment(employee.id, row.id, focusedSelect).then(() => {
        setShouldUpdate(true)
      })
    }
  }

  const handleChange = (value) => {
    let newFocusedSelect = [...focusedSelect]

    if (newFocusedSelect.includes(value)) {
      newFocusedSelect = newFocusedSelect.filter((select) => select !== value)
    } else {
      newFocusedSelect.push(value)
    }

    setFocusedSelect(newFocusedSelect)
  }

  const handleFocus = (row) => {
    setFocusedSelect(row?.roles?.map((role) => role.role_ref))
  }

  const columns = [
    {
      name: 'project',
      orderKey: 'project__name',
      label: <Trans>Project</Trans>,
      width: '35%',
      render: (val) => val.name,
    },
    {
      name: 'roles',
      width: '65%',
      label: <Trans>Roles</Trans>,
      render: (val, col, rowIndex, row) => (
        <span className={classes.fullWidth}>
          <Select
            name={row?.project?.name}
            className={classes.fullWidth}
            multiple
            value={row?.roles?.map((role) => role.role_ref)}
            onClose={() => {
              return handleBlur(row)
            }}
            onOpen={() => {
              return handleFocus(row)
            }}
            renderValue={() => (
              <div className={classes.chips}>
                {row?.roles
                  ?.map((role) => role.role_ref)
                  .map((value) => (
                    <CustomTooltip
                      key={value}
                      title={roleList?.find((role) => role.ref === value)?.name}
                    >
                      <Chip size={'small'} label={value} className={classes.chipMargin} />
                    </CustomTooltip>
                  ))}
              </div>
            )}
          >
            {roleList.map((value, index) => (
              <MenuItem
                className={classes.tableMenuItem}
                key={index}
                value={value.ref}
                onClick={() => {
                  return handleChange(value.ref)
                }}
              >
                <Checkbox checked={focusedSelect?.includes(value.ref)} size={'small'} />
                <ListItemText
                  primary={`${value.ref} - ${value.name}`}
                  primaryTypographyProps={{variant: 'body2'}}
                />
              </MenuItem>
            ))}
          </Select>
        </span>
      ),
    },
  ]

  useEffect(() => {
    if (open && employee) {
      getEmployeeAssignments(employee.id, rowsPerPage, page * rowsPerPage, tableSort)
      getRoles()
    }
  }, [open, rowsPerPage, page, tableSort])

  useEffect(() => {
    if (shouldUpdate) {
      getEmployeeAssignments(employee.id, rowsPerPage, page * rowsPerPage, tableSort)
      setShouldUpdate(false)
    }
  }, [shouldUpdate, rowsPerPage, page, tableSort])

  return (
    <>
      {employeeAssignmentsLoading ? (
        <Loader wrapper={true} />
      ) : (
        <Table
          data={employeeAssignments}
          columns={columns}
          isOverflowAuto={false}
          pagination={true}
          loading={employeeAssignmentsLoading}
          orderDirection={tableSort.orderDirection}
          orderBy={tableSort.orderBy}
          onSortClick={onSortClick}
          count={employeeAssignmentsMeta.total_count}
          page={page}
          onChangePage={onChangePage}
          onChangeRowsPerPage={onChangeRowsPerPage}
        />
      )}
    </>
  )
}

EmployeeAssignments.propTypes = {
  classes: PropTypes.object,
  employeeAssignments: PropTypes.array,
  employeeAssignmentsLoading: PropTypes.bool,
  getEmployeeAssignments: PropTypes.func,
  patchEmployeeAssignment: PropTypes.func,
  open: PropTypes.bool,
  employee: PropTypes.object,
  onClose: PropTypes.func,
  roleList: PropTypes.array,
  getRoles: PropTypes.func,
  employeeAssignmentsMeta: PropTypes.object,
  rowsPerPage: PropTypes.number,
  updateGlobalSetting: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getEmployeeAssignments,
      patchEmployeeAssignment,
      getRoles,
      updateGlobalSetting,
    },
    dispatch
  )
}

export default compose(
  withStyles(projektomatPageStyle),
  connect((store) => {
    return {
      employeeAssignments: store.projektomat.employeeAssignments,
      employeeAssignmentsLoading: store.projektomat.employeeAssignmentsLoading,
      employeeAssignmentsMeta: store.projektomat.employeeAssignmentsMeta,
      roleList: store.role.roles,
      rowsPerPage: store.globalSetting.rowsPerPage,
    }
  }, mapDispatchToProps)
)(EmployeeAssignments)
