import React, {useEffect, useState} from 'react'
import GridItem from 'component/material/GridItem'
import {withStyles} from '@material-ui/core'
import {Trans} from '@lingui/macro'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {updateGlobalSetting} from 'redux/action/globalSettingAction'
import KapacitomatPage from 'page/KapacitomatPage'
import {getKapacitomatAssignments, patchKapacitomatAssignment} from 'redux/action/kapacitomatAction'
import GridContainer from 'component/material/GridContainer'
import kapacitomatPageStyle from 'component/KapacitomatPage/kapacitomatPageStyle'
import cx from 'classnames'
import CustomTooltip from 'component/material/Tooltip'
import BoxFullWidth from 'component/material/BoxFullWidth'
import Loader from 'component/material/Loader'
import PrimaryButton from 'component/material/PrimaryButton'

let debounceInterval = null

const AssignmentPageKap = (props) => {
  const {
    classes,
    getKapacitomatAssignments,
    patchKapacitomatAssignment,
    periodId,
    assignmentList,
    assignmentsLoading,
  } = props

  const [showActive, setShowActive] = useState(true)
  const [editableIndex, setEditableIndex] = useState(null)
  const [mouseOverIndexes, setMoseOverIndexes] = useState({project: null, employee: null})

  const handleMouseOverIndexes = (indexes) => () => {
    clearTimeout(debounceInterval)
    debounceInterval = setTimeout(() => {
      setMoseOverIndexes(indexes)
    }, 100)
  }

  const fetchAssignments = () => {
    return getKapacitomatAssignments(1000, 0, periodId, showActive)
  }

  const getFieldColor = (status) => {
    if (status === 'good') return classes.assignmentCellGood
    if (status === 'almost_good') return classes.assignmentCellAlmostGood
    if (status === 'almost_bad') return classes.assignmentCellAlmostBad
    if (status === 'bad') return classes.assignmentCellBad
  }

  const handleInlineEdit = (assignmentLength, index, assignmentIndex) => () => {
    setEditableIndex(assignmentLength * index + assignmentIndex)
  }

  const handleSubmitAssignment = (e, assignment) => {
    const value = e.target.value
    if (e.charCode === 13) {
      return patchKapacitomatAssignment(assignment?.id, {
        assigned_mds: value ? Number(value.replaceAll(',', '.')) : 0.0,
      }).then(() => {
        fetchAssignments().then(() => setEditableIndex(null))
      })
    }
  }

  useEffect(() => {
    if (periodId !== undefined) {
      fetchAssignments()
    }

    return () => {
      clearTimeout(debounceInterval)
    }
  }, [periodId, showActive])

  return (
    <KapacitomatPage
      title={<Trans>Monthly utilization</Trans>}
      actions={
        <>
          <PrimaryButton
            variant={showActive ? 'contained' : 'outlined'}
            textPadding={1}
            fullWidth={false}
            text={
              <CustomTooltip title={<Trans>Show only active assignments</Trans>}>
                <Trans>Only active</Trans>
              </CustomTooltip>
            }
            onClick={() => setShowActive(true)}
          />
          <PrimaryButton
            variant={showActive ? 'outlined' : 'contained'}
            textPadding={1}
            fullWidth={false}
            text={
              <CustomTooltip title={<Trans>Show all assignments</Trans>}>
                <Trans>All</Trans>
              </CustomTooltip>
            }
            onClick={() => setShowActive(false)}
          />
        </>
      }
    >
      <GridContainer className={classes.assignmentsTable} justifyContent={'center'}>
        {assignmentList === null && assignmentsLoading ? (
          <GridContainer direction={'row'} wrap={'nowrap'}>
            <BoxFullWidth py={4}>
              <Loader />
            </BoxFullWidth>
          </GridContainer>
        ) : (
          <>
            {/* table on this page is split into two parts (columns) in order
            to allow horizontal scroll with static part with project names */}
            <GridContainer
              direction={'row'}
              wrap={'nowrap'}
              onMouseLeave={() => {
                setMoseOverIndexes({employee: null, project: null})
                clearTimeout(debounceInterval)
              }}
            >
              {/*STATIC PART*/}
              <GridContainer
                item
                xs={12}
                direction={'column'}
                wrap={'nowrap'}
                className={classes.staticContainer}
              >
                <GridContainer direction={'row'} wrap={'nowrap'}>
                  <GridItem
                    className={cx(
                      classes.assignmentCell,
                      classes.assignmentHeadCell,
                      classes.leftHeadBorder,
                      classes.rightHeadBorder
                    )}
                  >
                    {/* empty field */}
                  </GridItem>
                  <GridItem
                    className={cx(
                      classes.assignmentCell,
                      classes.assignmentHeadCell,
                      classes.assignmentSmallWidth
                    )}
                  >
                    <Trans>Remaining</Trans>
                  </GridItem>
                  <GridItem
                    className={cx(
                      classes.assignmentCell,
                      classes.assignmentHeadCell,
                      classes.assignmentSmallWidth,
                      classes.rightHeadBorder
                    )}
                  >
                    <Trans>Alloc.</Trans>
                  </GridItem>
                </GridContainer>
                <GridContainer direction={'row'} wrap={'nowrap'}>
                  <GridItem
                    className={cx(
                      classes.assignmentCell,
                      classes.leftHeadBorder,
                      classes.rightHeadBorder,
                      classes.assignmentCellBorderBottom
                    )}
                  >
                    <Trans>Remaining</Trans>
                  </GridItem>
                  <GridItem
                    className={cx(
                      classes.assignmentCell,
                      classes.assignmentEmptyCell,
                      classes.assignmentSmallWidth
                    )}
                  >
                    {/* empty field */}
                  </GridItem>
                  <GridItem
                    className={cx(
                      classes.assignmentCell,
                      classes.assignmentEmptyCell,
                      classes.rightHeadBorder,
                      classes.assignmentSmallWidth
                    )}
                  >
                    {/* empty field */}
                  </GridItem>
                </GridContainer>
                <GridContainer direction={'row'} wrap={'nowrap'}>
                  <GridItem
                    className={cx(
                      classes.assignmentCell,
                      classes.leftHeadBorder,
                      classes.bottomHeadBorder,
                      classes.rightHeadBorder
                    )}
                  >
                    <Trans>Alloc.</Trans>
                  </GridItem>
                  <GridItem
                    className={cx(
                      classes.assignmentCell,
                      classes.bottomHeadBorder,
                      classes.assignmentEmptyCell,
                      classes.assignmentSmallWidth
                    )}
                  >
                    {/* empty field */}
                  </GridItem>
                  <GridItem
                    className={cx(
                      classes.assignmentCell,
                      classes.bottomHeadBorder,
                      classes.assignmentEmptyCell,
                      classes.rightHeadBorder,
                      classes.assignmentSmallWidth
                    )}
                  >
                    {/* empty field */}
                  </GridItem>
                </GridContainer>

                {/* Table with assignments */}
                {assignmentList?.projects?.map((project, index) => (
                  <GridContainer
                    key={index}
                    direction={'row'}
                    wrap={'nowrap'}
                    className={classes.borderBoxSizing}
                  >
                    <CustomTooltip title={`${project.project?.ref} - ${project.project?.name}`}>
                      <GridItem
                        className={cx(
                          classes.assignmentCell,
                          classes.bottomHeadBorder,
                          classes.leftHeadBorder,
                          classes.rightHeadBorder,
                          mouseOverIndexes.project === index ? classes.assignmentCellEmployee : ''
                        )}
                      >
                        {project?.project?.ref}
                      </GridItem>
                    </CustomTooltip>
                    <GridItem
                      className={cx(
                        classes.assignmentCell,
                        index === assignmentList.projects.length - 1 && classes.bottomHeadBorder,
                        getFieldColor(project.summary?.assignment_status),
                        classes.assignmentSmallWidth
                      )}
                    >
                      {project?.summary?.remaining_mds}
                    </GridItem>
                    <GridItem
                      className={cx(
                        classes.assignmentCell,
                        classes.rightHeadBorder,
                        index === assignmentList.projects.length - 1 && classes.bottomHeadBorder,
                        classes.assignmentSmallWidth
                      )}
                    >
                      {project?.summary?.assigned_mds}
                    </GridItem>
                  </GridContainer>
                ))}
              </GridContainer>

              {/* SCROLLABLE PART */}
              <GridContainer
                item
                xs={12}
                direction={'column'}
                wrap={'nowrap'}
                className={classes.scrollableContainer}
              >
                <GridContainer direction={'row'} wrap={'nowrap'}>
                  {assignmentList?.employees?.map((employee, index) => (
                    <CustomTooltip
                      key={index}
                      title={`${employee.employee?.name} - ${employee.employee?.email}`}
                    >
                      <GridItem
                        className={cx(
                          classes.assignmentCell,
                          classes.assignmentHeadCell,
                          mouseOverIndexes.employee === index ? classes.assignmentCellEmployee : ''
                        )}
                      >
                        {employee?.employee?.name || employee?.employee?.email}
                      </GridItem>
                    </CustomTooltip>
                  ))}
                </GridContainer>

                <GridContainer direction={'row'} wrap={'nowrap'}>
                  {assignmentList?.employees?.map((employee, index) => (
                    <GridItem
                      key={index}
                      className={cx(
                        classes.assignmentCell,
                        classes.assignmentCellBorderBottom,
                        index === assignmentList.employees.length - 1 && classes.rightHeadBorder,
                        getFieldColor(employee.summary?.assignment_status)
                      )}
                    >
                      {employee?.summary?.remaining_mds}
                    </GridItem>
                  ))}
                </GridContainer>

                <GridContainer direction={'row'} wrap={'nowrap'}>
                  {assignmentList?.employees?.map((employee, index) => (
                    <GridItem
                      key={index}
                      className={cx(
                        classes.assignmentCell,
                        classes.bottomHeadBorder,
                        index === assignmentList.employees.length - 1 && classes.rightHeadBorder
                      )}
                    >
                      {employee?.summary?.assigned_mds}
                    </GridItem>
                  ))}
                </GridContainer>

                {/* Table with assignments */}
                {assignmentList?.projects?.map((project, index) => (
                  <GridContainer key={index} direction={'row'} wrap={'nowrap'}>
                    {/* PART OF THE TABLE WITH ASSIGNED NUMBERS EMPLOYEE / PROJECT */}
                    {assignmentList?.assignments?.[index]?.map((assignment, assignmentIndex) => (
                      <GridItem
                        onMouseEnter={handleMouseOverIndexes({
                          project: index,
                          employee: assignmentIndex,
                        })}
                        key={assignmentIndex}
                        className={cx(
                          Number(assignment.assigned_mds) === 0
                            ? index % 2
                              ? classes.assignmentCellLight
                              : classes.assignmentCellDark
                            : classes.assignmentEmptyCell,

                          assignmentList.assignments[index].length - 1 === assignmentIndex &&
                            classes.rightHeadBorder,

                          assignmentList.projects.length - 1 === index && classes.bottomHeadBorder,
                          classes.assignmentActiveCell,

                          editableIndex ===
                            assignmentList.assignments[index].length * index + assignmentIndex
                            ? classes.assignmentEditableCell
                            : classes.assignmentCell,

                          mouseOverIndexes.project === index ? classes.assignmentCellHover : '',
                          mouseOverIndexes.employee === assignmentIndex
                            ? classes.assignmentCellHover
                            : ''
                        )}
                        onClick={handleInlineEdit(
                          assignmentList.assignments[index].length,
                          index,
                          assignmentIndex
                        )}
                      >
                        {editableIndex ===
                        assignmentList.assignments[index].length * index + assignmentIndex ? (
                          <input
                            autoFocus
                            autoComplete="off"
                            id={'assigned_mds'}
                            name={'assigned_mds'}
                            defaultValue={
                              assignment.assigned_mds !== 0 ? assignment.assigned_mds : 0.0
                            }
                            onKeyPress={(e) => handleSubmitAssignment(e, assignment)}
                            onBlur={() => setEditableIndex(null)}
                            // select all text in the field
                            onFocus={(e) =>
                              document
                                .getElementById('assigned_mds')
                                .setSelectionRange(0, e.target.value.length)
                            }
                          />
                        ) : (
                          assignment.assigned_mds !== 0 && assignment.assigned_mds
                        )}
                      </GridItem>
                    ))}
                  </GridContainer>
                ))}
              </GridContainer>
            </GridContainer>
          </>
        )}
      </GridContainer>
    </KapacitomatPage>
  )
}

AssignmentPageKap.propTypes = {
  classes: PropTypes.object,
  getKapacitomatAssignments: PropTypes.func,
  patchKapacitomatAssignment: PropTypes.func,
  periodId: PropTypes.string,
  assignmentList: PropTypes.object,
  assignmentsLoading: PropTypes.bool,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getKapacitomatAssignments,
      patchKapacitomatAssignment,
      updateGlobalSetting,
    },
    dispatch
  )
}

export default compose(
  withStyles(kapacitomatPageStyle),
  connect((store) => {
    return {
      periodId: store.kapacitomat.period.id,
      assignmentList: store.kapacitomat.assignments,
      assignmentsLoading: store.kapacitomat.assignmentsLoading,
    }
  }, mapDispatchToProps)
)(AssignmentPageKap)
