const kapacitomatPageStyle = (theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },

  paperMinimalHeight: {
    minHeight: 95,
  },

  tabs: {
    backgroundColor: theme.palette.white,
    color: theme.palette.primary.main,

    '& .MuiTabScrollButton-root:first-of-type': {
      borderRight: '0px solid ' + theme.palette.secondary.main,
    },
    '& .MuiTabScrollButton-root:last-of-type': {
      borderLeft: '0px solid ' + theme.palette.secondary.main,
    },
    '& .MuiTab-textColorInherit.Mui-selected': {
      fontWeight: 700,
      opacity: 1,
    },
    '& .MuiTab-textColorInherit': {
      opacity: 0.8,
      fontWeight: 600,
    },
    '& .MuiTab-root': {
      minWidth: '90px !important',
      padding: '6px 24px',
    },
  },
  borderBottomTabs: {
    '& .MuiTabs-flexContainer': {
      borderBottom: '0px solid ' + theme.palette.secondary.main,
    },
  },

  appBarBottomBorder: {
    borderBottom: '0px solid ' + theme.palette.secondary.main,
  },

  summaryBorderTop: {
    borderTop: '1px solid ' + theme.palette.border + ' !important',
    width: 'calc(100% + 32px)',
    marginLeft: '-16px',
  },

  attachmentIdRow: {
    display: 'flex',
    alignItems: 'center',
  },

  attachmentIdText: {
    width: 150,
    fontSize: 14,
  },
  defaultCheck: {
    cursor: 'default',
    pointerEvents: 'none',
    color: theme.palette.border,
    width: 26,
    height: 26,
  },
  attachmentCheck: {
    color: theme.palette.primary.main,
  },
  summaryText: {
    color: theme.palette.primary.main,
    fontWeight: 500,
  },

  createInvoiceDialogTab: {
    paddingTop: 20,
    width: 928,
    height: 355,
  },

  overflowAuto: {
    overflow: 'auto',
  },

  borderBoxSizing: {
    boxSizing: 'border-box',
  },

  projectCellClass: {
    height: 30,
    boxSizing: 'border-box',
    paddingTop: 0,
    paddingBottom: 0,
  },

  iconButtonHeight: {
    padding: 6,
  },

  kapacitomatCellClass: {
    height: 30,
    boxSizing: 'border-box',
    paddingTop: 0,
    paddingBottom: 0,
  },

  tooltipWidth: {
    maxWidth: 'fit-content',
    margin: '0 0 0 auto',
  },

  unprocessedMdsChip: {
    fontWeight: 500,
    minWidth: 70,
  },

  redChipColor: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.white,
  },

  greenChipColor: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.white,
  },

  assignmentsTable: {
    fontSize: 12,
    boxSizing: 'border-box',
  },

  buttonMargin: {
    '& button': {
      height: 30,
      marginLeft: 8,
    },
  },

  assignmentCell: {
    height: 24,
    maxHeight: 24,
    padding: 4,
    width: 103,
    minWidth: 103,
    boxSizing: 'border-box',
    borderRight: '1px solid ' + theme.palette.divider,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'default',
    transition: 'background-color 100ms',
    backgroundColor: theme.palette.white,
    textAlign: 'right',
  },

  assignmentCellBorderBottom: {
    borderBottom: '1px solid ' + theme.palette.divider,
  },

  assignmentEditableCell: {
    padding: 0,
    width: 103,
    minWidth: 103,
    boxSizing: 'border-box',
    borderRight: '1px solid ' + theme.palette.divider,
    borderBottom: '1px solid ' + theme.palette.divider,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    cursor: 'default',
    transition: 'background-color 100ms',
    backgroundColor: theme.palette.white,

    '& input': {
      boxSizing: 'border-box',
      width: '100%',
      textAlign: 'right',
      padding: 4,
      border: 0,
      fontFamily: 'Montserrat',
      fontSize: 12,

      '&:focus-visible': {
        outline: 'none',
      },
    },
  },

  assignmentSmallWidth: {
    width: 50,
    minWidth: 50,
    borderBottom: '1px solid ' + theme.palette.divider,
  },

  // static colors because originally there was opacity set on colors
  // but due to the design opacity is not possible
  assignmentCellGood: {
    backgroundColor: '#c0e3c1',
  },

  assignmentCellAlmostGood: {
    backgroundColor: '#ffdba6',
  },

  assignmentCellAlmostBad: {
    backgroundColor: '#fabd7f',
  },

  assignmentCellBad: {
    backgroundColor: '#f2b9b9',
  },

  leftHeadBorder: {
    borderLeft: '1px solid black',
    textAlign: 'left !important',
  },

  rightHeadBorder: {
    borderRight: '1px solid black',
  },

  bottomHeadBorder: {
    borderBottom: '1px solid black',
  },

  assignmentHeadCell: {
    borderTop: '1px solid black',
    borderBottom: '1px solid black',
    textAlign: 'left !important',

    '&:last-child': {
      borderRight: '1px solid black',
    },
  },

  assignmentEmptyCell: {
    backgroundColor: theme.palette.white + ' !important',
    transition: 'background-color 100ms',

    '&:hover': {
      backgroundColor: theme.palette.white + ' !important',
    },
  },

  assignmentCellLight: {
    backgroundColor: '#3F659Dbb',
    opacity: 0.8,
  },

  assignmentCellDark: {
    backgroundColor: '#3F659Dcc',
    opacity: 0.8,
  },

  assignmentCellHover: {
    opacity: 1,
  },

  assignmentCellEmployee: {
    boxShadow: 'inset 0 0 0 2px #3F659Dcc',
  },

  assignmentActiveCell: {
    '&:hover': {
      transition: 'background-color 100ms',
      backgroundColor: theme.palette.primary.main + '50',
      boxSizing: 'border-box',
      cursor: 'pointer',
    },
  },

  fullWidth: {
    width: '100%',
  },

  staticContainer: {
    position: 'absolute',
    zIndex: 120,
    maxWidth: 200,
  },
  scrollableContainer: {
    paddingLeft: 200,
    overflow: 'auto',
    position: 'relative',
    zIndex: 100,
  },

  syncButton: {
    marginLeft: 10,
    cursor: 'pointer',
  },

  syncButtonRotating: {
    animation: 'rotation 1.5s infinite linear',
  },
})

export default kapacitomatPageStyle
