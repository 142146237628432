import React from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import cx from 'classnames'
import loadingStyle from 'component/loading/loadingStyle'
import withStyles from '@material-ui/core/styles/withStyles'

const LoadingText = (props) => {
  const {text, classes} = props
  return (
    <div className={classes.container}>
      <Typography variant={'h6'} color="primary" align="center">
        {text}
      </Typography>

      <div>
        <h3 className={cx(classes.dot, classes.one)}>.</h3>
        <h3 className={cx(classes.dot, classes.two)}>.</h3>
        <h3 className={cx(classes.dot, classes.three)}>.</h3>
      </div>
    </div>
  )
}

LoadingText.propTypes = {
  text: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  classes: PropTypes.object,
}

export default withStyles(loadingStyle)(LoadingText)
