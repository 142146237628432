import React from 'react'
import {Trans} from '@lingui/macro'
import PropTypes from 'prop-types'
import GroupIcon from '@material-ui/icons/Group'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import TableIconButton from 'component/general/TableIconButton'

const getProjectTableColumns = (classes, handleOpenProjectAssignment) => {
  return [
    {
      name: 'ref',
      orderKey: 'ref',
      label: <Trans>Reference</Trans>,
    },
    {
      name: 'name',
      orderKey: 'name',
      label: <Trans>Name</Trans>,
    },
    {
      name: 'client',
      orderKey: 'client__name',
      label: <Trans>Client</Trans>,
      render: (val) => val?.name,
    },
    {
      name: '',
      align: 'right',
      render: (val, col, rowIndex, row) => (
        <GridContainer spacing={2} direction={'row'} wrap={'nowrap'} justifyContent={'flex-end'}>
          <GridItem>
            <TableIconButton
              tooltipTitle={<Trans>Display team and roles on project</Trans>}
              onClick={handleOpenProjectAssignment(row)}
              Icon={GroupIcon}
            />
          </GridItem>
        </GridContainer>
      ),
    },
  ]
}

getProjectTableColumns.propTypes = {
  classes: PropTypes.object,
  handleOpenProjectAssignment: PropTypes.func,
}

export default getProjectTableColumns
