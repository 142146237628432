import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import fieldStyle from 'component/field/fieldStyle'
import PropTypes from 'prop-types'
import {Trans} from '@lingui/macro'
import FormHelperText from '@material-ui/core/FormHelperText'
import InputAdornment from '@material-ui/core/InputAdornment'
import CircularProgress from '@material-ui/core/CircularProgress'
import cx from 'classnames'

const SelectInput = (props) => {
  const {
    classes,
    disabled,
    label,
    name,
    input,
    value,
    onChange,
    onBlur,
    meta,
    options,
    helperText,
    showHelperText = true,
    variant = 'outlined',
    loading,
    emptyOption = false,
    customError,
    size = 'small',
    formControlClassName,
    ...rest
  } = props

  const errorMessage = customError || (meta && meta.touched && (meta.error || meta.submitError))
  const errorHelperText = showHelperText && (errorMessage || helperText || ' ')
  return (
    <FormControl
      size={size}
      error={!!errorMessage}
      variant={variant}
      className={cx(classes.formControl, formControlClassName)}
    >
      {label && <InputLabel>{label}</InputLabel>}
      <Select
        {...input}
        {...rest}
        className={classes.inputFontSize}
        value={input ? input.value : value}
        onChange={(e) => {
          input?.onChange(e) //final-form's onChange
          if (onChange) {
            //props.onChange
            onChange(e)
          }
        }}
        onBlur={() => {
          onBlur && onBlur(input?.value, input?.name)
          input?.onBlur()
        }}
        name={name}
        label={label}
        disabled={disabled}
        endAdornment={
          loading && (
            <InputAdornment position="end" className={classes.loadingEndAdornment}>
              <CircularProgress color="secondary" />
            </InputAdornment>
          )
        }
      >
        {emptyOption && (
          <MenuItem value="" className={classes.selectMenuItem}>
            <em>
              <Trans>---</Trans>
            </em>
          </MenuItem>
        )}

        {options?.map((option, index) => (
          // if option is object with value and label, use that
          // otherwise use option as string
          <MenuItem
            key={index}
            className={classes.selectMenuItem}
            value={
              option.value ? option.value : option.id ? option.id : option.ref ? option.ref : option
            }
          >
            {option.label ? option.label : option.name ? option.name : option}
          </MenuItem>
        ))}

        {!loading && options?.length === 0 && (
          <MenuItem value={null} className={cx(classes.noDataText, classes.selectMenuItem)}>
            <em>
              <Trans>No data</Trans>
            </em>
          </MenuItem>
        )}
      </Select>
      {showHelperText && (
        <FormHelperText className={classes.formHelperText}>{errorHelperText}</FormHelperText>
      )}
    </FormControl>
  )
}

SelectInput.propTypes = {
  classes: PropTypes.object,
  label: PropTypes.object,
  input: PropTypes.object,
  name: PropTypes.string,
  meta: PropTypes.object,
  options: PropTypes.array,
  variant: PropTypes.string,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  showHelperText: PropTypes.bool,
  helperText: PropTypes.string,
  loading: PropTypes.bool,
}

export default withStyles(fieldStyle)(SelectInput)
