import React, {useEffect} from 'react'
import {useParams} from 'react-router-dom'
import PrivateContentLayout from 'layout/PrivateLayout/PrivateContentLayout'
import GridContainer from 'component/material/GridContainer'
import Box from '@material-ui/core/Box'
import {Add, Delete, Edit, Visibility} from '@material-ui/icons'
import {Trans} from '@lingui/macro'
import PrimaryButton from 'component/material/PrimaryButton'
import {bindActionCreators, compose} from 'redux'
import Table from 'component/material/table/Table'
import {mapErrorResponseToForm, redirectTo} from 'helper/functions'
import {useTableSort} from 'hooks/useTableSort'
import NabidkaForm from 'component/OdhadomatPage/Nabidky/NabidkaForm'
import {connect} from 'react-redux'
import {deleteQuote, getQuotes, patchQuote, postQuote} from 'redux/action/odhadomatAction'
import GridItem from 'component/material/GridItem'
import TableIconButton from 'component/general/TableIconButton'
import {diff} from 'deep-object-diff'
import {updateGlobalSetting} from 'redux/action/globalSettingAction'
import Breadcrumbs from 'component/general/Breadcrumbs'
import {usePagination} from 'hooks/usePagination'
import {useWarningDialog} from 'hooks/useWarningDialog'
import {useDialog} from 'hooks/useDialog'

const NabidkyPage = (props) => {
  const {
    deleteQuote,
    postQuote,
    getQuotes,
    quotes,
    quotesLoading,
    quotesMeta,
    patchQuote,
    rowsPerPage,
    updateGlobalSetting,
  } = props

  const {id} = useParams()

  const [Dialog, dialogData, onOpen, onClose] = useDialog()
  const [DeleteWarningDialog, deleteData, onOpenDelete, onCloseDelete] = useWarningDialog()
  const [tableSort, onSortClick] = useTableSort({
    orderBy: 'id',
    orderDirection: 'desc',
  })
  const [page, onChangePage, onChangeRowsPerPage] = usePagination(updateGlobalSetting)

  const handleSubmitQuote = (values) => {
    const data = {
      ...values,
      include_vat: !!values.include_vat,
      show_estimated_mds: !!values.show_estimated_mds,
      target_estimated_mds: values?.target_estimated_mds && Number(values.target_estimated_mds),
      target_price_from: values?.target_price_from && Number(values.target_price_from) * 100,
      target_price_to: values?.target_price_to && Number(values.target_price_to) * 100,
    }

    if (dialogData?.id) {
      let patchData = diff(dialogData, data)
      return patchQuote(id, dialogData.id, patchData)
        .then(() => {
          onClose()
          fetchQuotes()
        })
        .catch((err) => mapErrorResponseToForm(err))
    } else {
      return postQuote(id, data)
        .then(() => {
          onClose()
          fetchQuotes()
        })
        .catch((err) => mapErrorResponseToForm(err))
    }
  }

  const handleDeletePhase = () => {
    if (deleteData?.id) {
      deleteQuote(id, deleteData.id).then(() => {
        onCloseDelete()
        fetchQuotes()
      })
    }
  }

  const columns = [
    {
      name: 'id',
      orderKey: 'id',
      label: <Trans>Id</Trans>,
    },
    {
      name: 'title',
      orderKey: 'title',
      label: <Trans>Title</Trans>,
    },
    {
      name: 'price_policy',
      orderKey: 'price_policy',
      label: <Trans>Price policy</Trans>,
    },
    {
      name: '',
      align: 'right',
      render: (val, col, rowIndex, row) => (
        <GridContainer spacing={2} direction={'row'} wrap={'nowrap'} justifyContent={'flex-end'}>
          <GridItem>
            <TableIconButton
              tooltipTitle={<Trans>Open quote detail</Trans>}
              onClick={() => redirectTo(`/odhadomat/odhady/${id}/nabidky/${row.id}`)}
              Icon={Visibility}
            />
          </GridItem>
          <GridItem>
            <TableIconButton
              tooltipTitle={<Trans>Edit quote</Trans>}
              onClick={onOpen(row)}
              Icon={Edit}
            />
          </GridItem>
          <GridItem>
            <TableIconButton
              tooltipTitle={<Trans>Delete quote</Trans>}
              onClick={onOpenDelete(row)}
              Icon={Delete}
            />
          </GridItem>
        </GridContainer>
      ),
    },
  ]

  const fetchQuotes = () => {
    getQuotes(id, rowsPerPage, page * rowsPerPage, tableSort)
  }

  useEffect(() => {
    fetchQuotes()
  }, [tableSort, rowsPerPage, page])

  return (
    <>
      {/* NEW/EDIT DIALOG */}
      <Dialog title={dialogData ? <Trans>Edit quote</Trans> : <Trans>New quote</Trans>}>
        <NabidkaForm handleSubmit={handleSubmitQuote} row={dialogData} />
      </Dialog>

      {/* DELETE DIALOG */}
      <DeleteWarningDialog
        onConfirm={handleDeletePhase}
        message={<Trans>Quote {deleteData?.title} will be deleted!</Trans>}
      />

      <PrivateContentLayout
        title={
          <Breadcrumbs
            breadcrumbs={[
              {title: <Trans>Estimations</Trans>, url: '/odhadomat/odhady'},
              {title: <Trans>Quotes</Trans>},
            ]}
          />
        }
        actions={
          <PrimaryButton
            fullWidth={false}
            text={
              <GridContainer alignItems={'center'}>
                <Box pr={2}>
                  <Add fontSize={'small'} />
                </Box>
                <Trans>Create</Trans>
              </GridContainer>
            }
            onClick={onOpen(null)}
          />
        }
        content={
          <Table
            columns={columns}
            data={quotes}
            loading={quotesLoading}
            orderBy={tableSort.orderBy}
            orderDirection={tableSort.orderDirection}
            onSortClick={onSortClick}
            count={quotesMeta.total_count}
            page={page}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            pagination={true}
          />
        }
      />
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      deleteQuote,
      postQuote,
      getQuotes,
      patchQuote,
      updateGlobalSetting,
    },
    dispatch
  )
}

export default compose(
  connect((store) => {
    return {
      quotes: store.odhadomat.quotes,
      quotesLoading: store.odhadomat.quotesLoading,
      quotesMeta: store.odhadomat.quotesMeta,
      rowsPerPage: store.globalSetting.rowsPerPage,
    }
  }, mapDispatchToProps)
)(NabidkyPage)
