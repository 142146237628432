import React, {useEffect} from 'react'
import {Trans} from '@lingui/macro'
import PageTitleWrapper from 'layout/PrivateLayout/PageTitleWrapper'
import DashboardGridItemTablePie from 'component/DashboardPage/DashboardGridItemTablePie'
import GridContainer from 'component/material/GridContainer'
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar'
import moment from 'moment'
import {bindActionCreators, compose} from 'redux'
import {
  getDashboardAssignmentsCurrent,
  getDashboardAssignmentsNext,
} from 'redux/action/dashboardAction'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {getDashboardAssignments} from 'redux/action/projektomatAction'
import DashboardAssignments from 'component/DashboardPage/DashboardAssignments'

const DashboardPage = (props) => {
  const {
    getDashboardAssignmentsCurrent,
    getDashboardAssignmentsNext,
    current,
    currentLoading,
    next,
    nextLoading,
    currentSummary,
    nextSummary,
  } = props

  const getUtilizationTitle = (month) => {
    let monthText = moment().format('MMMM')
    if (month === 'next') monthText = moment().add(1, 'month').format('MMMM')

    return (
      <>
        <Trans>Monthly utilization</Trans> - {monthText}
      </>
    )
  }

  const dashboardConfig = [
    {
      title: getUtilizationTitle('current'),
      icon: <PermContactCalendarIcon />,
      data: current,
      summary: currentSummary,
      type: 'table-pie',
      loading: currentLoading,
    },
    {
      title: getUtilizationTitle('next'),
      icon: <PermContactCalendarIcon />,
      data: next,
      summary: nextSummary,
      type: 'table-pie',
      loading: nextLoading,
    },
  ]

  const getPeriod = (next) => {
    let year = moment().month() === 11 && next ? moment().add(1, 'year').year() : moment().year()
    let month = next ? moment().add(1, 'month').month() + 1 : moment().month() + 1
    month = month < 10 ? `0${month}` : month

    return `${year}-M-${month}`
  }

  useEffect(() => {
    getDashboardAssignmentsCurrent(getPeriod(false))
    getDashboardAssignmentsNext(getPeriod(true))
  }, [])

  return (
    <PageTitleWrapper title={<Trans>Dashboard</Trans>}>
      <GridContainer spacing={4}>
        {dashboardConfig.map((item, index) => {
          if (item.data?.length !== 0 && item.type === 'table-pie') {
            return (
              <DashboardGridItemTablePie
                key={index}
                loading={item.loading}
                icon={item.icon}
                title={item.title}
                data={item.data}
                summary={item.summary}
              />
            )
          }
        })}

        <DashboardAssignments />
      </GridContainer>
    </PageTitleWrapper>
  )
}

DashboardPage.propTypes = {
  getDashboardAssignmentsCurrent: PropTypes.func,
  getDashboardAssignmentsNext: PropTypes.func,
  current: PropTypes.array,
  currentLoading: PropTypes.bool,
  next: PropTypes.array,
  nextLoading: PropTypes.bool,
  currentSummary: PropTypes.object,
  nextSummary: PropTypes.object,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getDashboardAssignmentsCurrent,
      getDashboardAssignmentsNext,
      getDashboardAssignments,
    },
    dispatch
  )
}

export default compose(
  connect((store) => {
    return {
      current: store.dashboard.current,
      currentLoading: store.dashboard.currentLoading,
      currentSummary: store.dashboard.currentSummary,
      next: store.dashboard.next,
      nextLoading: store.dashboard.nextLoading,
      nextSummary: store.dashboard.nextSummary,
    }
  }, mapDispatchToProps)
)(DashboardPage)
