import React from 'react'
import Table from 'component/material/table/Table'
import {Trans} from '@lingui/macro'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'

const PotentialTableStrick = (props) => {
  const {classes, data, loading} = props

  const getRowColorClass = (surplusPercentage) => {
    if (surplusPercentage > 10) return classes.greenStrickLandRow
    if (surplusPercentage < -10) return classes.redStrickLandRow
  }

  const columns = [
    {
      name: 'project_ref',
      label: <Trans>Project</Trans>,
      render: (val, col, rowIndex, row) => (
        <span className={getRowColorClass(row.surplus_percentage)}>{val}</span>
      ),
    },
    {
      name: 'reported_mds',
      align: 'right',
      label: <Trans>Reported MD</Trans>,
      render: (val, col, rowIndex, row) => (
        <span className={getRowColorClass(row.surplus_percentage)}>{Number(val).toFixed(2)}</span>
      ),
    },
    {
      name: 'distributed_revenue',
      align: 'right',
      label: <Trans>Really invoiced</Trans>,
      render: (val, col, rowIndex, row) => (
        <span className={getRowColorClass(row.surplus_percentage)}>
          <NumberFormat
            thousandSeparator={' '}
            displayType="text"
            suffix={' Kč'}
            value={val && Number(val / 100).toFixed(2)}
          />
        </span>
      ),
    },
    {
      name: 'potential_revenue',
      align: 'right',
      label: <Trans>Potentially invoiced</Trans>,
      render: (val, col, rowIndex, row) => (
        <span className={getRowColorClass(row.surplus_percentage)}>
          <NumberFormat
            thousandSeparator={' '}
            displayType="text"
            suffix={' Kč'}
            value={val && Number(val / 100).toFixed(2)}
          />
        </span>
      ),
    },
    {
      name: 'distributed_amount_per_md',
      align: 'right',
      label: <Trans>Real CZK/MD</Trans>,
      render: (val, col, rowIndex, row) => (
        <span className={getRowColorClass(row.surplus_percentage)}>
          <NumberFormat
            thousandSeparator={' '}
            displayType="text"
            suffix={' Kč'}
            value={val && Number(val / 100).toFixed(2)}
          />
        </span>
      ),
    },
    {
      name: 'potential_amount_per_md',
      align: 'right',
      label: <Trans>Potential CZK/MD</Trans>,
      render: (val, col, rowIndex, row) => (
        <span className={getRowColorClass(row.surplus_percentage)}>
          <NumberFormat
            thousandSeparator={' '}
            displayType="text"
            suffix={' Kč'}
            value={val && Number(val / 100).toFixed(2)}
          />
        </span>
      ),
    },
    {
      name: 'surplus_revenue',
      align: 'right',
      label: <Trans>Surplus (CZK)</Trans>,
      render: (val, col, rowIndex, row) => (
        <span className={getRowColorClass(row.surplus_percentage)}>
          <NumberFormat
            thousandSeparator={' '}
            displayType="text"
            suffix={' Kč'}
            value={val && Number(val / 100).toFixed(2)}
          />
        </span>
      ),
    },
    {
      name: 'surplus_percentage',
      align: 'right',
      label: <Trans>Surplus (%)</Trans>,
      render: (val, col, rowIndex, row) => (
        <span className={getRowColorClass(row.surplus_percentage)}>
          <NumberFormat
            thousandSeparator={' '}
            displayType="text"
            suffix={' %'}
            value={val && Number(val).toFixed(2)}
          />
        </span>
      ),
    },
  ]

  return <Table columns={columns} data={data} loading={loading} />
}

PotentialTableStrick.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  classes: PropTypes.object,
}

export default PotentialTableStrick
