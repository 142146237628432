import React from 'react'
import {Form, Field} from 'react-final-form'
import {Trans} from '@lingui/macro'
import PrimaryButton from 'component/material/PrimaryButton'
import PropTypes from 'prop-types'
import {required} from 'helper/validations'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import TextInput from 'component/field/TextInput'
import {STORY_LAYERS} from 'helper/constants'
import MultiSelectInput from 'component/field/MultiSelectInput'
import Box from '@material-ui/core/Box'
import SelectInput from 'component/field/SelectInput'

const StoryForm = (props) => {
  const {handleSubmit, initValues, estimation, estimationLoading} = props

  const prepareOptionsForMultiSelect = () => {
    const options = {}
    STORY_LAYERS.forEach((item, index) => {
      options[index] = item
    })
    return options
  }

  return (
    <Form onSubmit={handleSubmit} initialValues={initValues}>
      {(formProps) => {
        const {submitting} = formProps
        return (
          <form onSubmit={formProps.handleSubmit}>
            <GridContainer spacing={2}>
              <GridItem xs={12} sm={6}>
                <Field
                  name="title"
                  label={<Trans>Title</Trans>}
                  component={TextInput}
                  validate={required}
                  disabled={submitting}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <Field
                  name="category"
                  label={<Trans>Category</Trans>}
                  component={SelectInput}
                  options={estimation?.all_story_categories}
                  loading={estimationLoading}
                  validate={required}
                  disabled={submitting}
                />
              </GridItem>
              <GridItem xs={12}>
                <Field
                  name="layers"
                  label={<Trans>Layers</Trans>}
                  options={prepareOptionsForMultiSelect()}
                  component={MultiSelectInput}
                  validate={required}
                  disabled={submitting}
                  size={'small'}
                />
              </GridItem>
            </GridContainer>
            <GridContainer justifyContent={'flex-end'}>
              <Box pt={3}>
                <PrimaryButton
                  fullWidth={false}
                  disabled={submitting}
                  type="submit"
                  text={<Trans>Save</Trans>}
                />
              </Box>
            </GridContainer>
          </form>
        )
      }}
    </Form>
  )
}

StoryForm.propTypes = {
  handleSubmit: PropTypes.func,
  initValues: PropTypes.object,
}

export default StoryForm
