import {ENVIRONMENT} from 'helper/constants'
import {createStore, applyMiddleware, compose} from 'redux'
import thunk from 'redux-thunk'
import rootReducer from 'redux/index'

const composeEnhancers =
  ENVIRONMENT !== 'production' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)))
export default store
