import {useState} from 'react'

export const usePagination = (updateGlobalSetting, initPage = 0) => {
  const [page, setPage] = useState(initPage)

  const onChangePage = (e, newPage) => {
    setPage(newPage)
  }

  const onChangeRowsPerPage = (e) => {
    setPage(0)
    updateGlobalSetting({rowsPerPage: e.target.value})
  }

  return [page, onChangePage, onChangeRowsPerPage]
}
