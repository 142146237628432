import React, {useEffect} from 'react'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import {Typography, withStyles} from '@material-ui/core'
import {Trans} from '@lingui/macro'
import {redirectTo} from 'helper/functions'
import PaperFlexGrow from 'component/material/PaperFlexGrow'
import BoxFullWidth from 'component/material/BoxFullWidth'
import vykazomatPageStyle from 'component/VykazomatPage/vykazomatPageStyle'
import BackText from 'component/general/BackText'

// VYKAZOMAT VYKAZ STRANKA
const VykazomatResultPage = (props) => {
  const {reportUrl, classes} = props

  useEffect(() => {
    if (!reportUrl) {
      redirectTo('/vykazomat')
    }
  }, [])

  return (
    <GridContainer alignItems="center" wrap="nowrap" direction="column" flexGrow={true}>
      <GridItem
        container
        xs={12}
        alignItems={'flex-start'}
        justifyContent={'space-between'}
        className={classes.flexBasisZero}
      >
        <Typography variant={'h6'} color="primary" align="center">
          <Trans>Výkazomat</Trans>
        </Typography>
        <BackText path={'/vykazomat'} />
      </GridItem>
      <GridContainer direction="row" justifyContent="center" alignItems="center" flexGrow={true}>
        <GridItem container xs={12} sm={10} md={8} lg={6} xl={4}>
          <PaperFlexGrow elevation={5}>
            <BoxFullWidth p={14}>
              <Typography variant={'h6'} color="primary" align="center">
                <Trans>Report is ready</Trans>{' '}
                <a href={reportUrl} target="_blank" className={classes.link}>
                  <Trans>here</Trans>
                </a>
                .
              </Typography>
            </BoxFullWidth>
          </PaperFlexGrow>
        </GridItem>
      </GridContainer>
    </GridContainer>
  )
}

VykazomatResultPage.propTypes = {
  reportUrl: PropTypes.string,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch)
}

export default compose(
  withStyles(vykazomatPageStyle),
  connect((store) => {
    return {
      reportUrl: store.vykazomat.reportUrl,
    }
  }, mapDispatchToProps)
)(VykazomatResultPage)
