import {UPDATE_FILTERS} from 'redux/actionType'

const initState = {
  issuedDocument: {
    filter: null,
    orderDirection: 'desc',
    orderBy: 'define_date',
  },
  acceptedDocument: {
    filter: null,
    orderDirection: 'desc',
    orderBy: 'define_date',
  },
  indirectExpensesDocument: {
    filter: null,
    orderDirection: 'desc',
    orderBy: 'define_date',
  },
  workReportsDocument: {
    filter: null,
    orderDirection: 'desc',
    orderBy: 'date_from',
  },
}

const filterReducer = (state = initState, action) => {
  if (action.type === UPDATE_FILTERS) {
    return {
      ...state,
      ...action.data,
    }
  }
  return state
}

export default filterReducer
