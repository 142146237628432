import React from 'react'
import {Router, Route, Switch, Redirect} from 'react-router-dom'
import browserHistory from 'helper/history'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import LoginPage from 'page/LoginPage'
import PublicLayout from 'layout/PublicLayout/PublicLayout'
import {I18nProvider} from '@lingui/react'
import catalogEn from 'App/locale/en/messages'
import catalogCs from 'App/locale/cs/messages'
import {connect} from 'react-redux'
import {compose} from 'redux'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import toastStyle from 'component/toast/toastStyle'
import VykazomatPage from 'page/VykazomatPage'
import VykazomatResultPage from 'component/VykazomatPage/VykazomatResultPage'
import moment from 'moment'
import 'moment/locale/cs'
import LoginCallbackPage from 'page/LoginCallbackPage'
import PrivateRoute from 'App/PrivateRoute'
import ProjectPage from 'page/ProjectPage'
import EmployeePage from 'page/EmployeePage'
import ProjectPageKap from 'component/KapacitomatPage/ProjectPageKap'
import EmployeePageKap from 'component/KapacitomatPage/EmployeePageKap'
import AssignmentPageKap from 'component/KapacitomatPage/AssignmentPageKap'
import DocumentPage from 'page/DocumentPage'
import ManagementUserPage from 'component/ManagementPage/ManagementUserPage'
import ManagementRolePage from 'component/ManagementPage/ManagementRolePage'
import DashboardPage from 'page/DashboardPage'
import {en, cs} from 'make-plural'
import OdhadyPage from 'page/OdhadyPage'
import OdhadDetail from 'component/OdhadomatPage/OdhadDetail/OdhadDetail'
import i18nProvider from 'helper/i18n'
import NabidkyPage from 'page/NabidkyPage'
import SchedulePage from 'page/SchedulePage'
import NabidkaDetail from 'component/OdhadomatPage/Nabidky/NabidkaDetail'
import PiggyPageStrick from 'component/Strickland/Piggy/PiggyPageStrick'
import ProjectPageStrick from 'component/Strickland/Project/ProjectPageStrick'
import EmployeePageStrick from 'component/Strickland/Employee/EmployeePageStrick'
import PotentialStrick from 'component/Strickland/Potential/PotentialStrick'

function App(props) {
  const {currentLanguage, classes} = props

  const activeLang = currentLanguage ? currentLanguage : 'cs'
  moment.locale(activeLang)

  i18nProvider.load({
    en: catalogEn.messages,
    cs: catalogCs.messages,
  })

  i18nProvider.loadLocaleData('en', {plurals: en})
  i18nProvider.loadLocaleData('cs', {plurals: cs})

  i18nProvider.activate(activeLang)

  return (
    <I18nProvider i18n={i18nProvider}>
      <ToastContainer
        position="top-right"
        closeOnClick
        pauseOnHover
        pauseOnFocusLoss
        draggable
        newestOnTop
        closeButton={false}
        autoClose={2500}
        className={classes.containerStyle}
      />
      <Router history={browserHistory}>
        <Switch>
          {/* PUBLIC ROUTES */}
          <Route exact path="/login">
            <PublicLayout>
              <LoginPage />
            </PublicLayout>
          </Route>
          <Route path="/login/callback">
            <PublicLayout>
              <LoginCallbackPage />
            </PublicLayout>
          </Route>

          {/* PRIVATE ROUTES */}
          <PrivateRoute exact path={'/dashboard'} component={<DashboardPage />} />

          {/* ODHADOMAT */}
          <PrivateRoute exact path={'/odhadomat/odhady'} component={<OdhadyPage />} />
          <PrivateRoute exact path={'/odhadomat/odhady/:id'} component={<OdhadDetail />} />
          <PrivateRoute exact path={'/odhadomat/odhady/:id/nabidky'} component={<NabidkyPage />} />
          <PrivateRoute
            exact
            path={'/odhadomat/odhady/:id/nabidky/:nabidkaId'}
            component={<NabidkaDetail />}
          />
          <PrivateRoute
            exact
            path={'/odhadomat/odhady/:id/harmonogram'}
            component={<SchedulePage />}
          />

          {/* VYKAZOMAT */}
          <PrivateRoute exact path={'/vykazomat'} component={<VykazomatPage />} />
          <PrivateRoute exact path={'/vykazomat/vykaz'} component={<VykazomatResultPage />} />

          {/* PROJEKTOMAT */}
          <PrivateRoute exact path={'/projektomat/projekty'} component={<ProjectPage />} />
          <PrivateRoute exact path={'/projektomat/zamestnanci'} component={<EmployeePage />} />
          <PrivateRoute exact path={'/projektomat/dokumenty'} component={<DocumentPage />} />

          {/* KAPACITOMAT */}
          <PrivateRoute exact path={'/kapacitomat/projekty'} component={<ProjectPageKap />} />
          <PrivateRoute exact path={'/kapacitomat/zamestnanci'} component={<EmployeePageKap />} />
          <PrivateRoute exact path={'/kapacitomat/zakazky'} component={<AssignmentPageKap />} />

          {/* STRICKLAND */}
          <PrivateRoute exact path={'/strickland/projekty'} component={<ProjectPageStrick />} />
          <PrivateRoute exact path={'/strickland/zamestnanci'} component={<EmployeePageStrick />} />
          <PrivateRoute exact path={'/strickland/potencial'} component={<PotentialStrick />} />
          <PrivateRoute exact path={'/strickland/prasatko'} component={<PiggyPageStrick />} />

          {/* MANAGEMENT */}
          <PrivateRoute exact path={'/management/uzivatele'} component={<ManagementUserPage />} />
          <PrivateRoute exact path={'/management/role'} component={<ManagementRolePage />} />

          <Redirect to={'/login'} />
        </Switch>
      </Router>
    </I18nProvider>
  )
}

App.propTypes = {
  currentLanguage: PropTypes.string,
  classes: PropTypes.object,
}

export default compose(
  withStyles(toastStyle),
  connect((store) => {
    return {
      currentLanguage: store.globalSetting.currentLanguage,
    }
  })
)(App)
