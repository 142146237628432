import React from 'react'
import {KeyboardDatePicker} from '@material-ui/pickers'
import fieldStyle from 'component/field/fieldStyle'
import withStyles from '@material-ui/core/styles/withStyles'
import {DATE_FORMAT} from 'helper/constants'
import {Trans} from '@lingui/macro'
import PropTypes from 'prop-types'
import cx from 'classnames'

function DatePickerInput(props) {
  const {
    classes,
    helperText,
    input,
    meta,
    label,
    variant = 'outlined',
    showHelperText = true,
    size = 'small',
    onChange,
    customError,
    ...rest
  } = props

  const errorMessage = customError || (meta && meta.touched && (meta.error || meta.submitError))
  const errorHelperText = showHelperText && (errorMessage || helperText || ' ')

  return (
    <div
      className={cx(
        classes.datePickerInput,
        size === 'small' ? classes.datePickerInputIconSmall : '',
        classes.inputFormHelperText
      )}
    >
      <KeyboardDatePicker
        inputVariant={variant}
        autoOk
        format={DATE_FORMAT}
        label={label}
        {...input}
        value={input.value ? input.value : null}
        error={!!errorMessage}
        helperText={errorHelperText}
        invalidDateMessage={<Trans>Invalid Date Format</Trans>}
        maxDateMessage={<Trans>Date should not be after maximal date</Trans>}
        minDateMessage={<Trans>Date should not be before minimal date</Trans>}
        cancelLabel={<Trans>Close</Trans>}
        okLabel={<Trans>Ok</Trans>}
        size={size}
        onChange={(e) => {
          input?.onChange(e) //final-form's onChange
          if (onChange) {
            //props.onChange
            onChange(e)
          }
        }}
        {...rest}
      />
    </div>
  )
}

DatePickerInput.propTypes = {
  classes: PropTypes.object,
  label: PropTypes.object,
  input: PropTypes.object,
  meta: PropTypes.object,
  variant: PropTypes.string,
  showHelperText: PropTypes.bool,
  helperText: PropTypes.string,
  onChange: PropTypes.func,
}

export default withStyles(fieldStyle)(DatePickerInput)
