import CustomTooltip from 'component/material/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import React from 'react'

const TableIconButton = (props) => {
  const {
    tooltipTitle,
    onClick,
    buttonSize = 'small',
    iconSize = 'medium',
    color = 'primary',
    Icon,
  } = props

  return tooltipTitle ? (
    <CustomTooltip title={tooltipTitle}>
      <IconButton size={buttonSize} color={color} onClick={onClick}>
        <Icon fontSize={iconSize} />
      </IconButton>
    </CustomTooltip>
  ) : (
    <IconButton color={color} onClick={onClick}>
      <Icon />
    </IconButton>
  )
}

export default TableIconButton
