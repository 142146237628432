import React from 'react'
import Button from '@material-ui/core/Button'
import {Trans} from '@lingui/macro'
import google from 'asset/img/google.png'
import withStyles from '@material-ui/core/styles/withStyles'
import publicLayoutStyle from 'layout/PublicLayout/publicLayoutStyle'
import PropTypes from 'prop-types'
import Loader from 'component/material/Loader'

const LoginButton = (props) => {
  const {classes, onClick, loading} = props

  return (
    <Button
      onClick={onClick}
      color={'primary'}
      fullWidth={true}
      variant={'contained'}
      size={'large'}
      classes={{root: classes.googleLoginButton}}
    >
      <div className={classes.googleLogin}>
        <div className={classes.googleLoginLogo}>
          {loading ? (
            <Loader className={classes.googleLoginLoading} />
          ) : (
            <img src={google} alt={'G'} />
          )}
        </div>
        <div className={classes.googleLoginText}>
          <Trans>Sign in with Google</Trans>
        </div>
      </div>
    </Button>
  )
}

LoginButton.propTypes = {
  classes: PropTypes.object,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
}

export default withStyles(publicLayoutStyle)(LoginButton)
