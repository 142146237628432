import React, {useEffect, useState} from 'react'
import Table from 'component/material/table/Table'
import {Trans} from '@lingui/macro'
import PropTypes from 'prop-types'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import {Edit} from '@material-ui/icons'
import ObligatedMdsForm from 'component/KapacitomatPage/ObligatedMdsForm'
import {patchKapacitomatProject} from 'redux/action/kapacitomatAction'
import {fireSuccessToast} from 'helper/functions'
import TableIconButton from 'component/general/TableIconButton'
import {useDialog} from 'hooks/useDialog'

const ProjectTableKap = (props) => {
  const {
    data,
    loading,
    periodId,
    fetchProjects,
    pagination,
    patchKapacitomatProject,
    onSortClick,
    orderBy,
    orderDirection,
    projectList,
  } = props

  const [Dialog, dialogData, onOpen, onClose] = useDialog()
  const [tableSelectedRows, setTableSelectedRows] = useState([])

  const handleSetTableSelectedRows = (idArray) => {
    setTableSelectedRows(idArray)
  }

  const handleSelectRow = (row) => () => {
    setTableSelectedRows((prevState) =>
      prevState.includes(row.id) ? prevState.filter((id) => id !== row.id) : [...prevState, row.id]
    )
    return patchKapacitomatProject(row.id, {is_active: !row.is_active}).then(() => {
      fireSuccessToast(<Trans>Project updated.</Trans>)
    })
  }

  const columns = [
    {
      name: 'project',
      label: <Trans>Reference</Trans>,
      render: (val) => val.ref,
    },
    {
      name: 'project',
      label: <Trans>Name</Trans>,
      render: (val) => val.name,
    },
    {
      name: 'billable',
      orderKey: 'billable',
      align: 'right',
      label: <Trans>Billable</Trans>,
      render: (val, col, rowIndex, row) =>
        data.length - 1 !== rowIndex
          ? row?.project?.is_billable && row?.obliged_mds + ' MD'
          : val + ' MD',
    },
    {
      name: 'not_billable',
      orderKey: 'not_billable',
      align: 'right',
      label: <Trans>Not billable</Trans>,
      render: (val, col, rowIndex, row) =>
        data.length - 1 !== rowIndex
          ? !row?.project?.is_billable && row?.obliged_mds + ' MD'
          : val + ' MD',
    },
    {
      name: '',
      align: 'right',
      width: 'fit-content',
      render: (val, col, rowIndex, row) =>
        data.length - 1 !== rowIndex && (
          <TableIconButton
            tooltipTitle={<Trans>Edit agreed MD</Trans>}
            onClick={onOpen(row)}
            Icon={Edit}
          />
        ),
    },
  ]

  const prepareDataIgnoreMds = (data) => {
    return {
      obliged_mds: data?.obliged_mds && Number(data.obliged_mds.replaceAll(',', '.')),
      period_id: periodId,
    }
  }
  const handleSubmitObligatedMds = (data) => {
    const preparedData = prepareDataIgnoreMds(data)
    return patchKapacitomatProject(dialogData?.id, preparedData).then(() => {
      fireSuccessToast(<Trans>Project agreed MDs updated.</Trans>)
      fetchProjects()
      onClose()
    })
  }

  useEffect(() => {
    setTableSelectedRows(
      projectList.filter((project) => project.is_active).map((project) => project.id)
    )
  }, [projectList])

  return (
    <>
      {/* OBLIGATED MDS DIALOG */}
      <Dialog
        title={
          <>
            {dialogData?.project?.is_billable ? (
              <Trans>Billable</Trans>
            ) : (
              <Trans>Not billable</Trans>
            )}
            {' - '}
            {dialogData?.project?.ref}
          </>
        }
        maxWidth={'xs'}
      >
        <ObligatedMdsForm data={dialogData} handleSubmit={handleSubmitObligatedMds} />
      </Dialog>

      <Table
        columns={columns}
        data={data}
        loading={loading}
        orderDirection={orderDirection}
        orderBy={orderBy}
        onSortClick={onSortClick}
        pagination={pagination}
        lastRowBold={true}
        displayCheckbox={true}
        handleSetTableSelectedRows={handleSetTableSelectedRows}
        tableSelectedRows={tableSelectedRows}
        handleSelectRow={handleSelectRow}
        checkBoxTitle={<Trans>Active</Trans>}
      />
    </>
  )
}

ProjectTableKap.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  patchKapacitomatProject: PropTypes.func,
  fetchProjects: PropTypes.func,
  periodId: PropTypes.string,
  projectList: PropTypes.array,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      patchKapacitomatProject,
    },
    dispatch
  )
}

export default compose(
  connect((store) => {
    return {
      periodId: store.kapacitomat.period.id,
    }
  }, mapDispatchToProps)
)(ProjectTableKap)
