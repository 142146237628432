import Paper from '@material-ui/core/Paper'
import React from 'react'
import materialStyle from 'component/material/materialStyle'
import withStyles from '@material-ui/core/styles/withStyles'

const PaperFlexGrow = (props) => {
  const {classes, ...rest} = props

  return <Paper {...rest} className={classes.flexGrow} />
}

export default withStyles(materialStyle)(PaperFlexGrow)
