import {UPDATE_GLOBAL_SETTING} from 'redux/actionType'
import {getItemFromStorage, setItemToStorage} from 'helper/functions'
import {LS_GLOBAL_SETTINGS} from 'helper/configConstants'

const initState = getItemFromStorage(LS_GLOBAL_SETTINGS, {
  rowsPerPage: 20,
  rowsPerPageOptions: [5, 10, 20, 50],
  currentLanguage: 'cs',
})

const globalSettingReducer = (state = initState, action = null) => {
  if (action.type === UPDATE_GLOBAL_SETTING) {
    const settings = {
      ...state,
      ...action.data,
    }

    setItemToStorage(LS_GLOBAL_SETTINGS, settings)
    return settings
  }

  return state
}

export default globalSettingReducer
