import React from 'react'
import {Info, CheckCircle, Warning, Error} from '@material-ui/icons'
import withStyles from '@material-ui/core/styles/withStyles'
import toastStyle from 'component/toast/toastStyle'
import PropTypes from 'prop-types'

const Toast = (props) => {
  const {message, type, classes} = props

  const switchIcons = (type) => {
    switch (type) {
      case 'success':
        return <CheckCircle className={classes.toastIcon} />
      case 'warning':
        return <Warning className={classes.toastIcon} />
      case 'info':
        return <Info className={classes.toastIcon} />
      case 'error':
        return <Error className={classes.toastIcon} />
      default:
    }
    return true
  }

  return (
    <div className={classes.toast}>
      {switchIcons(type)}
      <strong>{message}</strong>
    </div>
  )
}

Toast.propTypes = {
  message: PropTypes.node,
  type: PropTypes.string,
  classes: PropTypes.object,
}

export default withStyles(toastStyle)(Toast)
