import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import PropTypes from 'prop-types'
import materialStyle from 'component/material/materialStyle'
import Grid from '@material-ui/core/Grid'
import cx from 'classnames'

const GridItem = (props) => {
  const {children, classes, className, ...rest} = props
  return (
    <Grid item {...rest} className={cx(className)}>
      {children}
    </Grid>
  )
}

GridItem.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  gridWidth: PropTypes.bool,
}

export default withStyles(materialStyle)(GridItem)
