import React, {useEffect, useRef, useState} from 'react'
import {useParams} from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  deleteStory,
  getStories,
  patchStory,
  patchStoryLayer,
  postStory,
} from 'redux/action/odhadomatAction'
import {bindActionCreators, compose} from 'redux'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  MenuItem,
  Paper,
  TextField,
  Typography,
  withStyles,
  Select,
} from '@material-ui/core'
import odhadyStyle from 'component/OdhadomatPage/odhadyStyle'
import {connect} from 'react-redux'
import Loader from 'component/material/Loader'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import {Trans} from '@lingui/macro'
import {Cancel, Edit, ExpandMore, Save, Delete} from '@material-ui/icons'
import CustomTooltip from 'component/material/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import DialogWindow from 'component/material/DialogWindow'
import StoryForm from 'component/OdhadomatPage/OdhadDetail/StoryForm'
import {STORY_LAYERS} from 'helper/constants'
import cx from 'classnames'
import {
  fireSuccessToast,
  mapErrorResponseToForm,
  renderAmountWithCurrency,
  showErrorToastFromObject,
} from 'helper/functions'
import TableIconButton from 'component/general/TableIconButton'
import NumberFormatCustom from 'component/field/NumberFormatCustom'
import NumberFormat from 'react-number-format'
import InputAdornment from '@material-ui/core/InputAdornment'
import {useWarningDialog} from 'hooks/useWarningDialog'

const StoryTab = (props) => {
  const {
    classes,
    mainCategory,
    getStories,
    stories,
    deleteStory,
    patchStory,
    patchStoryLayer,
    postStory,
    estimation,
    estimationLoading,
    setOpenEditCreate,
    openEditCreate,
    estimationCurrency,
  } = props

  const [initialLoad, setInitialLoad] = useState(true)
  const [editLayer, setEditLayer] = useState(null)
  const [layerError, setLayerError] = useState({})
  const [patchLoading, setPatchLoading] = useState(false)
  const [DeleteWarningDialog, deleteData, onOpenDelete, onCloseDelete] = useWarningDialog()

  const noteRef = useRef(null)
  const estimatedRef = useRef(null)
  const seniorityRef = useRef(null)
  const amountRef = useRef(null)

  const {id} = useParams()

  const handleOpenEdit = (story) => (e) => {
    e.stopPropagation()
    return setOpenEditCreate({visible: true, row: story})
  }

  const handleCloseEdit = () => {
    return setOpenEditCreate({visible: false, row: null})
  }

  const handleDeleteStory = () => {
    return deleteStory(id, deleteData?.id).then(() => {
      fetchStories()
      onCloseDelete()
    })
  }

  const prepareStoryData = (values) => {
    let layers = []

    values.layers.forEach((layer) =>
      layers.push(STORY_LAYERS.find((story_layer, index) => index === layer))
    )

    return {
      title: values.title,
      category: values.category,
      layers: layers,
    }
  }

  const handleSubmitStory = (values) => {
    const data = prepareStoryData(values)
    if (openEditCreate.row) {
      return patchStory(id, openEditCreate.row.id, data).then(() => {
        fetchStories()
        handleCloseEdit()
      })
    } else {
      return postStory(id, data).then(() => {
        fetchStories()
        handleCloseEdit()
      })
    }
  }

  const getInitValuesEdit = (values) => {
    if (values) {
      let layers = []

      values.layers.forEach((layer) =>
        layers.push(STORY_LAYERS.findIndex((story_layer) => story_layer === layer))
      )

      return {
        title: values.title,
        category: values.category,
        layers: layers,
      }
    } else {
      return {}
    }
  }

  const fetchStories = () => {
    return getStories(id, {category: mainCategory, orderBy: 'title', orderDirection: 'ASC'})
  }

  const handleEditLayer = (story, layer) => () => {
    if (story?.id && layer?.id) {
      setLayerError({})
      setEditLayer({storyId: story.id, layerId: layer.id})
    }
  }

  const prepareData = () => {
    return {
      note: noteRef.current?.value,
      estimated_mds: estimatedRef.current?.value && Number(estimatedRef.current.value),
      seniority: seniorityRef.current?.value,
      amount: amountRef.current?.value && Number(amountRef.current.value),
    }
  }

  const handleSubmitLayer = (story, layer) => {
    const data = prepareData()
    setPatchLoading(true)
    return patchStoryLayer(id, story.id, layer.id, data)
      .then(() => {
        fetchStories().then(() => {
          fireSuccessToast(<Trans>Layer updated.</Trans>)
          handleCancelChanges()
          setLayerError({})
        })
      })
      .catch((err) => {
        setLayerError(mapErrorResponseToForm(err))
        showErrorToastFromObject(mapErrorResponseToForm(err))
      })
      .finally(() => {
        setPatchLoading(false)
      })
  }

  const handleCancelChanges = () => {
    setEditLayer({story: null, layer: null})
  }

  const StoryGridItem = ({width, text, style, children, classNames, ...rest}) => {
    return (
      <GridItem
        px={2}
        style={{width: `${width}px`, ...style}}
        className={cx(classes.storyLayer, classNames)}
        {...rest}
      >
        {text}
        {children}
      </GridItem>
    )
  }

  const getSummaryValue = (attribute, story) => {
    let sum = 0

    if (story) {
      story.story_layers?.forEach((layer) => (sum += layer?.[attribute]))
    } else {
      stories?.forEach((story) =>
        story.story_layers?.forEach((layer) => (sum += layer?.[attribute]))
      )
    }
    return sum
  }

  const handleEnterPress = (e, story, layer) => {
    if (e.keyCode === 13) {
      // ENTER
      handleSubmitLayer(story, layer)
    } else if (e.keyCode === 27) {
      // ESCAPE
      handleCancelChanges()
    }
  }

  useEffect(() => {
    fetchStories().then(() => setInitialLoad(false))
  }, [mainCategory])

  return initialLoad ? (
    <Loader wrapper={true} />
  ) : (
    <>
      {/* EDIT & CREATE STORY DIALOG */}
      <DialogWindow
        onClose={handleCloseEdit}
        open={openEditCreate.visible}
        title={openEditCreate.row ? <Trans>Edit story</Trans> : <Trans>Create story</Trans>}
        maxWidth={'sm'}
        fullWidth={true}
      >
        <StoryForm
          estimation={estimation}
          estimationLoading={estimationLoading}
          handleSubmit={handleSubmitStory}
          initValues={getInitValuesEdit(openEditCreate.row)}
        />
      </DialogWindow>

      {/* DELETE DIALOG */}
      <DeleteWarningDialog
        onConfirm={handleDeleteStory}
        message={<Trans>Story {deleteData?.title} will be deleted!</Trans>}
      />

      <GridContainer>
        <div className={classes.storyTab}>
          {stories?.map((story, index) => (
            <Paper key={index} elevation={3} square style={{marginBottom: 12}}>
              <Accordion
                elevation={0}
                className={classes.storyAccordion}
                square
                defaultExpanded={true}
              >
                <AccordionSummary
                  className={classes.storyAccordionSummary}
                  expandIcon={<ExpandMore />}
                >
                  <GridContainer direction={'row'}>
                    <GridItem xs={10} container alignItems={'center'}>
                      <Typography variant={'body2'} noWrap={true}>
                        {story.title}
                      </Typography>
                    </GridItem>

                    <GridItem xs={2}>
                      <GridContainer direction={'row'} wrap={'nowrap'} justifyContent={'flex-end'}>
                        <GridItem>
                          <TableIconButton
                            tooltipTitle={<Trans>Edit story</Trans>}
                            onClick={handleOpenEdit(story)}
                            Icon={Edit}
                            iconSize={'small'}
                          />
                        </GridItem>
                        <GridItem>
                          <TableIconButton
                            tooltipTitle={<Trans>Delete story</Trans>}
                            onClick={(e) => {
                              e.stopPropagation()
                              const callOpen = onOpenDelete(story)
                              callOpen()
                            }}
                            Icon={Delete}
                            iconSize={'small'}
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>
                </AccordionSummary>

                <AccordionDetails>
                  <GridContainer style={{overflow: 'auto'}}>
                    <GridItem
                      container
                      xs={12}
                      style={{minWidth: 'max-content'}}
                      className={classes.storyLayerHeader}
                    >
                      <StoryGridItem width={60} text={<Trans>Layer</Trans>} />
                      <StoryGridItem
                        width={160}
                        style={{flex: 'auto'}}
                        text={<Trans>Note</Trans>}
                      />
                      <StoryGridItem
                        width={90}
                        text={<Trans>Length</Trans>}
                        classNames={classes.textAlignEnd}
                      />
                      <StoryGridItem width={120} text={<Trans>Junior x Senior</Trans>} />
                      <StoryGridItem
                        width={100}
                        text={<Trans>Wage for MD</Trans>}
                        classNames={classes.textAlignEnd}
                      />
                      <StoryGridItem
                        width={60}
                        text={<Trans>Count</Trans>}
                        classNames={classes.textAlignEnd}
                      />
                      <StoryGridItem
                        width={140}
                        text={<Trans>Price</Trans>}
                        classNames={classes.textAlignEnd}
                      />
                      <StoryGridItem
                        width={140}
                        text={<Trans>Price with reserve</Trans>}
                        classNames={classes.textAlignEnd}
                      />
                      {/* empty header for column with edit */}
                      <StoryGridItem width={60} />
                    </GridItem>

                    {story?.story_layers
                      ?.sort((a, b) => a.layer.localeCompare(b.layer))
                      ?.map((layer, index) => (
                        <GridItem
                          key={index}
                          container
                          xs={12}
                          style={{minWidth: 'max-content'}}
                          className={classes.storyLayerRow}
                        >
                          {editLayer?.storyId === story.id && editLayer?.layerId === layer.id ? (
                            <GridItem
                              container
                              xs={12}
                              onKeyDown={(e) => handleEnterPress(e, story, layer)}
                              alignItems={'flex-end'}
                            >
                              <StoryGridItem width={60} text={layer.layer} />
                              <TextField
                                autoComplete="off"
                                style={{width: '160px', flex: 'auto'}}
                                name={'note'}
                                defaultValue={layer.note}
                                error={!!layerError?.['note']}
                                className={classes.inlineTextField}
                                inputRef={noteRef}
                                disabled={patchLoading}
                              />
                              <TextField
                                autoComplete="off"
                                style={{width: '90px'}}
                                name={'estimated_mds'}
                                defaultValue={Number(layer.estimated_mds)?.toFixed(2) || 0}
                                error={!!layerError?.['estimated_mds']}
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                  endAdornment: (
                                    <InputAdornment
                                      className={classes.inlineAdornment}
                                      position="end"
                                      disableTypography={true}
                                    >
                                      &nbsp;MD
                                    </InputAdornment>
                                  ),
                                }}
                                inputProps={{suffix: ''}}
                                className={cx(classes.inlineTextField, classes.textAlignEnd)}
                                inputRef={estimatedRef}
                                disabled={patchLoading}
                              />
                              <FormControl
                                error={!!layerError?.['seniority']}
                                size={'small'}
                                className={classes.inlineSelectFormControl}
                              >
                                <Select
                                  className={classes.inlineSelectField}
                                  size={'small'}
                                  name={'seniority'}
                                  defaultValue={layer?.seniority}
                                  inputRef={seniorityRef}
                                  disabled={patchLoading}
                                >
                                  <MenuItem
                                    value={'junior'}
                                    className={classes.inlineSelectMenuItem}
                                  >
                                    <Trans>junior</Trans>
                                  </MenuItem>
                                  <MenuItem
                                    value={'senior'}
                                    className={classes.inlineSelectMenuItem}
                                  >
                                    <Trans>senior</Trans>
                                  </MenuItem>
                                </Select>
                              </FormControl>
                              <StoryGridItem
                                classNames={classes.textAlignEnd}
                                width={100}
                                text={
                                  layer.seniority === 'junior'
                                    ? estimation?.junior_price &&
                                      renderAmountWithCurrency(
                                        estimation.junior_price,
                                        estimationCurrency
                                      )
                                    : estimation?.senior_price &&
                                      renderAmountWithCurrency(
                                        estimation.senior_price,
                                        estimationCurrency
                                      )
                                }
                              />
                              <TextField
                                autoComplete="off"
                                style={{width: '60px'}}
                                name={'amount'}
                                defaultValue={layer.amount || 0}
                                error={!!layerError?.['amount']}
                                className={cx(classes.inlineTextField, classes.textAlignEnd)}
                                inputRef={amountRef}
                                disabled={patchLoading}
                              />
                              <StoryGridItem
                                classNames={classes.textAlignEnd}
                                width={140}
                                text={
                                  layer?.price &&
                                  renderAmountWithCurrency(layer.price, estimationCurrency)
                                }
                              />
                              <StoryGridItem
                                classNames={classes.textAlignEnd}
                                width={140}
                                text={
                                  layer?.price_with_reserve &&
                                  renderAmountWithCurrency(
                                    layer.price_with_reserve,
                                    estimationCurrency
                                  )
                                }
                              />
                              <StoryGridItem
                                width={60}
                                text={
                                  <GridContainer
                                    direction={'row'}
                                    wrap={'nowrap'}
                                    justifyContent={'flex-end'}
                                  >
                                    <CustomTooltip
                                      classNames={classes.iconTooltip}
                                      title={<Trans>Save changes</Trans>}
                                    >
                                      <IconButton
                                        color={'primary'}
                                        onClick={() => handleSubmitLayer(story, layer)}
                                        className={classes.iconButtonRow}
                                        disabled={patchLoading}
                                      >
                                        <Save />
                                      </IconButton>
                                    </CustomTooltip>
                                    <CustomTooltip
                                      classNames={classes.iconTooltip}
                                      title={<Trans>Cancel changes</Trans>}
                                    >
                                      <IconButton
                                        color={'primary'}
                                        onClick={handleCancelChanges}
                                        className={classes.iconButtonRow}
                                        disabled={patchLoading}
                                      >
                                        <Cancel />
                                      </IconButton>
                                    </CustomTooltip>
                                  </GridContainer>
                                }
                              />
                            </GridItem>
                          ) : (
                            <GridItem
                              style={{cursor: 'pointer'}}
                              container
                              xs={12}
                              onClick={handleEditLayer(story, layer)}
                              alignItems={'flex-end'}
                            >
                              <StoryGridItem width={60} text={layer.layer} />
                              <StoryGridItem
                                width={160}
                                classNames={classes.flexAuto}
                                text={
                                  <CustomTooltip
                                    classNames={classes.overflowEllipsis}
                                    title={layer.note}
                                  >
                                    {layer.note}
                                  </CustomTooltip>
                                }
                              />
                              <StoryGridItem
                                width={90}
                                text={Number(layer.estimated_mds).toFixed(2) + ' MD'}
                                classNames={classes.textAlignEnd}
                              />
                              <StoryGridItem width={120} text={layer.seniority} />
                              <StoryGridItem
                                classNames={classes.textAlignEnd}
                                width={100}
                                text={
                                  layer.seniority === 'junior'
                                    ? estimation?.junior_price &&
                                      renderAmountWithCurrency(
                                        estimation?.junior_price,
                                        estimationCurrency
                                      )
                                    : estimation?.senior_price &&
                                      renderAmountWithCurrency(
                                        estimation?.senior_price,
                                        estimationCurrency
                                      )
                                }
                              />
                              <StoryGridItem
                                width={60}
                                text={layer.amount}
                                classNames={classes.textAlignEnd}
                              />
                              <StoryGridItem
                                classNames={classes.textAlignEnd}
                                width={140}
                                text={
                                  layer?.price &&
                                  renderAmountWithCurrency(layer?.price, estimationCurrency)
                                }
                              />
                              <StoryGridItem
                                classNames={classes.textAlignEnd}
                                width={140}
                                text={
                                  layer?.price_with_reserve &&
                                  renderAmountWithCurrency(
                                    layer?.price_with_reserve,
                                    estimationCurrency
                                  )
                                }
                              />
                              <StoryGridItem
                                width={60}
                                text={
                                  <GridContainer
                                    direction={'row'}
                                    wrap={'nowrap'}
                                    justifyContent={'flex-end'}
                                  >
                                    <CustomTooltip
                                      classNames={classes.iconTooltip}
                                      title={<Trans>Edit layer</Trans>}
                                    >
                                      <IconButton
                                        color={'primary'}
                                        onClick={handleEditLayer(story, layer)}
                                        className={classes.iconButtonRow}
                                      >
                                        <Edit />
                                      </IconButton>
                                    </CustomTooltip>
                                  </GridContainer>
                                }
                              />
                            </GridItem>
                          )}
                        </GridItem>
                      ))}
                  </GridContainer>
                </AccordionDetails>
              </Accordion>
              <GridContainer>
                <GridItem
                  container
                  xs={12}
                  className={cx(classes.boldText, classes.noFlexWrap, classes.accordionSummary)}
                >
                  <StoryGridItem style={{flex: 'auto'}} text={<Trans>Total</Trans>} />
                  <StoryGridItem
                    width={90}
                    text={
                      <NumberFormat
                        thousandSeparator={' '}
                        displayType="text"
                        suffix={' MD'}
                        value={Number(getSummaryValue('estimated_mds', story)).toFixed(2)}
                      />
                    }
                    classNames={classes.textAlignEnd}
                  />
                  <StoryGridItem width={280} />
                  <StoryGridItem
                    width={140}
                    text={renderAmountWithCurrency(
                      getSummaryValue('price', story),
                      estimationCurrency
                    )}
                    classNames={classes.textAlignEnd}
                  />
                  <StoryGridItem
                    width={140}
                    text={renderAmountWithCurrency(
                      getSummaryValue('price_with_reserve', story),
                      estimationCurrency
                    )}
                    classNames={classes.textAlignEnd}
                  />
                  <StoryGridItem width={60} />
                </GridItem>
              </GridContainer>
            </Paper>
          ))}

          {/* SUMMARY */}
          <Paper elevation={3} className={classes.summary}>
            <GridContainer style={{overflow: 'auto'}}>
              <GridItem
                container
                xs={12}
                style={{minWidth: 'max-content'}}
                className={classes.storyLayerHeader}
              >
                <StoryGridItem style={{flex: 'auto'}} />
                <StoryGridItem
                  width={90}
                  text={<Trans>Length</Trans>}
                  classNames={classes.textAlignEnd}
                />
                <StoryGridItem width={280} />
                <StoryGridItem
                  width={140}
                  text={<Trans>Price</Trans>}
                  classNames={classes.textAlignEnd}
                />
                <StoryGridItem
                  width={140}
                  text={<Trans>Price with reserve</Trans>}
                  classNames={classes.textAlignEnd}
                />
                <StoryGridItem width={60} />
              </GridItem>

              <GridItem container xs={12} className={cx(classes.boldText, classes.noFlexWrap)}>
                <StoryGridItem style={{flex: 'auto'}} text={<Trans>Total</Trans>} />
                <StoryGridItem
                  width={90}
                  text={
                    <NumberFormat
                      thousandSeparator={' '}
                      displayType="text"
                      suffix={' MD'}
                      value={Number(getSummaryValue('estimated_mds')).toFixed(2)}
                    />
                  }
                  classNames={classes.textAlignEnd}
                />
                <StoryGridItem width={280} />
                <StoryGridItem
                  width={140}
                  text={renderAmountWithCurrency(getSummaryValue('price'), estimationCurrency)}
                  classNames={classes.textAlignEnd}
                />
                <StoryGridItem
                  width={140}
                  text={renderAmountWithCurrency(
                    getSummaryValue('price_with_reserve'),
                    estimationCurrency
                  )}
                  classNames={classes.textAlignEnd}
                />
                <StoryGridItem width={60} />
              </GridItem>
            </GridContainer>
          </Paper>
        </div>
      </GridContainer>
    </>
  )
}

StoryTab.propTypes = {
  classes: PropTypes.object,
  getStories: PropTypes.func,
  mainCategory: PropTypes.string,
  stories: PropTypes.array,
  storiesLoading: PropTypes.bool,
  storiesMeta: PropTypes.object,
  deleteStory: PropTypes.func,
  patchStory: PropTypes.func,
  patchStoryLayer: PropTypes.func,
  postStory: PropTypes.func,
  estimation: PropTypes.object,
  estimationLoading: PropTypes.bool,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getStories,
      deleteStory,
      patchStory,
      patchStoryLayer,
      postStory,
    },
    dispatch
  )
}

export default compose(
  withStyles(odhadyStyle),
  connect((store) => {
    return {
      stories: store.odhadomat.stories,
      storiesLoading: store.odhadomat.storiesLoading,
      storiesMeta: store.odhadomat.storiesMeta,
      estimationLoading: store.odhadomat.estimationLoading,
      estimation: store.odhadomat.estimation,
      estimationCurrency: store.odhadomat.estimation?.currency,
    }
  }, mapDispatchToProps)
)(StoryTab)
