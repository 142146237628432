import {combineReducers} from 'redux'
import globalSettingReducer from 'redux/reducer/globalSettingReducer'
import globalLoadingReducer from 'redux/reducer/globalLoadingReducer'
import roleReducer from 'redux/reducer/roleReducer'
import vykazomatReducer from 'redux/reducer/vykazomatReducer'
import projektomatReducer from 'redux/reducer/projektomatReducer'
import kapacitomatReducer from 'redux/reducer/kapacitomatReducer'
import filterReducer from 'redux/reducer/filterReducer'
import userReducer from 'redux/reducer/userReducer'
import dashboardReducer from 'redux/reducer/dashboardReducer'
import stricklandReducer from 'redux/reducer/stricklandReducer'
import odhadomatReducer from 'redux/reducer/odhadomatReducer'

export default combineReducers({
  globalSetting: globalSettingReducer,
  globalLoading: globalLoadingReducer,
  role: roleReducer,
  vykazomat: vykazomatReducer,
  projektomat: projektomatReducer,
  kapacitomat: kapacitomatReducer,
  filter: filterReducer,
  user: userReducer,
  dashboard: dashboardReducer,
  strickland: stricklandReducer,
  odhadomat: odhadomatReducer,
})
