export const MOCK_API = false

export const MOCK_PORT_MAPPING = {
  odhadomat: 8000,
  'odhadomat-2': 8001,
}

// names of local storage items used for private routes, auth, etc.
export const LS_SESSION_ID_NAME = 'btSessionId'
export const LS_LOGGED_USER = 'btLoggedUser'
export const LS_GLOBAL_SETTINGS = 'btGlobalSettings'
