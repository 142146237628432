import {Ability} from '@casl/ability'
import {getActualUser} from 'helper/functions'
import {createContext} from 'react'
import {createContextualCan} from '@casl/react'
import mapPermissionsToAbilities from 'helper/mapPermissionToAbility'

const currentUser = getActualUser()
// CODE FOR PRODUCTION WITH GOOGLE AUTH
const permissions = currentUser?.user?.permissions || []

// THIS CODE IS FOR DEVELOPMENT (no local host with google auth)
// login in to test, get session_id and copy that to localhost local storage
// then use permissions below in permissions variable above
// const permissions = currentUser?.user?.permissions || [
//   'can_manage_users',
//   'can_manage_roles',
//   'can_use_odhadomat',
//   'can_use_vykazomat',
//   'can_use_projektomat',
//   'can_use_kapacitomat',
//   'can_use_strickland',
//   'can_use_emmett',
// ]

const abilityContext = createContext()

// function translating permissions to abilities
export const authorizedAbility = new Ability(mapPermissionsToAbilities(permissions))

// provider (wrapper) used in AppProvider
export const AbilityContextProvider = abilityContext.Provider

// <Can element used in components
export const Can = createContextualCan(abilityContext.Consumer)

/**
  update abilities after successful login

  authorizedAbility.update(mapPermissionsToAbilities(res.data.user.permissions))


  use in function

   useEffect(() => {
      if (authorizedAbility.cannot('read', 'Organizations')) {
        unauthorizedRedirectToDashboard(<Trans>You are not authorized to list organizations.</Trans>)
      }
    }, [])


  use in component

   <Can I="delete" an="Events">
     <PrimaryButton
     disabled={submitting}
     type="submit"
     text={<Trans>Submit</Trans>}
     />
   </Can>

 */
