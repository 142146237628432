const stricklandStyle = (theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  appBarBottomBorder: {
    borderBottom: '0px solid ' + theme.palette.secondary.main,
  },
  tabs: {
    backgroundColor: theme.palette.white,
    color: theme.palette.primary.main,

    '& .MuiTabScrollButton-root:first-of-type': {
      borderRight: '0px solid ' + theme.palette.secondary.main,
    },
    '& .MuiTabScrollButton-root:last-of-type': {
      borderLeft: '0px solid ' + theme.palette.secondary.main,
    },
    '& .MuiTab-textColorInherit.Mui-selected': {
      fontWeight: 700,
      opacity: 1,
    },
    '& .MuiTab-textColorInherit': {
      opacity: 0.8,
      fontWeight: 600,
    },
    '& .MuiTab-root': {
      minWidth: '90px !important',
      padding: '6px 24px',
    },
  },
  overflowAuto: {
    overflowX: 'auto',
    overflowY: 'hidden',
  },
  gridBoxHeight: {
    minHeight: 180,
    display: 'flex',
  },
  customGraphTooltip: {
    width: 240,
    backgroundColor: theme.palette.white,
    padding: 10,
    border: '1px solid ' + theme.palette.border,
    fontSize: 14,
  },
  expected: {
    width: 10,
    height: 10,
    backgroundColor: theme.palette.primary.main,
    marginRight: 6,
    marginLeft: 60,
  },
  expectedBillable: {
    width: 10,
    height: 10,
    backgroundColor: theme.palette.primary.main + '70',
    marginRight: 6,
    marginLeft: 16,
  },
  expectedText: {
    color: theme.palette.primary.main,
  },
  reality: {
    width: 10,
    height: 10,
    backgroundColor: theme.palette.secondary.main,
    marginRight: 6,
    marginLeft: 16,
  },
  realityBillable: {
    width: 10,
    height: 10,
    backgroundColor: theme.palette.secondary.main + '70',
    marginRight: 6,
    marginLeft: 16,
  },
  realityText: {
    color: theme.palette.secondary.main,
  },
  legendLine: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  toggleButtons: {
    flexGrow: '1',
    textAlign: 'end',
    '& .MuiToggleButton-root': {
      padding: 5,
      fontSize: 14,
      width: 36,
      height: 36,
    },
  },
  tooltipLabel: {
    marginBottom: 4,
    fontWeight: 500,
  },
  tooltipList: {
    width: '100%',
    margin: '2px 0px',
    paddingLeft: 30,
  },
  employeeGraphName: {
    fontWeight: 500,
    color: theme.palette.primary.main,
  },
  employeeGraphEmail: {
    marginLeft: 4,
  },
  cursorPointer: {
    cursor: 'pointer',
  },
  greenStrickLandRow: {
    color: theme.palette.success.main,
  },
  redStrickLandRow: {
    color: theme.palette.error.main,
  },

  noDataGridItem: {
    height: '100%',
    fontWeight: 700,
    fontSize: 20,
    opacity: 0.2,
  },
})

export default stricklandStyle
