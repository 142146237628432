// USER
export const GET_USER_LIST_REQUEST = 'GET_USER_LIST_REQUEST'
export const GET_USER_LIST_SUCCESS = 'GET_USER_LIST_SUCCESS'
export const GET_USER_LIST_FAIL = 'GET_USER_LIST_FAIL'

export const GET_USER_REQUEST = 'GET_USER_REQUEST'
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS'
export const GET_USER_FAIL = 'GET_USER_FAIL'

export const GET_USER_ROLE_LIST_REQUEST = 'GET_USER_ROLE_LIST_REQUEST'
export const GET_USER_ROLE_LIST_SUCCESS = 'GET_USER_ROLE_LIST_SUCCESS'
export const GET_USER_ROLE_LIST_FAIL = 'GET_USER_ROLE_LIST_FAIL'

export const GET_USER_ROLE_REQUEST = 'GET_USER_ROLE_REQUEST'
export const GET_USER_ROLE_SUCCESS = 'GET_USER_ROLE_SUCCESS'
export const GET_USER_ROLE_FAIL = 'GET_USER_ROLE_FAIL'

export const GET_PERMISSION_REQUEST = 'GET_PERMISSION_REQUEST'
export const GET_PERMISSION_SUCCESS = 'GET_PERMISSION_SUCCESS'
export const GET_PERMISSION_FAIL = 'GET_PERMISSION_FAIL'

export const UPDATE_GLOBAL_SETTING = 'UPDATE_GLOBAL_SETTING'
export const UPDATE_GLOBAL_LOADING = 'UPDATE_GLOBAL_LOADING'

export const UPDATE_FILTERS = 'UPDATE_FILTERS'

// odhadomat
export const GET_ESTIMATION_LIST_REQUEST = 'GET_ESTIMATION_LIST_REQUEST'
export const GET_ESTIMATION_LIST_SUCCESS = 'GET_ESTIMATION_LIST_SUCCESS'
export const GET_ESTIMATION_LIST_FAIL = 'GET_ESTIMATION_LIST_FAIL'

export const GET_ESTIMATION_REQUEST = 'GET_ESTIMATION_REQUEST'
export const GET_ESTIMATION_SUCCESS = 'GET_ESTIMATION_SUCCESS'
export const GET_ESTIMATION_FAIL = 'GET_ESTIMATION_FAIL'

export const GET_STORIES_REQUEST = 'GET_STORIES_REQUEST'
export const GET_STORIES_SUCCESS = 'GET_STORIES_SUCCESS'
export const GET_STORIES_FAIL = 'GET_STORIES_FAIL'

export const GET_ACCOMPANYING_WORKS_REQUEST = 'GET_ACCOMPANYING_WORKS_REQUEST'
export const GET_ACCOMPANYING_WORKS_SUCCESS = 'GET_ACCOMPANYING_WORKS_SUCCESS'
export const GET_ACCOMPANYING_WORKS_FAIL = 'GET_ACCOMPANYING_WORKS_FAIL'

export const GET_SCHEDULE_REQUEST = 'GET_SCHEDULE_REQUEST'
export const GET_SCHEDULE_SUCCESS = 'GET_SCHEDULE_SUCCESS'
export const GET_SCHEDULE_FAIL = 'GET_SCHEDULE_FAIL'

export const GET_PHASES_REQUEST = 'GET_PHASES_REQUEST'
export const GET_PHASES_SUCCESS = 'GET_PHASES_SUCCESS'
export const GET_PHASES_FAIL = 'GET_PHASES_FAIL'

export const GET_QUOTES_REQUEST = 'GET_QUOTES_REQUEST'
export const GET_QUOTES_SUCCESS = 'GET_QUOTES_SUCCESS'
export const GET_QUOTES_FAIL = 'GET_QUOTES_FAIL'

export const GET_QUOTE_REQUEST = 'GET_QUOTE_REQUEST'
export const GET_QUOTE_SUCCESS = 'GET_QUOTE_SUCCESS'
export const GET_QUOTE_FAIL = 'GET_QUOTE_FAIL'

export const GET_QUOTE_ROWS_REQUEST = 'GET_QUOTE_ROWS_REQUEST'
export const GET_QUOTE_ROWS_SUCCESS = 'GET_QUOTE_ROWS_SUCCESS'
export const GET_QUOTE_ROWS_FAIL = 'GET_QUOTE_ROWS_FAIL'

// projektomat clients
export const GET_CLIENT_LIST_REQUEST = 'GET_CLIENT_LIST_REQUEST'
export const GET_CLIENT_LIST_SUCCESS = 'GET_CLIENT_LIST_SUCCESS'
export const GET_CLIENT_LIST_FAIL = 'GET_CLIENT_LIST_FAIL'

// projektomat projects
export const GET_PROJECT_LIST_REQUEST = 'GET_PROJECT_LIST_REQUEST'
export const GET_PROJECT_LIST_SUCCESS = 'GET_PROJECT_LIST_SUCCESS'
export const GET_PROJECT_LIST_FAIL = 'GET_PROJECT_LIST_FAIL'

// projektomat accepted invoices
export const GET_ACCEPTED_INVOICES_REQUEST = 'GET_ACCEPTED_INVOICES_REQUEST'
export const GET_ACCEPTED_INVOICES_SUCCESS = 'GET_ACCEPTED_INVOICES_SUCCESS'
export const GET_ACCEPTED_INVOICES_FAIL = 'GET_ACCEPTED_INVOICES_FAIL'

// projektomat issued invoices
export const GET_ISSUED_INVOICES_REQUEST = 'GET_ISSUED_INVOICES_REQUEST'
export const GET_ISSUED_INVOICES_SUCCESS = 'GET_ISSUED_INVOICES_SUCCESS'
export const GET_ISSUED_INVOICES_FAIL = 'GET_ISSUED_INVOICES_FAIL'

// projektomat work reports
export const GET_WORK_REPORTS_REQUEST = 'GET_WORK_REPORTS_REQUEST'
export const GET_WORK_REPORTS_SUCCESS = 'GET_WORK_REPORTS_SUCCESS'
export const GET_WORK_REPORTS_FAIL = 'GET_WORK_REPORTS_FAIL'

// projektomat indirect expenses
export const GET_INDIRECT_EXPENSES_REQUEST = 'GET_INDIRECT_EXPENSES_REQUEST'
export const GET_INDIRECT_EXPENSES_SUCCESS = 'GET_INDIRECT_EXPENSES_SUCCESS'
export const GET_INDIRECT_EXPENSES_FAIL = 'GET_INDIRECT_EXPENSES_FAIL'

// projektomat employees
export const GET_EMPLOYEES_REQUEST = 'GET_EMPLOYEES_REQUEST'
export const GET_EMPLOYEES_SUCCESS = 'GET_EMPLOYEES_SUCCESS'
export const GET_EMPLOYEES_FAIL = 'GET_EMPLOYEES_FAIL'

export const GET_EMPLOYEE_ASSIGNMENT_REQUEST = 'GET_EMPLOYEE_ASSIGNMENT_REQUEST'
export const GET_EMPLOYEE_ASSIGNMENT_SUCCESS = 'GET_EMPLOYEE_ASSIGNMENT_SUCCESS'
export const GET_EMPLOYEE_ASSIGNMENT_FAIL = 'GET_EMPLOYEE_ASSIGNMENT_FAIL'

// projektomat role
export const GET_ROLE_REQUEST = 'GET_ROLE_REQUEST'
export const GET_ROLE_SUCCESS = 'GET_ROLE_SUCCESS'
export const GET_ROLE_FAIL = 'GET_ROLE_FAIL'

// projektomat assignments
export const GET_PROJECT_ASSIGNMENTS_REQUEST = 'GET_PROJECT_ASSIGNMENTS_REQUEST'
export const GET_PROJECT_ASSIGNMENTS_SUCCESS = 'GET_PROJECT_ASSIGNMENTS_SUCCESS'
export const GET_PROJECT_ASSIGNMENTS_FAIL = 'GET_PROJECT_ASSIGNMENTS_FAIL'

// projektomat wages
export const GET_EMPLOYEE_WAGES_REQUEST = 'GET_EMPLOYEE_WAGES_REQUEST'
export const GET_EMPLOYEE_WAGES_SUCCESS = 'GET_EMPLOYEE_WAGES_SUCCESS'
export const GET_EMPLOYEE_WAGES_FAIL = 'GET_EMPLOYEE_WAGES_FAIL'

// projektomat companies
export const GET_COMPANIES_REQUEST = 'GET_COMPANIES_REQUEST'
export const GET_COMPANIES_SUCCESS = 'GET_COMPANIES_SUCCESS'
export const GET_COMPANIES_FAIL = 'GET_COMPANIES_FAIL'

export const GET_ELUVIA_COMPANIES_REQUEST = 'GET_ELUVIA_COMPANIES_REQUEST'
export const GET_ELUVIA_COMPANIES_SUCCESS = 'GET_ELUVIA_COMPANIES_SUCCESS'
export const GET_ELUVIA_COMPANIES_FAIL = 'GET_ELUVIA_COMPANIES_FAIL'

export const GET_CLIENT_COMPANIES_REQUEST = 'GET_CLIENT_COMPANIES_REQUEST'
export const GET_CLIENT_COMPANIES_SUCCESS = 'GET_CLIENT_COMPANIES_SUCCESS'
export const GET_CLIENT_COMPANIES_FAIL = 'GET_CLIENT_COMPANIES_FAIL'

export const GET_EMPLOYEE_COMPANIES_REQUEST = 'GET_EMPLOYEE_COMPANIES_REQUEST'
export const GET_EMPLOYEE_COMPANIES_SUCCESS = 'GET_EMPLOYEE_COMPANIES_SUCCESS'
export const GET_EMPLOYEE_COMPANIES_FAIL = 'GET_EMPLOYEE_COMPANIES_FAIL'

// projektomat dashboard
export const GET_DASHBOARD_ASSIGNMENT_REQUEST = 'GET_DASHBOARD_ASSIGNMENT_REQUEST'
export const GET_DASHBOARD_ASSIGNMENT_SUCCESS = 'GET_DASHBOARD_ASSIGNMENT_SUCCESS'
export const GET_DASHBOARD_ASSIGNMENT_FAIL = 'GET_DASHBOARD_ASSIGNMENT_FAIL'

// vykazomat projects
export const GET_VYKAZOMAT_PROJECT_LIST_REQUEST = 'GET_VYKAZOMAT_PROJECT_LIST_REQUEST'
export const GET_VYKAZOMAT_PROJECT_LIST_SUCCESS = 'GET_VYKAZOMAT_PROJECT_LIST_SUCCESS'
export const GET_VYKAZOMAT_PROJECT_LIST_FAIL = 'GET_VYKAZOMAT_PROJECT_LIST_FAIL'

// vykazomat report
export const CREATE_VYKAZOMAT_REPORT_REQUEST = 'CREATE_VYKAZOMAT_REPORT_REQUEST'
export const CREATE_VYKAZOMAT_REPORT_SUCCESS = 'CREATE_VYKAZOMAT_REPORT_SUCCESS'
export const CREATE_VYKAZOMAT_REPORT_FAIL = 'CREATE_VYKAZOMAT_REPORT_FAIL'

export const GET_VYKAZOMAT_REPORT_STATE_REQUEST = 'GET_VYKAZOMAT_REPORT_STATE_REQUEST'
export const GET_VYKAZOMAT_REPORT_STATE_SUCCESS = 'GET_VYKAZOMAT_REPORT_STATE_SUCCESS'
export const GET_VYKAZOMAT_REPORT_STATE_FAIL = 'GET_VYKAZOMAT_REPORT_STATE_FAIL'

export const GET_VYKAZOMAT_REPORT_RESULT_REQUEST = 'GET_VYKAZOMAT_REPORT_RESULT_REQUEST'
export const GET_VYKAZOMAT_REPORT_RESULT_SUCCESS = 'GET_VYKAZOMAT_REPORT_RESULT_SUCCESS'
export const GET_VYKAZOMAT_REPORT_RESULT_FAIL = 'GET_VYKAZOMAT_REPORT_RESULT_FAIL'

// kapacitomat
export const UPDATE_KAPACITOMAT_PROJECT_ID = 'UPDATE_KAPACITOMAT_PROJECT_ID'

// kapacitomat projects
export const GET_KAPACITOMAT_PROJECT_LIST_REQUEST = 'GET_KAPACITOMAT_PROJECT_LIST_REQUEST'
export const GET_KAPACITOMAT_PROJECT_LIST_SUCCESS = 'GET_KAPACITOMAT_PROJECT_LIST_SUCCESS'
export const GET_KAPACITOMAT_PROJECT_LIST_FAIL = 'GET_KAPACITOMAT_PROJECT_LIST_FAIL'

// kapacitomat employees
export const GET_KAPACITOMAT_EMPLOYEE_LIST_REQUEST = 'GET_KAPACITOMAT_EMPLOYEE_LIST_REQUEST'
export const GET_KAPACITOMAT_EMPLOYEE_LIST_SUCCESS = 'GET_KAPACITOMAT_EMPLOYEE_LIST_SUCCESS'
export const GET_KAPACITOMAT_EMPLOYEE_LIST_FAIL = 'GET_KAPACITOMAT_EMPLOYEE_LIST_FAIL'

// kapacitomat assignments
export const GET_KAPACITOMAT_ASSIGNMENT_LIST_REQUEST = 'GET_KAPACITOMAT_ASSIGNMENT_LIST_REQUEST'
export const GET_KAPACITOMAT_ASSIGNMENT_LIST_SUCCESS = 'GET_KAPACITOMAT_ASSIGNMENT_LIST_SUCCESS'
export const GET_KAPACITOMAT_ASSIGNMENT_LIST_FAIL = 'GET_KAPACITOMAT_ASSIGNMENT_LIST_FAIL'

// kapacitomat piggy bank
export const GET_KAPACITOMAT_PIGGY_REQUEST = 'GET_KAPACITOMAT_PIGGY_REQUEST'
export const GET_KAPACITOMAT_PIGGY_SUCCESS = 'GET_KAPACITOMAT_PIGGY_SUCCESS'
export const GET_KAPACITOMAT_PIGGY_FAIL = 'GET_KAPACITOMAT_PIGGY_FAIL'

// dashboard
export const GET_DASHBOARD_ASSIGNMENTS_REQUEST = 'GET_DASHBOARD_ASSIGNMENTS_REQUEST'
export const GET_DASHBOARD_ASSIGNMENTS_SUCCESS = 'GET_DASHBOARD_ASSIGNMENTS_SUCCESS'
export const GET_DASHBOARD_ASSIGNMENTS_FAIL = 'GET_DASHBOARD_ASSIGNMENTS_FAIL'

export const GET_DASHBOARD_ASSIGNMENTS_NEXT_REQUEST = 'GET_DASHBOARD_ASSIGNMENTS_NEXT_REQUEST'
export const GET_DASHBOARD_ASSIGNMENTS_NEXT_SUCCESS = 'GET_DASHBOARD_ASSIGNMENTS_NEXT_SUCCESS'
export const GET_DASHBOARD_ASSIGNMENTS_NEXT_FAIL = 'GET_DASHBOARD_ASSIGNMENTS_NEXT_FAIL'

// strickland
export const GET_STRICKLAND_EMPLOYEES_REQUEST = 'GET_STRICKLAND_EMPLOYEES_REQUEST'
export const GET_STRICKLAND_EMPLOYEES_SUCCESS = 'GET_STRICKLAND_EMPLOYEES_SUCCESS'
export const GET_STRICKLAND_EMPLOYEES_FAIL = 'GET_STRICKLAND_EMPLOYEES_FAIL'

export const GET_STRICKLAND_PROJECTS_REQUEST = 'GET_STRICKLAND_PROJECTS_REQUEST'
export const GET_STRICKLAND_PROJECTS_SUCCESS = 'GET_STRICKLAND_PROJECTS_SUCCESS'
export const GET_STRICKLAND_PROJECTS_FAIL = 'GET_STRICKLAND_PROJECTS_FAIL'

export const GET_STRICKLAND_PROJECTS_POTENTIAL_REQUEST = 'GET_STRICKLAND_PROJECTS_POTENTIAL_REQUEST'
export const GET_STRICKLAND_PROJECTS_POTENTIAL_SUCCESS = 'GET_STRICKLAND_PROJECTS_POTENTIAL_SUCCESS'
export const GET_STRICKLAND_PROJECTS_POTENTIAL_FAIL = 'GET_STRICKLAND_PROJECTS_POTENTIAL_FAIL'
