import {useState} from 'react'

export const useTableSort = (initData) => {
  const [tableSort, setTableSort] = useState({
    orderBy: initData.orderBy,
    orderDirection: initData.orderDirection,
  })

  const onSortClick = (col) => () => {
    if (tableSort.orderBy === col.orderKey && tableSort.orderDirection === 'asc') {
      setTableSort({orderBy: col.orderKey, orderDirection: 'desc'})
    } else if (tableSort.orderBy === col.orderKey && tableSort.orderDirection === 'desc') {
      setTableSort({orderBy: null, orderDirection: 'asc'})
    } else {
      setTableSort({orderBy: col.orderKey, orderDirection: 'asc'})
    }
  }

  return [tableSort, onSortClick]
}
