import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import privateLayoutStyle from 'layout/PrivateLayout/privateLayoutStyle'
import PropTypes from 'prop-types'
import Circles from 'asset/svg/circles.svg'
import DrawerMenu from 'layout/PrivateLayout/DrawerMenu'
import LinearProgress from '@material-ui/core/LinearProgress'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

const PrivateLayout = (props) => {
  const {classes, children, loading} = props

  return (
    <div className={classes.container}>
      {loading && <LinearProgress className={classes.topBarLinearProgress} color={'secondary'} />}

      <DrawerMenu />

      <div className={classes.content}>{children}</div>

      <div className={classes.bottomRightImage}>
        <img src={Circles} alt="circles" />
      </div>
    </div>
  )
}

PrivateLayout.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node,
  loading: PropTypes.bool,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch)
}

export default compose(
  withStyles(privateLayoutStyle),
  connect((store) => {
    return {
      loading: store.globalLoading.loading,
    }
  }, mapDispatchToProps)
)(PrivateLayout)
