import React from 'react'
import {Form, Field} from 'react-final-form'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import {Trans} from '@lingui/macro'
import TextInput from 'component/field/TextInput'
import {required} from 'helper/validations'
import CheckBoxInput from 'component/field/CheckBoxInput'
import SelectInput from 'component/field/SelectInput'
import {PRICE_POLICY} from 'helper/constants'
import Box from '@material-ui/core/Box'
import PrimaryButton from 'component/material/PrimaryButton'
import NumberFormatCustom from 'component/field/NumberFormatCustom'
import Divider from '@material-ui/core/Divider'
import InputAdornment from '@material-ui/core/InputAdornment'

const NabidkaForm = (props) => {
  const {handleSubmit, row} = props

  const getInitialValues = {
    ...row,
    target_price_from: row?.target_price_from && Number(row.target_price_from / 100),
    target_price_to: row?.target_price_to && Number(row.target_price_to / 100),
  }

  return (
    <Form onSubmit={handleSubmit} initialValues={row ? getInitialValues : {}}>
      {(formProps) => {
        const {submitting} = formProps
        return (
          <form onSubmit={formProps.handleSubmit}>
            <GridContainer spacing={2}>
              <GridItem xs={12} sm={6}>
                <Field
                  name="title"
                  label={<Trans>Title</Trans>}
                  component={TextInput}
                  validate={required}
                  disabled={submitting}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <Field
                  component={SelectInput}
                  options={PRICE_POLICY}
                  name="price_policy"
                  label={<Trans>Price policy</Trans>}
                  validate={required}
                  disabled={submitting}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <Field
                  disabled={submitting}
                  name="show_estimated_mds"
                  label={<Trans>Show estimated mds</Trans>}
                  component={CheckBoxInput}
                  type="checkbox"
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <Field
                  disabled={submitting}
                  name="include_vat"
                  label={<Trans>Include vat</Trans>}
                  component={CheckBoxInput}
                  type="checkbox"
                />
              </GridItem>
            </GridContainer>

            <GridContainer>
              <GridItem xs={12}>
                <Box sx={{margin: 40, marginTop: 0}}>
                  <Divider />
                </Box>
              </GridItem>
            </GridContainer>

            <GridContainer spacing={2}>
              <GridItem xs={12} sm={6}>
                <Field
                  name={'target_price_from'}
                  label={<Trans>Target price from</Trans>}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                  component={TextInput}
                  type={'number'}
                  disabled={submitting}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <Field
                  name={'target_price_to'}
                  label={<Trans>Target price to</Trans>}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                  component={TextInput}
                  type={'number'}
                  disabled={submitting}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <Field
                  name={'target_estimated_mds'}
                  label={<Trans>Target estimated</Trans>}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Trans>MD</Trans>
                      </InputAdornment>
                    ),
                  }}
                  component={TextInput}
                  type={'number'}
                  disabled={submitting}
                />
              </GridItem>
            </GridContainer>
            <Box pt={3}>
              <GridContainer justifyContent={'flex-end'}>
                <PrimaryButton
                  fullWidth={false}
                  disabled={submitting}
                  type="submit"
                  text={row ? <Trans>Save</Trans> : <Trans>Create</Trans>}
                />
              </GridContainer>
            </Box>
          </form>
        )
      }}
    </Form>
  )
}

export default NabidkaForm
