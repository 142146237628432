import React from 'react'
import {bindActionCreators, compose} from 'redux'
import {withStyles} from '@material-ui/core'
import odhadyStyle from 'component/OdhadomatPage/odhadyStyle'
import {connect} from 'react-redux'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import {Trans} from '@lingui/macro'
import cx from 'classnames'
import BoxFullWidth from 'component/material/BoxFullWidth'
import {renderAmountWithCurrency} from 'helper/functions'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'

const SummaryTab = (props) => {
  const {classes, estimation} = props

  return (
    <GridContainer>
      <BoxFullWidth pt={10}>
        <div className={classes.summaryTab}>
          <GridContainer className={cx(classes.storyLayerHeader, classes.summaryRow)}>
            <GridItem xs={3}>
              <Trans>Card</Trans>
            </GridItem>
            <GridItem xs={3} className={classes.textAlignEnd}>
              <Trans>Estimation</Trans>
            </GridItem>
            <GridItem xs={3} className={classes.textAlignEnd}>
              <Trans>Without reserve</Trans>
            </GridItem>
            <GridItem xs={3} className={classes.textAlignEnd}>
              <Trans>With reserve</Trans>
            </GridItem>
          </GridContainer>

          {estimation?.summary?.map((row, index) => (
            <GridContainer key={index} className={classes.summaryRow}>
              <GridItem xs={3}>{row.title}</GridItem>
              <GridItem xs={3} className={classes.textAlignEnd}>
                <NumberFormat
                  thousandSeparator={' '}
                  displayType="text"
                  suffix={' MD'}
                  value={row.estimated_mds.toFixed(2)}
                />
              </GridItem>
              <GridItem xs={3} className={classes.textAlignEnd}>
                {renderAmountWithCurrency(row.price, estimation?.currency)}
              </GridItem>
              <GridItem xs={3} className={classes.textAlignEnd}>
                {renderAmountWithCurrency(row.price_with_reserve, estimation?.currency)}
              </GridItem>
            </GridContainer>
          ))}

          <GridContainer className={classes.summaryTotalRow}>
            <GridItem xs={3}>
              <Trans>Total</Trans>
            </GridItem>
            <GridItem xs={3} className={classes.textAlignEnd}>
              <NumberFormat
                thousandSeparator={' '}
                displayType="text"
                suffix={' MD'}
                value={estimation?.summary
                  ?.reduce((acc, cur) => acc + cur.estimated_mds, 0)
                  .toFixed(2)}
              />
            </GridItem>
            <GridItem xs={3} className={classes.textAlignEnd}>
              {renderAmountWithCurrency(
                estimation?.summary?.reduce((acc, cur) => acc + cur.price, 0),
                estimation?.currency
              )}
            </GridItem>
            <GridItem xs={3} className={classes.textAlignEnd}>
              {renderAmountWithCurrency(
                estimation?.summary?.reduce((acc, cur) => acc + cur.price_with_reserve, 0),
                estimation?.currency
              )}
            </GridItem>
          </GridContainer>
        </div>
      </BoxFullWidth>
    </GridContainer>
  )
}

SummaryTab.propTypes = {
  classes: PropTypes.object,
  estimation: PropTypes.object,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch)
}

export default compose(
  withStyles(odhadyStyle),
  connect((store) => {
    return {
      estimation: store.odhadomat.estimation,
    }
  }, mapDispatchToProps)
)(SummaryTab)
