import React, {useEffect, useState} from 'react'
import {Trans} from '@lingui/macro'
import moment from 'moment'
import {MONTHS} from 'helper/constants'
import withStyles from '@material-ui/core/styles/withStyles'
import stricklandStyle from 'component/Strickland/stricklandStyle'
import PropTypes from 'prop-types'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import {bindActionCreators, compose} from 'redux'
import {getEmployeesReportStrickland} from 'redux/action/stricklandAction'
import {connect} from 'react-redux'
import BoxFullWidth from 'component/material/BoxFullWidth'
import TabPanel from 'component/material/TabPanel'
import EmployeesGraphStrick from 'component/Strickland/Employee/EmployeeGraphStrick'
import PrivateContentLayout from 'layout/PrivateLayout/PrivateContentLayout'

const EmployeePageStrick = (props) => {
  const {classes, getEmployeesReportStrickland, employeesReportLoading, employeesReport} = props

  const monthsToDisplay = 6

  const [value, setValue] = useState(0)
  const [listOfTabs, setListOfTabs] = useState([])

  const handleChangeTab = (e, newValue) => {
    setValue(newValue)
    fetchEmployeesReport(listOfTabs, newValue)
  }

  const setMonthTabs = (numberOfMonths) => {
    let monthTabs = []

    for (let i = 1; i <= numberOfMonths; i++) {
      const previousMonth = moment().subtract(i, 'months').month()
      const previousMonthYear = moment().subtract(i, 'months').year()

      monthTabs.unshift({
        label: MONTHS[previousMonth],
        month: moment().subtract(i, 'months').month() + 1,
        year: previousMonthYear,
      })
    }
    // minus one to align indexes
    setValue(monthTabs.length - 1)
    setListOfTabs(monthTabs)

    fetchEmployeesReport(monthTabs, monthTabs.length - 1)
  }

  const fetchEmployeesReport = (tabs, val) => {
    const periodMonth = tabs[val]?.month >= 10 ? tabs[val]?.month : '0' + tabs[val]?.month
    const periodId = `${tabs[val]?.year}-M-${periodMonth}`

    return getEmployeesReportStrickland(periodId)
  }

  useEffect(() => {
    setMonthTabs(monthsToDisplay)
  }, [])

  return (
    <PrivateContentLayout
      title={<Trans>Eluvians utilization</Trans>}
      contentPadding={0}
      content={
        <>
          <AppBar
            position="static"
            color="default"
            elevation={0}
            className={classes.appBarBottomBorder}
          >
            <Tabs
              value={value}
              className={classes.tabs}
              onChange={handleChangeTab}
              indicatorColor="secondary"
              variant="scrollable"
              scrollButtons="auto"
            >
              {listOfTabs.map((tab, index) => (
                <Tab
                  key={index}
                  index={index}
                  label={
                    <>
                      {tab.label} {tab.year}
                    </>
                  }
                />
              ))}
            </Tabs>
          </AppBar>
          <BoxFullWidth p={4} pt={2}>
            <div className={classes.root}>
              {/* TAB PANELS */}
              {listOfTabs.map((tab, index) => (
                <TabPanel value={value} index={index} key={index} className={classes.overflowAuto}>
                  <EmployeesGraphStrick loading={employeesReportLoading} data={employeesReport} />
                </TabPanel>
              ))}
            </div>
          </BoxFullWidth>
        </>
      }
    />
  )
}

EmployeePageStrick.propTypes = {
  classes: PropTypes.object,
  getEmployeesReportStrickland: PropTypes.func,
  employeesReportLoading: PropTypes.bool,
  employeesReport: PropTypes.array,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getEmployeesReportStrickland,
    },
    dispatch
  )
}

export default compose(
  withStyles(stricklandStyle),
  connect((store) => {
    return {
      employeesReport: store.strickland.employeesReport,
      employeesReportLoading: store.strickland.employeesReportLoading,
    }
  }, mapDispatchToProps)
)(EmployeePageStrick)
