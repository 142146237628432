import React from 'react'
import {COLORS, ENVIRONMENT, GATEWAY_URL} from 'helper/constants'
import browserHistory from 'helper/history'
import {
  LS_LOGGED_USER,
  LS_SESSION_ID_NAME,
  MOCK_API,
  MOCK_PORT_MAPPING,
} from 'helper/configConstants'
import {toast} from 'react-toastify'
import Toast from 'component/toast/Toast'
import {Trans} from '@lingui/macro'
import NumberFormat from 'react-number-format'

/**
 * Get environment parameter from .env file
 * @param {string} envName - name of parameter
 */
export const resolveEnv = (envName) => {
  const fullName = 'REACT_APP_' + envName
  if (ENVIRONMENT === 'production') {
    return window._env_[fullName]
  }
  return process.env[fullName]
}

/**
 * Redirect user to specific url, can be used outside of component
 * @param {string} route - url to be redirected to
 */
export const redirectTo = (route) => {
  browserHistory.push(route)
}

/**
 * Add service name to gateway url or use mock if set in configConstants
 * @param {string} name - name of service
 */
export const getServiceUrl = (name) => {
  if (ENVIRONMENT !== 'production' && MOCK_API) {
    return `http://localhost:${MOCK_PORT_MAPPING[name]}`
  }
  return `${GATEWAY_URL}/api/${name}`
}

export const fireSuccessToast = (message, options) => {
  return toast.success(<Toast message={message} type="success" />, options)
}

export const fireErrorToast = (message, options) => {
  return toast.error(<Toast message={message} type="error" />, {autoClose: 15000, ...options})
}

export const fireWarningToast = (message, options) => {
  return toast.warning(<Toast message={message} type="warning" />, options)
}

const loopThroughErrors = (message, errors) => {
  if (Array.isArray(errors) && errors?.length !== 0) {
    errors?.forEach((err) => {
      fireErrorToast(<>{err?.message || message}</>)
      if (err?.message === 'Session ID not authenticated.') {
        localStorage.removeItem(LS_SESSION_ID_NAME)
        localStorage.removeItem(LS_LOGGED_USER)
        redirectTo('/login')
      }
    })
  } else {
    fireErrorToast(message)
  }
}

export const globalApiErrorHandler = (error) => {
  if (error.response) {
    const {status, data} = error.response

    switch (status) {
      case 400: // bad request
        loopThroughErrors(<Trans>Bad request</Trans>, data?.errors)
        return Promise.reject(error?.response?.data?.errors)
      case 401: // unauthorized
        loopThroughErrors(<Trans>Unauthorized</Trans>, data?.errors)
        localStorage.removeItem(LS_SESSION_ID_NAME)
        localStorage.removeItem(LS_LOGGED_USER)
        redirectTo('/login')
        break
      case 403: // forbidden
        loopThroughErrors(<Trans>Forbidden</Trans>, data?.errors)
        return Promise.reject(error?.response?.data?.errors)
      case 404: // not found
        loopThroughErrors(<Trans>Not found</Trans>, data?.errors)
        return Promise.reject(error?.response?.data?.errors)
      case 422: // unprocessable entity
        return Promise.reject(error?.response?.data?.errors)
      case 500: // internal server error
        fireErrorToast(<Trans>Internal server error</Trans>)
        return Promise.reject(error?.response)
      default:
        return Promise.reject(error?.response?.data?.errors)
    }
  } else if (error.message === 'Network Error' || error.message === 'Server connection issue') {
    fireErrorToast(<Trans>Server connection issue</Trans>)
  } else {
    fireErrorToast(<Trans>Unknown error</Trans>)
  }
  return true
}

export const mapErrorResponseToForm = (err) => {
  let parsedError = {}
  if (Array.isArray(err)) {
    err.forEach((e) => {
      if (e.source.includes('.')) {
        let errorSplit = e.source.split('.')
        if (parsedError.hasOwnProperty(errorSplit[0])) {
          parsedError[errorSplit[0]][errorSplit[1]] = e.message
        } else {
          parsedError[errorSplit[0]] = {}
          parsedError[errorSplit[0]][errorSplit[1]] = e.message
        }
      } else {
        parsedError[e.source] = e.message
      }
    })
  }
  return parsedError
}

export const prepareOptionsForMultiSelect = (optionArray, valueKey = 'id', nameKey = 'name') => {
  if (Array.isArray(optionArray)) {
    const options = {}
    optionArray.forEach((item, index) => {
      // if you provide array of objects take id as option value
      const optionIndex = item?.[valueKey] ? item[valueKey] : index
      const optionName = item?.[nameKey] ? item[nameKey] : item

      options[optionIndex] = optionName
    })
    return options
  } else {
    return {}
  }
}

export const catchResponseError = (err) => {
  if (err?.response?.status === 422 && err?.response?.data?.errors?.length) {
    return Promise.reject(err?.response?.data?.errors)
  } else {
    return err && globalApiErrorHandler(err)
  }
}

export const getPeriodId = (tab) => {
  const periodMonth = tab?.month >= 10 ? tab.month : '0' + tab.month
  return `${tab.year}-M-${periodMonth}`
}

export const getInitMdsValue = (mds) => {
  return mds < 0 ? 0 : mds
}

export const renderAmountWithCurrency = (value, currency) => {
  const fixedValue = value && Number(value / 100).toFixed(2)
  if (currency === 'CZK') {
    return (
      <NumberFormat thousandSeparator={' '} displayType="text" suffix={' Kč'} value={fixedValue} />
    )
  } else if (currency === 'EUR') {
    return (
      <NumberFormat thousandSeparator={' '} displayType="text" prefix={'€ '} value={fixedValue} />
    )
  } else if (currency === 'USD') {
    return (
      <NumberFormat thousandSeparator={' '} displayType="text" prefix={'$ '} value={fixedValue} />
    )
  } else {
    return (
      <NumberFormat
        thousandSeparator={' '}
        displayType="text"
        suffix={` ${currency}`}
        value={fixedValue}
      />
    )
  }
}

export const renderCurrencySymbol = (currency) => {
  if (currency === 'CZK') {
    return {suffix: ' Kč', prefix: ''}
  } else if (currency === 'EUR') {
    return {prefix: '€ ', suffix: ''}
  } else if (currency === 'USD') {
    return {prefix: '$ ', suffix: ''}
  } else {
    return {suffix: ` ${currency}`, prefix: ''}
  }
}

export const getActualUser = () => {
  return getItemFromStorage(LS_LOGGED_USER)
}

export const getItemFromStorage = (key, defaultValue = undefined) => {
  try {
    return localStorage.getItem(key) ? JSON.parse(localStorage.getItem(key)) : defaultValue
  } catch (e) {
    console.error(e)
    fireErrorToast(<Trans>Local storage could not be parsed</Trans>)
    return defaultValue
  }
}

export const setItemToStorage = (key, item) => {
  localStorage.setItem(key, JSON.stringify(item))
}

export const stringToColour = (str) => {
  let index = 0
  if (str.length === 0) return index
  for (let i = 0; i < str.length; i++) {
    index += str.toLowerCase().charCodeAt(i)
  }

  return COLORS[index % COLORS.length]
}

export const showErrorToastFromObject = (errObject) => {
  for (const [key, value] of Object.entries(errObject)) {
    fireErrorToast(`${key}: ${value}`)
  }
}
