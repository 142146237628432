import {
  GET_ACCOMPANYING_WORKS_FAIL,
  GET_ACCOMPANYING_WORKS_REQUEST,
  GET_ACCOMPANYING_WORKS_SUCCESS,
  GET_ESTIMATION_FAIL,
  GET_ESTIMATION_LIST_FAIL,
  GET_ESTIMATION_LIST_REQUEST,
  GET_ESTIMATION_LIST_SUCCESS,
  GET_ESTIMATION_REQUEST,
  GET_ESTIMATION_SUCCESS,
  GET_STORIES_FAIL,
  GET_STORIES_REQUEST,
  GET_STORIES_SUCCESS,
  GET_SCHEDULE_REQUEST,
  GET_SCHEDULE_SUCCESS,
  GET_SCHEDULE_FAIL,
  GET_PHASES_FAIL,
  GET_PHASES_REQUEST,
  GET_PHASES_SUCCESS,
  GET_QUOTES_REQUEST,
  GET_QUOTES_SUCCESS,
  GET_QUOTES_FAIL,
  GET_QUOTE_ROWS_FAIL,
  GET_QUOTE_ROWS_REQUEST,
  GET_QUOTE_ROWS_SUCCESS,
  GET_QUOTE_REQUEST,
  GET_QUOTE_SUCCESS,
  GET_QUOTE_FAIL,
} from 'redux/actionType'

const initState = {
  estimationListLoading: false,
  estimationListMeta: {},
  estimationList: [],

  estimationLoading: false,
  estimation: {},

  stories: [],
  storiesLoading: false,
  storiesMeta: {},

  accompanyingWorks: [],
  accompanyingWorksLoading: false,
  accompanyingWorksMeta: {},

  schedule: {},
  scheduleLoading: false,

  phases: [],
  phasesLoading: false,
  phasesMeta: {},

  phaseDetail: [],
  phaseDetailLoading: false,

  quotes: [],
  quotesMeta: {},
  quotesLoading: false,

  quote: {},
  quoteLoading: false,

  quoteRows: [],
  quoteRowsLoading: false,
  quoteRowsMeta: {},
}

const odhadomatReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_ESTIMATION_LIST_REQUEST: {
      return {
        ...state,
        estimationListLoading: true,
      }
    }
    case GET_ESTIMATION_LIST_SUCCESS: {
      return {
        ...state,
        estimationListLoading: false,
        estimationListMeta: action.data.meta,
        estimationList: action.data.objects,
      }
    }
    case GET_ESTIMATION_LIST_FAIL: {
      return {
        ...state,
        estimationListLoading: false,
      }
    }
    case GET_ESTIMATION_REQUEST: {
      return {
        ...state,
        estimationLoading: true,
      }
    }
    case GET_ESTIMATION_SUCCESS: {
      return {
        ...state,
        estimationLoading: false,
        estimation: action.data,
      }
    }
    case GET_ESTIMATION_FAIL: {
      return {
        ...state,
        estimationLoading: false,
      }
    }
    case GET_STORIES_REQUEST: {
      return {
        ...state,
        storiesLoading: true,
      }
    }
    case GET_STORIES_SUCCESS: {
      return {
        ...state,
        storiesLoading: false,
        stories: action.data.objects,
        storiesMeta: action.data.meta,
      }
    }
    case GET_STORIES_FAIL: {
      return {
        ...state,
        storiesLoading: false,
      }
    }
    case GET_ACCOMPANYING_WORKS_REQUEST: {
      return {
        ...state,
        accompanyingWorksLoading: true,
      }
    }
    case GET_ACCOMPANYING_WORKS_SUCCESS: {
      return {
        ...state,
        accompanyingWorksLoading: false,
        accompanyingWorks: action.data.objects.reduce((acc, cur) => {
          if (acc[cur.category]) {
            acc[cur.category] = [...acc[cur.category], cur]?.sort((a, b) => a.id - b.id)
          } else {
            acc[cur.category] = [cur]
          }
          return acc
        }, {}),
        accompanyingWorksMeta: action.data.meta,
      }
    }
    case GET_ACCOMPANYING_WORKS_FAIL: {
      return {
        ...state,
        accompanyingWorksLoading: false,
      }
    }
    case GET_SCHEDULE_REQUEST: {
      return {
        ...state,
        scheduleLoading: true,
      }
    }
    case GET_SCHEDULE_SUCCESS: {
      return {
        ...state,
        scheduleLoading: false,
        schedule: action.data,
      }
    }
    case GET_SCHEDULE_FAIL: {
      return {
        ...state,
        scheduleLoading: false,
      }
    }
    case GET_PHASES_REQUEST: {
      return {
        ...state,
        phasesLoading: true,
      }
    }
    case GET_PHASES_SUCCESS: {
      return {
        ...state,
        phasesLoading: false,
        phases: action.data.objects,
        phasesMeta: action.data.meta,
      }
    }
    case GET_PHASES_FAIL: {
      return {
        ...state,
        phasesLoading: false,
      }
    }
    case GET_QUOTES_REQUEST: {
      return {
        ...state,
        quotesLoading: true,
      }
    }
    case GET_QUOTES_SUCCESS: {
      return {
        ...state,
        quotes: action.data.objects,
        quotesMeta: action.data.meta,
        quotesLoading: false,
      }
    }
    case GET_QUOTES_FAIL: {
      return {
        ...state,
        quotesLoading: false,
      }
    }
    case GET_QUOTE_ROWS_REQUEST: {
      return {
        ...state,
        quoteRowsLoading: true,
      }
    }
    case GET_QUOTE_ROWS_SUCCESS: {
      return {
        ...state,
        quoteRows: action.data.objects,
        quoteRowsLoading: false,
        quoteRowsMeta: action.data.meta,
      }
    }
    case GET_QUOTE_ROWS_FAIL: {
      return {
        ...state,
        quoteRowsLoading: false,
      }
    }
    case GET_QUOTE_REQUEST: {
      return {
        ...state,
        quoteLoading: true,
      }
    }
    case GET_QUOTE_SUCCESS: {
      return {
        ...state,
        quote: action.data,
        quoteLoading: false,
      }
    }
    case GET_QUOTE_FAIL: {
      return {
        ...state,
        quoteLoading: false,
      }
    }
    default: {
      return state
    }
  }
}

export default odhadomatReducer
