import {useParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {useEffect} from 'react'
import {getEstimationDetail} from 'redux/action/odhadomatAction'

export const useEstimation = () => {
  const dispatch = useDispatch()
  const {estimation, estimationLoading} = useSelector((store) => store.odhadomat)
  const {id} = useParams()

  useEffect(() => {
    if (Number(id) !== Number(estimation?.id)) {
      dispatch(getEstimationDetail(id))
    }
  }, [])

  return {estimation, estimationLoading}
}
