import React from 'react'
import {Field, Form} from 'react-final-form'
import PropTypes from 'prop-types'
import {required} from 'helper/validations'
import {Trans} from '@lingui/macro'
import PrimaryButton from 'component/material/PrimaryButton'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import TextInput from 'component/field/TextInput'
import Box from '@material-ui/core/Box'

const EmployeeMdsForm = (props) => {
  const {handleSubmit, initialValues} = props

  return (
    <Form onSubmit={handleSubmit} initialValues={initialValues}>
      {(formProps) => {
        return (
          <form onSubmit={formProps.handleSubmit}>
            <GridContainer spacing={2}>
              <GridItem xs={6}>
                <Field
                  name="obligation_percentage"
                  label={<Trans>Obligated (%)</Trans>}
                  component={TextInput}
                  type={'number'}
                  inputProps={{min: 0, max: 100}}
                  validate={required}
                />
              </GridItem>
              <GridItem xs={6}>
                <Field
                  name="utilization_percentage"
                  label={<Trans>Utilized (%)</Trans>}
                  component={TextInput}
                  type={'number'}
                  inputProps={{min: 0, max: 100}}
                  validate={required}
                />
              </GridItem>
            </GridContainer>

            <GridContainer justifyContent={'flex-end'}>
              <Box pt={3}>
                <PrimaryButton fullWidth={false} type="submit" text={<Trans>Save</Trans>} />
              </Box>
            </GridContainer>
          </form>
        )
      }}
    </Form>
  )
}

EmployeeMdsForm.propTypes = {
  handleSubmit: PropTypes.func,
  obligatedMds: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default EmployeeMdsForm
