const vykazomatPageStyle = (theme) => ({
  link: {
    fontWeight: 600,
    color: theme.palette.primary.main,
    borderBottom: '1px solid ' + theme.palette.secondary.main,
    cursor: 'pointer',
    textDecoration: 'none',
  },
  flexBasisZero: {
    flexBasis: 0,
    flexGrow: 0,
  },
})

export default vykazomatPageStyle
