import Table from 'component/material/table/Table'
import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {createEstimation, deleteEstimation, getEstimationList} from 'redux/action/odhadomatAction'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import {updateGlobalSetting} from 'redux/action/globalSettingAction'
import GridItem from 'component/material/GridItem'
import {Trans} from '@lingui/macro'
import GridContainer from 'component/material/GridContainer'
import {DATE_FORMAT, DB_DATE_TIME_FORMAT, ODHAD_LEVEL, STORY_LAYERS} from 'helper/constants'
import {Add, Visibility, AccessTime, BusinessCenter, Delete} from '@material-ui/icons'
import {mapErrorResponseToForm, redirectTo} from 'helper/functions'
import PrimaryButton from 'component/material/PrimaryButton'
import DialogWindow from 'component/material/DialogWindow'
import OdhadForm from 'component/OdhadomatPage/OdhadDetail/OdhadForm'
import PrivateContentLayout from 'layout/PrivateLayout/PrivateContentLayout'
import Box from '@material-ui/core/Box'
import {useTableSort} from 'hooks/useTableSort'
import moment from 'moment'
import TableIconButton from 'component/general/TableIconButton'
import {usePagination} from 'hooks/usePagination'
import {useWarningDialog} from 'hooks/useWarningDialog'

const OdhadyPage = (props) => {
  const {
    updateGlobalSetting,
    getEstimationList,
    estimationList,
    estimationListMeta,
    estimationListLoading,
    rowsPerPage,
    deleteEstimation,
    createEstimation,
  } = props

  const [openNew, setOpenNew] = useState(false)

  const [tableSort, onSortClick] = useTableSort({orderBy: 'created_at', orderDirection: 'desc'})
  const [page, onChangePage, onChangeRowsPerPage] = usePagination(updateGlobalSetting)

  const [DeleteWarningDialog, deleteData, onOpenDelete, onCloseDelete] = useWarningDialog()

  const columns = [
    {
      name: 'title',
      orderKey: 'title',
      label: <Trans>Title</Trans>,
    },
    {
      name: 'level',
      orderKey: 'level',
      label: <Trans>Level</Trans>,
      render: (val) => ODHAD_LEVEL.find((level) => level.value === val)?.label,
    },
    {
      name: 'created_at',
      orderKey: 'created_at',
      label: <Trans>Created at</Trans>,
      render: (val) => val && moment(val, DB_DATE_TIME_FORMAT)?.format(DATE_FORMAT),
    },
    {
      name: '',
      align: 'right',
      render: (val, col, rowIndex, row) => (
        <GridContainer spacing={2} direction={'row'} wrap={'nowrap'} justifyContent={'flex-end'}>
          <GridItem>
            <TableIconButton
              tooltipTitle={<Trans>Open estimation</Trans>}
              onClick={handleOpenEstimation(row)}
              Icon={Visibility}
            />
          </GridItem>
          <GridItem>
            <TableIconButton
              tooltipTitle={<Trans>Open schedule</Trans>}
              onClick={handleOpenSchedule(row)}
              Icon={AccessTime}
            />
          </GridItem>
          <GridItem>
            <TableIconButton
              tooltipTitle={<Trans>Open quotes</Trans>}
              onClick={handleOpenQuotes(row)}
              Icon={BusinessCenter}
            />
          </GridItem>
          <GridItem>
            <TableIconButton
              tooltipTitle={<Trans>Delete estimation</Trans>}
              onClick={onOpenDelete(row)}
              Icon={Delete}
            />
          </GridItem>
        </GridContainer>
      ),
    },
  ]

  const handleOpenQuotes = (row) => () => {
    redirectTo(`/odhadomat/odhady/${row.id}/nabidky`)
  }

  const handleOpenEstimation = (row) => () => {
    redirectTo('/odhadomat/odhady/' + row.id)
  }

  const handleOpenSchedule = (row) => () => {
    redirectTo(`/odhadomat/odhady/${row.id}/harmonogram`)
  }

  const handleDeleteEstimation = () => {
    return deleteEstimation(deleteData?.id).then(() => {
      onCloseDelete()
      fetchEstimationList()
    })
  }

  const fetchEstimationList = () => {
    getEstimationList(rowsPerPage, page * rowsPerPage, tableSort)
  }

  const handleOpenCreateWindow = () => {
    setOpenNew(true)
  }

  const handleCloseCreateWindow = () => {
    setOpenNew(false)
  }

  const prepareData = (values) => {
    const layers = []
    values.layers.forEach((layer) => layers.push(STORY_LAYERS[layer]))

    return {
      ...values,
      layers: layers,
      avg_story_mds: Number(values.avg_story_mds),
      reserve: Number(values.reserve),
      team_size: Number(values.team_size),
      senior_price: Number(values.senior_price) * 100,
      junior_price: Number(values.junior_price) * 100,
      hosting_price: Number(values.hosting_price) * 100,
    }
  }

  const handleSubmit = (values) => {
    const data = prepareData(values)
    return createEstimation(data)
      .then(() => {
        handleCloseCreateWindow()
        fetchEstimationList()
      })
      .catch((err) => {
        return mapErrorResponseToForm(err)
      })
  }

  useEffect(() => {
    fetchEstimationList()
  }, [page, rowsPerPage, tableSort])

  return (
    <>
      {/* NEW ESTIMATION */}
      <DialogWindow
        onClose={handleCloseCreateWindow}
        open={openNew}
        title={<Trans>New estimation</Trans>}
        maxWidth={'md'}
        fullWidth={true}
      >
        <OdhadForm handleSubmit={handleSubmit} />
      </DialogWindow>

      {/* DELETE DIALOG */}
      <DeleteWarningDialog
        onConfirm={handleDeleteEstimation}
        message={<Trans>Estimation {deleteData?.title} will be deleted!</Trans>}
      />

      <PrivateContentLayout
        title={<Trans>Estimations</Trans>}
        actions={
          <PrimaryButton
            fullWidth={false}
            text={
              <GridContainer alignItems={'center'}>
                <Box pr={2}>
                  <Add fontSize={'small'} />
                </Box>
                <Trans>Create</Trans>
              </GridContainer>
            }
            onClick={handleOpenCreateWindow}
          />
        }
        content={
          <Table
            columns={columns}
            data={estimationList}
            loading={estimationListLoading}
            orderBy={tableSort.orderBy}
            orderDirection={tableSort.orderDirection}
            onSortClick={onSortClick}
            count={estimationListMeta.total_count}
            page={page}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            pagination={true}
          />
        }
      />
    </>
  )
}

OdhadyPage.propTypes = {
  rowsPerPage: PropTypes.number,
  updateGlobalSetting: PropTypes.func,
  getEstimationList: PropTypes.func,
  estimationList: PropTypes.array,
  estimationListMeta: PropTypes.object,
  estimationListLoading: PropTypes.bool,
  deleteEstimation: PropTypes.func,
  createEstimation: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getEstimationList,
      updateGlobalSetting,
      deleteEstimation,
      createEstimation,
    },
    dispatch
  )
}

export default compose(
  connect((store) => {
    return {
      estimationList: store.odhadomat.estimationList,
      estimationListMeta: store.odhadomat.estimationListMeta,
      estimationListLoading: store.odhadomat.estimationListLoading,
      rowsPerPage: store.globalSetting.rowsPerPage,
    }
  }, mapDispatchToProps)
)(OdhadyPage)
