import {useEffect} from 'react'
import {bindActionCreators, compose} from 'redux'
import withStyles from '@material-ui/core/styles/withStyles'
import projektomatPageStyle from 'component/ProjektomatPage/projektomatPageStyle'
import {connect} from 'react-redux'
import {getEmployeeWages} from 'redux/action/projektomatAction'
import Loader from 'component/material/Loader'
import Table from 'component/material/table/Table'
import {Trans} from '@lingui/macro'
import NewEmployeeWageForm from 'component/ProjektomatPage/NewEmployeeWageForm'
import {renderAmountWithCurrency} from 'helper/functions'
import moment from 'moment'
import {DATE_FORMAT} from 'helper/constants'

const EmployeeWages = (props) => {
  const {classes, employee, getEmployeeWages, wages, wagesLoading} = props

  useEffect(() => {
    getEmployeeWages(employee.id)
  }, [])

  const columns = [
    {
      name: 'monthly_amount',
      label: <Trans>Amount</Trans>,
      align: 'right',
      render: (val, col, rowIndex, row) => val && renderAmountWithCurrency(val, row.currency),
    },
    {
      name: 'date_from',
      align: 'right',
      label: <Trans>Date from</Trans>,
      render: (val) => val && moment(val).format(DATE_FORMAT),
    },
    {
      name: 'date_to',
      align: 'right',
      label: <Trans>Date to</Trans>,
      render: (val) => (val ? moment(val).format(DATE_FORMAT) : <Trans>Until now</Trans>),
    },
  ]

  return (
    <>
      {wagesLoading ? (
        <Loader wrapper={true} />
      ) : (
        <>
          <NewEmployeeWageForm employee={employee} />
          <Table data={wages} isOverflowAuto={false} columns={columns} />
        </>
      )}
    </>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getEmployeeWages,
    },
    dispatch
  )
}

export default compose(
  withStyles(projektomatPageStyle),
  connect((store) => {
    return {
      wages: store.projektomat.employeeWages,
      wagesLoading: store.projektomat.employeeWagesLoading,
      wagesMeta: store.projektomat.employeeWagesMeta,
    }
  }, mapDispatchToProps)
)(EmployeeWages)
