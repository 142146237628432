import React, {useEffect, useState} from 'react'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import {Trans} from '@lingui/macro'
import withStyles from '@material-ui/core/styles/withStyles'
import managementPageStyle from 'component/ManagementPage/managementPageStyle'
import PropTypes from 'prop-types'
import {bindActionCreators, compose} from 'redux'
import {getUserList} from 'redux/action/userAction'
import {updateGlobalSetting} from 'redux/action/globalSettingAction'
import {connect} from 'react-redux'
import Table from 'component/material/table/Table'
import {Edit} from '@material-ui/icons'
import DialogWindow from 'component/material/DialogWindow'
import Chip from '@material-ui/core/Chip'
import Box from '@material-ui/core/Box'
import UserManagementForm from 'component/ManagementPage/UserManagementForm'
import PrivateContentLayout from 'layout/PrivateLayout/PrivateContentLayout'
import {useTableSort} from 'hooks/useTableSort'
import TableIconButton from 'component/general/TableIconButton'
import {usePagination} from 'hooks/usePagination'

const ManagementUserPage = (props) => {
  const {
    classes,
    getUserList,
    updateGlobalSetting,
    userList,
    userListLoading,
    userListMeta,
    rowsPerPage,
  } = props

  const [page, onChangePage, onChangeRowsPerPage] = usePagination(updateGlobalSetting)
  const [tableSort, onSortClick] = useTableSort({orderBy: 'name', orderDirection: 'asc'})
  const [openDialog, setOpenDialog] = useState({visible: false, row: null})

  const handleOpenDialog = (row) => () => {
    setOpenDialog({visible: true, row: row})
  }
  const handleCloseDialog = () => {
    setOpenDialog({visible: false, row: null})
  }

  const columns = [
    {
      name: 'name',
      orderKey: 'name',
      label: <Trans>Name</Trans>,
    },
    {
      name: 'email',
      orderKey: 'email',
      label: <Trans>Email</Trans>,
    },
    {
      name: 'roles',
      orderKey: 'roles',
      label: <Trans>Roles</Trans>,
      render: (val) => (
        <GridContainer>
          {val.map((role, index) => (
            <Box p={1} key={index}>
              <Chip size={'small'} key={role.id} label={role.name} />
            </Box>
          ))}
        </GridContainer>
      ),
    },
    {
      name: 'is_active',
      orderKey: 'is_active',
      label: <Trans>State</Trans>,
      render: (val) =>
        val ? (
          <span className={classes.activeState}>
            <Trans>Active</Trans>
          </span>
        ) : (
          <span className={classes.disabledState}>
            <Trans>Disabled</Trans>
          </span>
        ),
    },
    {
      name: '',
      align: 'right',
      render: (val, col, rowIndex, row) => (
        <GridContainer spacing={2} direction={'row'} wrap={'nowrap'} justifyContent={'flex-end'}>
          <GridItem>
            <TableIconButton
              tooltipTitle={<Trans>Edit issued invoice</Trans>}
              onClick={handleOpenDialog(row)}
              Icon={Edit}
            />
          </GridItem>
        </GridContainer>
      ),
    },
  ]

  const handleGetUserList = () => {
    getUserList(rowsPerPage, page * rowsPerPage, tableSort)
  }

  useEffect(() => {
    handleGetUserList()
  }, [tableSort, page, rowsPerPage])

  return (
    <>
      <DialogWindow
        onClose={handleCloseDialog}
        open={openDialog.visible}
        title={openDialog?.row?.name}
        maxWidth={'xs'}
        fullWidth={true}
      >
        <UserManagementForm
          initValues={openDialog.row}
          onClose={handleCloseDialog}
          handleGetUserList={handleGetUserList}
        />
      </DialogWindow>

      <PrivateContentLayout
        title={<Trans>Users</Trans>}
        content={
          <Table
            columns={columns}
            data={userList}
            loading={userListLoading}
            count={userListMeta.total_count}
            page={page}
            onChangePage={onChangePage}
            onChangeRowsPerPage={onChangeRowsPerPage}
            pagination={true}
            onSortClick={onSortClick}
            orderBy={tableSort.orderBy}
            orderDirection={tableSort.orderDirection}
          />
        }
      />
    </>
  )
}

ManagementUserPage.propTypes = {
  classes: PropTypes.object,
  getUserList: PropTypes.func,
  updateGlobalSetting: PropTypes.func,
  userList: PropTypes.array,
  userListLoading: PropTypes.bool,
  userListMeta: PropTypes.object,
  rowsPerPage: PropTypes.number,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      updateGlobalSetting,
      getUserList,
    },
    dispatch
  )
}

export default compose(
  withStyles(managementPageStyle),
  connect((store) => {
    return {
      userList: store.user.userList,
      userListLoading: store.user.userListLoading,
      userListMeta: store.user.userListMeta,
      rowsPerPage: store.globalSetting.rowsPerPage,
    }
  }, mapDispatchToProps)
)(ManagementUserPage)
