import {Trans} from '@lingui/macro'
import React from 'react'
import {resolveEnv} from 'helper/functions'

export const ENVIRONMENT = process.env.NODE_ENV
export const PUBLIC_API_KEY = resolveEnv('PUBLIC_API_KEY')
export const GATEWAY_URL = resolveEnv('GATEWAY_URL')

export const DRAWER_WIDTH = 220

// number of days from the initial day of invoice to the latest possible to be paid
export const MATURITY_DATE_DAYS = 14

// database date format
export const DB_DATE_FORMAT = 'YYYY-MM-DD'
export const TIME_FORMAT = 'HH:mm'
export const DATE_FORMAT = 'DD.MM.YYYY'
export const DB_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm'

export const LANGUAGE_OPTIONS = [
  {value: 'CZ', label: <Trans>Czech</Trans>},
  {value: 'EN', label: <Trans>English</Trans>},
]

export const REPORT_VERSION = [
  {value: 'full', label: <Trans>Full version</Trans>},
  {value: 'lite', label: <Trans>Lite version</Trans>},
]

export const STORY_LAYERS = ['fe', 'be', 'db', 'api', 'ux', 'ui', 'test', 'infra']

export const ODHAD_LEVEL = [
  {value: 'indicative', label: <Trans>Indicative</Trans>},
  {value: 'obligatory', label: <Trans>Obligatory</Trans>},
]

export const CURRENCY_LIST = ['CZK', 'EUR', 'USD']

export const MONTHS = [
  <Trans>JANUARY</Trans>,
  <Trans>FEBRUARY</Trans>,
  <Trans>MARCH</Trans>,
  <Trans>APRIL</Trans>,
  <Trans>MAY</Trans>,
  <Trans>JUNE</Trans>,
  <Trans>JULY</Trans>,
  <Trans>AUGUST</Trans>,
  <Trans>SEPTEMBER</Trans>,
  <Trans>OCTOBER</Trans>,
  <Trans>NOVEMBER</Trans>,
  <Trans>DECEMBER</Trans>,
]

// colors used in graphs
export const COLORS = [
  '#1B98E0',
  '#6FEDB7',
  '#FFBB28',
  '#FF8042',
  '#A882DD',
  '#b4e301',
  '#931621',
  '#1b7ee0',
  '#ffed28',
  '#b956e1',
]

export const PRICE_POLICY = ['min', 'max', 'avg', 'range']
