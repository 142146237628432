import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import {Paper, Typography} from '@material-ui/core'
import React from 'react'
import PropTypes from 'prop-types'
import BoxFullWidth from 'component/material/BoxFullWidth'
import withStyles from '@material-ui/core/styles/withStyles'
import privateLayoutStyle from 'layout/PrivateLayout/privateLayoutStyle'

const PrivateContentLayout = (props) => {
  const {classes, title, actions, content, contentPadding = 4} = props

  return (
    <GridContainer direction="row" alignItems="center">
      {/* TITLE */}
      <GridItem
        container
        xs={12}
        alignItems={'center'}
        justifyContent={'space-between'}
        className={classes.privateContentLayoutTopBar}
      >
        <GridItem xs={true}>
          <Typography variant={'h6'} color="primary" align="left">
            {title}
          </Typography>
        </GridItem>
        <GridItem xs={'auto'}>{actions}</GridItem>
      </GridItem>

      {/* CONTENT */}
      <GridItem container xs={12} justifyContent="center">
        <BoxFullWidth pt={8}>
          <Paper elevation={5}>
            <BoxFullWidth p={contentPadding}>{content}</BoxFullWidth>
          </Paper>
        </BoxFullWidth>
      </GridItem>
    </GridContainer>
  )
}

PrivateContentLayout.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.node,
  actions: PropTypes.node,
  content: PropTypes.node,
  contentPadding: PropTypes.number,
}

export default withStyles(privateLayoutStyle)(PrivateContentLayout)
