import {
  GET_VYKAZOMAT_PROJECT_LIST_FAIL,
  GET_VYKAZOMAT_PROJECT_LIST_REQUEST,
  GET_VYKAZOMAT_PROJECT_LIST_SUCCESS,
  GET_VYKAZOMAT_REPORT_STATE_REQUEST,
  GET_VYKAZOMAT_REPORT_STATE_SUCCESS,
  GET_VYKAZOMAT_REPORT_STATE_FAIL,
  GET_VYKAZOMAT_REPORT_RESULT_REQUEST,
  GET_VYKAZOMAT_REPORT_RESULT_SUCCESS,
  GET_VYKAZOMAT_REPORT_RESULT_FAIL,
  CREATE_VYKAZOMAT_REPORT_REQUEST,
  CREATE_VYKAZOMAT_REPORT_SUCCESS,
  CREATE_VYKAZOMAT_REPORT_FAIL,
} from 'redux/actionType'

const initState = {
  loading: false,
  projectList: [],
  taskId: null,
  reportInProgress: false,
  reportId: null,
  reportUrl: null,
}

const vykazomatReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_VYKAZOMAT_PROJECT_LIST_REQUEST: {
      return {
        ...state,
        loading: true,
      }
    }
    case GET_VYKAZOMAT_PROJECT_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        projectList: action.data,
      }
    }
    case GET_VYKAZOMAT_PROJECT_LIST_FAIL: {
      return {
        ...state,
        loading: false,
      }
    }
    case CREATE_VYKAZOMAT_REPORT_REQUEST: {
      return {
        ...state,
        reportInProgress: true,
      }
    }
    case CREATE_VYKAZOMAT_REPORT_SUCCESS: {
      return {
        ...state,
        reportId: action.data.task_id,
      }
    }
    case CREATE_VYKAZOMAT_REPORT_FAIL: {
      return {
        ...state,
        reportInProgress: false,
      }
    }
    case GET_VYKAZOMAT_REPORT_STATE_REQUEST: {
      return {
        ...state,
      }
    }
    case GET_VYKAZOMAT_REPORT_STATE_SUCCESS: {
      return {
        ...state,
        reportId: null,
      }
    }
    case GET_VYKAZOMAT_REPORT_STATE_FAIL: {
      return {
        ...state,
        reportInProgress: false,
      }
    }
    case GET_VYKAZOMAT_REPORT_RESULT_REQUEST: {
      return {
        ...state,
      }
    }
    case GET_VYKAZOMAT_REPORT_RESULT_SUCCESS: {
      return {
        ...state,
        reportUrl: action.data.url,
        reportInProgress: false,
        reportId: null,
      }
    }
    case GET_VYKAZOMAT_REPORT_RESULT_FAIL: {
      return {
        ...state,
        reportInProgress: false,
        reportId: null,
      }
    }
    default: {
      return state
    }
  }
}

export default vykazomatReducer
