import axios from 'axios'
import {ENVIRONMENT, GATEWAY_URL, PUBLIC_API_KEY} from 'helper/constants'
import {getItemFromStorage} from 'helper/functions'
import {LS_SESSION_ID_NAME, MOCK_API} from 'helper/configConstants'

const instance = axios.create({
  baseURL: ENVIRONMENT !== 'production' && MOCK_API ? 'http://localhost:' : `${GATEWAY_URL}/api/`,
})

// `withCredentials` indicates whether or not cross-site Access-Control requests
// should be made using credentials , default = false
instance.defaults.withCredentials = true

instance.interceptors.request.use((config) => {
  const configCopy = config
  const token = getItemFromStorage(LS_SESSION_ID_NAME)

  if (token) {
    configCopy.headers['session-id'] = token
  }

  if (PUBLIC_API_KEY) {
    configCopy.headers.common['X-PUBLIC-API-KEY'] = PUBLIC_API_KEY
  }

  return configCopy
})

axios.interceptors.response.use(
  (res) => {
    return res
  },
  (err) => {
    return Promise.reject(err)
  }
)

export default instance
