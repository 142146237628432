import {UPDATE_FILTERS} from 'redux/actionType'

const updateFilter = (data) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_FILTERS,
      data,
    })
  }
}

export default updateFilter
