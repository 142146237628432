const generalStyle = (theme) => ({
  backButton: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    '& svg': {
      width: 16,
      height: 16,
    },
  },
})

export default generalStyle
