import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import {bindActionCreators, compose} from 'redux'
import {getProjectAssignments, patchProjectAssignments} from 'redux/action/projektomatAction'
import withStyles from '@material-ui/core/styles/withStyles'
import projektomatPageStyle from 'component/ProjektomatPage/projektomatPageStyle'
import {connect} from 'react-redux'
import Table from 'component/material/table/Table'
import {Trans} from '@lingui/macro'
import Chip from '@material-ui/core/Chip'
import MenuItem from '@material-ui/core/MenuItem'
import Checkbox from '@material-ui/core/Checkbox'
import ListItemText from '@material-ui/core/ListItemText'
import Select from '@material-ui/core/Select'
import {getRoles} from 'redux/action/roleAction'
import CustomTooltip from 'component/material/Tooltip'
import Loader from 'component/material/Loader'

const ProjectAssignment = (props) => {
  const {
    classes,
    getProjectAssignments,
    getRoles,
    patchProjectAssignments,
    roleList,
    project,
    projectAssignments,
    projectAssignmentsLoading,
  } = props

  const [focusedSelect, setFocusedSelect] = useState([])
  const [shouldUpdate, setShouldUpdate] = useState(false)

  const handleBlur = (row) => {
    const rowRoles = row.roles.map((role) => role.role_ref)
    if (rowRoles.sort().toString() !== focusedSelect.sort().toString()) {
      patchProjectAssignments(project.id, row.id, focusedSelect).then(() => {
        setShouldUpdate(true)
      })
    }
  }

  const handleChange = (value) => {
    let newFocusedSelect = [...focusedSelect]

    if (newFocusedSelect.includes(value)) {
      newFocusedSelect = newFocusedSelect.filter((select) => select !== value)
    } else {
      newFocusedSelect.push(value)
    }

    setFocusedSelect(newFocusedSelect)
  }

  const handleFocus = (row) => {
    setFocusedSelect(row?.roles?.map((role) => role.role_ref))
  }

  const columns = [
    {
      name: 'employee',
      label: <Trans>Eluvian</Trans>,
      width: '180px',
      render: (val) => val.name,
    },
    {
      name: 'roles',
      label: <Trans>Roles</Trans>,
      render: (val, col, rowIndex, row) => (
        <span className={classes.fullWidth}>
          <Select
            name={row?.employee?.name}
            className={classes.fullWidth}
            multiple
            value={row?.roles?.map((role) => role.role_ref)}
            onClose={() => {
              return handleBlur(row)
            }}
            onOpen={() => {
              return handleFocus(row)
            }}
            renderValue={() => (
              <div className={classes.chips}>
                {row?.roles
                  ?.map((role) => role.role_ref)
                  .map((value) => (
                    <CustomTooltip
                      key={value}
                      title={roleList?.find((role) => role.ref === value)?.name}
                    >
                      <Chip size={'small'} label={value} className={classes.chipMargin} />
                    </CustomTooltip>
                  ))}
              </div>
            )}
          >
            {roleList.map((value, index) => (
              <MenuItem
                className={classes.tableMenuItem}
                key={index}
                value={value.ref}
                onClick={() => {
                  return handleChange(value.ref)
                }}
              >
                <Checkbox checked={focusedSelect?.includes(value.ref)} size={'small'} />
                <ListItemText
                  primary={`${value.ref} - ${value.name}`}
                  primaryTypographyProps={{variant: 'body2'}}
                />
              </MenuItem>
            ))}
          </Select>
        </span>
      ),
    },
  ]

  useEffect(() => {
    getProjectAssignments(project.id)
    getRoles()
  }, [])

  useEffect(() => {
    if (shouldUpdate) {
      getProjectAssignments(project.id)
      setShouldUpdate(false)
    }
  }, [shouldUpdate])

  return (
    <>
      {projectAssignmentsLoading ? (
        <Loader wrapper={true} />
      ) : (
        <Table data={projectAssignments} columns={columns} isOverflowAuto={false} />
      )}
    </>
  )
}

ProjectAssignment.propTypes = {
  classes: PropTypes.object,
  project: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  getProjectAssignments: PropTypes.func,
  getRoles: PropTypes.func,
  roleList: PropTypes.array,
  patchProjectAssignments: PropTypes.func,
  projectAssignmentsLoading: PropTypes.bool,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getProjectAssignments,
      getRoles,
      patchProjectAssignments,
    },
    dispatch
  )
}

export default compose(
  withStyles(projektomatPageStyle),
  connect((store) => {
    return {
      projectAssignmentsLoading: store.projektomat.projectAssignmentsLoading,
      projectAssignments: store.projektomat.projectAssignments,
      roleList: store.role.roles,
    }
  }, mapDispatchToProps)
)(ProjectAssignment)
