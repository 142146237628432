import React, {useState} from 'react'
import {BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, LabelList, Cell} from 'recharts'
import GridContainer from 'component/material/GridContainer'
import Loader from 'component/material/Loader'
import GridItem from 'component/material/GridItem'
import BoxFullWidth from 'component/material/BoxFullWidth'
import withStyles from '@material-ui/core/styles/withStyles'
import stricklandStyle from 'component/Strickland/stricklandStyle'
import PropTypes from 'prop-types'
import {Trans} from '@lingui/macro'
import {ToggleButton, ToggleButtonGroup} from '@material-ui/lab'

const EmployeeGraphStrick = (props) => {
  const {loading, data, classes} = props

  const [selectedView, setSelectedView] = useState('MD')
  const [selectedEmployee, setSelectedEmployee] = useState(null)

  const handleChangeView = (e, value) => {
    setSelectedView(value)
  }

  const CustomTooltip = (props) => {
    const {active, payload, label} = props
    const getLabel = () => {
      if (payload?.[0]?.payload?.project_is_billable) {
        return `${label} $`
      } else {
        return `${label}`
      }
    }

    if (active && payload && payload.length) {
      return (
        <GridContainer className={classes.customGraphTooltip}>
          <GridItem xs={12} className={classes.tooltipLabel}>
            {getLabel()}
          </GridItem>

          <GridItem style={{color: payload[0]?.color}} xs={12}>{`${payload[1]?.name}`}</GridItem>
          <ul className={classes.tooltipList} style={{color: payload[0]?.color}}>
            <li>
              <GridContainer justifyContent={'space-between'} alignItems={'flex-end'}>
                <GridItem xs={7} style={{color: payload[0]?.color}}>
                  <Trans>Billable</Trans>
                  {' $'}
                </GridItem>
                <GridItem
                  xs={5}
                  style={{textAlign: 'end'}}
                >{`${payload[0]?.value} ${selectedView}`}</GridItem>
              </GridContainer>
            </li>{' '}
            <li>
              <GridContainer justifyContent={'space-between'} alignItems={'flex-end'}>
                <GridItem xs={7} style={{color: payload[0]?.color}}>
                  <Trans>Not billable</Trans>
                </GridItem>
                <GridItem
                  xs={5}
                  style={{textAlign: 'end'}}
                >{`${payload[1]?.value} ${selectedView}`}</GridItem>
              </GridContainer>
            </li>
          </ul>

          <GridItem style={{color: payload[2]?.color}} xs={12}>{`${payload[3]?.name}`}</GridItem>
          <ul className={classes.tooltipList}>
            <li>
              <GridContainer justifyContent={'space-between'} alignItems={'flex-end'}>
                <GridItem xs={7}>
                  <Trans>Billable</Trans>
                  {' $'}
                </GridItem>
                <GridItem
                  xs={5}
                  style={{textAlign: 'end'}}
                >{`${payload[2]?.value} ${selectedView}`}</GridItem>
              </GridContainer>
            </li>{' '}
            <li>
              <GridContainer justifyContent={'space-between'} alignItems={'flex-end'}>
                <GridItem xs={7}>
                  <Trans>Not billable</Trans>
                </GridItem>
                <GridItem
                  xs={5}
                  style={{textAlign: 'end'}}
                >{`${payload[3]?.value} ${selectedView}`}</GridItem>
              </GridContainer>
            </li>
          </ul>
        </GridContainer>
      )
    }
    // Not billable
    return null
  }

  const CustomEmployeeTooltip = (props) => {
    const {active, payload, label} = props

    const getLabel = () => {
      if (payload?.[0]?.payload?.project_is_billable) {
        return `${label} $`
      } else {
        return `${label}`
      }
    }

    if (active && payload && payload.length) {
      return (
        <GridContainer className={classes.customGraphTooltip}>
          <GridItem xs={12}>{getLabel()}</GridItem>
          <GridItem style={{color: payload[0]?.color}} xs={8}>{`${payload[0]?.name}:`}</GridItem>
          <GridItem xs={4} style={{textAlign: 'end'}}>{`${payload[0]?.value} MD`}</GridItem>
          <GridItem style={{color: payload[1]?.color}} xs={8}>{`${payload[1]?.name}:`}</GridItem>
          <GridItem xs={4} style={{textAlign: 'end'}}>{`${payload[1]?.value} MD`}</GridItem>
        </GridContainer>
      )
    }

    return null
  }

  const getEmployeeName = (record) => {
    const name = record.employee_email?.split('@')?.[0]
    return name
      ?.split('.')
      ?.map((namePart) => namePart.charAt(0).toUpperCase() + namePart.slice(1))
      ?.join(' ')
  }

  const openSelectedEmployee = (index) => {
    if (index || index === 0) {
      setSelectedEmployee(data[index])
    }
  }

  const formatXAxis = (value, index) => {
    if (selectedEmployee?.projects?.[index]?.project_is_billable) {
      return `${value} $`
    } else {
      return `${value}`
    }
  }

  const getEmployeeBarChartWidth = (length) => {
    if (length) {
      if (length < 4) {
        return length * 160
      } else {
        return length * 100
      }
    }
  }

  const getProjectData = () => {
    return selectedEmployee?.projects?.map((record) => ({
      Očekávání: record.planned_utilized_mds,
      Realita: record.real_utilized_mds,
      project_ref: record.project_ref,
      project_is_billable: record.project_is_billable,
    }))
  }

  return (
    <GridContainer>
      <BoxFullWidth p={2} className={classes.gridBoxHeight}>
        {loading ? (
          <Loader wrapper={true} />
        ) : (
          <GridContainer alignItems={'flex-start'}>
            {data?.length ? (
              <>
                <GridItem xs={12} container>
                  <GridItem xs={10} container alignItems={'center'}>
                    <div className={classes.legendLine}>
                      <div className={classes.expected} />
                      <div className={classes.expectedText}>
                        <Trans>Expectation</Trans>
                        {' $'}
                      </div>
                      <div className={classes.expectedBillable} />
                      <div className={classes.expectedText}>
                        <Trans>Expectation</Trans>
                      </div>
                      <div className={classes.reality} />
                      <div className={classes.realityText}>
                        <Trans>Reality</Trans>
                        {' $'}
                      </div>
                      <div className={classes.realityBillable} />
                      <div className={classes.realityText}>
                        <Trans>Reality</Trans>
                      </div>
                    </div>
                  </GridItem>
                  <GridItem xs={2}>
                    <GridItem className={classes.toggleButtons}>
                      <ToggleButtonGroup value={selectedView} exclusive onChange={handleChangeView}>
                        <ToggleButton value="MD">MD</ToggleButton>
                        <ToggleButton value="%">%</ToggleButton>
                      </ToggleButtonGroup>
                    </GridItem>
                  </GridItem>
                </GridItem>

                {/* ELUVIANI */}
                <GridItem xs={12} className={classes.overflowAuto}>
                  <BarChart
                    width={data.length * 100}
                    height={340}
                    data={data.map((record) => ({
                      name: getEmployeeName(record),
                      Očekávání:
                        selectedView === 'MD'
                          ? Math.round(
                              (record.planned_utilized_mds - record.planned_billable_mds) * 100
                            ) / 100
                          : Math.round(
                              (record.planned_utilization_percentage -
                                record.planned_billable_percentage) *
                                100
                            ) / 100,
                      planned_utilized_mds:
                        selectedView === 'MD'
                          ? record.planned_utilized_mds
                          : record.planned_utilization_percentage,
                      'Očekávání $':
                        selectedView === 'MD'
                          ? record.planned_billable_mds
                          : record.planned_billable_percentage,
                      Realita:
                        selectedView === 'MD'
                          ? Math.round(
                              (record.real_utilized_mds - record.real_billable_mds) * 100
                            ) / 100
                          : Math.round(
                              (record.real_utilization_percentage -
                                record.real_billable_percentage) *
                                100
                            ) / 100,
                      real_utilized_mds:
                        selectedView === 'MD'
                          ? record.real_utilized_mds
                          : record.real_utilization_percentage,
                      'Realita $':
                        selectedView === 'MD'
                          ? record.real_billable_mds
                          : record.real_billable_percentage,
                    }))}
                    margin={{
                      top: 20,
                      right: 0,
                      left: 0,
                      bottom: 10,
                    }}
                    onClick={(record) => openSelectedEmployee(record?.activeTooltipIndex)}
                  >
                    <CartesianGrid stroke="#dddddd" strokeDasharray="4 4" />
                    <XAxis
                      dataKey="name"
                      interval={0}
                      tickLine={false}
                      tick={{fontSize: 12}}
                      height={60}
                      onClick={(record, index) => openSelectedEmployee(index)}
                    />
                    <YAxis
                      unit={selectedView === 'MD' ? ' MD' : ' %'}
                      tickLine={false}
                      tick={{fontSize: 12}}
                    />
                    {/* tooltip visible on hover above single bar */}
                    <Tooltip content={<CustomTooltip />} />
                    <Bar
                      isAnimationActive={false}
                      dataKey={'Očekávání $'}
                      stackId="a"
                      fill="#16407B"
                    />
                    <Bar isAnimationActive={false} dataKey="Očekávání" stackId="a" fill="#16407B90">
                      <LabelList fontSize={12} dataKey="planned_utilized_mds" position="top" />
                    </Bar>
                    <Bar
                      isAnimationActive={false}
                      dataKey={'Realita $'}
                      stackId="b"
                      fill="#F8BAA0"
                    />
                    <Bar isAnimationActive={false} dataKey="Realita" stackId="b" fill="#F8BAA090">
                      <LabelList fontSize={12} dataKey="real_utilized_mds" position="top" />
                    </Bar>
                  </BarChart>
                </GridItem>

                {/* ELUVIANOVI PROJEKTY */}
                {selectedEmployee && (
                  <GridItem xs={12} className={classes.overflowAuto}>
                    <BoxFullWidth pt={12}>
                      <BoxFullWidth pb={5}>
                        <GridContainer alignItems={'flex-start'} justifyContent={'center'}>
                          <span className={classes.employeeGraphName}>
                            {getEmployeeName(selectedEmployee)}
                          </span>
                          <span className={classes.employeeGraphEmail}>
                            {`- ${selectedEmployee.employee_email}`}
                          </span>
                        </GridContainer>
                      </BoxFullWidth>

                      <GridContainer alignItems={'flex-start'}>
                        <GridItem xs={12} container>
                          <GridItem xs={10} container alignItems={'center'}>
                            <div className={classes.legendLine}>
                              <div className={classes.expected} />
                              <div className={classes.expectedText}>
                                <Trans>Expectation</Trans>
                                {' $'}
                              </div>
                              <div className={classes.expectedBillable} />
                              <div className={classes.expectedText}>
                                <Trans>Expectation</Trans>
                              </div>
                              <div className={classes.reality} />
                              <div className={classes.realityText}>
                                <Trans>Reality</Trans>
                                {' $'}
                              </div>
                              <div className={classes.realityBillable} />
                              <div className={classes.realityText}>
                                <Trans>Reality</Trans>
                              </div>
                            </div>
                          </GridItem>
                        </GridItem>
                        <GridItem xs={12} className={classes.overflowAuto}>
                          <BarChart
                            width={getEmployeeBarChartWidth(selectedEmployee?.projects?.length)}
                            height={340}
                            data={getProjectData()}
                            margin={{
                              top: 20,
                              right: 0,
                              left: 0,
                              bottom: 10,
                            }}
                          >
                            <CartesianGrid stroke="#dddddd" strokeDasharray="4 4" />
                            <XAxis
                              interval={0}
                              dataKey="project_ref"
                              tickLine={false}
                              tick={{fontSize: 12}}
                              tickFormatter={formatXAxis}
                              height={60}
                            />
                            <YAxis unit={' MD'} tickLine={false} tick={{fontSize: 12}} />
                            {/* tooltip visible on hover above single bar */}
                            <Tooltip content={<CustomEmployeeTooltip />} />
                            <Bar
                              dataKey="Očekávání"
                              stackId="a"
                              fill="#16407B"
                              isAnimationActive={false}
                            >
                              {getProjectData().map((entry, index) => (
                                <Cell
                                  key={`cell-${index}`}
                                  fill={entry.project_is_billable ? '#16407B' : '#16407B90'}
                                />
                              ))}
                              <LabelList fontSize={12} dataKey="Očekávání" position="top" />
                            </Bar>
                            <Bar
                              dataKey="Realita"
                              stackId="b"
                              fill="#F8BAA0"
                              isAnimationActive={false}
                            >
                              {getProjectData().map((entry, index) => (
                                <Cell
                                  key={`cell-${index}`}
                                  fill={entry.project_is_billable ? '#F8BAA0' : '#F8BAA090'}
                                />
                              ))}
                              <LabelList fontSize={12} dataKey="Realita" position="top" />
                            </Bar>
                          </BarChart>
                        </GridItem>
                      </GridContainer>
                    </BoxFullWidth>
                  </GridItem>
                )}
              </>
            ) : (
              <GridItem
                container
                xs={12}
                alignContent={'center'}
                justifyContent={'center'}
                className={classes.noDataGridItem}
              >
                No data
              </GridItem>
            )}
          </GridContainer>
        )}
      </BoxFullWidth>
    </GridContainer>
  )
}

EmployeeGraphStrick.propTypes = {
  classes: PropTypes.object,
  loading: PropTypes.bool,
  data: PropTypes.array,
}

export default withStyles(stricklandStyle)(EmployeeGraphStrick)
