import React from 'react'
import {Field, Form} from 'react-final-form'
import PropTypes from 'prop-types'
import {dateFromToValidation, required} from 'helper/validations'
import {Trans} from '@lingui/macro'
import SelectInput from 'component/field/SelectInput'
import DatePickerInput from 'component/field/DatePickerInput'
import BoxFullWidth from 'component/material/BoxFullWidth'
import PrimaryButton from 'component/material/PrimaryButton'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import {LANGUAGE_OPTIONS, REPORT_VERSION} from 'helper/constants'
import AutocompleteInput from 'component/field/AutocompleteInput'

const VykazomatForm = (props) => {
  const {handleSubmit, reportInProgress, projectList} = props

  return (
    <Form onSubmit={handleSubmit} validate={dateFromToValidation}>
      {(formProps) => {
        return (
          <form onSubmit={formProps.handleSubmit}>
            <GridContainer spacing={4}>
              <GridItem xs={12}>
                <Field
                  name="name"
                  label={<Trans>Project to report</Trans>}
                  component={AutocompleteInput}
                  options={projectList}
                  validate={required}
                  disabled={reportInProgress}
                  loading={true}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <Field
                  name="date_from"
                  label={<Trans>Date from</Trans>}
                  component={DatePickerInput}
                  validate={required}
                  disabled={reportInProgress}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <Field
                  name="date_to"
                  label={<Trans>Date to</Trans>}
                  component={DatePickerInput}
                  validate={required}
                  disabled={reportInProgress}
                  minDate={formProps.values?.date_from}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <Field
                  name="language"
                  label={<Trans>Language</Trans>}
                  component={SelectInput}
                  options={LANGUAGE_OPTIONS}
                  defaultValue={'CZ'}
                  validate={required}
                  disabled={reportInProgress}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <Field
                  name="version"
                  label={<Trans>Version</Trans>}
                  component={SelectInput}
                  options={REPORT_VERSION}
                  defaultValue={'full'}
                  validate={required}
                  disabled={reportInProgress}
                />
              </GridItem>
            </GridContainer>

            <BoxFullWidth pt={3}>
              <PrimaryButton
                disabled={reportInProgress}
                type="submit"
                text={<Trans>Creating a time report</Trans>}
              />
            </BoxFullWidth>
          </form>
        )
      }}
    </Form>
  )
}

VykazomatForm.propTypes = {
  handleSubmit: PropTypes.func,
  reportInProgress: PropTypes.bool,
  projectList: PropTypes.array,
}

export default VykazomatForm
