import React from 'react'
import {TextField} from '@material-ui/core'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import fieldStyle from 'component/field/fieldStyle'
import cx from 'classnames'

const TextInput = (props) => {
  const {
    classes,
    input,
    meta,
    helperText,
    variant = 'outlined',
    showHelperText = true,
    onBlur,
    type,
    // custom input type of string with number styling
    inputType,
    customError,
    hideNumberArrows = true,
    onChange,
    size = 'small',
    ...rest
  } = props

  const errorMessage = customError || (meta && meta.touched && (meta.error || meta.submitError))
  const errorHelperText = showHelperText && (errorMessage || helperText || ' ')

  // hide number arrows for number input
  const inputClasses = cx({
    hideNumberArrows: hideNumberArrows,
  })

  return (
    <TextField
      {...input}
      type={type ? type : input?.type}
      className={cx(
        (input?.type === 'number' || type === 'number' || inputType === 'number') &&
          classes.inputJustifyRight,
        inputClasses,
        classes.inputFormHelperText,
        classes.inputFontSize
      )}
      variant={variant}
      fullWidth
      onChange={(e) => {
        input?.onChange(e) //final-form's onChange
        if (onChange) {
          //props.onChange
          onChange(e)
        }
      }}
      onBlur={() => {
        onBlur && onBlur(input?.value, input?.name)
        input?.onBlur()
      }}
      error={!!errorMessage}
      helperText={errorHelperText}
      size={size}
      {...rest}
    />
  )
}

TextInput.propTypes = {
  classes: PropTypes.object,
  type: PropTypes.string,
  input: PropTypes.object,
  meta: PropTypes.object,
  rest: PropTypes.object,
  variant: PropTypes.string,
  showHelperText: PropTypes.bool,
  helperText: PropTypes.string,
  customError: PropTypes.bool,
  onChange: PropTypes.func,
}

export default withStyles(fieldStyle)(TextInput)
