import React, {useState} from 'react'
import Typography from '@material-ui/core/Typography'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import {Trans} from '@lingui/macro'
import PaperFlexGrow from 'component/material/PaperFlexGrow'
import BoxFullWidth from 'component/material/BoxFullWidth'
import LoginButton from 'component/LoginPage/LoginButton'
import PropTypes from 'prop-types'
import {bindActionCreators, compose} from 'redux'
import {login} from 'redux/action/authAction'
import {connect} from 'react-redux'

const LoginPage = (props) => {
  const {login} = props

  const [loginLoading, setLoginLoading] = useState(false)

  const handleSubmit = () => {
    setLoginLoading(true)
    return login()
      .then((res) => {
        window.location.href = res.redirect_url
        return null
      })
      .finally(() => {
        setLoginLoading(true)
      })
  }

  return (
    <GridContainer direction="row" justifyContent="center" alignItems="center">
      <GridItem xs={'auto'}>
        <PaperFlexGrow elevation={5}>
          <BoxFullWidth p={14}>
            <BoxFullWidth pb={7}>
              <Typography variant="h4" color="primary" align="center" gutterBottom>
                <Trans>Eluvia business tools</Trans>
              </Typography>
            </BoxFullWidth>
            <BoxFullWidth pt={1}>
              <GridContainer direction="row" justifyContent="center" alignItems="center">
                <LoginButton onClick={handleSubmit} loading={loginLoading} />
              </GridContainer>
            </BoxFullWidth>
          </BoxFullWidth>
        </PaperFlexGrow>
      </GridItem>
    </GridContainer>
  )
}

LoginPage.propTypes = {
  login: PropTypes.func.isRequired,
  classes: PropTypes.object,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      login,
    },
    dispatch
  )
}

export default compose(connect(null, mapDispatchToProps))(LoginPage)
