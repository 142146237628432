import React from 'react'
import {Field, Form} from 'react-final-form'
import moment from 'moment'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import {Trans} from '@lingui/macro'
import DatePickerInput from 'component/field/DatePickerInput'
import SelectInput from 'component/field/SelectInput'
import {CURRENCY_LIST, DB_DATE_FORMAT} from 'helper/constants'
import TextInput from 'component/field/TextInput'
import NumberFormatCustom from 'component/field/NumberFormatCustom'
import PrimaryButton from 'component/material/PrimaryButton'
import {bindActionCreators, compose} from 'redux'
import {withStyles} from '@material-ui/core'
import projektomatPageStyle from 'component/ProjektomatPage/projektomatPageStyle'
import {connect} from 'react-redux'
import {getEmployeeWages, postEmployeeWage} from 'redux/action/projektomatAction'
import {required} from 'helper/validations'

const NewEmployeeWageForm = (props) => {
  const {classes, postEmployeeWage, employee, getEmployeeWages} = props

  const handleSubmit = (values, formProps) => {
    const data = {
      date_from: moment(values.date_from).format(DB_DATE_FORMAT),
      monthly_amount: Number(values.monthly_amount) * 100,
      currency: values.currency,
    }

    return postEmployeeWage(employee.id, data).then(() => {
      getEmployeeWages(employee.id)
      formProps.form.change('date_from', moment())
      formProps.form.change('currency', 'CZK')
      formProps.form.change('monthly_amount', null)
    })
  }

  return (
    <Form onSubmit={handleSubmit} initialValues={{date_from: moment(), currency: 'CZK'}}>
      {(formProps) => {
        return (
          <form onSubmit={formProps.handleSubmit}>
            <GridContainer spacing={2}>
              <GridItem xs={12} sm={4}>
                <Field
                  name="monthly_amount"
                  label={<Trans>Amount</Trans>}
                  component={TextInput}
                  type={'number'}
                  disabled={formProps.submitting}
                  autoComplete="off"
                  InputProps={{inputComponent: NumberFormatCustom}}
                  inputProps={{suffix: ''}}
                  validate={required}
                />
              </GridItem>
              <GridItem xs={12} sm={3}>
                <Field
                  name="currency"
                  label={<Trans>Currency</Trans>}
                  component={SelectInput}
                  options={CURRENCY_LIST}
                  disabled={formProps.submitting}
                  validate={required}
                />
              </GridItem>
              <GridItem container xs={12} sm={5}>
                <GridItem xs={true} className={classes.marginRight}>
                  <Field
                    name="date_from"
                    label={<Trans>Date from</Trans>}
                    component={DatePickerInput}
                    disabled={formProps.submitting}
                    validate={required}
                  />
                </GridItem>
                <GridItem xs={false}>
                  <PrimaryButton
                    fullWidth={true}
                    type="submit"
                    text={<Trans>Add</Trans>}
                    disabled={formProps.submitting}
                  />
                </GridItem>
              </GridItem>
            </GridContainer>
          </form>
        )
      }}
    </Form>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      postEmployeeWage,
      getEmployeeWages,
    },
    dispatch
  )
}

export default compose(
  withStyles(projektomatPageStyle),
  connect((store) => {
    return {}
  }, mapDispatchToProps)
)(NewEmployeeWageForm)
