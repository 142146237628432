import React, {useEffect} from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import PropTypes from 'prop-types'
import TableRow from 'component/material/table/TableRow'
import materialStyle from 'component/material/materialStyle'
import withStyles from '@material-ui/core/styles/withStyles'
import cx from 'classnames'
import BoxFullWidth from 'component/material/BoxFullWidth'
import Loader from 'component/material/Loader'
import CustomTooltip from 'component/material/Tooltip'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import GridContainer from 'component/material/GridContainer'
import GridItem from 'component/material/GridItem'
import TablePagination from '@material-ui/core/TablePagination'
import {bindActionCreators, compose} from 'redux'
import updateFilter from 'redux/action/filterAction'
import {connect} from 'react-redux'
import Checkbox from '@material-ui/core/Checkbox'
import {t, Trans} from '@lingui/macro'
import i18nProvider from 'helper/i18n'

const CustomTable = (props) => {
  const {
    columns,
    data,
    size = 'small',
    cellClassName,
    headCellClassName,
    whiteSpaceNowrap = true,
    classes,
    loading,
    page,
    onChangePage,
    onChangeRowsPerPage,
    rowsPerPage,
    rowsPerPageOptions,
    count,
    pagination,
    onSortClick,
    orderDirection,
    orderBy,
    lastRowBold = false,
    filterName,
    filters,
    updateFilter,
    // checkbox props
    displayCheckbox = false,
    handleSetTableSelectedRows,
    tableSelectedRows,
    handleSelectRow,
    checkBoxTitle,
    onTableRowClick,
    isOverflowAuto = true,
  } = props

  const rowClick = (row) => () => {
    onTableRowClick(row)
  }

  useEffect(() => {
    if (filters && filterName) {
      updateFilter({[filterName]: {...filters, orderDirection: orderDirection, orderBy: orderBy}})
    }
  }, [orderDirection, orderBy])

  return (
    <GridContainer className={classes.tableWrapper}>
      <GridItem xs={12} className={isOverflowAuto ? classes.overflowAuto : ''}>
        <Table>
          <TableHead>
            <TableRow>
              {displayCheckbox && (
                <TableCell
                  padding="none"
                  style={{width: '40px'}}
                  className={cx(classes.tableHeader)}
                >
                  {checkBoxTitle && checkBoxTitle}
                </TableCell>
              )}

              {columns?.map((col, index) => (
                <TableCell
                  key={`head-${index}`}
                  align={col.align ? col.align : 'left'}
                  size={size ? size : 'medium'}
                  style={{width: col.width ? col.width : 'auto'}}
                  className={cx(
                    whiteSpaceNowrap && classes.whiteSpaceNowrap,
                    classes.tableHeader,
                    headCellClassName
                  )}
                  sortDirection={orderDirection?.toLowerCase()}
                >
                  {onSortClick && col.orderKey ? (
                    <TableSortLabel
                      active={orderBy === col.orderKey}
                      direction={orderDirection?.toLowerCase()}
                      onClick={onSortClick(col)}
                    >
                      <CustomTooltip title="Sort" placement="bottom-start" enterDelay={300}>
                        {col.label}
                      </CustomTooltip>
                    </TableSortLabel>
                  ) : (
                    <span>{col.label}</span>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <tr>
                <td colSpan={columns.length}>
                  <BoxFullWidth py={4}>
                    <Loader wrapper={true} />
                  </BoxFullWidth>
                </td>
              </tr>
            ) : data?.length ? (
              data?.map((row, index) => (
                <TableRow key={`row-${index}`} className={onTableRowClick && classes.rowClickHover}>
                  {displayCheckbox && (
                    <TableCell
                      padding="none"
                      style={{width: '40px'}}
                      className={cx(classes.tableCellCheckboxTransition)}
                    >
                      {lastRowBold && data.length - 1 !== index && (
                        <Checkbox
                          disableRipple={true}
                          className={cx(classes.tableCellCheckboxClass)}
                          size={'small'}
                          onChange={handleSetTableSelectedRows && handleSelectRow(row)}
                          checked={tableSelectedRows?.includes(row.id)}
                        />
                      )}
                    </TableCell>
                  )}
                  {columns?.map((col, colIndex) => (
                    <TableCell
                      onClick={onTableRowClick && col.name !== 'action' ? rowClick(row) : null}
                      key={`cell-${colIndex}`}
                      align={col.align ? col.align : 'left'}
                      size={size ? size : 'medium'}
                      style={{width: col.width ? col.width : 'auto'}}
                      className={cx(
                        classes.bodyCellClass,
                        col.className,
                        cellClassName,
                        whiteSpaceNowrap && classes.whiteSpaceNowrap,
                        onTableRowClick && classes.tableRowCursorPointer,
                        lastRowBold && data.length - 1 === index && classes.boldRow
                      )}
                    >
                      {col.render ? col.render(row[col.name], col, index, row) : row[col.name]}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={Number(columns.length)}>
                  <BoxFullWidth py={4} className={classes.tableNoData}>
                    <Trans>No data</Trans>
                  </BoxFullWidth>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </GridItem>
      {pagination && (
        <GridItem xs={12}>
          <TablePagination
            component="div"
            count={count || 0}
            page={page}
            onPageChange={onChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={onChangeRowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
            backIconButtonText={i18nProvider._(t`Předchozí stránka`)}
            nextIconButtonText={i18nProvider._(t`Další stránka`)}
            labelRowsPerPage={i18nProvider._(t`Řádků na stránce`)}
            labelDisplayedRows={({from, to, count}) => `${from}-${to} z ${count}`}
          />
        </GridItem>
      )}
    </GridContainer>
  )
}

CustomTable.propTypes = {
  classes: PropTypes.object,
  columns: PropTypes.array,
  data: PropTypes.array,
  size: PropTypes.string,
  cellClassName: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  loading: PropTypes.bool,
  onSortClick: PropTypes.func,
  orderBy: PropTypes.string,
  orderDirection: PropTypes.string,
  pagination: PropTypes.bool,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  rowsPerPageOptions: PropTypes.array,
  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
  updateFilter: PropTypes.func,
  lastRowBold: PropTypes.bool,
  displayCheckbox: PropTypes.bool,
  handleSetTableSelectedRows: PropTypes.func,
  tableSelectedRows: PropTypes.array,
  checkBoxTitle: PropTypes.node,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({updateFilter}, dispatch)
}

export default compose(
  withStyles(materialStyle),
  connect((store) => {
    return {
      rowsPerPageOptions: store.globalSetting.rowsPerPageOptions,
      rowsPerPage: store.globalSetting.rowsPerPage,
    }
  }, mapDispatchToProps)
)(CustomTable)
