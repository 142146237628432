import React, {useState} from 'react'
import GridItem from 'component/material/GridItem'
import GridContainer from 'component/material/GridContainer'
import {bindActionCreators, compose} from 'redux'
import {withStyles} from '@material-ui/core'
import odhadyStyle from 'component/OdhadomatPage/odhadyStyle'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'
import cx from 'classnames'
import {Trans} from '@lingui/macro'
import {
  deleteSchedulePhases,
  getEstimationDetail,
  getSchedulePhases,
  patchSchedulePhaseMonth,
  patchSchedulePhases,
} from 'redux/action/odhadomatAction'
import {useParams} from 'react-router-dom'
import DialogWindow from 'component/material/DialogWindow'
import PhaseForm from 'component/OdhadomatPage/Harmonogram/PhaseForm'
import {mapErrorResponseToForm} from 'helper/functions'
import PhasesTable from 'component/OdhadomatPage/Harmonogram/PhasesTable'
import {useWarningDialog} from 'hooks/useWarningDialog'

const ScheduleTable = (props) => {
  const {
    classes,
    phases,
    phasesLoading,
    schedule,
    deleteSchedulePhases,
    getSchedulePhases,
    patchSchedulePhases,
    estimation,
    fetchSchedulePhases,
    patchSchedulePhaseMonth,
    getEstimationDetail,
  } = props

  const {id} = useParams()

  const [DeleteWarningDialog, deleteData, onOpenDelete, onCloseDelete] = useWarningDialog()
  const [openPhase, setOpenPhase] = useState({visible: false, row: null})
  const [editableIndex, setEditableIndex] = useState({phase: null, layer: null, month: null})

  const handleInlineEdit = (phaseIndex, layerIndex, monthIndex) => () => {
    setEditableIndex({phase: phaseIndex, layer: layerIndex, month: monthIndex})
  }

  const handleDeletePhase = () => {
    return deleteSchedulePhases(id, deleteData?.id).then(() => {
      onCloseDelete()
      getSchedulePhases(id)
    })
  }

  const handleOpenEditPhase = (phase) => {
    setOpenPhase({visible: true, row: phase})
  }
  const handleCloseEditPhase = () => {
    setOpenPhase({visible: false, row: null})
  }

  const prepareData = (data) => {
    return {
      title: data.title,
      story_categories: estimation?.all_story_categories?.filter((cat, index) =>
        data.story_categories.includes(index)
      ),
    }
  }

  const handleSubmitPhase = (values) => {
    const data = prepareData(values)
    return patchSchedulePhases(id, openPhase?.row?.id, data)
      .then(() => {
        getEstimationDetail(id)
        fetchSchedulePhases()
        handleCloseEditPhase()
      })
      .catch((err) => mapErrorResponseToForm(err))
  }

  const getPhaseFormInitialValues = (row) => {
    return {
      title: row?.title,
      story_categories: row?.story_categories?.map((cat) =>
        estimation?.all_story_categories?.findIndex((allStoryCat) => cat === allStoryCat)
      ),
    }
  }

  // PATCH SCHEDULE PHASE
  const handleSubmitMonth = (e, phase, month) => {
    const value = e.target.value
    if (e.charCode === 13) {
      return patchSchedulePhaseMonth(id, phase.id, month.id, {scheduled_mms: value}).then((res) => {
        fetchSchedulePhases().then(() => {
          setEditableIndex({phase: null, layer: null, month: null})
        })
      })
    }
  }

  const reduceValueFromAllPhases = (attribute) => {
    if (!phasesLoading) {
      const value = phases.reduce((prev, cur) => {
        return (
          prev +
            cur.layers?.reduce((prev, cur) => {
              return prev + cur?.[attribute] || 0
            }, 0) || 0
        )
      }, 0)
      return Number(value).toFixed(2)
    }
  }

  const reduceMonthValueFromAllPhases = (index) => {
    if (!phasesLoading) {
      const value = phases.reduce((prev, cur) => {
        return (
          prev +
            cur.layers?.reduce((prev, cur) => {
              return prev + cur?.months?.[index]?.scheduled_mms || 0
            }, 0) || 0
        )
      }, 0)
      return Number(value).toFixed(2)
    }
  }

  return (
    <div className={classes.scheduleTable}>
      {/* EDIT PHASE DIALOG */}
      <DialogWindow
        onClose={handleCloseEditPhase}
        open={openPhase.visible}
        title={<Trans>Edit phase</Trans>}
        maxWidth={'sm'}
        fullWidth={true}
      >
        <PhaseForm
          handleSubmit={handleSubmitPhase}
          initValues={openPhase.row && getPhaseFormInitialValues(openPhase.row)}
        />
      </DialogWindow>

      {/* DELETE DIALOG */}
      <DeleteWarningDialog
        onConfirm={handleDeletePhase}
        message={<Trans>Phase {deleteData?.title} will be deleted!</Trans>}
      />

      <div className={classes.phasesTable}>
        <div className={classes.phaseTableHeader}>
          {/* FIRST ROW */}
          <GridContainer direction={'row'} wrap={'nowrap'} className={classes.boldText}>
            <GridItem className={cx(classes.scheduleCell, classes.scheduleCellTopRow)}>
              {/*  */}
            </GridItem>
            <GridItem
              className={cx(
                classes.scheduleCell,
                classes.smallScheduleCell,
                classes.scheduleCellTopRow
              )}
            >
              {/*  */}
            </GridItem>
            <GridItem className={cx(classes.scheduleCell, classes.scheduleCellTopRow)}>
              {/*  */}
            </GridItem>
            <GridItem className={cx(classes.scheduleCell, classes.scheduleCellTopRow)}>
              {/*  */}
            </GridItem>
            {schedule?.months?.map((month, index) => (
              <GridItem
                className={cx(
                  classes.scheduleCell,
                  classes.scheduleCellTopRow,
                  classes.monthTextCapitalized
                )}
                key={index}
              >
                {month.month &&
                  schedule.start_date &&
                  moment(schedule.start_date)
                    .add(month.month - 1, 'months')
                    .format('MMMM')}
              </GridItem>
            ))}
          </GridContainer>

          {/* SECOND ROW */}
          <GridContainer direction={'row'} wrap={'nowrap'} className={classes.boldText}>
            <GridItem className={cx(classes.scheduleCell, classes.borderBottom)}>
              <Trans>Month</Trans>
            </GridItem>
            <GridItem
              className={cx(classes.scheduleCell, classes.smallScheduleCell, classes.borderBottom)}
            >
              {/*  */}
            </GridItem>
            <GridItem className={cx(classes.scheduleCell, classes.borderBottom)}>
              <Trans>Exp (M)</Trans>
            </GridItem>
            <GridItem className={cx(classes.scheduleCell, classes.borderBottom)}>
              <Trans>Real (M)</Trans>
            </GridItem>
            {schedule?.months?.map((month, index) => (
              <GridItem className={cx(classes.scheduleCell, classes.borderBottom)} key={index}>
                {month.month}
              </GridItem>
            ))}
          </GridContainer>

          {/* THIRD EMPTY ROW */}
          <GridContainer direction={'row'} wrap={'nowrap'} className={classes.borderBottomBlack}>
            <GridItem className={classes.scheduleCell}>{/*  */}</GridItem>
            <GridItem className={cx(classes.scheduleCell, classes.smallScheduleCell)}>
              {/*  */}
            </GridItem>
            <GridItem className={classes.scheduleCell}>{/*  */}</GridItem>
            <GridItem className={classes.scheduleCell}>{/*  */}</GridItem>
            {schedule?.months?.map((month, index) => (
              <GridItem className={classes.scheduleCell} key={index}>
                {/*  */}
              </GridItem>
            ))}
          </GridContainer>
        </div>

        {/* PHASES TABLE */}
        <PhasesTable
          phases={phases}
          handleOpenEditPhase={handleOpenEditPhase}
          handleOpenDeletePhase={onOpenDelete}
          editableIndex={editableIndex}
          setEditableIndex={setEditableIndex}
          handleInlineEdit={handleInlineEdit}
          handleSubmitMonth={handleSubmitMonth}
        />
        {/* PRE-LAST EMPTY ROW */}
        <GridContainer direction={'row'} wrap={'nowrap'} className={classes.borderBottomBlack}>
          <GridItem className={classes.scheduleCell}>{/*  */}</GridItem>
          <GridItem className={cx(classes.scheduleCell, classes.smallScheduleCell)}>
            {/*  */}
          </GridItem>
          <GridItem className={classes.scheduleCell}>{/*  */}</GridItem>
          <GridItem className={classes.scheduleCell}>{/*  */}</GridItem>
          {schedule?.months?.map((month, index) => (
            <GridItem className={classes.scheduleCell} key={index}>
              {/*  */}
            </GridItem>
          ))}
        </GridContainer>

        <GridContainer direction={'row'} wrap={'nowrap'} className={classes.scheduleSummary}>
          <GridItem className={classes.scheduleSummaryCell}>
            <Trans>Utilization</Trans>
          </GridItem>
          <GridItem className={cx(classes.scheduleSummaryCell, classes.smallScheduleCell)}>
            {/*  */}
          </GridItem>
          <GridItem className={cx(classes.scheduleSummaryCell, classes.textEnd)}>
            {reduceValueFromAllPhases('estimated_mms')}
          </GridItem>
          <GridItem className={cx(classes.scheduleSummaryCell, classes.textEnd)}>
            {reduceValueFromAllPhases('scheduled_mms')}
          </GridItem>
          {schedule?.months?.map((month, index) => (
            <GridItem className={cx(classes.scheduleSummaryCell, classes.textEnd)} key={index}>
              {reduceMonthValueFromAllPhases(index)}
            </GridItem>
          ))}
        </GridContainer>
      </div>
    </div>
  )
}

ScheduleTable.propTypes = {
  classes: PropTypes.object,
  phases: PropTypes.array,
  phasesLoading: PropTypes.bool,
  schedule: PropTypes.object,
  deleteSchedulePhases: PropTypes.func,
  getSchedulePhases: PropTypes.func,
  patchSchedulePhases: PropTypes.func,
  estimation: PropTypes.object,
  fetchSchedulePhases: PropTypes.func,
  patchSchedulePhaseMonth: PropTypes.func,
  getEstimationDetail: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      deleteSchedulePhases,
      getSchedulePhases,
      patchSchedulePhases,
      patchSchedulePhaseMonth,
      getEstimationDetail,
    },
    dispatch
  )
}

export default compose(
  withStyles(odhadyStyle),
  connect((store) => {
    return {
      phases: store.odhadomat.phases,
      schedule: store.odhadomat.schedule,
      phasesLoading: store.odhadomat.phasesLoading,
      estimation: store.odhadomat.estimation,
    }
  }, mapDispatchToProps)
)(ScheduleTable)
