import {
  GET_STRICKLAND_EMPLOYEES_REQUEST,
  GET_STRICKLAND_EMPLOYEES_SUCCESS,
  GET_STRICKLAND_EMPLOYEES_FAIL,
  GET_STRICKLAND_PROJECTS_REQUEST,
  GET_STRICKLAND_PROJECTS_SUCCESS,
  GET_STRICKLAND_PROJECTS_FAIL,
  GET_STRICKLAND_PROJECTS_POTENTIAL_REQUEST,
  GET_STRICKLAND_PROJECTS_POTENTIAL_SUCCESS,
  GET_STRICKLAND_PROJECTS_POTENTIAL_FAIL,
} from 'redux/actionType'
import httpClient from 'helper/httpClient'
import endpoints from 'helper/endpoints'
import {globalApiErrorHandler} from 'helper/functions'

export const getEmployeesReportStrickland = (periodId) => {
  return (dispatch) => {
    dispatch({type: GET_STRICKLAND_EMPLOYEES_REQUEST})
    return httpClient
      .get(endpoints.employeesStrick, {
        limit: 1000,
        offset: 0,
        order_by: 'employee_email:asc',
        period_id: periodId || null,
      })
      .then((res) => {
        dispatch({type: GET_STRICKLAND_EMPLOYEES_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_STRICKLAND_EMPLOYEES_FAIL})
        globalApiErrorHandler(err)
      })
  }
}

export const getProjectsReportStrickland = (periodId) => {
  return (dispatch) => {
    dispatch({type: GET_STRICKLAND_PROJECTS_REQUEST})
    return httpClient
      .get(endpoints.projectsStrick, {
        limit: 1000,
        offset: 0,
        order_by: 'project_ref:asc',
        period_id: periodId || null,
      })
      .then((res) => {
        dispatch({type: GET_STRICKLAND_PROJECTS_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_STRICKLAND_PROJECTS_FAIL})
        globalApiErrorHandler(err)
      })
  }
}

export const getProjectsPotentialStrickland = (periodId) => {
  return (dispatch) => {
    dispatch({type: GET_STRICKLAND_PROJECTS_POTENTIAL_REQUEST})
    return httpClient
      .get(endpoints.potentialStrick, {
        limit: 1000,
        offset: 0,
        order_by: 'project_ref:asc',
        period_id: periodId || null,
      })
      .then((res) => {
        dispatch({type: GET_STRICKLAND_PROJECTS_POTENTIAL_SUCCESS, data: res.data})
        return Promise.resolve(res.data)
      })
      .catch((err) => {
        dispatch({type: GET_STRICKLAND_PROJECTS_POTENTIAL_FAIL})
        globalApiErrorHandler(err)
      })
  }
}
